import { Dispatch } from 'redux';
import { Weddings } from '@bridebook/models';
import { ICost } from '@bridebook/models/source/models/Weddings/Costs.types';
import { getDocsFromCacheOrServer } from 'lib/auth/utils/get-docs-from-cache-or-server';
import { onFirebaseBudget } from 'lib/budget/actions/on-listener';
import { mapCleanTimestamps } from 'lib/utils';

export const fetchBudgetData = async (dispatch: Dispatch<any>, weddingId: string) => {
  const weddingCostsRef = Weddings._.getById(weddingId).Costs.reference;
  const weddingCostsSnapshot = await getDocsFromCacheOrServer(weddingCostsRef);
  const weddingCostsData: Record<string, ICost> = {};
  weddingCostsSnapshot.docs.forEach((doc) => {
    weddingCostsData[doc.id] = doc.data();
  });

  dispatch(onFirebaseBudget(mapCleanTimestamps(weddingCostsData)));
};
