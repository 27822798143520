import React from 'react';
import { connect } from 'react-redux';
import DrawerMenu from 'components/app/drawer-menu';
import MobileSearchControls from 'components/search/mobile-search-controls/mobile-search-controls';
import { getIsMobile } from 'lib/app/selectors';
import { usePageSettings } from 'lib/page-settings-context';
import { ConnectedTypes, IApplicationState, IUrl } from 'lib/types';
import HeaderContent from './header-content';
import componentStyles from './protected-header.style';

const storeEnhancer = connect((state: IApplicationState) => ({
  isMobile: getIsMobile(state),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  location: IUrl;
}

const ProtectedHeader = ({ location, isMobile }: IProps) => {
  const { isSearchPage, isNewSearchBar } = usePageSettings();

  const showMobileSearchBar = isMobile && isSearchPage;
  const style = componentStyles(showMobileSearchBar, isNewSearchBar);

  return (
    <HeaderContent style={style.wrapper}>
      <DrawerMenu alwaysMounted={false} />
      {showMobileSearchBar && <MobileSearchControls location={location} />}
    </HeaderContent>
  );
};

export default storeEnhancer(ProtectedHeader);
