import {
  ACCOMMODATION_ROOMS_MAX,
  ACCOMMODATION_ROOMS_MIN,
} from '@bridebook/toolbox/src/search-suppliers/elastic-queries/filters/constants';
import { IItem } from 'components/bbcommon/option-list/option-list';
import { getI18n } from 'lib/i18n/getI18n';

export interface INumberOfBedroomsOption extends IItem {
  key: string;
  primaryText: string;
  minValue: number;
  maxValue?: number;
}

export const getNumberOfBedroomsFilterOptions = (
  includeKeys?: string[],
): INumberOfBedroomsOption[] => {
  const i18n = getI18n();

  return [
    {
      key: 'upTo10',
      primaryText: i18n.t('filters:noOfBedroomsFilter.upTo10'),
      minValue: ACCOMMODATION_ROOMS_MIN,
      maxValue: 10,
    },
    {
      key: 'rooms10',
      primaryText: i18n.t('filters:noOfBedroomsFilter.10'),
      minValue: 10,
    },
    {
      key: 'rooms20',
      primaryText: i18n.t('filters:noOfBedroomsFilter.20'),
      minValue: 20,
    },
    {
      key: 'rooms30',
      primaryText: i18n.t('filters:noOfBedroomsFilter.30'),
      minValue: 30,
    },
    {
      key: 'rooms50',
      primaryText: i18n.t('filters:noOfBedroomsFilter.50'),
      minValue: 50,
      maxValue: ACCOMMODATION_ROOMS_MAX,
    },
  ].filter((option) => (includeKeys ? includeKeys.includes(option.key) : true));
};
