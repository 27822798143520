import { hideSplashScreen, registerTalkJsDevice } from 'lib/mobile-app/actions';

export enum MobileAppActionTypes {
  WHICH_HANDHELD_DEVICE_TYPE = '[Mobile App] Detect type of mobile platform',
  HIDE_SPLASH_SCREEN = '[Mobile App] Hide splash screen',
  REGISTER_TALKJS_DEVICE = '[Mobile App] Register TalkJS device',
  REGISTER_TALKJS_DEVICE_SUCCESS = '[Mobile App] Register TalkJS device success',
  MOBILE_NATIVE_KEYBOARD_TOGGLE = '[Mobile App] Toggle app keyboard',
  REGISTER_PUSH_NOTIFICATIONS = '[Mobile App] Register for receiving push notifications',
  REQUEST_PUSH_NOTIFICATION_PERMISSIONS = '[Mobile App] Request permissions for receiving push notifications',
  PUSH_PRE_PROMPT_SHOWN = '[Mobile App] Toggle flag for the push pre-prompt was shown',
  SET_PLATFORM_INFO = '[Mobile App] Set information about the currently used platform',
  APP_URL_OPEN_LISTENER_REGISTERED = '[Mobile App] App URL open listener registered',
  APP_OPENED_FROM_URL = '[Mobile App] App opened from URL',
}

export type RegisterTalkJsDeviceAction = ReturnType<typeof registerTalkJsDevice>;

export type HideSplashScreenAction = ReturnType<typeof hideSplashScreen>;
