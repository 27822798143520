import React from 'react';
import { Box } from '@bridebook/ui';
import { IconMagGlass, IconSearch } from '@bridebook/ui/src/icons/dynamic';
import componentStyles, { componentSize } from './cta-search-button.style';

interface IProps {
  text: string;
  onClick(): void;
  onSearchClick?: React.MouseEventHandler<React.ReactNode>;
  size?: componentSize;
  withIconButtonStyle?: boolean;
}

const CTASearchButton = ({
  text,
  onClick,
  onSearchClick,
  size = 'default',
  withIconButtonStyle,
}: IProps) => {
  const styles = componentStyles({ size, withIconButtonStyle });
  return (
    <Box style={styles.fakeInput} onClick={onClick}>
      <Box onClick={onSearchClick} style={styles.buttonStyle}>
        {withIconButtonStyle ? (
          <IconMagGlass width={16} color="white" />
        ) : (
          <IconSearch width={16} color="space75" />
        )}
      </Box>
      <div style={styles.fakeInputText}>{text}</div>
    </Box>
  );
};

export default CTASearchButton;
