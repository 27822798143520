import React from 'react';

const photographerBookedLocked = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 144 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M135.138 37.631L135.144 37.6348L135.151 37.6386C138.2 39.3193 140 42.4019 140 45.666V110.361C140 113.625 138.2 116.708 135.151 118.389L135.145 118.392L135.139 118.395L76.9866 150.723C76.9848 150.724 76.983 150.725 76.9812 150.726C73.894 152.425 70.1051 152.425 67.0181 150.725C67.0165 150.724 67.015 150.723 67.0134 150.723L8.86115 118.395L8.85494 118.392L8.84873 118.389C5.8 116.708 4 113.625 4 110.361V45.666C4 42.402 5.8 39.3193 8.84873 37.6386L8.85557 37.6348L8.8624 37.631L67.0134 5.27742C67.0155 5.27628 67.0176 5.27514 67.0196 5.274C70.1059 3.57546 73.8933 3.57533 76.9797 5.27362C76.982 5.27488 76.9843 5.27615 76.9866 5.27742L135.138 37.631Z"
      fill="#EDEFF0"
      stroke="#DDE0E3"
      strokeWidth="8"
    />
    <g clipPath="url(#clip0_12666_15225)">
      <path
        d="M84.2117 70.5011L81.6505 63.0751C81.4304 62.4363 81.0078 61.8808 80.4427 61.4871C79.8776 61.0935 79.1986 60.8817 78.5021 60.8819H66.6979C66.0013 60.8817 65.3223 61.0935 64.7572 61.4871C64.1921 61.8808 63.7695 62.4363 63.5494 63.0751L60.9882 70.5011H51.0353C49.2755 70.5011 47.5878 71.1768 46.3434 72.3794C45.099 73.5821 44.4 75.2132 44.4 76.914V99.3589C44.4 101.06 45.099 102.691 46.3434 103.893C47.5878 105.096 49.2755 105.772 51.0353 105.772H94.1647C95.9245 105.772 97.6122 105.096 98.8565 103.893C100.101 102.691 100.8 101.06 100.8 99.3589V76.914C100.8 75.2132 100.101 73.5821 98.8565 72.3794C97.6122 71.1768 95.9245 70.5011 94.1647 70.5011H84.2117Z"
        fill="#F6F7F8"
        stroke="#BABFC5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.8527 65.4859L51.8157 64.8066C53.0727 64.7249 54.2849 64.3107 55.3265 63.6068C56.3681 62.9029 57.2012 61.935 57.7395 60.8035C58.2778 59.6719 58.5017 58.4179 58.3879 57.1715C58.2741 55.925 57.8267 54.7315 57.0923 53.7147L53.0977 48.5476L59.0516 52.0227C60.1595 52.7089 61.41 53.1359 62.7084 53.2711C64.0069 53.4064 65.3193 53.2466 66.5464 52.8036C67.7735 52.3607 68.8831 51.6462 69.7914 50.7144C70.6996 49.7826 71.3826 48.6577 71.7888 47.4249L73.1752 41.3145L74.5616 47.4249C74.9688 48.6569 75.6526 49.7808 76.5613 50.7115C77.47 51.6423 78.5797 52.3557 79.8067 52.7977C81.0337 53.2397 82.3457 53.3988 83.6437 53.2629C84.9416 53.127 86.1915 52.6997 87.2988 52.0133L93.2527 48.5382L89.2581 53.7147C88.527 54.7314 88.0824 55.9237 87.9704 57.1683C87.8583 58.413 88.0829 59.6647 88.6208 60.7942C89.1588 61.9238 89.9906 62.8901 91.0302 63.5932C92.0699 64.2964 93.2797 64.7109 94.5347 64.794L98.4976 65.4859"
        stroke="#BABFC5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.5826 101.444C80.5191 101.444 86.9528 95.1985 86.9528 87.4938C86.9528 79.789 80.5191 73.5431 72.5826 73.5431C64.6461 73.5431 58.2123 79.789 58.2123 87.4938C58.2123 95.1985 64.6461 101.444 72.5826 101.444Z"
        fill="#F6F7F8"
      />
      <path
        d="M66.8045 86.0175C66.8045 85.2712 67.1099 84.5556 67.6534 84.0279C68.1969 83.5002 68.9341 83.2038 69.7028 83.2038C70.4715 83.2038 71.2087 83.5002 71.7522 84.0279C72.2958 84.5556 72.6011 85.2712 72.6011 86.0175C72.6011 85.2712 72.9065 84.5556 73.45 84.0279C73.9935 83.5002 74.7307 83.2038 75.4994 83.2038C76.2681 83.2038 77.0053 83.5002 77.5488 84.0279C78.0923 84.5556 78.3977 85.2712 78.3977 86.0175C78.3977 88.6152 74.0975 91.8172 72.6011 91.8172C71.1047 91.8172 66.8045 88.6604 66.8045 86.0175Z"
        fill="#DDE0E3"
      />
      <path
        d="M72.601 73.5431C69.7554 73.5429 66.9735 74.3619 64.6074 75.8966C62.2412 77.4313 60.3969 79.6127 59.3078 82.1649C58.2187 84.7172 57.9336 87.5257 58.4887 90.2352C59.0437 92.9447 60.414 95.4336 62.4261 97.3871C64.4382 99.3406 67.0019 100.671 69.7928 101.21C72.5838 101.749 75.4768 101.472 78.1058 100.415C80.7349 99.3582 82.982 97.5679 84.563 95.2709C86.144 92.9739 86.9878 90.2734 86.9878 87.5108C86.9835 83.8077 85.4664 80.2576 82.7693 77.6391C80.0722 75.0205 76.4154 73.5475 72.601 73.5431ZM72.601 99.3693C70.1851 99.3693 67.8234 98.6738 65.8146 97.3708C63.8059 96.0677 62.2402 94.2157 61.3157 92.0488C60.3912 89.882 60.1493 87.4976 60.6206 85.1973C61.0919 82.897 62.2553 80.784 63.9636 79.1255C65.6719 77.4671 67.8485 76.3377 70.218 75.8801C72.5875 75.4226 75.0435 75.6574 77.2756 76.5549C79.5076 77.4525 81.4153 78.9724 82.7576 80.9225C84.0998 82.8727 84.8162 85.1654 84.8162 87.5108C84.8122 90.6546 83.5239 93.6685 81.234 95.8915C78.944 98.1145 75.8394 99.3655 72.601 99.3693Z"
        fill="#BABFC5"
      />
      <path
        d="M75.4998 82.1494C74.9566 82.149 74.4191 82.2569 73.9205 82.4662C73.422 82.6755 72.9729 82.9818 72.6011 83.3662C72.0541 82.802 71.3452 82.4104 70.5661 82.2421C69.7871 82.0738 68.9739 82.1366 68.2321 82.4224C67.4903 82.7082 66.8541 83.2038 66.4059 83.8449C65.9578 84.486 65.7184 85.2431 65.7189 86.0179C65.7189 89.2873 70.55 92.8725 72.6011 92.8725C74.6522 92.8725 79.4833 89.2873 79.4833 86.0179C79.4822 84.9924 79.0622 84.0093 78.3154 83.2841C77.5686 82.5589 76.556 82.1508 75.4998 82.1494ZM72.603 90.7629C71.6237 90.7466 67.8933 88.0157 67.8933 86.0175C67.8933 85.5508 68.0843 85.1031 68.4243 84.7731C68.7643 84.443 69.2254 84.2576 69.7062 84.2576C70.187 84.2576 70.6481 84.443 70.988 84.7731C71.328 85.1031 71.519 85.5508 71.519 86.0175C71.519 86.156 71.5471 86.293 71.6017 86.4209C71.6562 86.5488 71.7362 86.665 71.837 86.7629C71.9379 86.8608 72.0576 86.9384 72.1893 86.9914C72.321 87.0444 72.4622 87.0717 72.6048 87.0717C72.7474 87.0717 72.8886 87.0444 73.0204 86.9914C73.1521 86.9384 73.2718 86.8608 73.3726 86.7629C73.4735 86.665 73.5535 86.5488 73.608 86.4209C73.6626 86.293 73.6907 86.156 73.6907 86.0175C73.6907 85.5508 73.8817 85.1031 74.2216 84.7731C74.5616 84.443 75.0227 84.2576 75.5035 84.2576C75.9843 84.2576 76.4454 84.443 76.7854 84.7731C77.1253 85.1031 77.3163 85.5508 77.3163 86.0175C77.3126 88.0051 73.5804 90.7415 72.6011 90.7629H72.603Z"
        fill="#BABFC5"
      />
    </g>
    <defs>
      <clipPath id="clip0_12666_15225">
        <rect width="124.8" height="67.2" fill="white" transform="translate(9.59996 39.6)" />
      </clipPath>
    </defs>
  </svg>
);

export default photographerBookedLocked;
