import { isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { ValidationError } from '@bridebook/toolbox/src';

/**
 * Used in form elements such as Input, Dropdown, TextField
 * to highlight the field if a form error is related to it
 *
 * @param error
 * @param errorText
 * @param name
 */
export const useInputErrorState = <E extends Error>(
  error: boolean | E | null,
  errorText: string = '',
  name: string = '',
) => {
  const [hasError, setHasError] = useState(Boolean(error));
  const [errorMessage, setErrorMessage] = useState(
    error && typeof error === 'object' ? error.message : errorText,
  );

  useEffect(() => {
    if (
      (error instanceof ValidationError && error.prop === name) ||
      (typeof error === 'boolean' && error !== hasError) ||
      (hasError && isNil(error))
    ) {
      const errorMessage = error && typeof error === 'object' ? error.message : errorText;
      const hasError = typeof error === 'boolean' ? error : !isNil(error);

      setErrorMessage(errorMessage);
      setHasError(hasError);
    } else if (hasError && error instanceof ValidationError && error.prop !== name) {
      /**
       * Since we are usually using a single state variable for tracking all
       * form field errors, we have to reset the error state if the current
       * error was not meant for this field, but was previously set.
       */
      resetError();
    }
  }, [error, errorText, hasError, name]);

  const resetError = () => {
    setHasError(false);
    setErrorMessage('');
  };

  return { hasError, errorMessage, resetError };
};
