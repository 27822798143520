import { getIsQuizOpen } from 'lib/quiz/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const CakesQuizLazy = lazyComponent(() => import('./cakes-quiz'));

const CakesQuiz = () => {
  const isQuizOpen = useSelector(getIsQuizOpen('cake'));

  if (!isQuizOpen) {
    return null;
  }

  return <CakesQuizLazy />;
};

export default CakesQuiz;
