import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const ADD_COLLABORATOR_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '2fb2af80-e66c-4ddd-be43-32ac12177962',
  storage: 'wedding',
  name: 'Add collaborator task',
  type: 'interaction',
  isTaskCompleted: ({ getState }) => {
    const state = getState();
    return isTodayTaskCompleted(state, ADD_COLLABORATOR_TASK_DEFINITION);
  },
};

registerTodayTask(ADD_COLLABORATOR_TASK_DEFINITION);
