import { imgixBaseURL } from 'lib/utils';

export * from './onboarding-journey-type';
export * from '../hooks/use-get-onboarding-journey-type';
export * from './split-venue-must-haves';
export * from './reduce-filters-array';
export * from './get-supplier-suggestion-filters';
export * from './map-search-filters-to-section';
export * from './get-supplier-suggestions-pills';

export const onboardingImages = `${imgixBaseURL}/assets/onboarding`;
