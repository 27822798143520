import msg, { MsgKey } from 'lib/supplier/msg';

// get filter section filter data field name
const getSectionTitle = (sectionName: string): string => {
  const title = msg[`${sectionName}Filter` as MsgKey]?.();

  return title === undefined ? msg[sectionName as MsgKey]?.() : title;
};

export default getSectionTitle;
