import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { MenuOptionsObjectTypes } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

export const getMenu = (): MenuOptionsObjectTypes => {
  const i18n = getI18n();
  return {
    home: {
      id: 'home',
      title: i18n.t('common:menu.home'),
      url: '/home',
      type: 'home',
      icon: 'icon-home',
      color: 'rose',
      staticIndex: 0,
    },
    search: {
      id: 'search',
      title: i18n.t('common:menu.venueSupplierSearch'),
      type: 'search',
      icon: 'icon-mag-glass',
      color: 'blueCrush',
      bannerIcon: 'bannerIcon_search',
      staticIndex: 0,
    },
    guestlist: {
      id: 'guestlist',
      title: i18n.t('common:menu.guestlist'),
      url: '/guestlist',
      type: 'tool',
      icon: 'icon-capacity',
      color: 'yellow',
      bannerIcon: 'bannerIcon_guestlist',
      staticIndex: 1,
      staticIndexI18n: 5,
    },
    budget: {
      id: 'budget',
      title: i18n.t('common:menu.budget'),
      url: '/budget',
      type: 'tool',
      icon: 'icon-budget',
      color: 'amber',
      bannerIcon: 'bannerIcon_budget',
      staticIndex: 2,
      staticIndexI18n: 2,
    },
    checklist: {
      id: 'checklist',
      title: i18n.t('common:menu.checklist'),
      url: '/checklist',
      type: 'tool',
      icon: 'icon-checked',
      color: 'mint',
      bannerIcon: 'bannerIcon_checklist',
      staticIndex: 3,
      staticIndexI18n: 1,
    },
    shortlist: {
      id: 'shortlist',
      title: i18n.t('common:menu.shortlist'),
      url: '/shortlist',
      type: 'tool',
      icon: 'icon-heart-unfilled',
      color: 'blueCrush',
      bannerIcon: 'bannerIcon_shortlist',
      staticIndex: 4,
      staticIndexI18n: 3,
    },
    shortlistBooked: {
      id: 'booked-suppliers',
      title: i18n.t('common:menu.bookedSuppliers'),
      url: '/shortlist',
      type: 'tool-subdivision',
      icon: 'icon-tick-bold',
      color: 'mint',
      bannerIcon: 'icon-tick-bold',
      staticIndex: 5,
      staticIndexI18n: 4,
    },
    achievements: {
      id: 'achievements',
      title: i18n.t('common:menu.achievements'),
      url: UrlHelper.achievements,
      type: 'achievements',
      icon: 'icon-cup',
      color: 'yellow',
      bannerIcon: 'icon-cup',
      staticIndex: 6,
      staticIndexI18n: 5,
      newOption: true,
    },
    articles: {
      id: 'articles',
      title: i18n.t('common:menu.advice'),
      url: '/advice',
      type: 'articles',
      icon: 'icon-articles',
      color: 'rose',
      staticIndex: 7,
      staticIndexI18n: 7,
    },
    scrapbook: {
      id: 'scrapbook',
      title: i18n.t('common:menu.scrapbook'),
      url: '/scrapbook',
      type: 'scrapbook',
      icon: 'icon-scrapbook',
      color: 'purple',
      staticIndex: 8,
      staticIndexI18n: 6,
    },
    settings: {
      id: 'settings',
      title: i18n.t('common:menu.settings'),
      url: '/settings',
      type: 'settings',
      icon: 'icon-settings',
      color: 'silver',
      staticIndex: 0,
    },
    signUp: {
      id: 'signUp',
      title: i18n.t('common:menu.signup'),
      type: 'loggedOut',
      icon: 'icon-bride-groom',
      color: 'blueCrush',
      staticIndex: 0,
    },
    logIn: {
      id: 'logIn',
      title: i18n.t('common:menu.login'),
      type: 'loggedOut',
      icon: 'icon-lock-outlined',
      color: 'mint',
      staticIndex: 0,
    },
    businessArea: {
      id: 'businessArea',
      title: i18n.t('common:menu.business'),
      url: env.CMS_URL,
      type: 'external',
      icon: 'icon-supplier',
      color: 'blueCrush60',
      staticIndex: 0,
    },
    collaborators: {
      id: 'collaborators',
      title: i18n.t('common:menu.collaborators'),
      url: '/',
      type: 'collaborators',
      icon: 'icon-bride-groom',
      color: 'silver',
      staticIndex: 0,
    },
    logOut: {
      id: 'logOut',
      title: i18n.t('common:menu.logout'),
      type: 'logOut',
      icon: 'icon-logout',
      color: 'silver',
      staticIndex: 0,
    },
    searchVenues: {
      id: 'searchVenues',
      title: i18n.t('common:menu.searchVenues'),
      type: 'mobileSearch',
      icon: 'icon-mag-glass',
      color: 'mint',
      staticIndex: 0,
    },
    searchSuppliers: {
      id: 'searchSuppliers',
      title: i18n.t('common:menu.searchSuppliers'),
      type: 'mobileSearch',
      icon: 'icon-mag-glass',
      color: 'blueCrush',
      staticIndex: 0,
    },
    inbox: {
      id: 'inbox',
      title: i18n.t('common:menu.messages'),
      type: 'inbox',
      icon: 'icon-inbox',
      color: 'white',
      url: UrlHelper.inbox.main,
      staticIndex: 0,
    },
    feedback: {
      id: 'feedback',
      title: i18n.t('common:menu.feedback'),
      url: '',
      type: 'feedback',
      icon: 'icon-chat',
      color: 'silver',
      staticIndex: 0,
    },
  };
};
