import { getIsQuizOpen } from 'lib/quiz/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const FloristsQuizLazy = lazyComponent(() => import('./florists-quiz'));

const FloristsQuiz = () => {
  const isQuizOpen = useSelector(getIsQuizOpen('florists'));

  if (!isQuizOpen) {
    return null;
  }

  return <FloristsQuizLazy />;
};

export default FloristsQuiz;
