import { FelaCSS, colors } from '@bridebook/ui';
import { MOBILE_NAV_HEIGHT } from 'components/mobile-app/mobile-navigation.style';

interface IStyleProps {
  backgroundColor?: string;
  isMobile: boolean;
  isTablet: boolean;
  mobileNavShown: boolean;
  reservedStickySpace?: boolean;
  bottomSafeArea?: boolean;
  stickyHeader?: boolean;
  isHome?: boolean;
  contentOffset: {
    desktop: number;
    tablet: number;
    mobile: number;
  };
  disableSafeAreaInsetTop: boolean;
}

interface IStyles {
  wrapper: FelaCSS;
  contentWrap: FelaCSS;
  childrenWrap: FelaCSS;
  pageContent: FelaCSS;
}

const styles = ({
  isMobile,
  isTablet,
  mobileNavShown,
  reservedStickySpace,
  bottomSafeArea,
  contentOffset,
  isHome,
  disableSafeAreaInsetTop,
  backgroundColor,
}: IStyleProps): IStyles => {
  const shaderWrappers: FelaCSS = {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
  };

  const contentOffsetValue = isMobile
    ? contentOffset.mobile
    : isTablet
    ? contentOffset.tablet
    : contentOffset.desktop;

  return {
    wrapper: {
      paddingBottom: mobileNavShown ? `${MOBILE_NAV_HEIGHT}px` : '0px',
      height: '100%',
      flexGrow: 1,
      minHeight: isMobile || isTablet ? '100vh' : '0px',
      ...(isHome && { backgroundColor: colors.space04 }),
      supportsSafeAreaInset:
        mobileNavShown && bottomSafeArea
          ? {
              paddingBottom: 'calc(54px + env(safe-area-inset-bottom))',
            }
          : {},
    },

    contentWrap: {
      ...shaderWrappers,
      paddingTop: `${contentOffsetValue}px`,
      paddingBottom: reservedStickySpace ? 'calc(80px + env(safe-area-inset-bottom))' : '0',

      ...(!disableSafeAreaInsetTop && {
        supportsSafeAreaInset: {
          paddingTop: `calc(${contentOffsetValue}px + env(safe-area-inset-top))`,
        },
      }),
    },

    childrenWrap: {
      ...shaderWrappers,
    },

    pageContent: {
      ...shaderWrappers,
      backgroundColor,
    },
  };
};

export default styles;
