import { getHasNewMap30 } from 'lib/ab-tests/tests/LIVE-20786_Map30';
import { useLocationData } from 'lib/search/hooks/query/use-location-data';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { useSelector } from 'lib/utils';

export const useShowRadiusFilter = (): boolean => {
  const searchParams = useSearchParams();
  const hasNewMap = useSelector(getHasNewMap30);

  const { locationData } = useLocationData({
    slug: searchParams?.slug,
    area: searchParams?.area,
    market: searchParams?.market,
  });

  const isSearchOnMove = !!searchParams?.searchOnMove;

  const searchType = locationData?.type || '';
  return (
    !!searchType &&
    ['town', 'city'].includes(searchType) &&
    // hide radius filter if map has been moved
    !(isSearchOnMove && hasNewMap)
  );
};
