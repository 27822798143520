import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: (isMobile: boolean) => FelaCSS;
  toggleViewButton: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: (isMobile) => ({
    pointerEvents: 'auto',
    ...(isMobile && { paddingLeft: 3 }),
  }),

  toggleViewButton: {
    height: 35,
    fontDefault: 14,
    color: colors.white,
    paddingHorizontal: 4,
    cursor: 'pointer',
    gap: 8,
    lineHeight: '150%',
  },
});

export default styles;
