export enum AchievementIconType {
  AddedToFavourites = 'addedToFavourites',
  CheckedTenTasks = 'checkedTenTasks',
  CollaboratorAdded = 'collaboratorAdded',
  FirstMessageSent = 'firstMessageSent',
  GuestAdded = 'guestAdded',
  HalfChecklistChecked = 'halfChecklistChecked',
  PhotographerBooked = 'photographerBooked',
  ProfileImageAdded = 'profileImageAdded',
  VenueBooked = 'venueBooked',
}
