import { differenceInMinutes } from 'date-fns';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TQuizId, reverseSearchRoutesByQuizId } from 'components/quiz/types';
import { getIsCakeQuizSearchInterceptEnabled } from 'lib/ab-tests/tests/global/LIVE-19024_CakeQuizSearchIntercept';
import { getIsFloristQuizSearchInterceptEnabled } from 'lib/ab-tests/tests/global/LIVE-19024_FloristQuizSearchIntercept';
import { getIsHairAndBeautyQuizSearchInterceptEnabled } from 'lib/ab-tests/tests/global/LIVE-19024_HairAndBeautyQuizSearchIntercept';
import { getIsPhotoQuizSearchInterceptEnabled } from 'lib/ab-tests/tests/global/LIVE-19024_PhotoQuizSearchIntercept';
import { getIsVideographerQuizSearchInterceptEnabled } from 'lib/ab-tests/tests/global/LIVE-19024_VideographerQuizSearchIntercept';
import { QuizSearchInterceptStorage } from 'lib/quiz/quiz-search-intercept-storage';
// Assuming that these imports come from different files
import { setInterceptSearchParams, toggleQuiz } from 'lib/quiz/slice';
import { loadedSearchPreferencePageAnalytics } from 'lib/search/actions';
import { getSearchRequestSlug } from 'lib/search/selectors';
import { getStatusOfQuizzes } from 'lib/task/selectors';
import { UrlHelper } from 'lib/url-helper';
import { selectCurrentUser } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';

const SESSION_DURATION_IN_MINS = 8 * 60;
const MAX_NUMBER_OF_SKIPS = 2;

export const useReverseSearchRedirect = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [newRoute, setNewRoute] = useState<string | null>(null);

  const excludedPaths = [UrlHelper.onboarding];
  const isOnFloristSearch = router.asPath.includes(reverseSearchRoutesByQuizId.florists);
  const isOnCakeSearch = router.asPath.includes(reverseSearchRoutesByQuizId.cake);
  const isOnPhotoSearch = router.asPath.includes(reverseSearchRoutesByQuizId.photographyStyle);
  const isOnVideographerSearch = router.asPath.includes(
    reverseSearchRoutesByQuizId.videographyStyle,
  );
  const isOnHairAndBeautySearch = router.asPath.includes(reverseSearchRoutesByQuizId.hairAndBeauty);
  const isCakeQuizSearchInterceptEnabled = useSelector(getIsCakeQuizSearchInterceptEnabled);
  const isFloristQuizSearchInterceptEnabled = useSelector(getIsFloristQuizSearchInterceptEnabled);
  const isPhotoQuizSearchInterceptEnabled = useSelector(getIsPhotoQuizSearchInterceptEnabled);
  const isVideographerQuizSearchInterceptEnabled = useSelector(
    getIsVideographerQuizSearchInterceptEnabled,
  );
  const isHairAndBeautyQuizSearchInterceptEnabled = useSelector(
    getIsHairAndBeautyQuizSearchInterceptEnabled,
  );
  const user = useSelector(selectCurrentUser);
  const supplierCategory = useSelector(getSearchRequestSlug);
  const statusOfQuizzes = useSelector(getStatusOfQuizzes);

  const showQuiz = useCallback(
    (quizId: TQuizId) => {
      dispatch(
        toggleQuiz({ open: true, quizId, showIntroPage: true, quizSource: 'searchInterception' }),
      );
      dispatch(loadedSearchPreferencePageAnalytics(supplierCategory));
      router.events.emit('routeChangeError');
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw 'navigation aborted';
    },
    [dispatch, router.events, supplierCategory],
  );

  const wasQuizCompletedOrSkippedRecently = useCallback(
    (quizId: TQuizId) => {
      if (!user) throw new Error('Something went wrong, user not found');
      const quizSearchInterceptStorageManager = QuizSearchInterceptStorage(user.id);
      const quizInterceptStorage = quizSearchInterceptStorageManager.get();
      if (!quizInterceptStorage) return false;
      const currentDate = Date.now();
      const lastTimeQuizWasSkipped = quizInterceptStorage[quizId]?.lastSkipped ?? 0;
      const quizSkipCounter = quizInterceptStorage[quizId]?.skipCounter ?? 0;

      return (
        quizSkipCounter >= MAX_NUMBER_OF_SKIPS ||
        differenceInMinutes(currentDate, lastTimeQuizWasSkipped) < SESSION_DURATION_IN_MINS
      );
    },
    [user],
  );

  const shouldShowQuiz = useCallback(
    (quizId: TQuizId) => {
      if (wasQuizCompletedOrSkippedRecently(quizId) || statusOfQuizzes?.[quizId]?.done) {
        return false;
      }

      for (const excludedPath of excludedPaths) {
        if (router.asPath.includes(excludedPath)) {
          return false;
        }
      }

      switch (quizId) {
        case 'florists':
          return !isOnFloristSearch && isFloristQuizSearchInterceptEnabled;
        case 'photographyStyle':
          return !isOnPhotoSearch && isPhotoQuizSearchInterceptEnabled;
        case 'cake':
          return !isOnCakeSearch && isCakeQuizSearchInterceptEnabled;
        case 'videographyStyle':
          return !isOnVideographerSearch && isVideographerQuizSearchInterceptEnabled;
        case 'hairAndBeauty':
          return !isOnHairAndBeautySearch && isHairAndBeautyQuizSearchInterceptEnabled;
        default:
          return false;
      }
    },
    [
      excludedPaths,
      isCakeQuizSearchInterceptEnabled,
      isFloristQuizSearchInterceptEnabled,
      isHairAndBeautyQuizSearchInterceptEnabled,
      isOnCakeSearch,
      isOnFloristSearch,
      isOnHairAndBeautySearch,
      isOnPhotoSearch,
      isOnVideographerSearch,
      isPhotoQuizSearchInterceptEnabled,
      isVideographerQuizSearchInterceptEnabled,
      router.asPath,
      statusOfQuizzes,
      wasQuizCompletedOrSkippedRecently,
    ],
  );

  const handleRouteChangeStart = useCallback(
    (route: string) => {
      const searchParams = route.split('/').pop();
      if (searchParams) {
        dispatch(setInterceptSearchParams(searchParams));
      }
      setNewRoute(route);
      let quizId: TQuizId | null = null;
      switch (true) {
        case route.includes(reverseSearchRoutesByQuizId.florists):
          quizId = 'florists';
          break;
        case route.includes(reverseSearchRoutesByQuizId.cake):
          quizId = 'cake';
          break;
        case route.includes(reverseSearchRoutesByQuizId.photographyStyle):
          quizId = 'photographyStyle';
          break;
        case route.includes(reverseSearchRoutesByQuizId.videographyStyle):
          quizId = 'videographyStyle';
          break;
        case route.includes(reverseSearchRoutesByQuizId.hairAndBeauty):
          quizId = 'hairAndBeauty';
          break;
        default:
          return null;
      }
      if (quizId) {
        return shouldShowQuiz(quizId) && showQuiz(quizId);
      }
    },
    [dispatch, shouldShowQuiz, showQuiz],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => router.events.off('routeChangeStart', handleRouteChangeStart);
  }, [
    router.events,
    newRoute,
    isOnFloristSearch,
    isOnCakeSearch,
    isOnPhotoSearch,
    handleRouteChangeStart,
  ]);
};
