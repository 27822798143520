import { IRenderer } from 'fela';
import { ILayoutProps } from 'next';
import { useTranslation } from 'next-i18next';
import { Router as NextRouter } from 'next/router';
import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { FelaProvider } from '@bridebook/ui';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import FullpageLoader from 'app-shared/components/fullpage-loader/fullpage-loader';
import { AppBannerScript, MetaRouterScript } from 'app-shared/lib/app/scripts';
import { Recaptcha } from 'components/recaptcha/recaptcha';
import { useGlobalABTests } from 'lib/ab-tests/hooks/use-global-ab-tests';
import { AppRenderingContextProvider, useCanRender } from 'lib/app/app-rendering-context';
import ComponentLevelErrorBoundary from 'lib/app/component-level-error-boundary';
import { env } from 'lib/env';
import addUKPluralUpdate from 'lib/i18n/addUKPluralUpdate';
import { getIsCordova } from 'lib/mobile-app/selectors';
import { useSelector } from 'lib/utils';
import Page, { IPageProps } from '../components/page';
import { alwaysLoadedNS } from './i18n/constants';

// Create a client

export interface LayoutFullProps extends PropsWithChildren {
  renderer?: IRenderer;
  router: NextRouter;
  pageComponentProps: IPageProps;
  layoutProps: ILayoutProps;
}

export const PageWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { ready: translationsReady } = useTranslation(alwaysLoadedNS);
  const canRender = useCanRender();

  return (
    <>
      {canRender && translationsReady && children}
      {!canRender && <FullpageLoader />}
    </>
  );
};

export const LayoutFull = ({ children, renderer, pageComponentProps, router }: LayoutFullProps) => {
  addUKPluralUpdate();
  useGlobalABTests();
  const isCordova = useSelector(getIsCordova);
  const appStarted = useSelector((state) => state.app.started);
  const showReactQueryDevtools = Object.keys(router.query).includes('reactQuery');

  return (
    <AppRenderingContextProvider>
      {appStarted && (
        <>
          <AppBannerScript showBanner={!isCordova} />
          <MetaRouterScript isCordova={isCordova} />
        </>
      )}
      <Recaptcha>
        <FelaProvider renderer={renderer}>
          <ComponentLevelErrorBoundary component={'LayoutFull'}>
            <PageWrapper>
              <Page {...pageComponentProps}>{children}</Page>
            </PageWrapper>
          </ComponentLevelErrorBoundary>
          {!env.IS_PRODUCTION_BUILD && showReactQueryDevtools && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </FelaProvider>
      </Recaptcha>
    </AppRenderingContextProvider>
  );
};

type IWithLayoutLogged = (
  page: ReactElement,
  props: LayoutFullProps,
  layoutProps: LayoutFullProps['layoutProps'],
  pageComponentProps?: LayoutFullProps['pageComponentProps'],
) => ReactElement;

export const withFullLayout: IWithLayoutLogged = (
  page,
  props,
  layoutProps,
  pageComponentProps = {},
) => (
  <LayoutFull
    layoutProps={layoutProps}
    renderer={props.renderer}
    router={props.router}
    pageComponentProps={pageComponentProps}>
    {page}
  </LayoutFull>
);
