import type { IWedding } from '@bridebook/models/source/models/Weddings.types';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { mapDateToUI, mapToDateRange } from '../datepicker';

export type WeddingDateRange = { from: number; to: number } | null;

export const getWeddingDateRange = (
  weddingDate: IWedding['date'],
  country: IWedding['l10n']['country'],
): WeddingDateRange => {
  const market = gazetteer.getMarketByCountry(country);
  const dateRange = mapToDateRange(mapDateToUI(weddingDate), market);

  if (dateRange) {
    return {
      from: dateRange.from.valueOf(),
      to: dateRange.to.valueOf(),
    };
  }

  return null;
};
