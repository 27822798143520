import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const PLACEHOLDER_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '457a688a-ecbc-4536-abb5-25081d8097c8',
  storage: 'none',
  name: 'Placeholder',
  type: 'interaction',
  isTaskCompleted: () => false,
};

registerTodayTask(PLACEHOLDER_TASK_DEFINITION);
