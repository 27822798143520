import React from 'react';
import { Box } from '@bridebook/ui';
import componentStyles from 'components/onboarding-new/components/animations/blocks/background-stars-animation.style';

export const BackgroundStarsAnimation = () => {
  const styles = componentStyles();
  return (
    <Box style={styles.wrapper}>
      <Box style={styles.stars} />
    </Box>
  );
};
