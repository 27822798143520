import React, { FunctionComponent, MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, colors } from '@bridebook/ui';
import IconBack from '@bridebook/ui/src/components/svg/icon-back';
import { decrementPilingCounter } from 'lib/app/actions';
import { goHomeBack } from 'lib/ui/actions';

interface ITopBarBackProps {
  onClick?: MouseEventHandler;
  theme?: 'light' | 'dark';
}

const TopBarBack: FunctionComponent<ITopBarBackProps> = ({
  onClick,
  theme = 'dark',
}: ITopBarBackProps) => {
  const [clicked, setClicked] = useState(false);
  const isWhiteBackground = theme === 'light';
  const dispatch = useDispatch();
  const backTo: MouseEventHandler = (e) => {
    e.preventDefault();
    dispatch(goHomeBack({ back: true }));
    dispatch(decrementPilingCounter());
    setClicked(true);
  };

  return (
    <Box style={{ marginLeft: '-6px', marginTop: '1px' }}>
      <Button
        theme="ghost"
        color={isWhiteBackground ? 'space04' : 'white'}
        onClick={onClick || backTo}
        icon={
          <IconBack
            color={isWhiteBackground ? colors.space : undefined}
            fillColor={clicked ? 'black' : isWhiteBackground ? colors.space40 : 'white'}
          />
        }
      />
    </Box>
  );
};

export default TopBarBack;
