import { AnyAction } from 'redux';
import { TrackingEvent } from 'lib/track-utils/tracking-event';

export interface ITrackEventAction<P> {
  type: typeof trackEventType;
  payload: P;
  event: TrackingEvent;
}

export const trackEvent = <P>(event: TrackingEvent, payload: P): ITrackEventAction<P> => ({
  type: trackEventType,
  payload,
  event,
});

export const trackEventType = 'TRACK_EVENT';
export const isTrackingAction = (action: AnyAction): action is ITrackEventAction<unknown> =>
  action.type === trackEventType;
