import { createSelector } from 'reselect';
import { TQuizId } from 'components/quiz/types';
import { IApplicationState } from '../types';

const _getQuizState = (state: IApplicationState) => state.quiz;
export const getIsQuizOpen = (quizId: TQuizId) =>
  createSelector(_getQuizState, (quiz) => quiz.modal.open && quiz.modal.quizId === quizId);

export const getShowIntroPage = (quizId: TQuizId) =>
  createSelector(
    _getQuizState,
    (quiz) => quiz.modal.open && quiz.modal.showIntroPage && quiz.modal.quizId === quizId,
  );

export const getQuizSource = (quizId?: TQuizId) =>
  createSelector(_getQuizState, (quiz) =>
    quiz.modal.open && quiz.modal.quizId === quizId ? quiz.modal.quizSource : undefined,
  );

export const getInterceptSearchParams = createSelector(
  _getQuizState,
  (quiz) => quiz.interceptSearchParams,
);
export const getIsAnyQuizOpen = createSelector(_getQuizState, (quiz) => quiz.modal.open);
