import React from 'react';
import { useSelector } from 'react-redux';
import { IFeedbackPopupProps } from 'components/settings/feedback-popup/index';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const FeedbackPopup = lazyComponent<IFeedbackPopupProps>(
  () => import('components/settings/feedback-popup'),
);

interface FeedbackPopupLazyProps {}

const FeedbackPopupLazy: React.FC<FeedbackPopupLazyProps> = () => {
  const show = useSelector<IApplicationState, boolean>((state) => state.settings.showFeedbackPopup);

  if (!show) return null;

  return <FeedbackPopup showFeedbackPopup={show} />;
};

export default React.memo(FeedbackPopupLazy);
