import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
    lineHeight: '1.5em',
    color: colors.space60,
  },
});

export default styles;
