import { colors } from '../themes/variables';
import { alpha } from './color-mixer';

const indigoCrushDark20Alpha20 = alpha(colors.indigoCrush80, 20);

export const getDefaultStylesGlobal = (fontFamily: string) => `
  * {
    box-sizing: border-box;
  }

  *:before, *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0 !important;
  }

  *::-ms-clear {
    display: none;
  }

  #app {
    height:100%
  }

  html,
  body {
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    display: block;
  }

  html {
    font-family: ${fontFamily};
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
   }

   ul, li {
     padding: 0;
     margin: 0;
   }

   body {
    margin: 0;
    line-height: 1.428571429;
    color: ${colors.space};
   }

   h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: 1.1em;
    font-weight: 400;
   }

   h1, h2, h3 {
    margin-top: 22px;
    margin-bottom: 11px;
   }

   h4, h5, h6 {
    margin-top: 11px;
    margin-bottom: 11px;
   }

   h1 {
    font-size: 40px;
    line-height: '1.1em';
   }

   h2 {
    font-size: 30px;
   }

   h3 {
    font-size: 25px;
   }

   h4 {
    font-size: 18px;
   }

   h5 {
    font-size: 14px;
   }

   p {
    margin: 0 0 11px;
   }

   article,
   aside,
   details,
   figcaption,
   figure,
   footer,
   header,
   hgroup,
   main,
   menu,
   nav,
   section,
   summary {
    display block
   }

   audio,
   canvas,
   progress,
   video {
    display: inline-block;
    vertical-align: baseline;
   }

   audio:not([controls]) {
    display: none;
    height: 0;
   }

   [hidden],
   template {
    display: none;
   }

   a {
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    color: ${colors.indigoCrush};
   }

   a:active,
   a:hover {
    outline 0
   }

   abbr[title] {
    border-bottom 1px dotted
   }

   b,
   strong {
    font-weight: bold;
   }

   dfn {
    font-style: italic;
   }

   mark {
    background: #ff0;
    color: #000;
   }

   small {
    font-size: 88%;
   }

   sub,
   sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
   }

   sup {
    top: -0.5em;
   }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
    vertical-align: middle;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  hr {
    box-sizing: content-box;
    height: 0;
  }

  pre {
    overflow: auto;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font: inherit;
    margin: 0;
  }

  button {
    overflow: visible;
    border-radius: 0;
  }

  button,
  select {
    text-transform: none;
  }

  label {
    display: inline-block;
    max-width: 100%;
    font-weight: bold;
    margin-bottom: 0;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: none;
    box-sizing: border-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  fieldset,
  legend {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 0;
  }

  fieldset {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
   }

  legend {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    resize: none;
  }

  optgroup {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  .pac-container {
    z-index: 3001;
    margin-top: -10px;
    padding-top: 10px;
    border: 1px solid ${colors.indigoCrush80} !important;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none !important;
    box-sizing: content-box;
    box-shadow: 2px 3px 4px 0 ${indigoCrushDark20Alpha20} !important;
  }

  @media screen and (max-width:480px) {
    .pac-container {
      overflow-y: scroll;
      max-height: 300px;
    }
  }

  .pac-container:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${colors.indigoCrush80};
  }

  .pac-container:after {
    background-image: none !important;
    height: 0px;
    padding: 0;
  }

  .pac-container .pac-icon {
    display: none;
  }

  .pac-container .pac-item {
    padding: 8px 13px;
    margin-top: 4px;
    border: none;
    cursor: pointer;
    font-family: ${fontFamily};
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 20px;
  }

  .pac-container .pac-item:first-of-type {
    margin-top: 0;
  }

  .pac-container .pac-item:hover{
    background-color: ${colors.space04};
  }

  .pac-container .pac-item .pac-item-query {
    display: block;
    padding-right: 0;
  }

  .pac-container .pac-item .pac-item-query,
  .pac-container .pac-item .pac-matched {
    font-family: ${fontFamily};
    -webkit-font-smoothing:antialiased;
    font-size: 14px;
    line-height: 20px;
  }

  .pac-container .pac-item .pac-matched {
    padding-left: 0;
  }

  .ie-flex-fix {
    display: flex;
  }

  input:required,
  input:invalid {
    box-shadow: none;
  }

  .cordova.landing-page.welcome,
  .cordova.landing-page.our-testimonials,
  .cordova.landing-page.as-seen-in,
  .cordova.landing-page.how-couples-search,
  .cordova.landing-page.features,
  .cordova.landing-page.quote-block,
  .cordova.landing-page.landing-footer,
  .cordova.landing-page.how-it-works {
    display: none !important;
  }

  span[class^="PIN_"] {
    z-index: 1 !important;
  }

  body .full-height-wrapper {
    position: relative;
    height: 100%;
  }

  body .full-height-wrapper #__next {
    height: 100%;
  }

  body .full-height-wrapper #__next > div:first-child {
    height: 100%;
  }

  body .full-height-wrapper #__next > div:first-child .app-wrapper {
    height: 100%;
  }

  .app-loader .bb-loader-holder {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  _::-webkit-full-page-media, _:future, :root body {
    -webkit-font-smoothing: antialiased;
  }

  /* Alice Carousel */
  .alice-carousel .animated {
    animation-fill-mode: both;
  }
  
  .alice-carousel .animated-out {
    z-index: 1;
  }
  
  .alice-carousel .fadeOut {
    animation-name: fadeOut;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  .alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
  }
  
  .alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  
  .alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
  }
  .alice-carousel__stage-item * {
    line-height: initial;
  }
  .alice-carousel__stage-item.__hidden {
    opacity: 0;
    overflow: hidden;
  }
  
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 10px 5px;
  }
  .alice-carousel__prev-btn [data-area]::after,
  .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize;
  }
  
  .alice-carousel__prev-btn {
    text-align: right;
  }
  
  .alice-carousel__next-btn {
    text-align: left;
  }
  
  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    color: #465798;
  }
  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    color: darkred;
  }
  .alice-carousel__prev-btn-item.__inactive,
  .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4;
    pointer-events: none;
  }
  
  .alice-carousel__play-btn {
    position: absolute;
    top: 30px;
    left: 20px;
    display: inline-block;
  }
  .alice-carousel__play-btn:hover {
    cursor: pointer;
  }
  .alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
  }
  
  .alice-carousel__play-btn-item {
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
  }
  .alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    position: absolute;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    content: "";
    transition: all 0.4s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #465798;
  }
  .alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px;
  }
  .alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px;
  }
  .alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px;
  }
  .alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px;
  }
  
  .alice-carousel__dots {
    margin: 30px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
  }
  .alice-carousel__dots > li {
    display: inline-block;
  }
  .alice-carousel__dots-item:not(.__custom) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb;
  }
  .alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 20px;
  }
  .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #6e7ebc;
  }
  
  .alice-carousel__slide-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #465798;
    border-radius: 5px;
    background-color: rgba(224, 228, 251, 0.6);
  }
  .alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0;
  }
`;
