import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    width: spacing[8],
    height: spacing[8],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 64,
    border: `1px solid ${colors.space08}`,
    background: colors.white,
    position: 'absolute',
    top: spacing[6],
    right: spacing[6],
    cursor: 'pointer',
    zIndex: 1,
  },
});

export default styles;
