import { useTranslation } from 'next-i18next';
import { SortOptionValue } from 'app-shared/lib/search/types';
import { getDistanceSortDisabled, getIsVenueSearch } from 'lib/search/selectors';
import { useSelector } from 'lib/utils';

export const useSortOptions = () => {
  const { t } = useTranslation('search');
  const isVenueSearch = useSelector(getIsVenueSearch);
  const distanceSortDisabled = useSelector(getDistanceSortDisabled);
  const exclude = isVenueSearch ? (distanceSortDisabled ? ['distance'] : []) : ['distance', 'new'];

  return (
    [
      {
        value: 'favourites',
        label: t('search:options.favourites'),
      },
      {
        value: 'popular',
        label: t('search:options.popular'),
      },
      {
        value: 'new',
        label: t('search:options.new'),
      },
      {
        value: 'distance',
        label: t('search:options.distance'),
      },
    ] as { value: SortOptionValue; label: string }[]
  ).filter(({ value }) => !exclude.includes(value));
};
