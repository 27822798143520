import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const PHOTOGRAPHY_STYLE_QUIZ_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '77fe0510-a124-4751-8ca5-0fb09d4e9658',
  storage: 'wedding',
  name: 'Photography style quiz',
  type: 'quiz',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;

    return (
      !suppliers?.includes('photo') ||
      isTodayTaskCompleted(state, PHOTOGRAPHY_STYLE_QUIZ_TASK_DEFINITION)
    );
  },
};

registerTodayTask(PHOTOGRAPHY_STYLE_QUIZ_TASK_DEFINITION);
