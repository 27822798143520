import { getIsQuizOpen } from 'lib/quiz/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const AtYourVenueQuizLazy = lazyComponent(
  () => import('components/quiz/at-your-venue-quiz/at-your-venue-quiz'),
);

const AtYourVenueQuiz = () => {
  const isQuizOpen = useSelector(getIsQuizOpen('atYourVenue'));

  if (!isQuizOpen) {
    return null;
  }

  return <AtYourVenueQuizLazy />;
};

export default AtYourVenueQuiz;
