import { ADD_COLLABORATOR_TASK_DEFINITION } from 'components/task/today-task/add-collaborator-task/task-definition';
import { ADD_PROFILE_PICTURE_TASK_DEFINITION } from 'components/task/today-task/add-profile-picture-task/task-definition';
import { ADD_YOUR_FIRST_GUEST_TASK_DEFINITION } from 'components/task/today-task/add-your-first-guest-task/task-definition';
import {
  ARTICLE_TASK_DEFINITION,
  SPECIFIC_ARTICLE_TASK_DEFINITION,
} from 'components/task/today-task/article-task/task-definition';
import { FIND_DREAM_VENUE_TASK_DEFINITION } from 'components/task/today-task/find-dream-venue-task/task-definition';
import { MARK_AS_BOOKED_TASK_DEFINITION } from 'components/task/today-task/mark-as-booked-task/task-definition';
import { PLACEHOLDER_TASK_DEFINITION } from 'components/task/today-task/placeholder-task/task-definition';
import { SEARCH_PHOTOGRAPHERS_TASK_DEFINITION } from 'components/task/today-task/search-photographers-task/task-definition';
import { TTaskFlowDefinition, createSession } from 'lib/task/task-flow-engine';

export const ROOT_TODAY_TASK_FLOW_ID = 'todayTaskFlow';
export const todayTaskFlow: TTaskFlowDefinition = {
  id: ROOT_TODAY_TASK_FLOW_ID,
  sessions: [
    createSession({
      id: 1,
      tasks: [
        FIND_DREAM_VENUE_TASK_DEFINITION,
        ADD_PROFILE_PICTURE_TASK_DEFINITION,
        ARTICLE_TASK_DEFINITION,
      ],
      fallbackTask: PLACEHOLDER_TASK_DEFINITION,
    }),
    createSession({
      id: 2,
      tasks: [
        ADD_COLLABORATOR_TASK_DEFINITION,
        SPECIFIC_ARTICLE_TASK_DEFINITION('meet-the-wedding-venues-championing-lgbtq-inclusivity'),
        ARTICLE_TASK_DEFINITION,
      ],
      fallbackTask: PLACEHOLDER_TASK_DEFINITION,
    }),
    createSession({
      id: 3,
      tasks: [
        ADD_YOUR_FIRST_GUEST_TASK_DEFINITION,
        SPECIFIC_ARTICLE_TASK_DEFINITION(
          '11-tips-for-planning-a-queer-wedding-from-a-queer-engaged-wedding-photographer',
        ),
        SPECIFIC_ARTICLE_TASK_DEFINITION(
          'the-best-accommodation-for-your-guests-bridebook-x-hotelplanner',
        ),
        FIND_DREAM_VENUE_TASK_DEFINITION,
      ],
      fallbackTask: PLACEHOLDER_TASK_DEFINITION,
    }),
    createSession({
      id: 4,
      tasks: [
        SPECIFIC_ARTICLE_TASK_DEFINITION(
          'the-best-accommodation-for-your-guests-bridebook-x-hotelplanner',
        ),
        ARTICLE_TASK_DEFINITION,
        FIND_DREAM_VENUE_TASK_DEFINITION,
      ],
      fallbackTask: PLACEHOLDER_TASK_DEFINITION,
    }),
    createSession({
      id: 5,
      tasks: [ARTICLE_TASK_DEFINITION, FIND_DREAM_VENUE_TASK_DEFINITION],
      fallbackTask: PLACEHOLDER_TASK_DEFINITION,
    }),
    createSession(
      {
        id: 6,
        tasks: [
          MARK_AS_BOOKED_TASK_DEFINITION,
          SEARCH_PHOTOGRAPHERS_TASK_DEFINITION,
          ARTICLE_TASK_DEFINITION,
        ],
        fallbackTask: PLACEHOLDER_TASK_DEFINITION,
      },
      // 'randomized',
    ),
  ],
};
