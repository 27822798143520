import { Market } from '@bridebook/toolbox/src/gazetteer';
import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { useQuery } from '@tanstack/react-query';
import { fetchLocationData } from 'app-shared/lib/search/utils';

const LOCATION_DATA_CACHE_TIME = toMillis(1, 'hour');

/**
 * Returns location data for a given area and slug, fetched from RTDB
 */
export const useLocationData = ({
  area,
  slug,
  market,
}: {
  area?: string;
  slug?: Slug | UrlFriendlySlug;
  market?: Market;
}) => {
  const {
    data: locationData,
    isLoading: isLocationDataLoading,
    isFetched: isLocationDataFetched,
  } = useQuery(
    ['location-data', area, slug, market],
    async () => {
      if (!area || !market) return;
      return await fetchLocationData({
        market,
        area,
        slug,
      });
    },
    {
      cacheTime: LOCATION_DATA_CACHE_TIME,
      staleTime: LOCATION_DATA_CACHE_TIME,
      enabled: Boolean(area),
    },
  );

  return {
    locationData,
    isLocationDataLoading,
    isLocationDataFetched,
  };
};
