import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  width?: number;
  style?: FelaCSS;
  fillOpacity?: number;
  fillColor?: string;
  color?: string;
}

interface IconTypes {
  width: number;
  height: number;
}

const IconClose = ({
  id,
  width = 38,
  style,
  fillOpacity = 0.2,
  fillColor = 'white',
  color = 'white',
  ...restProps
}: IProps) => {
  const viewBoxWidth = width;
  const viewBoxHeight = width;

  const icon = ({ width, height }: IconTypes) => (
    <svg viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`} width={width} height={height} fill="none">
      <rect
        width={width}
        height={width}
        rx={width / 2}
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        d="M13 13L24.5 24.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M13 24.5L24.5 13"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconClose;
