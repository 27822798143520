import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  bodyWrapper: FelaCSS;
  title: FelaCSS;
  buttonWrapper: FelaCSS;
  modal: FelaCSSWithCustomSelectors;
  content: FelaCSS;
  closeButtonWrapper: FelaCSS;
  description: FelaCSSWithCustomSelectors;
}

const styles = (): IStyles => ({
  wrapper: {
    height: '100vh',
    desktopDesign: {
      justifyContent: 'center',
    },
  },

  bodyWrapper: {
    paddingVertical: 4,
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
  },
  title: {
    fontDefaultSemiBold: 24,
    marginTop: 0,
    paddingVertical: 4,
  },
  buttonWrapper: {
    paddingVertical: 4,
    width: '80%',
    supportsSafeAreaInset: {
      marginBottom: 'env(safe-area-inset-bottom)',
    },
  },
  modal: {
    background: colors.white,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    borderTopLeftRadius: '30px',
    borderTopRightRadius: '30px',
    overflow: 'hidden',
    maxHeight: '90%',
    desktopDesign: {
      position: 'relative',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '60%',
      minHeight: 540,
      height: 580, //5 lines of description
      marginHorizontal: 'auto',
      borderRadius: '8px',
    },
  },
  content: {
    alignItems: 'center',
    overflow: 'hidden',
    desktopDesign: {
      justifyContent: 'center',
    },
  },
  closeButtonWrapper: {
    paddingBottom: 4,
    width: '100%',
  },
  description: {
    color: colors.space60,
    fontDefault: 16,
    //temp SE detection
    '@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2)':
      {
        fontDefault: 14,
      },
  },
});

export default styles;
