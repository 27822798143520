import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  buttonWrapper: FelaCSS;
  label: FelaCSS;
}

const styles: IStyles = {
  wrapper: {
    fontDefault: 16,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '22px',
    cursor: 'pointer',
    flexDirection: 'row',
    color: colors.blueCrush04,
    ':hover': {
      color: colors.blueCrush04,
      textDecoration: 'none',
    },
  },
  buttonWrapper: {
    height: '48px',
    paddingLeft: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontDefault: 16,
    whiteSpace: 'nowrap',
  },
};

export default styles;
