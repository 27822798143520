import { ofType } from 'redux-observable';
import { pluck } from 'rxjs/operators';
import { IProps as ButtonProps } from '@bridebook/ui/src/components/bbcommon/buttons/button';
import { ActionWithPayload } from 'lib/types';
import { MuteActionsService } from 'lib/utils/mute-actions-service';

export interface IWarningModalPayload {
  title?: string;
  subtitle?: string | string[];
  confirmText?: string;
  cancelText?: string;
  onConfirm?(): void;
  onCancel?(): void;
  // Theme of the CTA button
  theme?: ButtonProps['theme'];
  // Confirm modal has Cancel and CTA buttons
  // Accept modal only has one CTA button
  type?: 'confirm' | 'accept';
  // Hide icon at the top of the modal
  noIcon?: boolean;
}
/**
 * A simple service to handle packages/web/components/bbcommon/warning-modal/warning-modal-lazy.tsx component.
 * Use it in thunks/epics/components to show a warning modal:
 *
 * Component example:
 *
 * const { warningModal } = useServices();
 * warningModal.toggleWarningModal({ ... });
 *
 * In epics/thunks it's available trough dependency.
 * `subscribe` method is used to subscribe to the modal state changes and it is meant to be used in warning modal component only.
 */
export class WarningModalService {
  constructor(private muteActions: MuteActionsService) {}

  toggleWarningModal(payload?: IWarningModalPayload) {
    this.muteActions.dispatch({ type: 'TOGGLE_WARNING_MODAL', payload });
  }

  subscribe(callback: (payload?: IWarningModalPayload) => void) {
    return this.muteActions
      .actions()
      .pipe(
        ofType('TOGGLE_WARNING_MODAL'),
        pluck<ActionWithPayload<IWarningModalPayload>, IWarningModalPayload>('payload'),
      )
      .subscribe((payload) => {
        callback(payload);
      });
  }
}
