import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  contentWrap: FelaCSS;
  buttonWrap: FelaCSS;
}

const styles = (): IStyles => ({
  contentWrap: {
    position: 'relative',
    maxWidth: 360,
    alignSelf: 'center',
    paddingTop: 8,
    paddingHorizontal: 6,
    paddingBottom: '6',
    borderRadius: '16px',
    backgroundColor: colors.white,
  },

  buttonWrap: {
    position: 'absolute',
    right: '4px',
    top: '4px',
    zIndex: 1,
  },
});

export default styles;
