import { CountryCodes, type Market } from '@bridebook/toolbox/src/gazetteer';

// TODO: [i18n][bb-global] we likely have to remove/expand this for bb-global

interface IWhitelistedSupplierLocale {
  supplierCountry?: CountryCodes;
  market: Market;
  isLoggedIn: boolean;
}

const localeToHandler: Partial<
  Record<CountryCodes, (supplierCountry: CountryCodes, isLoggedIn: boolean) => boolean>
> = {
  [CountryCodes.GB]: (supplierCountry: CountryCodes, isLoggedIn: boolean) =>
    // US venues should be shown on /uk routing until full implementation
    supplierCountry === CountryCodes.GB || (isLoggedIn && supplierCountry === CountryCodes.US),
  [CountryCodes.DE]: (supplierCountry: CountryCodes) =>
    // Also whitelist Austrian supplier
    supplierCountry === CountryCodes.DE || supplierCountry === CountryCodes.AT,
  [CountryCodes.FR]: (supplierCountry: CountryCodes) => supplierCountry === CountryCodes.FR,
  [CountryCodes.IE]: (supplierCountry: CountryCodes) => supplierCountry === CountryCodes.IE,
};

/**
 * Validate supplier country and site routing locale so only relevant country specific suppliers are displayed.
 * @param supplierCountry - supplier / venue country code ( coming from Firestore address.country )
 * @param locale - site routing locale prefix
 * @param isLoggedIn - used to hide US venues for google indexer on /uk path https://bridebook.atlassian.net/browse/LIVE-13670
 */
export const isWhitelistedSupplierLocale = ({
  supplierCountry,
  market,
  isLoggedIn,
}: IWhitelistedSupplierLocale): boolean => {
  /**
   * Logged in users can see all the suppliers from every country.
   */
  if (isLoggedIn) {
    return true;
  }

  if (!supplierCountry) {
    return true;
  }

  /**
   * @TODO [i18n] Should we make use of additional countries here?
   */
  if (localeToHandler[market.country]) {
    return Boolean(localeToHandler[market.country]?.(supplierCountry, isLoggedIn));
  }

  return true;
};
