import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import { useUpdateFilters } from 'components/search/hooks';
import {
  IRadiusOption,
  getDistanceFilterOptions,
} from 'lib/search/get-radius-increase-filter-options';
import { IQuickDropdownHookData } from 'lib/types';
import { useSelector } from 'lib/utils';
import { getFilters } from '../selectors';
import { useSearchParams } from './query/use-search';

const DEFAULT_RADIO_VALUE = '0';

type IRadiusIncreaseFilter = IQuickDropdownHookData<IRadiusOption, number | undefined> & {
  defaultRadioValue: string;
  label: string;
};

const useRadiusIncreaseFilter = (location: string): IRadiusIncreaseFilter => {
  const { t } = useTranslation('search');
  const filters = useSelector(getFilters);
  const searchParams = useSearchParams();
  const slug = searchParams?.slug;
  const market = searchParams?.market;
  const optionItems = useMemo(() => getDistanceFilterOptions(market, slug), [slug, market]);
  const isFilterActive = !!filters?.misc?.radiusIncrease;
  const updateFilters = useUpdateFilters(location);
  const label = t('filters.quickFilterRadius.distanceLabel');

  const checkedOptions = useMemo(
    () =>
      optionItems
        .filter(({ value }) => value === parseInt(filters?.misc?.radiusIncrease, 10))
        .slice(-1),
    [filters?.misc?.radiusIncrease, optionItems],
  );

  const onFilterApply: IRadiusIncreaseFilter['onFilterApply'] = useCallback(
    (value, skipAnalytics) => {
      updateFilters({
        name: 'misc',
        value: { radiusIncrease: value },
        skipAnalytics,
      });
    },
    [updateFilters],
  );

  return {
    optionItems,
    isFilterActive,
    checkedOptions,
    onFilterApply,
    defaultRadioValue: DEFAULT_RADIO_VALUE,
    label,
  };
};

export default useRadiusIncreaseFilter;
