import React from 'react';
import { getAddSupplierModalOpened } from 'lib/shortlist/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const AddSupplierModal = lazyComponent(() => import('./add-supplier-modal'));

interface AddSupplierModalLazyProps {}

const AddSupplierModalLazy: React.FC<AddSupplierModalLazyProps> = () => {
  const show = useSelector(getAddSupplierModalOpened);

  if (!show) {
    return null;
  }

  return <AddSupplierModal />;
};

export default React.memo(AddSupplierModalLazy);
