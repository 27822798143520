import { guestsCount as getGuestsCount } from 'lib/guestlist/selectors';
import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const ADD_YOUR_FIRST_GUEST_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '6330a8e3-929d-4c7d-acc7-b0da8123fde7',
  storage: 'wedding',
  name: 'Add your first guest',
  type: 'interaction',
  isTaskCompleted: ({ getState }) => {
    const state = getState();
    const guestsCount = getGuestsCount(state);
    return guestsCount > 0 || isTodayTaskCompleted(state, ADD_YOUR_FIRST_GUEST_TASK_DEFINITION);
  },
};

registerTodayTask(ADD_YOUR_FIRST_GUEST_TASK_DEFINITION);
