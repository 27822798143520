import { FelaCSS } from '@bridebook/ui';

interface IStyle {
  wrapper: FelaCSS;
  searchBarMiddle: {
    dashboard: FelaCSS;
    searchBar: FelaCSS;
  };
}

const style = (showMobileSearchBar?: boolean, isNewSearchbar?: boolean): IStyle => ({
  wrapper: showMobileSearchBar
    ? {
        position: 'sticky',
        zIndex: 4,
        top: 0,
      }
    : {},
  searchBarMiddle: {
    dashboard: {
      ...(isNewSearchbar
        ? {
            desktopDesign: {
              paddingBottom: '53px',
            },
          }
        : {}),
    },
    searchBar: {
      ...(isNewSearchbar
        ? {
            marginTop: '-53px',
          }
        : {}),
    },
  },
});

export default style;
