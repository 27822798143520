import { IGroup } from '@bridebook/models/source/models/Countries/Groups.types';
import type { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { AuthActionTypes } from 'lib/auth/action-types';
import { MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { SearchActionTypes } from 'lib/search/action-types';
import { Query } from 'lib/types';

export interface DeviceState {
  height: number;
  host: string;
  iosVersion?: string;
  isAndroid: boolean;
  isChrome: boolean;
  isCordova: boolean;
  isDesktop: boolean;
  isDesktopDesign: boolean;
  isIE: boolean;
  isiOS: boolean;
  isMac: boolean;
  isMainLiveCDN: boolean;
  isMobile: boolean;
  isMobileSafari: boolean;
  isMobileUA: boolean;
  isTablet: boolean;
  isTabletUA: boolean;
  sessionId: string;
  versionMajor: number;
  width: number;
  serverCountryCode: CountryCodes | null;
  analyticsContext?: any;
  isLandscape?: boolean;
}

export type AppAction =
  | {
      type: 'SET_APP_ONLINE';
      payload: { online: boolean };
    }
  | { type: 'APP_STARTED' }
  | { type: 'TOGGLE_BASELINE' }
  | {
      type: 'ROUTE_CHANGE_COMPLETE';
      payload: {
        url: string;
        query: Query;
        previousPath: string;
        serverPage: string;
      };
    }
  | {
      type: 'ROUTE_CHANGE_START';
      payload: {
        url: string;
        urlWithoutMarket: string;
        query: Query;
        nextUrl: string;
        locale: string;
      };
    }
  | { type: 'SIGN_OUT' }
  | { type: 'TOGGLE_DARK' }
  | {
      type: 'RESIZE_WINDOW';
      payload: {
        width: number;
        height: number;
        isMobile: boolean;
        isTablet: boolean;
        isLandscape: boolean;
        isDesktop: boolean;
        isDesktopDesign: boolean;
      };
    }
  | { type: 'SCROLL_SEARCH_WINDOW'; payload: number }
  | { type: 'ON_APP_VERSION'; payload: string }
  | { type: 'CHECK_IF_CORDOVA_AGAIN'; payload: boolean }
  | { type: 'APP_ERROR' }
  | {
      type: 'COMPLETED_APP_BANNER_ANALYTICS';
    }
  | {
      type: 'SET_PATHNAME';
      payload: string;
    }
  | { type: 'TOGGLE_MOBILE_KEYBOARD'; payload: boolean }
  | { type: 'GLOBAL_LISTENERS_INITIALISED' }
  | {
      type: 'SET_META_LINKS';
      payload: {
        canonical?: string;
        alternate?: AlternateLink[];
      };
    }
  | {
      type: 'SET_DEVICE';
      payload: DeviceState;
    }
  | {
      type: 'FETCH_GROUPS_SUCCESS';
      payload: Record<string, IGroup>;
    }
  | {
      type: MobileAppActionTypes.WHICH_HANDHELD_DEVICE_TYPE;
      payload: {
        isAndroid: boolean;
        isiOS: boolean;
      };
    }
  | {
      type: SearchActionTypes.CLEAR_ALL_SEARCH;
    }
  | {
      type: AuthActionTypes.TRIGGER_SIGN_OUT;
    }
  | {
      type: 'USED_HEADER_NAVIGATION' | 'USED_MENU_NAVIGATION' | 'USED_HEADER_SEARCH';
    }
  | { type: 'DECREMENT_PILING_COUNTER' }
  | { type: 'MOBILE_NAVIGATION_TOGGLE_SEARCH' }
  | { type: AuthActionTypes.SAVE_NEXT_PATH_AFTER_ONBOARDING; payload: string };

export type AlternateLink = {
  href: string;
  hrefLang: Market['locale'] | 'x-default';
};

export interface AppState {
  online: boolean;
  started: boolean;
  version: string | null;
  pathname: string;
  serverPage: string;
  redirectToAfterOnboarding: string | null;
  groups: Record<string, IGroup>;
  groupsLoaded: boolean;
  query: Query;
  asPath: string;
  url: string;
  locale: string;
  nextUrl: string;
  previousPath: string;
  // added lastPath since previousPath isn't working as expected and fixing it right now is hard due to some features depending on this broken functionality
  lastPath: string;
  previousQuery: Query;
  previousSearch: string;
  scrollY: number;
  scrollYSearch: number;
  prevScrollY: number;
  device: DeviceState;
  previousSearchQuery: Query;
  mobileKeyboardOpened: boolean;
  startedMainNavigationCounter: number;
  navigationJourneyStartPath: string;
  pilingCounter: number;
  canonicalLink: string;
  alternateLinks: AlternateLink[];
  deviceServerSet: boolean;
  deviceClientSet: boolean;
}

export interface AppUrl extends Record<PropertyKey, unknown> {
  pathname: AppState['pathname'];
  query?: AppState['query'];
}

export enum DevicePlatform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}
