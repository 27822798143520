import React from 'react';
import { IBookPhotographerModalProps } from 'components/book-photographer/book-photographer-modal';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const BookPhotographerModal = lazyComponent<IBookPhotographerModalProps>(
  () => import('components/book-photographer/book-photographer-modal'),
);

const BookPhotographerModalLazy: React.FC = () => {
  const nextAction = useSelector((state) => state.ui.nextAction);

  if (
    nextAction &&
    nextAction.type === 'venueConfirmation' &&
    nextAction.action === 'discoverPhotographers' &&
    nextAction.active
  ) {
    return (
      <BookPhotographerModal
        supplier={nextAction.triggerAction.payload.item}
        show={nextAction.active}
      />
    );
  } else {
    return null;
  }
};

export default React.memo(BookPhotographerModalLazy);
