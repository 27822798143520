import { mergeAll } from 'ramda';
import { ISearchQuery } from 'app-shared/lib/search/types';
import getDefaultExtraFilters from 'lib/search/utils/get-default-extra-filters';

export const createFullQuery = (
  searchQuery: ISearchQuery,
  extraFilters: ReturnType<typeof getDefaultExtraFilters> = {},
) => {
  const clonedSearchQuery = { ...searchQuery };

  const { facets, ...rest } = clonedSearchQuery;

  delete clonedSearchQuery.query;
  delete clonedSearchQuery.searchParams;
  delete clonedSearchQuery.facets;

  const facetsMapped = mergeAll((facets || []).map((key) => ({ [key]: 'true' })));
  const fullQuery = { ...rest, ...clonedSearchQuery, ...extraFilters, ...facetsMapped };

  return fullQuery;
};

export type FullQuery = ReturnType<typeof createFullQuery>;
