import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconChecklist = ({ id, color, width, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 16;

  const icon = ({ iconColor }: IconTypes) => (
    <svg
      width={width || '18'}
      height={width || '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 8.78571L7.37471 11.4159C7.52805 11.7093 7.91903 11.7753 8.16026 11.5486L13 7"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-2-inside-1_1976_809" fill="white">
        <rect x="1" y="1" width="16" height="16" rx="1" />
      </mask>
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="1"
        stroke={iconColor}
        strokeWidth="3"
        mask="url(#path-2-inside-1_1976_809)"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconChecklist;
