import dynamic, { LoaderComponent } from 'next/dynamic';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';

const Loading = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);
  return <></>;
};

export function lazyComponent<P>(loader: () => LoaderComponent<P>, loading = Loading) {
  return dynamic(loader, {
    loading,
    ssr: false,
  });
}
