import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import { useOnPathChange } from 'lib/utils/hooks/use-on-path-change';
import { useServices } from 'lib/utils/hooks/use-services';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';
import { IWarningModalPayload } from 'lib/utils/warning-modal-service';

const WarningModal = lazyComponent<IWarningModalPayload>(
  () => import('components/bbcommon/warning-modal'),
);

const WarningModalLazy: React.FC<IWarningModalPayload> = () => {
  const { warningModal } = useServices();
  const [modalState, setModalState] = useState<IWarningModalPayload | undefined>();
  const subscription = useRef<Subscription | undefined>(undefined);

  useEffect(() => {
    subscription.current = warningModal.subscribe((state) => {
      setModalState(state);
    });

    return () => {
      subscription.current?.unsubscribe();
      setModalState(undefined);
    };
  }, [warningModal]);

  useOnPathChange(() => {
    warningModal.toggleWarningModal();
  });

  const onConfirm = useCallback(() => {
    modalState?.onConfirm?.();
  }, [modalState]);

  const onCancel = useCallback(() => {
    modalState?.onCancel?.();
  }, [modalState]);

  if (!modalState) return null;
  return <WarningModal {...modalState} onCancel={onCancel} onConfirm={onConfirm} />;
};

export default React.memo(WarningModalLazy);
