import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { imgixBaseURL } from '../buildImgixUrl';
import { CMSLocales, RoutingLocales } from './constants';

export const getTeamChatLockedImg = (locale: CMSLocales) => {
  const imgPathPrefix = `${imgixBaseURL}/assets/cms-team-chat/`;
  const imgPathSuffix = '?auto=compress';
  const localeToTeamChatImg: Record<CMSLocales, string | null> = {
    [CMSLocales.DE]: imgPathPrefix + 'chats-de.png' + imgPathSuffix,
    [CMSLocales.GB]: imgPathPrefix + 'chats.png' + imgPathSuffix,
    [CMSLocales.FR]: imgPathPrefix + 'chats-fr.png' + imgPathSuffix,
    [CMSLocales.PL]: null,
    [CMSLocales.US]: null,
    [CMSLocales.IE]: null,
  };

  return localeToTeamChatImg[locale] || (localeToTeamChatImg[CMSLocales.GB] as string);
};

// This is the prefix of the data used in RTDB
export const countryCodeToVenueProPrefix: Partial<Record<CountryCodes, string | null>> = {
  [CountryCodes.AT]: 'de',
  [CountryCodes.CH]: 'de',
  [CountryCodes.DE]: 'de',
  [CountryCodes.FR]: 'fr',
  [CountryCodes.GB]: 'en',
  [CountryCodes.IE]: 'ie',
  [CountryCodes.PL]: null,
  [CountryCodes.RU]: null,
  [CountryCodes.US]: null,
};

const toolCardChecklistBase = `${imgixBaseURL}/tools-cards/checklist-`;
const toolsCardChecklistImg: Record<RoutingLocales, string> = {
  [RoutingLocales.DE]: `${toolCardChecklistBase}de.png`,
  [RoutingLocales.FR]: `${toolCardChecklistBase}fr.png`, // @TODO: Upload when ready
  [RoutingLocales.UK]: `${toolCardChecklistBase}uk.png`,
  [RoutingLocales.IE]: `${toolCardChecklistBase}uk.png`,
};

export const getToolsCardChecklistImg = (locale: string) => {
  const key = locale as keyof typeof toolsCardChecklistImg;
  return toolsCardChecklistImg[key] ? toolsCardChecklistImg[key] : toolsCardChecklistImg.uk;
};

const prePromptModalImg: Partial<Record<CountryCodes | 'BBLite', string>> = {
  [CountryCodes.DE]: `${imgixBaseURL}/assets/push-pre-prompt/pre-prompt-de.png`,
  [CountryCodes.GB]: `${imgixBaseURL}/assets/push-pre-prompt/pre-prompt-uk.png`,
  [CountryCodes.IE]: `${imgixBaseURL}/assets/push-pre-prompt/pre-prompt-ie.png`,
  [CountryCodes.FR]: `${imgixBaseURL}/assets/push-pre-prompt/pre-prompt-fr.png`,
  BBLite: `${imgixBaseURL}/assets/push-pre-prompt/pre-prompt-bblite.png`,
};

export const getPrePromptModalImg = (countryCode: CountryCodes) =>
  prePromptModalImg[countryCode] ? prePromptModalImg[countryCode] : prePromptModalImg['BBLite'];
