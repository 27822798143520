import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SortByOption } from '@bridebook/toolbox/src/search-suppliers/types';
import { ISearchPageQuery } from 'app-shared/lib/search/types';
import {
  toggleFilters,
  toggleFiltersAnalytics,
  updateAllSearchFiltersAnalytics,
  updateSearchUrl,
} from 'lib/search/actions';
import { IUrl } from 'lib/types';

interface IUseToggleFiltersParams {
  filtersShown?: boolean;
  location?: IUrl<ISearchPageQuery>;
  sortBy?: SortByOption;
}

type IUseToggleFilters = (params?: IUseToggleFiltersParams) => () => void;

/**
 * useToggleFilters hook:
 *  - hides/shows search filters area
 *  - notifies google analytics about toggling filters area
 *  - for filters visible, it updates search url
 */
export const useToggleFilters: IUseToggleFilters = (params) => {
  const { filtersShown, location, sortBy } = params || {};
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(toggleFilters());
    dispatch(toggleFiltersAnalytics(filtersShown ? 'closeFilters' : 'searchBar'));

    if (filtersShown && location) {
      // When all filters modal is closing, we want to notify google analytics by sending Used
      // search filters action for each section individually.
      dispatch(updateAllSearchFiltersAnalytics('filtersModal'));
      // Update URL with search filters
      dispatch(updateSearchUrl({ location, sortBy }));
    }
  }, [dispatch, filtersShown, location, sortBy]);
};
