import React from 'react';
import { useSelector } from 'react-redux';
import { IShortlistUserLimitModal } from 'components/shortlist/shortlist-user-limit/shortlist-user-limit-modal/index';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const ShortlistUserLimitModal = lazyComponent<IShortlistUserLimitModal>(
  () => import('components/shortlist/shortlist-user-limit/shortlist-user-limit-modal'),
);

interface ShortlistUserLimitModalLazyProps {}

const ShortlistUserLimitModalLazy: React.FC<ShortlistUserLimitModalLazyProps> = () => {
  const show = useSelector<IApplicationState, boolean>((state) => state.shortlist.showUserLimit);

  if (!show) return null;

  return <ShortlistUserLimitModal show={show} />;
};

export default React.memo(ShortlistUserLimitModalLazy);
