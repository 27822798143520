import { LottiePlayer } from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getPathname } from 'lib/app/selectors';
import { getIsAnyQuizOpen } from 'lib/quiz/selectors';
import { hideAchievement } from 'lib/ui/actions';
import { imgixBaseURL, useDispatch, useSelector } from 'lib/utils';
import { selectShowVenueConfirm } from 'lib/venue-confirmation/selectors';
import { WEDDING_BADGES_QUERY_KEY } from 'lib/weddings/hooks/use-wedding-badges-query';
import {
  AchievementBadgeModal,
  AchievementBadgeVenueBookedModals,
} from './achievement-badge-modal';
import { achievements } from './achievements-data';

const AchievementBadgesModals = () => {
  const stateAchievements = useSelector((state) => state.ui.achievements);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const animationContainer = useRef<HTMLDivElement>();
  const pathName = useSelector(getPathname);
  const getAchievement = achievements();
  const openedAchievementIds = Object.keys(stateAchievements).filter(
    (id) => stateAchievements[id].isOpen,
  );
  const isAnyQuizOpen = useSelector(getIsAnyQuizOpen);
  const showVenueConfirm = useSelector(selectShowVenueConfirm);

  useEffect(() => {
    if (openedAchievementIds?.length > 0) {
      // TODO invalidate query on achievement unlocked - right now is on component level as there is no solution to call query client methods inside epics
      queryClient.invalidateQueries([WEDDING_BADGES_QUERY_KEY]);
    }
  }, [openedAchievementIds, queryClient]);

  useEffect(() => {
    const { current: wrapperEl } = animationContainer;

    if (!wrapperEl || wrapperEl?.childNodes.length > 0) {
      return;
    }

    let unmounted = false;

    (async () => {
      const lottie = (await import('lottie-web')) as unknown as LottiePlayer;

      if (unmounted) {
        return;
      }

      lottie.loadAnimation({
        autoplay: true,
        container: wrapperEl,
        loop: true,
        path: imgixBaseURL + `/assets/achievements/achievementsAnimation.json`,
        renderer: 'svg',
      });
    })();

    return () => {
      unmounted = true;
    };
  }, [openedAchievementIds]);

  const isOnboarding = (pathname: string): boolean => pathname.indexOf('/onboarding') === 0;

  if (!openedAchievementIds) return null;
  if (openedAchievementIds && isOnboarding(pathName)) {
    openedAchievementIds.map((id) => {
      dispatch(hideAchievement({ id }));
    });
    return null;
  }

  // Don't show any badges while venue confirm or quiz flow is active
  if (showVenueConfirm || isAnyQuizOpen) return null;

  return (
    <>
      {openedAchievementIds.map((id) => {
        if (id === 'venueBooked') {
          return (
            <AchievementBadgeVenueBookedModals
              key={id}
              achievement={getAchievement[id]()}
              badgeState={stateAchievements[id]}
              setAnimationRef={animationContainer}
            />
          );
        } else {
          return (
            <AchievementBadgeModal
              key={id}
              achievement={getAchievement[id]()}
              badgeState={stateAchievements[id]}
              setAnimationRef={animationContainer}
            />
          );
        }
      })}
    </>
  );
};

export default AchievementBadgesModals;
