import React from 'react';
import { useSelector } from 'react-redux';
import { IViewVenuerexPopup } from 'components/venuerex/view-venuerex-popup/index';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const ViewVenuerexPopup = lazyComponent<IViewVenuerexPopup>(
  () => import('components/venuerex/view-venuerex-popup'),
);

interface LazyViewVenuerexPopupProps {}

const LazyViewVenuerexPopup: React.FC<LazyViewVenuerexPopupProps> = () => {
  const show = useSelector<IApplicationState, boolean>(
    (state) => state.venuerex.showGoToVenuerexPageModal,
  );

  if (!show) return null;

  return <ViewVenuerexPopup showGoToVenuerexPageModal={show} />;
};

export default React.memo(LazyViewVenuerexPopup);
