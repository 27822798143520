import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';
import { getVenueBooked } from 'lib/weddings/selectors';

export const MARK_AS_BOOKED_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'b9410920-6fe7-410d-b2b3-3ea6a0f14ff4',
  storage: 'wedding',
  name: 'Mark venue as booked',
  type: 'interaction',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;
    const venueBooked = getVenueBooked(state);

    return !suppliers?.includes('venue') || venueBooked;
  },
};

registerTodayTask(MARK_AS_BOOKED_TASK_DEFINITION);
