import { User as FirebaseUser } from 'firebase/auth';
import { IUser } from '@bridebook/models/source/models/Users.types';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IDeps } from 'lib/types';
import { saveUserProvider } from 'lib/users/actions';

/* ############################################################################
 *  SIGN IN
 * ######################################################################### */

/**
 * When signing in, start setting up the user and wedding data
 */
export const userSetupStarted = () => ({
  type: AuthActionTypes.USER_SETUP_STARTED,
});

/**
 * When signing in, save our user (received from the authenticate api endpoint) to state
 * @param firebaseUser
 * @param user
 * @param wedding
 */
export const userSetupCompleted =
  (firebaseUser: FirebaseUser, user: IUser, wedding: IWedding) =>
  ({ dispatch }: IDeps) => {
    const { providerData } = firebaseUser;
    providerData.forEach((userInfo) => {
      if (userInfo) {
        dispatch(saveUserProvider(userInfo));
      }
    });

    return {
      type: AuthActionTypes.USER_SETUP_COMPLETED,
      payload: { firebaseUser, user, wedding },
    };
  };

/**
 * After user is logged-out from state, API and Firebase
 * */
export const signOutCompleted = () => ({
  type: AuthActionTypes.SIGN_OUT_COMPLETED,
});

/**
 * Triggered if a user is signed out from Firebase
 * */
export const isSignedOutFirebase = () => ({
  type: AuthActionTypes.USER_IS_SIGNED_OUT,
});
