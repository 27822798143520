import { FelaCSS } from '@bridebook/ui';

export const createAnimation =
  (keyframes: Record<string, FelaCSS>, override?: FelaCSS) =>
  (duration: number, delay: number = 0): FelaCSS => ({
    animationName: keyframes as unknown as FelaCSS['animationName'],
    animationDelay: `${delay}s`,
    animationDuration: `${duration}s`,
    animationIterationCount: 1,
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
    ...override,
  });
