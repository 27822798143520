import React, { ReactNode } from 'react';
import { Box, H4, P } from '@bridebook/ui';
import Modal from 'components/bbcommon/modal';
import ModalCloseButton from 'components/bbcommon/modal/modal-close-button/modal-close-button';
import componentStyles from './modal-notification.style';

interface IProps {
  show: boolean;
  id: string;
  children: ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
}

interface IPropsChildren {
  children: ReactNode;
}

const styles = componentStyles();

const Body = ({ children }: IPropsChildren) => <Box style={styles.bodyWrapper}>{children}</Box>;

const Title = ({ children }: IPropsChildren) => <H4 style={styles.title}>{children}</H4>;

const Buttons = ({ children }: IPropsChildren) => (
  <Box style={styles.buttonWrapper}>{children}</Box>
);

const Description = ({ children }: IPropsChildren) => <P style={styles.description}>{children}</P>;

const ModalNotification = ({ show, id, children, onClose, showCloseButton }: IProps) => (
  <Modal alignTop onClose={onClose} show={show} id={id}>
    <Box style={styles.wrapper}>
      <Box style={styles.modal}>
        {showCloseButton && (
          <Box style={styles.closeButtonWrapper}>
            <ModalCloseButton onClose={onClose} />
          </Box>
        )}
        <Box style={styles.content}>{children}</Box>
      </Box>
    </Box>
  </Modal>
);

ModalNotification.Body = Body;
ModalNotification.Title = Title;
ModalNotification.Description = Description;
ModalNotification.Buttons = Buttons;

export default ModalNotification;
