import { useEffect, useState } from 'react';
import { App } from '@capacitor/app';
import { PermissionStatus, PushNotifications } from '@capacitor/push-notifications';
import { isCordovaPluginAvailable } from 'lib/mobile-app/utils/cordova-plugin-check';

export const useCheckPushPermissions = () => {
  const [pushPermissions, setPushPermissions] = useState<PermissionStatus['receive']>();

  useEffect(() => {
    const checkPushPermissions = async () => {
      if (!isCordovaPluginAvailable('PushNotifications')) {
        return;
      }

      /** Check if the user has already granted permission to receive push notifications */
      const permissions = await PushNotifications.checkPermissions();

      setPushPermissions(permissions?.receive ?? 'denied');
    };

    /**
     * Since we have no listener for actual push permissions, we need to add
     * a listener for the app state change, which is automatically triggered
     * when either the permission system dialog is triggered or the user is
     * routed to the device settings and comming back from it. This will
     * ensure that the state of push permissions is always up to date.
     */
    const addAppStateListener = async () =>
      await App.addListener('appStateChange', (state) => {
        if (state.isActive) {
          checkPushPermissions();
        }
      });

    const initialize = async () => {
      const appStateHandler = await addAppStateListener();
      await checkPushPermissions();

      return () => {
        appStateHandler.remove();
      };
    };

    const cleanup = initialize();

    return () => {
      cleanup.then((handler) => handler && handler());
    };
  }, []);

  return pushPermissions;
};
