import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  width?: number;
  style?: FelaCSS;
  color?: string;
  borderColor?: string;
  fillOpacity?: number;
  fillColor?: string;
}

interface IconTypes {
  width: number;
  height: number;
}

const IconBack = ({
  id,
  width = 38,
  style,
  color = 'white',
  borderColor = 'none',
  fillOpacity = 0.2,
  fillColor = 'white',
  ...restProps
}: IProps) => {
  const icon = ({ width, height }: IconTypes) => (
    <svg viewBox={`0 0 38 38`} width={width} height={height} fill="none">
      <rect
        x="1"
        y="1"
        width="36"
        height="36"
        rx="18"
        stroke={borderColor}
        fill={fillColor}
        fillOpacity={fillOpacity}
      />
      <path
        style={{ fill: 'none' }}
        d="M20 13 L14 19 L20 25"
        stroke={color}
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      width={width}
      viewBoxWidth={width}
      viewBoxHeight={width}
      style={style}
      {...restProps}
    />
  );
};

export default IconBack;
