import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { getDateFnsLocale } from 'lib/i18n/utils/get-date-fns-format';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import {
  PriceFilterWeekDay,
  getPriceFilterSeasonTranslations,
} from 'lib/search/utils/wedding-estimate-price-filter-helpers';

export const useSeasonsAndWeekDaysOptionsForPriceFilter = () => {
  const { t } = useTranslation('search');
  const locale = getDateFnsLocale();
  const searchParams = useSearchParams();
  const searchMarket = searchParams?.market;
  const weddingMarket = useMarket();
  const market = searchMarket || weddingMarket;

  const priceFilterWeekDayTranslations: Record<PriceFilterWeekDay, string> = useMemo(
    () => ({
      monWed: t('search:dayOfWeek.mondayToWednesday'),
      thu: t('search:dayOfWeek.thursday'),
      fri: t('search:dayOfWeek.friday'),
      sat: t('search:dayOfWeek.saturday'),
      sun: t('search:dayOfWeek.sunday'),
    }),
    [t],
  );
  const priceFilterSeasonTranslations = getPriceFilterSeasonTranslations(market, locale);

  return {
    priceFilterSeasonTranslations,
    priceFilterWeekDayTranslations,
  };
};
