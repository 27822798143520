import { getI18n } from 'lib/i18n/getI18n';
import { IIntlMessageDescriptor } from 'lib/types';

export const getCustomAuthMessages = (): { [k: string]: IIntlMessageDescriptor } => {
  const i18n = getI18n();
  return {
    'custom/api-authenticate-failed': {
      defaultMessage: i18n.t('auth:apiFailed.tryAgain'),
      id: 'custom.apiFailed',
    },
  };
};
