import { createSelector } from 'reselect';
import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { IDropdownOption } from '@bridebook/ui';
import { IGuestlistCategory } from 'lib/guestlist/types';
import { getCategoryName } from 'lib/guestlist/utils';
import { InitialisedGuestListStorage } from 'lib/storage-manager/basic/initialised-guest-list';
import { IApplicationState } from 'lib/types';
import { getPartners } from 'lib/weddings/selectors';

const getCategories = (state: IApplicationState) => state.weddings.profile.guests.categories;

const getTables = (state: IApplicationState) => state.weddings.profile.guests.tables;

const isCordova = (state: IApplicationState) => state.app.device.isCordova;

const getModalGuest = (state: IApplicationState) => state.guestlist.guest;

export const guestsCount = (state: IApplicationState) => state.weddings.profile.guests.count;

export const getGuests = (state: IApplicationState) => state.guestlist.list;

const hasInitialised = () => InitialisedGuestListStorage.get();

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

export const getSortedCategories = createSelector(
  [getCategories],
  ({ data, sort = [] }): Array<IGuestlistCategory> =>
    sort.map((id: string) => ({
      id,
      value: data[id],
    })),
);

export const getCategoriesDropdownOptions = createSelector(
  [getSortedCategories, getPartners],
  (categories, partners): Array<IDropdownOption> =>
    categories.map((category: IGuestlistCategory) => ({
      value: category.id,
      label: getCategoryName({ category, partners }),
    })),
);

export const getGuestTableName = createSelector(
  [getTables, getModalGuest],
  (tables, guest): string | undefined => {
    if (!tables || !guest.table) {
      return undefined;
    }
    return tables.data[guest.table];
  },
);

export const hasCordovaContacts = createSelector([isCordova], (isCordova): boolean => isCordova);

export const isGuestListInitialised = createSelector(
  [guestsCount, hasInitialised],
  (guestsCount, hasInitialised) => guestsCount > 0 || hasInitialised,
);
export const getCurrentGuest = createSelector([getModalGuest], (guest: IGuest) => guest);

export const getGuestlistLoaded = (state: IApplicationState) => state.guestlist.loaded;
