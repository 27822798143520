import React from 'react';
import { useSelector } from 'react-redux';
import { IEnquiriesUserLimitModal } from 'components/enquiries/enquiries-user-limit/enquiries-user-limit-modal/index';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const EnquiriesUserLimitModal = lazyComponent<IEnquiriesUserLimitModal>(
  () => import('components/enquiries/enquiries-user-limit/enquiries-user-limit-modal'),
);

interface EnquiriesUserLimitModalLazyProps {}

const EnquiriesUserLimitModalLazy: React.FC<EnquiriesUserLimitModalLazyProps> = () => {
  const show = useSelector<IApplicationState, boolean>((state) => state.enquiries.showUserLimit);
  if (!show) return null;

  return <EnquiriesUserLimitModal show={show} />;
};

export default React.memo(EnquiriesUserLimitModalLazy);
