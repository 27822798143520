import { FelaCSS } from '@bridebook/ui';

interface IStyleArgs {
  imageUrl: string;
}

interface IStyles {
  imageWrapper: FelaCSS;
}

const styles = ({ imageUrl }: IStyleArgs): IStyles => ({
  imageWrapper: {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

export default styles;
