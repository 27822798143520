import {
  addedGuestAnalytics,
  addedMultipleGuestsAnalytics,
  clickedAddGuestsAnalytics,
  initialisedGuestlistAnalytics,
  sharedGuestCollectorLinkAnalytics,
  toggleBulkAddGuestsMethodAnalytics,
} from 'lib/guestlist/analytics/actions';

export enum GuestlistAnalyticsActionTypes {
  INITIALISED_GUESTLIST = '[Analytics] Guestlist initialised',
  TOGGLE_BULK_ADD_METHOD = '[Analytics] Toggle bulk add guests method',
  CLICKED_ADD_GUESTS = '[Analytics] Clicked to add guests',
  ADDED_MULTIPLE_GUESTS = '[Analytics] Added multiple guests',
  OPENED_GUEST_COLLECTOR_POPUP = '[Analytics] Opened guest collector popup',
  CLOSED_GUEST_COLLECTOR_POPUP = '[Analytics] Closed guest collector popup',
  CLICKED_TO_SHARE_GUEST_COLLECTOR_LINK = '[Analytics] Clicked to share guest collector link',
  SHARED_GUEST_COLLECTOR_LINK = '[Analytics] Shared guest collector link',
  COPIED_GUEST_COLLECTOR_LINK = '[Analytics] Copied guest collector link',
}

export type AddedMultipleGuestsAnalyticsAction = ReturnType<typeof addedMultipleGuestsAnalytics>;

export type AddedGuestAnalyticsAction = ReturnType<typeof addedGuestAnalytics>;

export type InitialisedGuestlistAnalyticsAction = ReturnType<typeof initialisedGuestlistAnalytics>;

export type ToggleBulkAddGuestsMethodAnalyticsAction = ReturnType<
  typeof toggleBulkAddGuestsMethodAnalytics
>;

export type ClickedAddGuestsAnalyticsAction = ReturnType<typeof clickedAddGuestsAnalytics>;

export type SharedGuestCollectorLinkAnalyticsAction = ReturnType<
  typeof sharedGuestCollectorLinkAnalytics
>;
