import React, { memo } from 'react';
import { Box } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import Modal from 'components/bbcommon/modal';
import { BackgroundStarsAnimation } from 'components/onboarding-new/components/animations/blocks/background-stars-animation';
import QuizStepWrapper from 'components/quiz/partials/quiz-step-wrapper/quiz-step-wrapper';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';
import { getSupplierToBook, selectShowVenueConfirm } from 'lib/venue-confirmation/selectors';
import GlobalVenueModalInfo from './shared/global-venue-modal-info';
import componentStyles from './venue-confirmation-modal.style';

const VenueConfirmation = lazyComponent(
  () => import('components/venue-confirmation/venue-confirmation'),
);

const VenueConfirmationModal = () => {
  const showVenueConfirm = useSelector(selectShowVenueConfirm);
  const supplierToBook = useSelector(getSupplierToBook);
  const { country } = useMarket();

  const showInfoDialog =
    showVenueConfirm &&
    supplierToBook &&
    !supplierToBook.customSupplier &&
    country !== supplierToBook?.countryCode;

  const styles = componentStyles();

  if (showInfoDialog) {
    return <GlobalVenueModalInfo />;
  }

  return (
    <Modal show={showVenueConfirm} id="venue-confirmation-modal" roundedBorders>
      <Box style={styles.wrapper}>
        <BackgroundStarsAnimation />
        <QuizStepWrapper>
          <VenueConfirmation />
        </QuizStepWrapper>
      </Box>
    </Modal>
  );
};
export default memo(VenueConfirmationModal);
