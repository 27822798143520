import { ChangeEventHandler } from 'react';
import Checkbox from 'components/common/checkbox/checkbox';
import { getFilters } from 'lib/search/selectors';
import { getBookedVenuePublicId } from 'lib/shortlist/selectors';
import { UpdateSearchFilters } from 'lib/types';
import { useSelector } from 'lib/utils';

interface IProps {
  field?: string;
  prop?: string | Record<'section' | 'field', any>;
  title: string;
  id: string;
  action(data: Omit<UpdateSearchFilters, 'location'>): void;
}

const FilterCheckbox = ({ field, prop, title, action, id }: IProps) => {
  const filters = useSelector(getFilters);
  const bookedVenuePublicId = useSelector(getBookedVenuePublicId);
  const getValue = (checked: boolean): any => {
    if (prop === 'ourVenue') {
      return checked ? bookedVenuePublicId : null;
    }
    return checked;
  };

  const getChecked = (
    parent: string,
    filterProp: string,
    filterSet: Record<string, any>,
  ): boolean | undefined => {
    const definedFilter =
      parent && typeof filterSet[parent] !== 'undefined' ? filterSet[parent] : undefined;

    const sec = typeof parent === 'undefined' ? filterSet : definedFilter;

    return sec && sec[filterProp];
  };

  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const parent = e.currentTarget.getAttribute('data-parent');
    const prop = e.currentTarget.getAttribute('data-prop');

    action({
      name: parent || e.target.name,
      value: { [prop as string]: getValue(e.target.checked) },
    });
  };

  const parent = typeof prop === 'object' ? prop.section : field;
  const filterProp = typeof prop === 'object' ? prop.field : prop;

  return (
    <Checkbox
      id={id}
      value={getChecked(parent, filterProp, filters)}
      name="name"
      data-parent={parent}
      data-prop={filterProp}
      onChange={onCheckboxChange}
      label={title}
      alignCheckboxToTop
    />
  );
};

export default FilterCheckbox;
