import React from 'react';

const venueBookedLocked = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 144 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M135.138 37.631L135.144 37.6348L135.151 37.6386C138.2 39.3193 140 42.4019 140 45.666V110.361C140 113.625 138.2 116.708 135.151 118.389L135.145 118.392L135.139 118.395L76.9866 150.723C76.985 150.723 76.9835 150.724 76.9819 150.725C73.8947 152.425 70.1053 152.425 67.0181 150.725C67.0165 150.724 67.015 150.723 67.0134 150.723L8.86115 118.395L8.85495 118.392L8.84873 118.389C5.8 116.708 4 113.625 4 110.361V45.666C4 42.402 5.8 39.3193 8.84873 37.6386L8.85557 37.6348L8.8624 37.631L67.0134 5.27742C67.0155 5.27628 67.0176 5.27514 67.0196 5.274C70.1061 3.57533 73.8939 3.57533 76.9804 5.274C76.9824 5.27514 76.9845 5.27628 76.9866 5.27742L135.138 37.631Z"
      fill="#EDEFF0"
      stroke="#DDE0E3"
      strokeWidth="8"
    />
    <path
      d="M108.634 78.1914C108.983 78.9105 109.316 79.1741 109.722 78.9424C111.086 78.1675 112.012 78.9345 113.011 79.7015C114.001 80.4684 115.041 80.4684 115.942 79.4937C116.194 79.2221 116.478 78.8785 116.088 78.511C115.812 78.2474 115.471 78.2953 115.228 78.543C114.253 79.5337 113.612 78.6788 112.938 78.1435C111.817 77.2567 110.672 76.977 109.389 77.7999C109.113 77.9757 108.812 78.1035 108.634 78.1914Z"
      fill="#BABFC5"
    />
    <path
      d="M89.9143 56.1078C89.7681 54.7416 89.5245 53.7269 90.9456 53.4632C91.0592 53.4393 91.2135 52.9918 91.1405 52.8241C91.0674 52.6563 90.7507 52.5285 90.5233 52.5045C89.3215 52.3607 88.3389 53.5032 88.5582 54.9653C88.7449 56.2196 88.9398 57.3781 87.535 58.1131C87.202 58.2889 87.1696 58.6324 87.3644 58.9361C87.5999 59.2956 87.9329 59.1438 88.2171 59.008C89.6706 58.3368 90.0929 57.1704 89.9143 56.1078Z"
      fill="#BABFC5"
    />
    <path
      d="M109.562 63.6903C110.074 63.7383 110.447 63.7383 110.594 63.4107C110.74 63.0991 110.618 62.7316 110.252 62.6517C108.75 62.3321 109.278 61.2136 109.343 60.3507C109.44 59.0245 109.116 57.9459 107.865 57.2907C107.548 57.123 107.159 56.8194 106.834 57.2428C106.533 57.6343 106.728 57.9858 107.11 58.1856C108.352 58.8407 108.239 59.9033 108.027 61.0139C107.768 62.436 108.263 63.3788 109.562 63.6903Z"
      fill="#BABFC5"
    />
    <path
      d="M101.506 66.295C102.099 66.2551 102.497 65.9355 102.554 65.3762C102.619 64.7131 102.221 64.2657 101.547 64.2497C100.873 64.2337 100.459 64.6652 100.499 65.3363C100.524 65.9035 100.922 66.2231 101.506 66.295Z"
      fill="#BABFC5"
    />
    <path
      d="M80.823 66.295C81.4726 66.239 81.9111 65.9115 81.9111 65.2324C81.9111 64.6491 81.5295 64.2736 80.9529 64.2417C80.2708 64.2097 79.8567 64.6092 79.8485 65.2803C79.8404 65.8635 80.2383 66.1751 80.823 66.295Z"
      fill="#BABFC5"
    />
    <path
      d="M108.302 71.1741C107.709 71.3099 107.157 71.5815 107.19 72.2367C107.222 72.8599 107.75 73.2354 108.375 73.2114C108.984 73.1874 109.528 72.8199 109.496 72.1808C109.471 71.5496 108.927 71.262 108.302 71.1741Z"
      fill="#BABFC5"
    />
    <path
      d="M95.8621 73.6542C96.3737 73.7022 96.7473 73.7022 96.8934 73.3746C97.0396 73.063 96.9178 72.6955 96.5524 72.6156C95.0501 72.296 95.5779 71.1775 95.6429 70.3146C95.7403 68.9883 95.4155 67.9097 94.165 67.2546C93.8483 67.0868 93.4585 66.7832 93.1337 67.2067C92.8332 67.5982 93.0281 67.9497 93.4098 68.1494C94.6522 68.8046 94.5385 69.8672 94.3274 70.9777C94.0675 72.3999 94.5629 73.3426 95.8621 73.6542Z"
      fill="#BABFC5"
    />
    <path
      d="M67.1228 76.2588C67.7724 76.2029 68.2109 75.8753 68.2109 75.1962C68.2109 74.613 67.8293 74.2375 67.2527 74.2055C66.5706 74.1736 66.1565 74.573 66.1483 75.2442C66.1402 75.8274 66.5381 76.139 67.1228 76.2588Z"
      fill="#BABFC5"
    />
    <path
      d="M34.8204 78.1914C34.4713 78.9105 34.1383 79.1741 33.7323 78.9424C32.3681 78.1675 31.4423 78.9345 30.4435 79.7015C29.4528 80.4684 28.4134 80.4684 27.512 79.4937C27.2603 79.2221 26.9761 78.8785 27.3659 78.511C27.6419 78.2474 27.983 78.2953 28.2266 78.543C29.2011 79.5337 29.8426 78.6788 30.5166 78.1435C31.6372 77.2567 32.7822 76.977 34.0652 77.7999C34.3413 77.9757 34.6418 78.1035 34.8204 78.1914Z"
      fill="#BABFC5"
    />
    <path
      d="M53.54 56.1078C53.6861 54.7416 53.9297 53.7269 52.5087 53.4632C52.395 53.4393 52.2407 52.9918 52.3138 52.8241C52.3869 52.6563 52.7036 52.5285 52.9309 52.5045C54.1328 52.3607 55.1153 53.5032 54.8961 54.9653C54.7093 56.2196 54.5144 57.3781 55.9193 58.1131C56.2522 58.2889 56.2847 58.6324 56.0898 58.9361C55.8543 59.2956 55.5214 59.1438 55.2371 59.008C53.7836 58.3368 53.3613 57.1704 53.54 56.1078Z"
      fill="#BABFC5"
    />
    <path
      d="M33.892 63.6903C33.3804 63.7383 33.0069 63.7383 32.8607 63.4107C32.7145 63.0991 32.8364 62.7316 33.2018 62.6517C34.7041 62.3321 34.1762 61.2136 34.1113 60.3507C34.0138 59.0245 34.3386 57.9459 35.5892 57.2907C35.9059 57.123 36.2957 56.8194 36.6205 57.2428C36.921 57.6343 36.7261 57.9858 36.3444 58.1856C35.102 58.8407 35.2157 59.9033 35.4268 61.0139C35.6866 62.436 35.1913 63.3788 33.892 63.6903Z"
      fill="#BABFC5"
    />
    <path
      d="M41.9479 66.295C41.3551 66.2551 40.9572 65.9355 40.9003 65.3762C40.8354 64.7131 41.2333 64.2657 41.9073 64.2497C42.5813 64.2337 42.9954 64.6652 42.9548 65.3363C42.9305 65.9035 42.5326 66.2231 41.9479 66.295Z"
      fill="#BABFC5"
    />
    <path
      d="M62.6312 66.295C61.9816 66.239 61.5431 65.9115 61.5431 65.2324C61.5431 64.6491 61.9248 64.2736 62.5013 64.2417C63.1834 64.2097 63.5976 64.6092 63.6057 65.2803C63.6138 65.8635 63.2159 66.1751 62.6312 66.295Z"
      fill="#BABFC5"
    />
    <path
      d="M35.1521 71.1741C35.7449 71.3099 36.2971 71.5815 36.2646 72.2367C36.2321 72.8599 35.7043 73.2354 35.079 73.2114C34.47 73.1874 33.9259 72.8199 33.9584 72.1808C33.9828 71.5496 34.5268 71.262 35.1521 71.1741Z"
      fill="#BABFC5"
    />
    <path
      d="M47.5921 73.6542C47.0805 73.7022 46.707 73.7022 46.5608 73.3746C46.4146 73.063 46.5364 72.6955 46.9018 72.6156C48.4041 72.296 47.8763 71.1775 47.8113 70.3146C47.7139 68.9883 48.0387 67.9097 49.2893 67.2546C49.606 67.0868 49.9958 66.7832 50.3206 67.2067C50.621 67.5982 50.4261 67.9497 50.0445 68.1494C48.802 68.8046 48.9157 69.8672 49.1269 70.9777C49.3867 72.3999 48.8914 73.3426 47.5921 73.6542Z"
      fill="#BABFC5"
    />
    <path
      d="M76.3314 76.2588C75.6818 76.2029 75.2433 75.8753 75.2433 75.1962C75.2433 74.613 75.6249 74.2375 76.2015 74.2055C76.8836 74.1736 77.2978 74.573 77.3059 75.2442C77.314 75.8274 76.9161 76.139 76.3314 76.2588Z"
      fill="#BABFC5"
    />
    <path
      d="M96.9195 104.8H48.3468V84.8727L59.5559 76.1545V68.6818L72.6332 54.9818L85.7104 68.6818V76.1545L96.9195 84.8727V104.8Z"
      fill="#F6F7F8"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M77.3385 53.0801C77.3385 52.441 77.0846 51.828 76.6326 51.376C76.1806 50.9241 75.5677 50.6702 74.9285 50.6702C74.2893 50.6702 73.6764 50.9241 73.2244 51.376C72.7724 51.828 72.5185 52.441 72.5185 53.0801C72.5185 52.441 72.2646 51.828 71.8127 51.376C71.3607 50.9241 70.7477 50.6702 70.1086 50.6702C69.4694 50.6702 68.8564 50.9241 68.4045 51.376C67.9525 51.828 67.6986 52.441 67.6986 53.0801C67.6986 55.3056 71.2742 58.0477 72.5185 58.0477C73.7628 58.0477 77.3385 55.3439 77.3385 53.0801Z"
      fill="#DDE0E3"
    />
    <path
      d="M72.5184 58.7181C74.1114 58.7181 78.0083 55.7085 78.0083 53.0803C78.0086 52.4412 77.8101 51.8179 77.4402 51.2968C77.0704 50.7756 76.5475 50.3825 75.9441 50.1719C75.3407 49.9613 74.6868 49.9437 74.073 50.1215C73.4591 50.2993 72.9158 50.6637 72.5184 51.1642C72.121 50.6637 71.5777 50.2993 70.9639 50.1215C70.3501 49.9437 69.6961 49.9613 69.0927 50.1719C68.4894 50.3825 67.9665 50.7757 67.5966 51.2968C67.2267 51.818 67.0282 52.4413 67.0286 53.0804C67.0286 55.7088 70.9254 58.7181 72.5184 58.7181ZM70.1085 51.3406C70.5697 51.3411 71.012 51.5246 71.3382 51.8507C71.6644 52.1768 71.8479 52.619 71.8485 53.0803C71.8485 53.258 71.9191 53.4284 72.0447 53.554C72.1704 53.6797 72.3408 53.7503 72.5184 53.7503C72.6961 53.7503 72.8665 53.6797 72.9922 53.554C73.1178 53.4284 73.1884 53.258 73.1884 53.0803C73.1884 52.6188 73.3717 52.1762 73.698 51.8499C74.0243 51.5236 74.4669 51.3403 74.9284 51.3403C75.3899 51.3403 75.8325 51.5236 76.1588 51.8499C76.4851 52.1762 76.6684 52.6188 76.6684 53.0803C76.6684 54.9383 73.4519 57.3777 72.5184 57.3777C71.6554 57.3777 68.3685 54.9202 68.3685 53.0803C68.369 52.619 68.5526 52.1768 68.8788 51.8507C69.205 51.5246 69.6472 51.3411 70.1085 51.3406Z"
      fill="#BABFC5"
    />
    <path
      d="M72.6332 86.1182C74.4499 86.1182 76.1923 86.8211 77.4769 88.0724C78.7615 89.3236 79.4832 91.0207 79.4832 92.7902V104.8H65.7832V92.7902C65.7832 91.0207 66.5049 89.3236 67.7895 88.0724C69.0741 86.8211 70.8165 86.1182 72.6332 86.1182Z"
      fill="#DDE0E3"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="59.3104"
      y1="76.4019"
      x2="63.5377"
      y2="76.4019"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="80.4832"
      y1="76.4019"
      x2="84.7104"
      y2="76.4019"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default venueBookedLocked;
