import React, { PropsWithChildren } from 'react';
import { Box, FelaCSS } from '@bridebook/ui';
import mergeStyles from '../../../../fela-utils/merge-styles';
import { IStylingProps } from '../../../../themes/types';
import componentStyles from './h6.style';

interface IProps extends IStylingProps, PropsWithChildren {
  style?: FelaCSS;
}

const H6 = ({ style = {}, children, ...restProps }: IProps) => {
  const styles = componentStyles();

  return (
    <Box as="h6" style={mergeStyles([styles.wrapper, style])} data-name="h6" {...restProps}>
      {children}
    </Box>
  );
};

export default H6;
