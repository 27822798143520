import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconLock = ({ id, color, width = 11, style, ...restProps }: IProps) => {
  const viewBoxWidth = 11;
  const viewBoxHeight = 14;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="nonzero"
        d="M1.5 3.99C1.5 1.79 3.29 0 5.49 0s3.99 1.79 3.99 3.99v1.2h.376c.62 0 1.124.505 1.124 1.124v6.562c0 .62-.504 1.124-1.124 1.124H1.124C.504 14 0 13.496 0 12.876V6.314c0-.62.504-1.124 1.124-1.124H1.5v-1.2zm-.376 2.259a.068.068 0 0 0-.065.065v6.562c0 .035.03.066.065.066h8.732a.068.068 0 0 0 .066-.066V6.314a.068.068 0 0 0-.066-.065H1.124zm4.409 5.52h-.085c-.393 0-.712-.424-.712-.945V8.568c0-.522.32-.946.712-.946h.085c.392 0 .711.424.711.946v2.256c0 .521-.319.946-.711.946zm2.889-7.78A2.935 2.935 0 0 0 5.49 1.06a2.935 2.935 0 0 0-2.932 2.93V5.19h5.864v-1.2z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconLock;
