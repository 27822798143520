import { isEmpty, omit, pick } from 'ramda';
import { Month, Season } from '@bridebook/toolbox/src/datepicker/types';
import { ISearchParams } from 'lib/search/hooks/query/use-search';
import {
  PriceFilterWeekDay,
  getPriceFilterSeasonMapping,
  priceFilterWeekDayMapping,
} from 'lib/search/utils/wedding-estimate-price-filter-helpers';

/**
 * Converts wedding estimate price options (e.g. spring=true, thu=true)
 * to the format needed for backend
 * (array of months and array of week days)
 */
export const prepareEstimateWeddingPriceParamsForSearchRequest = (params: ISearchParams) => {
  const priceFilterSeasonOptions = getPriceFilterSeasonMapping(params.market);

  const selectedWeddingSeasons = Object.keys(pick(Object.keys(priceFilterSeasonOptions), params));
  const selectedWeddingWeekDays = Object.keys(pick(Object.keys(priceFilterWeekDayMapping), params));

  const weddingMonths = selectedWeddingSeasons.reduce<Month[]>(
    (acc, season) => [...priceFilterSeasonOptions[season as Season], ...acc],
    [],
  );

  const weddingWeekDays = selectedWeddingWeekDays.reduce<string[]>(
    (acc, weekDay) => [...priceFilterWeekDayMapping[weekDay as PriceFilterWeekDay], ...acc],
    [],
  );

  return {
    ...omit([...selectedWeddingSeasons, ...selectedWeddingWeekDays], params),
    ...(isEmpty(weddingMonths) ? {} : { weddingMonths }),
    ...(isEmpty(weddingWeekDays) ? {} : { weddingWeekDays }),
  } as ISearchParams;
};
