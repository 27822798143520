import {
  changeCollaboratorFormField,
  fetchCollaboratorsEmailsSuccess,
  inviteCollaboratorError,
  inviteCollaboratorSuccess,
  onAccessControlListener,
  resetInviteForm,
  setInviteForm,
} from './actions';

export enum AccessControlActionTypes {
  CHANGE_COLLABORATOR_FORM_FIELD = '[ACL] Change collaborator form field',
  CLICKED_INVITE_COLLABORATOR_ANALYTICS = '[ACL] Clicked Invite Collaborator Analytics',
  CLOSE_INVITE_COLLABORATOR_ANALYTICS = '[ACL] Close Invite Collaborator Analytics',
  FAILED_TO_INVITE_COLLABORATOR_ANALYTICS = '[ACL] Failed to Invite Collaborator Analytics',
  FETCH_COLLABORATORS_EMAILS_SUCCESS = '[ACL] Fetch collaborators emails success',
  INVITE_COLLABORATOR_ERROR = '[ACL] Invite collaborator error',
  INVITE_COLLABORATOR_SUCCESS = '[ACL] Invite collaborator success',
  INVITE_COLLABORATOR_SUCCESS_ANALYTICS = '[ACL] Invite collaborator success analytics',
  ON_ACCESS_CONTROL_LISTENER = '[ACL] On wedding access control listener',
  OPEN_INVITE_COLLABORATOR_ANALYTICS = '[ACL] Open Invite Collaborator Analytics',
  RESET_INVITE_FORM = '[ACL] Reset invite form',
  SET_INVITE_FORM = '[ACL] Set invite form',
  UPDATE_ACCESS_CONTROL_SUCCESS = '[ACL] Update access control success',
  ACCEPTED_COLLABORATOR_INVITE = '[ACL] Accepted collaborator invite',
}

export type ChangeCollaboratorFormFieldAction = ReturnType<typeof changeCollaboratorFormField>;

export type IOnAccessControlListenerAction = ReturnType<typeof onAccessControlListener>;

export type IFetchCollaboratorsEmailsSuccessAction = ReturnType<
  typeof fetchCollaboratorsEmailsSuccess
>;

export type IInviteCollaboratorSuccessAction = ReturnType<
  ReturnType<typeof inviteCollaboratorSuccess>
>;

export type SetInviteForm = ReturnType<typeof setInviteForm>;

export type ResetInviteForm = ReturnType<typeof resetInviteForm>;

export type IInviteCollaboratorErrorAction = ReturnType<ReturnType<typeof inviteCollaboratorError>>;
