import { values } from 'ramda';
import { Dispatch } from 'redux';
import { Weddings } from '@bridebook/models';
import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { getDocsFromCacheOrServer } from 'lib/auth/utils/get-docs-from-cache-or-server';
import { onFirebaseGuestlist } from 'lib/guestlist/actions';
import { mapCleanTimestamps } from 'lib/utils';

export const fetchGuestlistData = async (dispatch: Dispatch<any>, weddingId: string) => {
  const weddingGuestsRef = Weddings._.getById(weddingId).Guests.reference;
  const weddingGuestsSnapshot = await getDocsFromCacheOrServer(weddingGuestsRef);
  const weddingGuestsData: Record<string, IGuest> = {};
  weddingGuestsSnapshot.docs.forEach((doc) => {
    weddingGuestsData[doc.id] = doc.data();
  });

  dispatch(onFirebaseGuestlist(values(mapCleanTimestamps(weddingGuestsData))));
};
