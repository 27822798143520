import { CSSProperties } from 'react';
import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  iconWrapper: CSSProperties;
  icon: FelaCSS;
  wrapper: (filtersCount: number) => FelaCSSWithCustomSelectors;
}

const styles: IStyles = {
  wrapper: (filtersCount) => ({
    position: 'relative',
    border: 'none',
    backgroundColor: 'transparent',

    ':after': {
      display: filtersCount > 0 ? 'flex' : 'none',
      position: 'absolute',
      top: '-9px',
      right: '-2px',
      content: filtersCount > 99 ? '"ツ"' : `"${filtersCount}"`,
      padding: '0px 5px 0px',
      borderRadius: '16px',
      backgroundColor: colors.indigoCrush,
      border: `1px solid ${colors.white}`,
      color: colors.white,
      fontDefaultSemiBold: 12,
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2, //filter-pill style zIndex is set to 1, thus the badge needs to be drawn on top of it
    },

    '& + &': {
      marginLeft: 2,
    },
  }),
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingLeft: 2,
  },
};

export default styles;
