import { getIsLostLeadModalVisible } from 'lib/ui/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const LostLeadModalLazy = lazyComponent(() => import('./lost-lead-modal'));

export const LostLeadModal = () => {
  const showModal = useSelector(getIsLostLeadModalVisible);

  if (!showModal) {
    return null;
  }

  return <LostLeadModalLazy />;
};
