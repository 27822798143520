import React, { Component, ComponentType } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { IApplicationState } from 'lib/types';

const ViewPortBuggyFill = process.browser ? require('viewport-units-buggyfill') : null;

const storeEnahncer = connect((state: IApplicationState) => ({
  isMobile: state.app.device.isMobile,
  isTablet: state.app.device.isTablet,
}));

interface IProps extends ConnectedProps<typeof storeEnahncer> {}

/**
 * Viewport units buggyfill Higher Order Component
 */
const buggyfill = (WrappedComponent: ComponentType) =>
  class Wrapper extends Component<IProps> {
    componentDidMount() {
      const { isMobile, isTablet } = this.props;
      if ((isMobile || isTablet) && ViewPortBuggyFill) {
        try {
          ViewPortBuggyFill.init({ force: true });
          ViewPortBuggyFill.refresh();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    }

    render() {
      // @ts-ignore FIXME
      return <WrappedComponent {...this.props} />;
    }
  };

export default compose<ComponentType>(storeEnahncer, buggyfill);
