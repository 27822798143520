import { useEffect, useState } from 'react';

/**
 * A small hook intended to detect network connection status using native browser online/offline events.
 * @returns {Boolean}
 */
const useOfflineDetection = () => {
  const [isOffline, setIsOffline] = useState(false);
  useEffect(() => {
    const setOfflineState = () => setIsOffline(true);
    const setOnlineState = () => setIsOffline(false);
    window.addEventListener('offline', setOfflineState);
    window.addEventListener('online', setOnlineState);

    return () => {
      window.removeEventListener('offline', setOfflineState);
      window.removeEventListener('online', setOnlineState);
    };
  }, []);

  return isOffline;
};

export default useOfflineDetection;
