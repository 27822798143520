import { createSelector } from 'reselect';
import { IApplicationState } from 'lib/types';

const getVenueConfirmation = (state: IApplicationState) => state.venueConfirmation;
const _showVenueConfirm = (state: IApplicationState) => state.venueConfirmation.showVenueConfirm;

export const selectShowVenueConfirm = createSelector(
  [_showVenueConfirm],
  (showVenueConfirm) => showVenueConfirm,
);

export const getSupplierIdToConfirm = createSelector(
  getVenueConfirmation,
  (venueConfirmation) => venueConfirmation.supplierIdToConfirm,
);

export const getVenueConfirmationCurrentSlide = createSelector(
  getVenueConfirmation,
  ({ currentSlide }) => currentSlide,
);

export const getVenueConfirmationShowIntro = createSelector(
  getVenueConfirmation,
  ({ showIntro }) => showIntro,
);

export const getVenueConfirmationTriggerLocation = createSelector(
  getVenueConfirmation,
  ({ triggerLocation }) => triggerLocation,
);

export const getVenueConfirmationWeddingDay = createSelector(
  getVenueConfirmation,
  ({ weddingDay }) => weddingDay,
);

export const getVenueConfirmationWeddingMonth = createSelector(
  getVenueConfirmation,
  ({ weddingMonth }) => weddingMonth,
);

export const getVenueConfirmationWeddingYear = createSelector(
  getVenueConfirmation,
  ({ weddingYear }) => weddingYear,
);

export const getVenueConfirmationSearchSupplierType = createSelector(
  getVenueConfirmation,
  ({ searchSupplierType }) => searchSupplierType,
);

export const getHasFoundVenueValue = createSelector(
  getVenueConfirmation,
  (venueConfirmation) => venueConfirmation.hasFoundVenue,
);

export const getSupplierToBook = createSelector(
  getVenueConfirmation,
  ({ supplierToBook }) => supplierToBook,
);
