import { ValidationError } from '@bridebook/toolbox/src';
import { getFirebaseMessages } from 'lib/auth/firebase-messages';
import { FirebaseErrorCodes } from 'lib/auth/types';

/**
 * Given firebase error code, return new error object with custom error message and
 * "prop" property matching input field
 * @method mapFBErrorToValidationError
 * @param {string} firebase error code
 * @returns {ValidationError}
 */
const mapFBErrorToValidationError = (code: FirebaseErrorCodes): ValidationError => {
  const codesThatMapToSocialFieldFieldMapping: Array<FirebaseErrorCodes> = [
    'auth/popup-closed-by-user',
  ];

  const codesThatMapToEmailFieldFieldMapping: Array<FirebaseErrorCodes> = [
    'auth/email-already-in-use',
    'auth/invalid-email',
    'auth/user-not-found',
  ];

  const prop = codesThatMapToSocialFieldFieldMapping.includes(code)
    ? 'social'
    : codesThatMapToEmailFieldFieldMapping.includes(code)
    ? 'email'
    : 'password';

  const msg = getFirebaseMessages()[code]?.defaultMessage || code;
  return new ValidationError(msg, prop, code);
};

export default mapFBErrorToValidationError;
