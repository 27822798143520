import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { selectIsUserLoggedIn } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';
import ProtectedHeader from './protected-header';
import PublicHeader from './public-header';

const Header = () => {
  const { pathname, query } = useRouter();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const url = { pathname, query };

  // Required as /reviews page is accessible for both - logged in and out users
  // with full layout enabled
  return isLoggedIn ? <ProtectedHeader location={url} /> : <PublicHeader />;
};

export default memo(Header);
