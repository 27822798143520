import { createAnimation } from 'components/onboarding-new/components/animations/helpers/create-animation';

/* ############################################################################
 *  FADE OUT
 * ######################################################################### */

export const fadeOutAnimation = createAnimation({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

export const fadeOutLeftAnimation = createAnimation({
  '0%': { opacity: 1, transform: 'translateX(0)' },
  '100%': { opacity: 0, transform: 'translateX(-20px)' },
});

/* ############################################################################
 *  FADE IN
 * ######################################################################### */

export const fadeInAnimation = createAnimation(
  {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  { animationFillMode: 'both' },
);

export const fadeInRightAnimation = createAnimation(
  {
    '0%': { opacity: 0, transform: 'translateX(20px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
  },
  { animationFillMode: 'both' },
);

export const fadeInBottomAnimation = createAnimation(
  {
    '0%': { opacity: 0, transform: 'translateY(20px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },
  { animationFillMode: 'both' },
);
