import React from 'react';
import { useSelector } from 'react-redux';
import { IEnquiryFormModal } from 'components/enquiries/enquiry-form-modal/index';
import { getShowEnquiryForm } from 'lib/enquiries/selectors';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const EnquiryFormModal = lazyComponent<IEnquiryFormModal>(
  () => import('components/enquiries/enquiry-form-modal'),
);

interface EnquiryFormModalLazyProps {}

const EnquiryFormModalLazy: React.FC<EnquiryFormModalLazyProps> = () => {
  const show = useSelector(getShowEnquiryForm);
  if (!show) return null;

  return <EnquiryFormModal showEnquiryForm={show} />;
};

export default React.memo(EnquiryFormModalLazy);
