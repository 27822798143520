import { useTranslation } from 'next-i18next';
import { useCallback, useEffect } from 'react';
import { ButtonV2, Text } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { requestPushNotificationPermissions } from 'lib/mobile-app/actions';
import { PN_OPT_IN_STORAGE_KEY } from 'lib/mobile-app/constants';
import { getPrefNumber, getPreferences } from 'lib/mobile-app/utils/preferences-wrapper';
import { PushNotificationOptInInteraction } from 'lib/types';
import { togglePushNotificationOptIn, togglePushNotificationOptInAnalytics } from 'lib/ui/actions';
import { useDispatch, useSelector } from 'lib/utils';
import PrePromptImage from './push-pre-prompt/pre-prompt-image';
import PrePromptModal from './push-pre-prompt/pre-prompt-modal';

const CordovaPushNotificationsOptIn = () => {
  const { t } = useTranslation('mobileApp');
  const pushNotificationOptInShown = useSelector((state) => state.ui.pushNotificationOptInShown);
  const dispatch = useDispatch();
  const market = useMarket();
  const hasAnyDirectory = market.hasAnyDirectory;
  const isBBLite = hasAnyDirectory === false;

  const registerPN = useCallback(() => {
    dispatch(togglePushNotificationOptInAnalytics({ show: false, interactionType: 'accepted' }));
    dispatch(requestPushNotificationPermissions());
  }, [dispatch]);

  const closePushNotificationOptIn = useCallback(
    (option: PushNotificationOptInInteraction) => {
      dispatch(togglePushNotificationOptInAnalytics({ show: false, interactionType: option }));
      dispatch(togglePushNotificationOptIn(false));
    },
    [dispatch],
  );

  useEffect(() => {
    const setLocalStorage = async () => {
      if (pushNotificationOptInShown) {
        let currentValue: number = await getPrefNumber({
          key: PN_OPT_IN_STORAGE_KEY,
        });

        getPreferences().set({
          key: PN_OPT_IN_STORAGE_KEY,
          value: (++currentValue).toString(),
        });
      }
    };

    setLocalStorage();
  }, [pushNotificationOptInShown]);

  return (
    <PrePromptModal
      show={pushNotificationOptInShown}
      onClose={() => closePushNotificationOptIn('closed')}
      id="push-notification-opt-in-modal">
      <PrePromptModal.Body>
        <PrePromptModal.InnerContent>
          <PrePromptModal.Title>
            {isBBLite
              ? t('pushNotificationsOptInModal.bbLiteTitle')
              : t('pushNotificationsOptInModal.title')}
          </PrePromptModal.Title>

          <PrePromptModal.Image>
            <PrePromptImage />
          </PrePromptModal.Image>

          <PrePromptModal.Description>
            {isBBLite
              ? t('pushNotificationsOptInModal.bbLiteDescription')
              : t('pushNotificationsOptInModal.description')}
          </PrePromptModal.Description>
        </PrePromptModal.InnerContent>
        <PrePromptModal.Buttons>
          <ButtonV2 onClick={registerPN}>
            <Text>{t('pushNotificationsOptInModal.confirmButton')}</Text>
          </ButtonV2>
          <ButtonV2 variant="link" onClick={() => closePushNotificationOptIn('dismissed')}>
            {t('pushNotificationsOptInModal.notNow')}
          </ButtonV2>
        </PrePromptModal.Buttons>
      </PrePromptModal.Body>
    </PrePromptModal>
  );
};

export default CordovaPushNotificationsOptIn;
