import React from 'react';

const profileImageAddedLocked = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 145 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="71.2611" cy="79.239" r="27.7609" fill="#EAF8F6" stroke="#1B7064" strokeWidth="2" />
    <circle cx="71.0734" cy="72.0066" r="6.14785" fill="#F7FCFB" stroke="#1B7064" strokeWidth="2" />
    <path
      d="M71.0731 79.1543C61.8163 79.1543 59.8271 87.1448 59.9704 91.8928C59.986 92.4081 60.4201 92.8002 60.9357 92.8002H81.2927C81.7728 92.8002 82.1894 92.4605 82.2389 91.9829C82.7296 87.2467 80.3887 79.1543 71.0731 79.1543Z"
      fill="#81D6CA"
      stroke="#1B7064"
      strokeWidth="2"
    />
    <path
      d="M85.9678 53.8862C86.548 53.1181 87.4096 52.612 88.3629 52.4792C89.3163 52.3464 90.2834 52.5978 91.0514 53.178C91.8194 53.7582 92.3255 54.6198 92.4583 55.5732C92.5911 56.5265 92.3398 57.4936 91.7595 58.2616C92.3398 57.4936 93.2013 56.9875 94.1547 56.8547C95.108 56.7219 96.0751 56.9733 96.8431 57.5535C97.6112 58.1337 98.1173 58.9953 98.2501 59.9486C98.3829 60.902 98.1315 61.8691 97.5513 62.6371C95.5314 65.3108 88.7452 65.3603 87.25 64.2308C85.7549 63.1012 83.9128 56.6063 85.9678 53.8862Z"
      fill="#C0EAE5"
    />
    <path
      d="M97.6785 56.5838C97.1363 56.1736 96.5157 55.8788 95.8551 55.7178C95.1945 55.5567 94.5079 55.5327 93.8377 55.6473C93.7298 54.6537 93.3259 53.7154 92.6784 52.9541C92.0308 52.1928 91.1695 51.6437 90.206 51.3779C89.2426 51.112 88.2215 51.1418 87.2751 51.4634C86.3288 51.7849 85.5009 52.3833 84.8989 53.1811C82.357 56.5457 84.3966 63.8822 86.4465 65.4309C88.4965 66.9796 96.111 66.9363 98.6528 63.5717C99.4486 62.5152 99.7929 61.1863 99.6103 59.8763C99.4276 58.5664 98.7329 57.3823 97.6785 56.5838ZM88.0871 63.262C87.1204 62.5053 85.5167 56.8786 87.0699 54.8227C87.4401 54.364 87.9742 54.0673 88.5593 53.9954C89.1443 53.9234 89.7344 54.0819 90.2047 54.4372C90.6751 54.7925 90.9887 55.3168 91.0794 55.8993C91.1701 56.4817 91.0307 57.0766 90.6907 57.558C90.4788 57.8463 90.389 58.2064 90.4407 58.5603C90.4923 58.9143 90.6813 59.2336 90.9668 59.4493C91.2522 59.6649 91.6111 59.7594 91.9657 59.7124C92.3203 59.6654 92.6421 59.4806 92.8614 59.198C93.2242 58.7179 93.7628 58.4015 94.3588 58.3185C94.9548 58.2354 95.5594 58.3926 96.0395 58.7553C96.5197 59.118 96.8361 59.6566 96.9191 60.2527C97.0021 60.8487 96.845 61.4532 96.4822 61.9334C94.9359 63.9783 89.0809 63.9782 88.0863 63.2611L88.0871 63.262Z"
      fill="#1B7064"
    />
    <path
      d="M135.638 37.631L135.644 37.6348L135.651 37.6386C138.7 39.3193 140.5 42.4019 140.5 45.666V110.361C140.5 113.625 138.7 116.708 135.651 118.389L135.645 118.392L135.639 118.395L77.4866 150.723C77.485 150.723 77.4835 150.724 77.4819 150.725C74.3947 152.425 70.6053 152.425 67.5181 150.725C67.5165 150.724 67.515 150.723 67.5134 150.723L9.36115 118.395L9.35494 118.392L9.34872 118.389C6.3 116.708 4.5 113.625 4.5 110.361V45.666C4.5 42.402 6.3 39.3193 9.34873 37.6386L9.35557 37.6348L9.3624 37.631L67.5134 5.27742C67.5155 5.27628 67.5176 5.27514 67.5196 5.274C70.6061 3.57533 74.3939 3.57533 77.4804 5.274C77.4824 5.27514 77.4845 5.27628 77.4866 5.27742L135.638 37.631Z"
      fill="#EDEFF0"
      stroke="#DDE0E3"
      strokeWidth="8"
    />
    <circle cx="71.2611" cy="79.239" r="27.7609" fill="#F6F7F8" stroke="#BABFC5" strokeWidth="2" />
    <circle cx="71.0734" cy="72.0066" r="6.14785" fill="#F6F7F8" stroke="#BABFC5" strokeWidth="2" />
    <path
      d="M71.0731 79.1543C61.8163 79.1543 59.8271 87.1448 59.9704 91.8928C59.986 92.4081 60.4201 92.8002 60.9357 92.8002H81.2927C81.7728 92.8002 82.1894 92.4605 82.2389 91.9829C82.7296 87.2467 80.3887 79.1543 71.0731 79.1543Z"
      fill="#DDE0E3"
      stroke="#BABFC5"
      strokeWidth="2"
    />
    <path
      d="M85.9678 53.8862C86.548 53.1181 87.4096 52.612 88.3629 52.4792C89.3163 52.3464 90.2834 52.5978 91.0514 53.178C91.8194 53.7582 92.3255 54.6198 92.4583 55.5732C92.5911 56.5265 92.3398 57.4936 91.7595 58.2616C92.3398 57.4936 93.2013 56.9875 94.1547 56.8547C95.108 56.7219 96.0751 56.9733 96.8431 57.5535C97.6112 58.1337 98.1173 58.9953 98.2501 59.9486C98.3829 60.902 98.1315 61.8691 97.5513 62.6371C95.5314 65.3108 88.7452 65.3603 87.25 64.2308C85.7549 63.1012 83.9128 56.6063 85.9678 53.8862Z"
      fill="#EDEFF0"
    />
    <path
      d="M97.6785 56.5838C97.1363 56.1736 96.5157 55.8788 95.8551 55.7178C95.1945 55.5567 94.5079 55.5327 93.8377 55.6473C93.7298 54.6537 93.3259 53.7154 92.6784 52.9541C92.0308 52.1928 91.1695 51.6437 90.206 51.3779C89.2426 51.112 88.2215 51.1418 87.2751 51.4634C86.3288 51.7849 85.5009 52.3833 84.8989 53.1811C82.357 56.5457 84.3966 63.8822 86.4465 65.4309C88.4965 66.9796 96.111 66.9363 98.6528 63.5717C99.4486 62.5152 99.7929 61.1863 99.6103 59.8763C99.4276 58.5664 98.7329 57.3823 97.6785 56.5838ZM88.0871 63.262C87.1204 62.5053 85.5167 56.8786 87.0699 54.8227C87.4401 54.364 87.9742 54.0673 88.5593 53.9954C89.1443 53.9234 89.7344 54.0819 90.2047 54.4372C90.6751 54.7925 90.9887 55.3168 91.0794 55.8993C91.1701 56.4817 91.0307 57.0766 90.6907 57.558C90.4788 57.8463 90.389 58.2064 90.4407 58.5603C90.4923 58.9143 90.6813 59.2336 90.9668 59.4493C91.2522 59.6649 91.6111 59.7594 91.9657 59.7124C92.3203 59.6654 92.6421 59.4806 92.8614 59.198C93.2242 58.7179 93.7628 58.4015 94.3588 58.3185C94.9548 58.2354 95.5594 58.3926 96.0395 58.7553C96.5197 59.118 96.8361 59.6566 96.9191 60.2527C97.0021 60.8487 96.845 61.4532 96.4822 61.9334C94.9359 63.9783 89.0809 63.9782 88.0863 63.2611L88.0871 63.262Z"
      fill="#BABFC5"
    />
  </svg>
);

export default profileImageAddedLocked;
