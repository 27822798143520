import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconAwardBadge = ({ id, color, width = 35, style, ...restProps }: IProps) => {
  const viewBoxWidth = 35;
  const viewBoxHeight = 50;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M24.5 42.019a1.951 1.951 0 0 0-2.445.71l-.398.606-2.111-7.437.185-.652a17.253 17.253 0 0 0 3.21-.728l2.215 7.8-.656-.299zm-11.158 1.315l-.398-.605a1.953 1.953 0 0 0-2.444-.71l-.656.299 2.215-7.8c.776.255 1.571.474 2.395.62.269.048.543.073.815.108l.185.652-2.112 7.436zm13.21-10.51l3.555 12.523a2.003 2.003 0 0 1-.602 2.053 1.949 1.949 0 0 1-2.095.309l-3.006-1.37-1.828 2.776a1.964 1.964 0 0 1-1.938.862 1.973 1.973 0 0 1-1.589-1.417l-1.55-5.456-1.55 5.456A1.973 1.973 0 0 1 14.06 50c-.65 0-1.268-.327-1.636-.885l-1.828-2.777-3.005 1.37a1.95 1.95 0 0 1-2.096-.308 2.003 2.003 0 0 1-.602-2.053L8.44 32.853C3.326 29.73 0 24.071 0 17.703 0 7.942 7.85 0 17.5 0S35 7.942 35 17.703a17.732 17.732 0 0 1-8.448 15.122zm-22.62-15.12c0 6.68 4.712 12.364 11.204 13.516.773.138 1.568.208 2.364.208 7.481 0 13.567-6.157 13.567-13.725 0-7.567-6.086-13.724-13.567-13.724S3.933 10.136 3.933 17.703zm17.26 8.39L17.5 24.13l-3.692 1.962a1.708 1.708 0 0 1-1.822-.132 1.758 1.758 0 0 1-.687-1.711l.705-4.16-2.988-2.944a1.763 1.763 0 0 1-.437-1.794 1.735 1.735 0 0 1 1.396-1.19l4.127-.608 1.847-3.783a1.727 1.727 0 0 1 1.55-.975c.66 0 1.26.378 1.551.975l1.847 3.783 4.127.607a1.734 1.734 0 0 1 1.396 1.191 1.763 1.763 0 0 1-.437 1.794l-2.987 2.945.706 4.159a1.76 1.76 0 0 1-.688 1.71 1.714 1.714 0 0 1-1.823.133zM15.3 21.347l1.395-.743a1.71 1.71 0 0 1 1.61 0l1.394.743-.266-1.572a1.762 1.762 0 0 1 .497-1.548l1.13-1.114-1.56-.23a1.73 1.73 0 0 1-1.303-.956l-.697-1.43-.698 1.43a1.73 1.73 0 0 1-1.302.956l-1.56.23 1.129 1.114c.408.402.593.98.498 1.548l-.267 1.572z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconAwardBadge;
