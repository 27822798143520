import { ValidationError } from '@bridebook/toolbox/src';
import { AuthActionTypes } from 'lib/auth/action-types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { BridebookError, ICredentialsFields, IDeps } from 'lib/types';
import type { FirebaseErrorCodes } from '../types';

export const changePasswordSuccess =
  (fields: ICredentialsFields) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'CHANGE_PASSWORD_SUCCESS_ANALYTICS',
      payload: { fields },
    });

    return {
      type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: fields,
    };
  };

export const changePasswordError =
  (fields: ICredentialsFields, error: BridebookError) =>
  ({ dispatch }: IDeps) => {
    const throwError = error.code
      ? mapFBErrorToValidationError(error.code as FirebaseErrorCodes)
      : new ValidationError(error.message, 'password');

    dispatch({
      type: 'CHANGE_PASSWORD_ERROR_ANALYTICS',
      payload: {
        fields,
        error: throwError,
      },
    });

    return {
      type: AuthActionTypes.CHANGE_PASSWORD_ERROR,
      payload: throwError,
    };
  };
