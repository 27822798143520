import { useEffect } from 'react';
import { usePrevious } from './use-previous';

/**
 * Triggers update function when given value changes.
 * Used to update state value if external value changes in externally controlled components.
 *
 * @param value
 * @param setValue
 */
export function useInputValueListener<T>(value: T, setValue: (val: T) => void) {
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (value !== previousValue) {
      setValue(value);
    }
  }, [value]);
}
