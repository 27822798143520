import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  root: FelaCSS;
  backBtn: FelaCSS;
}

const styles: IStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    marginLeft: '3px',
    marginRight: '3px',
    justifyContent: 'space-between',
    gap: 8,
  },
  backBtn: {
    flexShrink: 0,
  },
};

export default styles;
