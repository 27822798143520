import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  controlsWrap: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    flexShrink: 0,
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    backgroundColor: colors.space,

    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
    },
  },

  controlsWrap: {
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '50px',
    backgroundColor: colors.space,
  },
});

export default styles;
