import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import React, { SyntheticEvent } from 'react';
import { A, Box, InlineTag, TColor } from '@bridebook/ui';
import IconAwardBadge from '@bridebook/ui/src/components/svg/icon-award-badge';
import {
  IconBb,
  IconBookmark,
  IconBudget,
  IconChat,
  IconChecklist,
  IconCup,
  IconHome,
  IconInbox,
  IconLock,
  IconLogout,
  IconRing,
  IconSearch,
} from '@bridebook/ui/src/icons';
import { Link } from 'app-shared/components/link/link';
import { IconClass } from 'lib/ui/constants';
import openInBrowser from 'lib/utils/open-in-browser';
import componentStyles from './menu-option.style';

export interface SharedTypes {
  color?: TColor;
  first: boolean;
  newOption?: boolean;
}

interface IProps extends SharedTypes {
  title?: string;
  url?: string;
  icon?: string;
  onClick?: (e?: SyntheticEvent<any>) => unknown;
  counter?: number;
  id?: string;
  dataTest?: string;
}

const MenuOption = ({
  title,
  url,
  icon,
  onClick,
  color,
  first,
  newOption = false,
  counter = 0,
  id,
  dataTest,
}: IProps) => {
  const { t } = useTranslation('common');
  const externalUrl = url ? url.includes('http') : false;
  const relValue = externalUrl ? 'noopener nofollow' : undefined;

  const goToExternalUrl = () => {
    if (onClick) {
      onClick();
    }
    openInBrowser(url);
  };

  const tabClick = (e: any) => {
    e.preventDefault();

    if (externalUrl) {
      goToExternalUrl();
    } else {
      if (onClick) {
        onClick(e);
      }
      if (url) Router.push(url);
    }
  };

  const styles = componentStyles({
    color,
    first,
    newOption,
  });

  const itemIcon = () => {
    switch (icon) {
      case 'icon-home':
        return <IconHome color={color} width={14} />;
      case 'icon-mag-glass':
        return <IconSearch color={color} width={12} />;
      case 'icon-capacity':
        return <span className={IconClass.Guest} style={styles.guestIcon} />;
      case 'icon-budget':
        return <IconBudget borderColor={color} width={17} />;
      case 'icon-checked':
        return <IconChecklist color={color} width={16} />;
      case 'icon-heart-unfilled':
        return <span className={IconClass.Favourites} style={styles.favouritesIcon} />;
      case 'icon-articles':
        return <IconBookmark color={color} width={8} />;
      case 'icon-settings':
        return <span className={IconClass.Settings} style={styles.settingsIcon} />;
      case 'icon-bride-groom':
        return <IconRing color={color} width={15} />;
      case 'icon-lock-outlined':
        return <IconLock color={color} width={11} />;
      case 'icon-supplier':
        return <IconBb color={color} width={14} />;
      case 'icon-logout':
        return <IconLogout color={color} width={14} />;
      case 'icon-scrapbook':
        return <span className={IconClass.Scrapbook} style={styles.scrapbookIcon} />;
      case 'icon-tick-bold':
        return <span className={IconClass.TickBold} style={styles.tickboldIcon} />;
      case 'icon-chat':
        return <IconChat color={color} width={13} />;
      case 'icon-award-badge':
        return <IconAwardBadge color={color} width={11} />;
      case 'icon-inbox':
        return <IconInbox color={color} width={16} />;
      case 'icon-cup':
        return <IconCup color={color} width={16} />;
      default:
        return <IconBb color={color} width={14} />;
    }
  };

  const withPotentialLink = (children: React.ReactElement): React.ReactElement => {
    /**
     * If our element includes a proper URL wrap the children into a <Link> component
     * to properly refer to the localized version of the href (in case someone wants
     * to open the link in a new tab).
     */
    if (url) {
      return (
        <Link href={url} passHref legacyBehavior>
          {children}
        </Link>
      );
    }

    return children;
  };

  const dataTabName = (id || '').toLowerCase() || (title || '').split(' ').join('-').toLowerCase();

  return withPotentialLink(
    <A
      data-tab-name={dataTabName}
      onClick={tabClick}
      rel={relValue}
      target={externalUrl ? '_blank' : undefined}
      style={styles.wrapper}>
      <Box style={styles.iconTextWrapper} dataTest={dataTest}>
        {icon && <Box style={styles.iconWrapper}>{itemIcon()}</Box>}
        <Box style={styles.titleWrap}>{title}</Box>
        {newOption && (
          <InlineTag small backgroundColor="mint">
            {t('menu.newPill')}
          </InlineTag>
        )}
        {counter > 0 && (
          <Box style={styles.counter} ml={2}>
            {counter < 100 ? counter : '99+'}
          </Box>
        )}
      </Box>
    </A>,
  );
};

export default MenuOption;
