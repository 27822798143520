import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconBb = ({ id, color, width = 14, style, ...restProps }: IProps) => {
  const viewBoxWidth = 14;
  const viewBoxHeight = 14;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M11.993 10.064c-.586 2.123-2.759 2.196-2.759 2.196l-.007-.011a3.152 3.152 0 0 1-.889.067H2.086l-.01-.001h-.389v-.372l-.003-.03v-6.25c-.02-.296.002-.595.066-.886 0 0 .073-2.172 2.196-2.758 2.123-.586 3.192.911 3.192.911s.885.821.77 2.589a3.169 3.169 0 0 1-.084.658c.28-.067.568-.093.855-.082 1.639-.044 2.402.778 2.402.778s1.498 1.068.912 3.191m.623-4.137A4.687 4.687 0 0 0 9.453 4.55a4.82 4.82 0 0 0-.162-1.065 4.708 4.708 0 0 0-.258-.71A4.727 4.727 0 0 0 7.218.707 4.698 4.698 0 0 0 4.728 0a4.695 4.695 0 0 0-3.346 1.386 4.709 4.709 0 0 0-1.227 2.14A4.749 4.749 0 0 0 .01 5.018v8.971H1.41l.045.002h7.528a4.695 4.695 0 0 0 3.633-1.374 4.735 4.735 0 0 0 0-6.69"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconBb;
