import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  title: FelaCSS;
  note: FelaCSS;
  label: FelaCSS;
  header: FelaCSS;
  hr: FelaCSS;
  buttonTextWrapper: FelaCSS;
  buttonMainText: FelaCSS;
  button: FelaCSS;
  buttonsWrapper: FelaCSS;
}

const styles = (isQuickFilter?: boolean): IStyles => ({
  wrapper: {
    gap: 12,
    paddingTop: isQuickFilter ? 3 : 0,
    paddingBottom: isQuickFilter ? 3 : 4,
  },
  header: {
    gap: 8,
    paddingHorizontal: isQuickFilter ? 4 : 0,
  },
  title: {
    fontDefault: 14,
  },
  label: {
    fontDefaultSemiBold: 12,
    paddingHorizontal: isQuickFilter ? 4 : 0,
  },
  note: {
    fontDefault: 10,
  },
  hr: {
    width: '100%',
    border: 'none',
    borderTop: `1px solid ${colors.space08}`,
    margin: 0,
  },
  buttonTextWrapper: {
    justifyContent: 'center',
    fontDefault: 12,
    gap: 4,
  },
  buttonMainText: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
  },
  button: {
    paddingVertical: 2,
    borderRadius: 12,
    lineHeight: 'auto',
  },
  buttonsWrapper: {
    columnGap: 12,
    rowGap: 8,
    paddingHorizontal: isQuickFilter ? 4 : 0,
  },
});

export default styles;
