import { FC, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

interface IAppRenderingContext {
  canRender: boolean;
}

const defaultAppRenderingContext: IAppRenderingContext = {
  canRender: false,
};
const AppRenderingContext = createContext<IAppRenderingContext>(defaultAppRenderingContext);

interface AppRenderingContextProviderProps {
  children: ReactNode;
}

/*
  AppRenderingContextProvider provides info if component can be rendered.
  It disables rendering for client side pages on server side
  If we would render them also server side we might get a warning like: "Expected server HTML to contain..."
  for SSR / SSG pages it's always enabled
 */
export const AppRenderingContextProvider: FC<AppRenderingContextProviderProps> = ({ children }) => {
  const canRenderRef = useRef(false);
  const [canRender, setCanRender] = useState(canRenderRef.current);
  useEffect(() => {
    if (!canRenderRef.current) {
      setCanRender(true);
      canRenderRef.current = true;
    }
  }, []);

  return (
    <AppRenderingContext.Provider value={{ canRender }}>{children}</AppRenderingContext.Provider>
  );
};

export const useCanRender = () => {
  const bbAppContext = useContext(AppRenderingContext);

  return bbAppContext.canRender;
};
