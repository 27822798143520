import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  contentWrapper: FelaCSS;
}

const styles: IStyles = {
  contentWrapper: {
    overflow: 'hidden',
    flex: 1,
  },
};

export default styles;
