import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ITrackEventAction,
  isTrackingAction,
  trackEvent,
  trackEventType,
} from 'lib/track-utils/actions';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { MuteActionsService } from 'lib/utils/mute-actions-service';

/**
 * A simple services for usage inside epics and components. It allows to dispatch
 * a tracking action using MuteActionsService.
 *
 * cordovaTracker.track('event_name', { data });
 *
 * and listen to all the tracking events:
 *
 * cordovaTracker.actions().pipe(tap(doSomething));
 *
 * Available bot in epics as a dependency or in components
 * as a returned value of useServices hook:
 *
 * const { cordovaTracker } = useServices();
 */
export class CordovaTrackerService {
  constructor(private actionService: MuteActionsService) {}

  track<P>(event: TrackingEvent, payload?: P) {
    this.actionService.dispatch(trackEvent(event, payload));
  }

  actions(): Observable<ITrackEventAction<unknown>> {
    return this.actionService.actions().pipe(ofType(trackEventType), filter(isTrackingAction));
  }
}
