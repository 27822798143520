import React, { PropsWithChildren } from 'react';
import { Box, FelaCSS } from '@bridebook/ui';

interface IProps {
  style?: FelaCSS;
}

const HeaderContent = ({ children, style = {} }: PropsWithChildren<IProps>) => (
  <Box as="header" data-name="header" style={style}>
    {children}
  </Box>
);

export default HeaderContent;
