import { contains } from 'ramda';
import { FelaCSS, colors } from '@bridebook/ui';

const isSupplierCheck = (pathname: string) => {
  const paths = [
    'wedding-planning-tools',
    'wedding-suppliers',
    'wedding-ideas-inspiration',
    'wedding-gift-lists',
    'wedding-fairs',
    'article/wedding-venues',
    'search',
    'wedding-budget',
    'wedding-day',
  ];

  const exceptions = contains(
    true,
    paths.map((path) => pathname.includes(path)),
  );

  return (pathname.includes('/supplier/') || pathname.includes('/wedding-')) && !exceptions;
};

const checkColoredBg = (pathname: string) => {
  const paths = [
    'article',
    'about-us',
    'pressandmedia',
    'inspiration',
    'tool',
    'wedding-suppliers',
    'wedding-ideas-inspiration',
    'wedding-gift-lists',
    'bridesmaid-dresses',
    'compare-wedding-insurance',
  ];

  return contains(
    true,
    paths.map((path) => pathname.includes(path)),
  );
};

interface IStyles {
  wrapperStyle: FelaCSS;
  middleWrapper: FelaCSS;
  homeWrapper: FelaCSS;
  topBarStaticLinksWrap: FelaCSS;
  loggedOutWrapper: FelaCSS;
}

interface IStyleProps {
  pathname: string;
  isLoggedIn: boolean;
  isNewDesktopHeaderEnabled?: boolean;
  isNewSearchResultsHeaderMobile?: boolean;
}

const styles = ({
  pathname = '/',
  isLoggedIn,
  isNewDesktopHeaderEnabled,
  isNewSearchResultsHeaderMobile,
}: IStyleProps): IStyles => {
  const isSearch = pathname.includes('search');
  const isArticle = pathname.includes('article');

  const isSuppliersLanging = pathname.includes('/wedding-suppliers/');
  const loggedInHome = pathname === '/home';
  const isSupplier = isSupplierCheck(pathname);
  const noBgColor = checkColoredBg(pathname) || (isLoggedIn && !isSearch);
  const bgImage = isSupplier || isSearch || isSuppliersLanging;

  const backgroundImageWrapperStyle: FelaCSS =
    bgImage && !(!isLoggedIn && isArticle) && !isNewDesktopHeaderEnabled
      ? {
          backgroundPosition: 'top',
          backgroundSize: 'cover',
          background:
            'url("//images.bridebook.com/assets/header-bg-desktop.jpg?auto=compress,format")',
        }
      : {};

  return {
    wrapperStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 106,
      paddingHorizontal: 5,
      minHeight: '68px',
      // // Add space for phone's status bar
      // paddingTop: `calc(0px + env(safe-area-inset-top))`,

      desktopDesign: {
        backgroundColor: isNewDesktopHeaderEnabled
          ? colors.blueCrush
          : noBgColor
          ? 'none'
          : colors.white,
        ...backgroundImageWrapperStyle,
      },

      maxWidthSmMax: {
        backgroundColor:
          isNewSearchResultsHeaderMobile || loggedInHome ? 'transparent' : colors.white,
        paddingLeft: 2,
        paddingRight: 2,
      },

      supportsSafeAreaInset: {
        paddingTop: 'env(safe-area-inset-top)',
        minHeight: 'calc(68px + env(safe-area-inset-top))',
      },
    },

    middleWrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    homeWrapper: {
      desktopDesign: {
        minWidth: '96px',
      },

      maxWidthSmMax: {
        width: !isLoggedIn || loggedInHome ? 'auto' : '62px',
        alignItems: 'center',
        marginLeft: !isLoggedIn || loggedInHome ? '12px' : 0,
      },
    },

    topBarStaticLinksWrap: {
      display: 'none',

      maxWidthSmMax: {
        display: 'block',
      },
    },

    loggedOutWrapper: {
      paddingHorizontal: 2,
      flex: 1,
    },
  };
};

export default styles;
