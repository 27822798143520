import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  appNavWrapper: FelaCSS;
  messageIconWrap: (inboxUnreadCount: number) => FelaCSS;
}

export const MOBILE_NAV_HEIGHT = 54;

const styles: IStyles = {
  appNavWrapper: {
    position: 'fixed',
    bottom: '0px',
    height: MOBILE_NAV_HEIGHT,

    supportsSafeAreaInset: {
      height: `calc(${MOBILE_NAV_HEIGHT}px + env(safe-area-inset-bottom))`,
    },

    backgroundColor: colors.white,
    width: '100%',
    zIndex: 999,
    boxShadow: `0 -4px 10px ${alpha(colors.black, 5)}`,
  },
  messageIconWrap: (inboxUnreadCount) => ({
    position: 'relative',
    border: 'none',
    backgroundColor: 'transparent',
    ':after': {
      display: inboxUnreadCount > 0 ? 'flex' : 'none',
      position: 'absolute',
      top: '-6px',
      right: '-6px',
      content: inboxUnreadCount > 99 ? '"ツ"' : `"${inboxUnreadCount}"`,
      padding: '1px 6px 1px',
      borderRadius: '16px',
      backgroundColor: colors.alerts,
      color: colors.white,
      fontDefaultSemiBold: 12,
      justifyContent: 'center',
      alignItems: 'center',
      width: inboxUnreadCount > 9 ? 18 : 16,
      height: inboxUnreadCount > 9 ? 18 : 16,
    },
  }),
};

export default styles;
