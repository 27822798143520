import React, { MouseEventHandler, ReactNode, useMemo } from 'react';
import { Box, FelaCSS } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { IconClass } from '@bridebook/ui/src/icons/constants';
import componentStyles from './icon-tab.style';

interface IProps {
  id?: string;
  onClick?: MouseEventHandler<ReactNode>;
  isActive?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  icon: ReactNode | IconClass;
  iconActive?: ReactNode | IconClass;
  href?: string;
  style?: FelaCSS;
  iconStyle?: FelaCSS;
  titleStyle?: FelaCSS;
}

const IconTab = ({
  id,
  isActive = false,
  disabled = false,
  icon,
  iconActive,
  onClick,
  href,
  children,
  style = {},
  iconStyle,
  titleStyle,
  ...restProps
}: IProps) => {
  const styles = componentStyles({ isActive, disabled });

  const iconElement = useMemo(() => {
    if (isActive && iconActive) {
      if (typeof iconActive === 'string') return <div className={iconActive} />;
      return iconActive;
    }
    if (typeof icon === 'string') return <div className={icon} />;
    return icon;
  }, [isActive, icon, iconActive]);

  return (
    <Box
      as="a"
      data-tab-name={id}
      data-element="tab"
      onClick={onClick}
      href={href}
      style={mergeStyles([styles.wrapper, style])}
      data-name={isActive ? 'active' : undefined}
      {...restProps}>
      <Box>
        <Box style={{ ...styles.iconWrap, ...iconStyle }}>{iconElement}</Box>
      </Box>
      <Box style={{ ...styles.title, ...titleStyle }}>{children}</Box>
    </Box>
  );
};

export default IconTab;
