import { Trans, useTranslation } from 'next-i18next';
import { Box, Container, FelaProvider } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import componentStyles from './error-component.style';

interface IProps {
  component: string;
  statusCode: number;
}

const ErrorComponent = ({ statusCode, component }: IProps) => {
  const { t } = useTranslation('common');

  const market = useMarket();

  const title = t('errorPage.title');

  const homepageHref = `/${market.prefix}`;

  const styles = componentStyles();

  return (
    <FelaProvider>
      <Container>
        <Box style={styles.wrapper}>
          <Box as="h1" style={styles.heading}>
            {title}
          </Box>
          <Box style={styles.subTitle}>
            <Trans ns="common" i18nKey="errorPage.backToHomepage">
              Don't cry about it - Head back to the{' '}
              <Box as="a" href={homepageHref}>
                homepage
              </Box>
            </Trans>
          </Box>
          <Box>
            [{statusCode}] [{component}]
          </Box>
        </Box>
      </Container>
    </FelaProvider>
  );
};

export default ErrorComponent;
