import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetSearchFilters,
  setSortingOption,
  toggleFilters,
  updateSearchUrl,
} from 'lib/search/actions';
import { defaultSortValue } from 'lib/search/constants';

/**
 * Resets all filters applied
 */
export const useResetFilters = () => {
  const location = useRouter();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setSortingOption(defaultSortValue));
    dispatch(resetSearchFilters());
    dispatch(updateSearchUrl({ location, sortBy: defaultSortValue }));
    dispatch(toggleFilters());
  }, [dispatch, location]);
};
