import { getIsCordova } from 'lib/mobile-app/selectors';
import { useSelector } from 'lib/utils';
import CordovaPushNotificationsOptIn from './cordova-push-opt-in';
import CordovaTracking from './cordova-tracking';

declare let process: any;

const AppListeners = () => {
  const isCordova = useSelector(getIsCordova);

  if (!isCordova || !process.browser) return null;

  return (
    <>
      <CordovaPushNotificationsOptIn />
      <CordovaTracking />
    </>
  );
};

export default AppListeners;
