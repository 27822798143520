/**
 * Pick N number of uniq elements from given collection.
 * @param {array} collection collection to sample
 * @param {number} size sample size
 * @returns {array} collection sample
 */
export const sampleSize = <T>(collection: T[], size: number) => {
  const result: T[] = [];

  const pick = (array: T[]): T[] => {
    if (result.length === size || !array.length) return result;
    const randomIndex = Math.floor(Math.random() * array.length);
    const randomElement = array[randomIndex];

    result.push(randomElement);

    return pick(array.filter((_, index) => index !== randomIndex));
  };

  return pick(collection);
};
