import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const style = (): IStyles => ({
  wrapper: {
    paddingRight: 1,
  },
});

export default style;
