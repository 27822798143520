import React from 'react';

const profileImageAdded = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 121 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M112.541 31.9418L112.547 31.9456L112.554 31.9493C114.888 33.2357 116.25 35.5841 116.25 38.055V91.9677C116.25 94.4386 114.888 96.787 112.554 98.0733L112.548 98.0768L112.542 98.0802L64.0836 125.018C64.082 125.019 64.0804 125.02 64.0787 125.021C61.7069 126.326 58.7931 126.326 56.4213 125.021C56.4197 125.02 56.418 125.019 56.4164 125.018L7.95821 98.0802L7.952 98.0768L7.94579 98.0733C5.61235 96.787 4.25 94.4385 4.25 91.9677V38.055C4.25 35.5841 5.61235 33.2357 7.94578 31.9493L7.95263 31.9456L7.95946 31.9418L56.4164 4.98168C56.4184 4.98056 56.4204 4.97945 56.4224 4.97834C58.7937 3.67389 61.7063 3.67389 64.0776 4.97834C64.0796 4.97945 64.0816 4.98056 64.0836 4.98168L112.541 31.9418Z"
      fill="#57C8B9"
      stroke="#C0EAE5"
      strokeWidth="8"
    />
    <circle cx="59.2174" cy="66.0325" r="22.9674" fill="#EAF8F6" stroke="#1B7064" strokeWidth="2" />
    <circle cx="59.061" cy="60.0055" r="4.95654" fill="#F7FCFB" stroke="#1B7064" strokeWidth="2" />
    <path
      d="M59.0608 65.9619C51.4432 65.9619 49.7316 72.4553 49.805 76.4278C49.8145 76.9432 50.249 77.3335 50.7645 77.3335H67.4392C67.9194 77.3335 68.3366 76.9932 68.3788 76.5149C68.7289 72.5511 66.7344 65.9619 59.0608 65.9619Z"
      fill="#81D6CA"
      stroke="#1B7064"
      strokeWidth="2"
    />
    <path
      d="M71.4733 44.9051C71.9568 44.2651 72.6748 43.8434 73.4692 43.7327C74.2637 43.622 75.0696 43.8315 75.7096 44.315C76.3496 44.7985 76.7714 45.5165 76.8821 46.311C76.9927 47.1054 76.7833 47.9113 76.2997 48.5514C76.7833 47.9113 77.5012 47.4896 78.2957 47.3789C79.0902 47.2683 79.8961 47.4777 80.5361 47.9612C81.1761 48.4448 81.5978 49.1627 81.7085 49.9572C81.8192 50.7517 81.6097 51.5576 81.1262 52.1976C79.443 54.4256 73.7878 54.4669 72.5418 53.5256C71.2958 52.5844 69.7608 47.1719 71.4733 44.9051Z"
      fill="#C0EAE5"
    />
    <path
      d="M81.2322 47.1532C80.7804 46.8113 80.2632 46.5657 79.7127 46.4315C79.1622 46.2973 78.59 46.2773 78.0315 46.3728C77.9416 45.5448 77.6051 44.7629 77.0654 44.1284C76.5258 43.494 75.808 43.0364 75.0051 42.8149C74.2023 42.5934 73.3513 42.6182 72.5627 42.8861C71.7741 43.1541 71.0842 43.6528 70.5825 44.3176C68.4643 47.1214 70.1639 53.2352 71.8722 54.5258C73.5805 55.8163 79.9259 55.7802 82.0441 52.9764C82.7073 52.096 82.9942 50.9886 82.842 49.897C82.6898 48.8053 82.1109 47.8186 81.2322 47.1532ZM73.2394 52.7184C72.4338 52.0878 71.0974 47.3988 72.3917 45.6856C72.7002 45.3033 73.1453 45.0561 73.6329 44.9961C74.1204 44.9362 74.6121 45.0682 75.0041 45.3643C75.396 45.6604 75.6574 46.0973 75.733 46.5827C75.8085 47.0681 75.6924 47.5638 75.409 47.965C75.2325 48.2052 75.1576 48.5053 75.2007 48.8003C75.2437 49.0952 75.4012 49.3614 75.6391 49.5411C75.8769 49.7208 76.176 49.7995 76.4715 49.7603C76.767 49.7212 77.0352 49.5671 77.218 49.3317C77.5203 48.9315 77.9691 48.6679 78.4658 48.5987C78.9625 48.5295 79.4663 48.6605 79.8664 48.9628C80.2665 49.265 80.5302 49.7139 80.5994 50.2105C80.6685 50.7072 80.5376 51.211 80.2353 51.6111C78.9467 53.3153 74.0675 53.3152 73.2387 52.7176L73.2394 52.7184Z"
      fill="#1B7064"
    />
  </svg>
);

export default profileImageAdded;
