import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import type { Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { ILocationData } from 'app-shared/lib/search/types';
import { env } from 'lib/env';
import { formatArea } from './format-area';

const ALLOW_ALL_SUPPLIERS_COUNTRIES = [CountryCodes.GB, CountryCodes.DE] as string[];

/**
 * Returns location data for a given area, slug and locale, fetched from RTDB
 */
export const fetchLocationData = async ({
  market,
  area,
  slug,
}: {
  market: Market;
  area: string;
  slug?: Slug | UrlFriendlySlug;
}): Promise<ILocationData> => {
  const isVenue = slug === 'venue' || slug === 'venues';
  const formattedArea = formatArea(area);
  const fetchExtraInfo = slug && !isVenue && ALLOW_ALL_SUPPLIERS_COUNTRIES.includes(market.country);
  const location = market.country.toLowerCase();
  const baseUrl = `${env.STATIC}/location-info/${location}`;

  // Fetch main location data from either `venue` or `common` path
  const getLocationMain = (): Promise<ILocationData> =>
    fetch(`${baseUrl}/${isVenue ? 'venue' : 'common'}/${formattedArea}.json`).then((res) =>
      res.json(),
    );

  // For non-venues, fetch extra info from their slug path
  const getLocationExtras = (): Promise<ILocationData> =>
    fetch(`${baseUrl}/${slug}/${formattedArea}.json`).then((res) => res.json());

  const [locationMain, locationExtras] = await Promise.all([
    getLocationMain(),
    fetchExtraInfo ? getLocationExtras() : Promise.resolve({} as ILocationData),
  ]);

  // May return empty object (LIVE-18771)
  return { ...locationMain, ...locationExtras };
};
