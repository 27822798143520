import React, { memo } from 'react';
import { Box, LoadingCircle } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import componentStyles from './fullpage-loader.style';

interface IProps {
  backgroundColor?: string;
}

const FullpageLoader = ({ backgroundColor }: IProps) => {
  const style = componentStyles();

  return (
    <Box style={style.wrapper(backgroundColor)} data-name="fullpage-loader">
      <BridebookLogo color="indigoCrush" mb={8} />
      <Box style={style.rotatingAnimation}>
        <LoadingCircle size={50} color="indigoCrush" animate={false} />
      </Box>
    </Box>
  );
};

export default memo(FullpageLoader);
