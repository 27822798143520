import { createContext, useContext } from 'react';

export interface IPageSettings {
  isSearchPage?: boolean;
  isLandingPage?: boolean;
  isSupplierPage?: boolean;
  isReviewsPage?: boolean;
  isSticky?: boolean;
  noTopNav?: boolean;
  errorPage?: boolean;
  isHome?: boolean;
  backgroundColor?: string;
  isNewSearchBar?: boolean;
  showSearchBar?: boolean;
  hideToolButtons?: boolean;
  isSupplierSearchLandingPage?: boolean;
}

export const defaultPageSettings = {};
export const PageSettings = createContext<IPageSettings>(defaultPageSettings);

export const usePageSettings = () => {
  const settings = useContext(PageSettings);

  return settings;
};
