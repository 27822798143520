import React, { PropsWithChildren } from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './inline-tag.style';

export interface SharedTypes {
  backgroundColor?: string;
  color?: string;
  small?: boolean;
  medium?: boolean;
}

interface Props extends SharedTypes, IStylingProps, PropsWithChildren {}

const InlineTag = ({ children, backgroundColor, color, small, medium, ...restProps }: Props) => {
  const styles = componentStyles({ backgroundColor, color, small, medium });

  return children ? (
    <Box style={styles.wrapper} {...restProps}>
      {children}
    </Box>
  ) : null;
};

export default InlineTag;
