import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  buttonWrapper: FelaCSS;
  badge: FelaCSS;
  label: FelaCSS;
}

const styles = ({ isNotMobile }: { isNotMobile: boolean }): IStyles => {
  if (isNotMobile) {
    return {
      wrapper: {
        fontDefault: 16,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flexDirection: 'row',
        color: colors.blueCrush04,
        ':hover': {
          color: colors.blueCrush04,
          textDecoration: 'none',
        },
      },

      buttonWrapper: {
        height: '48px',
        paddingLeft: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },

      badge: {
        color: colors.white,
        fontDefaultSemiBold: 12,
        background: colors.amber,
        borderRadius: '100%',
        width: '12px',
        height: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        transform: 'translate(100%, -100%)',
        top: '10px',
        right: '0px',
        border: `2px solid ${colors.white}`,
      },

      label: {
        fontDefault: 16,
      },
    };
  } else {
    return {
      wrapper: {
        fontDefault: 16,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flexDirection: 'row',
        color: colors.white,
        ':hover': {
          color: colors.white,
          textDecoration: 'none',
        },
      },

      buttonWrapper: {
        height: '48px',
        width: '48px',
        alignItems: 'center',
        justifyContent: 'center',
      },

      badge: {
        color: colors.white,
        fontDefaultSemiBold: 12,
        background: colors.amber,
        borderRadius: '100%',
        width: '12px',
        height: '12px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '5px',
        right: '5px',
        border: `2px solid ${colors.white}`,
      },

      label: {
        fontDefault: 16,
      },
    };
  }
};

export default styles;
