import { Query, getDocsFromCache, getDocsFromServer } from 'firebase/firestore';
import { isEmpty } from 'ramda';
import { QuerySnapshot } from '@firebase/firestore';

export const getDocsFromCacheOrServer = async <T>(query: Query<T>): Promise<QuerySnapshot<T>> => {
  let snapshot;
  try {
    snapshot = await getDocsFromCache(query);
    if (isEmpty(snapshot)) {
      throw new Error('snapshot not found in cache');
    }
  } catch (error) {
    snapshot = await getDocsFromServer(query);
  }
  return snapshot;
};
