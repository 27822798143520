import { FelaCSS, colors } from '@bridebook/ui';

interface IStyleTypes {
  wrapper: FelaCSS;
  title: FelaCSS;
  description: FelaCSS;
}

const componentStyles: IStyleTypes = {
  wrapper: {
    marginHorizontal: 6,
    maxWidth: 500,
    alignSelf: 'center',
    height: 'max-content',
    backgroundColor: colors.white,
    borderRadius: 24,
    paddingHorizontal: 8,
    paddingBottom: 16,
    desktopDesign: {
      marginHorizontal: 0,
    },
  },
  title: {
    fontDefaultBold: 20,
    textAlign: 'center',
  },
  description: {
    fontDefault: 18,
    paddingTop: 8,
    textAlign: 'center',
  },
};

export default componentStyles;
