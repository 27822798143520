import React from 'react';
import { connect } from 'react-redux';
import { Box, Button } from '@bridebook/ui';
import { IconCrossBold } from '@bridebook/ui/src/icons';
import Modal from 'components/bbcommon/modal';
import { ConnectedTypes, IApplicationState, TMenuOptionsListTypes } from 'lib/types';
import { UIActionTypes } from 'lib/ui/action-types';
import componentStyles from './drawer-menu.style';
import MenuOptionsList from './menu-options-list/menu-options-list';

const storeEnhancer = connect((state: IApplicationState) => ({
  showNavMenuDrawer: state.ui.showNavMenuDrawer,
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  menu: TMenuOptionsListTypes;
  alwaysMounted?: boolean;
}

const DrawerMenu = ({ menu, showNavMenuDrawer, dispatch, alwaysMounted }: IProps) => {
  const closeDrawerMenu = (): void => {
    dispatch({
      type: UIActionTypes.TOGGLE_NAV_MENU_DRAWER,
      payload: false,
    });
  };

  const styles = componentStyles();

  return (
    <Modal
      alwaysMounted={alwaysMounted}
      show={showNavMenuDrawer}
      onClose={closeDrawerMenu}
      maxWidth="275px"
      id="menu-drawer"
      drawer>
      <Box style={styles.wrapper}>
        <Box style={styles.controlsWrap}>
          <Button
            theme="ghost"
            color="white"
            icon={<IconCrossBold width={16} />}
            onClick={closeDrawerMenu}
          />
        </Box>
        <MenuOptionsList menu={menu} />
      </Box>
    </Modal>
  );
};

export default storeEnhancer(DrawerMenu);
