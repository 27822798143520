import { TSpacingValue } from '@bridebook/ui/src/themes/types';
import { colors, transition } from '../../../fela-themes';
import { FelaCSS } from '../../fela/flowtypes';

interface IStyles {
  wrapper: FelaCSS;
  slider: FelaCSS;
  tab: (props: { active: boolean; disabled: boolean }) => FelaCSS;
}

interface IStyleProps {
  tabsAmount: number;
  fullWidth: boolean;
  tabPadding: TSpacingValue;
  size: 'default' | 'small';
}

const styles = ({ tabsAmount, fullWidth, tabPadding, size }: IStyleProps): IStyles => {
  const WRAPPER_RADIUS = 30;
  const WRAPPER_BORDER = size === 'small' ? 2 : 4;
  return {
    wrapper: {
      flexDirection: 'row',
      alignItems: 'stretch',
      backgroundColor: colors.space08,
      borderRadius: WRAPPER_RADIUS,
      border: `${WRAPPER_BORDER}px solid ${colors.space08}`,
      width: fullWidth ? '100%' : 'fit-content',
      overflow: 'hidden',
      ...(size === 'default' && {
        height: 48,
      }),
      ...(size === 'small' && {
        height: 32,
      }),
    },

    slider: {
      position: 'absolute',
      width: 100,
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: colors.white,
      borderRadius: WRAPPER_RADIUS - WRAPPER_BORDER,
    },

    tab: ({ active, disabled }) => ({
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      position: 'relative',
      paddingHorizontal: tabPadding,
      width: fullWidth ? `${100 / tabsAmount}%` : 'auto',
      height: '100%',
      cursor: disabled || active ? 'default' : 'pointer',
      color: disabled ? colors.space40 : active ? colors.space : colors.space60,
      transition: `${transition.fast} ease-out`,
      whiteSpace: 'nowrap',

      ...(size === 'default' && {
        fontDefault: 16,
      }),
      ...(size === 'small' && {
        fontDefault: 13,
      }),
    }),
  };
};

export default styles;
