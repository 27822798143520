import Router from 'next/router';
import React from 'react';
import { connect } from 'react-redux';
import { A, Button } from '@bridebook/ui';
import { IconShortlist } from '@bridebook/ui/src/icons';
import { Link } from 'app-shared/components/link/link';
import { NavigationDestination } from 'lib/analyticsTypes';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { usedHeaderNavigation } from 'lib/ui/actions';
import { UrlHelper } from 'lib/url-helper';

const storeEnhancer = connect((state: IApplicationState) => ({
  isLoggedIn: getAuthStatus({ user: state.users.user }),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const TopBarShortlist = ({ isLoggedIn, dispatch }: IProps) => {
  const clickHeaderShortlistButton = () => {
    dispatch(usedHeaderNavigation('shortlist' as NavigationDestination));
    Router.push(UrlHelper.shortlist.main);
  };

  const button = (
    <Button
      theme="ghost"
      color="white"
      icon={<IconShortlist color="white" width={22} />}
      onClick={clickHeaderShortlistButton}
    />
  );

  return isLoggedIn ? (
    button
  ) : (
    <Link href="/" passHref legacyBehavior>
      <A>{button}</A>
    </Link>
  );
};

export default storeEnhancer(TopBarShortlist);
