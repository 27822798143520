import { isSearchResultsPage } from 'lib/search/utils';
import { UrlHelper } from 'lib/url-helper';
import { isArticlePage, isSupplierPage, isToolPage } from 'lib/utils';

/**
 * Function displayBackButton returns boolean if we need to display back button.
 * If previous path is not the path that we started our journey with after clicking any tool in
 * main navigation
 * @previousPath previousPath as comes form reducer
 * @path path - current pathname as comes form reducer
 * @startedMainNavigationCounter counter of how many route changes happened since starting current
 *   navigation journey
 * @todo: [i18n][bb-global] Fix handling of useLocale
 */
export const useDisplayBackButton = (
  previousPath: string,
  path: string,
  startedMainNavigationCounter: number,
) => {
  const isPreviousSupplier = isSupplierPage(previousPath);
  const previousPathCondition =
    (previousPath && isToolPage(previousPath)) ||
    isArticlePage(previousPath) ||
    isPreviousSupplier ||
    previousPath.startsWith('/search') ||
    previousPath.startsWith(UrlHelper.inbox.main);

  const isSupplier = isSupplierPage(path);
  const isBack =
    startedMainNavigationCounter > 1 &&
    (isSupplier || isArticlePage(path) || isToolPage(path) || isSearchResultsPage(path)) &&
    previousPathCondition;

  return isBack;
};
