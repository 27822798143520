import React, { FC, PropsWithChildren, useCallback } from 'react';
import { useStore } from 'react-redux';
import Modal, { IModalProps } from 'app-shared/components/modal';
import buggyfill from 'components/common/buggyfill.hoc';
import { onModalToggle } from 'lib/bbcommon/actions';
import { useDispatch, useSelector } from 'lib/utils';

interface IProps extends Omit<IModalProps, 'onModalToggle' | 'store' | 'openedModalIds'> {}
const ConnectedModal = buggyfill(Modal) as FC<PropsWithChildren<IModalProps>>;

const ConnectedModalWithStore: FC<PropsWithChildren<IProps>> = (props) => {
  const store = useStore();
  const dispatch = useDispatch();
  const openedModalIds = useSelector((state) => state.bbcommon.openedModalIds);
  const onModalToggleCb = useCallback(
    (show: boolean, id: string) => dispatch(onModalToggle({ show, id })),
    [dispatch],
  );

  return (
    <ConnectedModal
      {...props}
      store={store}
      onModalToggle={onModalToggleCb}
      openedModalIds={openedModalIds}
    />
  );
};

export default ConnectedModalWithStore;
