import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  heading: FelaCSS;
  animation: FelaCSS;
}

const styles = (): IStyles => ({
  heading: {
    paddingTop: 8,
    fontDefaultSemiBold: 18,
    color: colors.blueCrush,
    paddingBottom: 0,
  },
  animation: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -20%)',
    width: '100%',
    height: '100%',
  },
});

export default styles;
