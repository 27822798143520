import React from 'react';
import { getIsSearchGoogleModalShown } from 'lib/shortlist/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const SearchGoogleModal = lazyComponent(() => import('.'));

const SearchGoogleModalLazy: React.FC = () => {
  const show = useSelector(getIsSearchGoogleModalShown);

  if (!show) {
    return null;
  }

  return <SearchGoogleModal />;
};

export default React.memo(SearchGoogleModalLazy);
