import { AnyAction } from 'redux';
import { Observable, Subject } from 'rxjs';

/**
 * A simple service that allows to dispatch an action and handle it in epics
 * without the need of disturbing the reducers.
 */
export class MuteActionsService {
  private action$: Subject<AnyAction>;

  constructor() {
    this.action$ = new Subject<AnyAction>();
  }

  dispatch(action: AnyAction): void {
    this.action$.next(action);
  }

  actions(): Observable<AnyAction> {
    return this.action$;
  }
}
