import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';
import { getProfilePhoto } from 'lib/users/selectors';

export const ADD_PROFILE_PICTURE_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'ab05aea9-967e-49ca-a64e-4d7cebec7619',
  storage: 'user',
  name: 'Add profile picture',
  type: 'interaction',
  isTaskCompleted: ({ getState }) => {
    const state = getState();
    const profilePhoto = getProfilePhoto(state);
    return !!profilePhoto || isTodayTaskCompleted(state, ADD_PROFILE_PICTURE_TASK_DEFINITION);
  },
};

registerTodayTask(ADD_PROFILE_PICTURE_TASK_DEFINITION);
