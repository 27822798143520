import Router, { useRouter } from 'next/router';
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { StickyContainer } from 'react-sticky';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { Box } from '@bridebook/ui';
import { Capacitor } from '@capacitor/core';
import { useQueryClient } from '@tanstack/react-query';
import FullpageLoader from 'app-shared/components/fullpage-loader/fullpage-loader';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import ConnectionStatus from 'components/app/connection-status';
import Head from 'components/app/head';
import Snackbar from 'components/app/snackbar-wrapper';
import { useAuthContext } from 'components/auth/auth-context';
import WarningModal from 'components/bbcommon/warning-modal/warning-modal-lazy';
import BookPhotographerModalLazy from 'components/book-photographer/book-photographer-modal-lazy';
import EnquiriesUserLimitModal from 'components/enquiries/enquiries-user-limit/enquiries-user-limit-modal/enquiries-user-limit-modal-lazy';
import EnquiryModalCollection from 'components/enquiries/enquiry-modal-collection';
import LoadingBar from 'components/loading-bar';
import { PageContent } from 'components/page-content';
import AtYourVenueQuiz from 'components/quiz/at-your-venue-quiz/at-your-venue-quiz-lazy';
import CakesQuiz from 'components/quiz/cakes-quiz/cakes-quiz-lazy';
import PhotoQuiz from 'components/quiz/photo-quiz/photo-quiz-lazy';
import FeedbackPopup from 'components/settings/feedback-popup/feedback-popup-lazy';
import ShortlistUserLimitModal from 'components/shortlist/shortlist-user-limit/shortlist-user-limit-modal/shortlist-user-limit-modal-lazy';
import VenueConfirmationModal from 'components/venue-confirmation/venue-confirmation-modal';
import ViewVenuerexPopup from 'components/venuerex/view-venuerex-popup/view-venuerex-popup-lazy';
import { setPathname } from 'lib/app/actions';
import ComponentLevelErrorBoundary from 'lib/app/component-level-error-boundary';
import { getLastPath } from 'lib/app/selectors';
import { hideSplashScreen } from 'lib/mobile-app/actions';
import { IPageSettings, PageSettings, defaultPageSettings } from 'lib/page-settings-context';
import { checkIsSearchLocationInitialized } from 'lib/search/selectors';
import { UrlHelper } from 'lib/url-helper';
import { useDispatch, useSelector, useStore } from 'lib/utils';
import useOfflineDetection from 'lib/utils/hooks/use-offline-detection';
import { useReverseSearchRedirect } from 'lib/utils/hooks/use-reverse-search-redirect';
import { usePathWithoutMarket } from 'lib/utils/url';
import { getIsProfileLoading, getProfileLoaded } from 'lib/weddings/selectors';
import AchievementBadgesModals from './achievements/achievement-badges-modals';
import { LostLeadModal } from './lost-lead-modal/lost-lead-modal-lazy';
import componentStyles from './page.style';
import BeautyQuiz from './quiz/beauty-quiz/beauty-quiz-lazy';
import FloristsQuiz from './quiz/florists-quiz/florists-quiz-lazy';
import VideoQuiz from './quiz/video-quiz/video-quiz-lazy';
import SearchByNameOrLocationModalLazy from './search/search-by-name-or-location-modal/search-by-name-or-location-modal-lazy';
import ShortlistModalCollectionLazy from './shortlist/shortlist-modal-lazy-collection';

if (process.browser) {
  require('smoothscroll-polyfill').polyfill(); // eslint-disable-line
}

export interface IPageProps {
  reservedStickySpace?: boolean;
  isSticky?: boolean;
  hideFooter?: boolean;
  hideFooterTop?: boolean;
  isSettingsCordova?: boolean;
  isSignupPage?: boolean;
  stickyHeader?: boolean;
  children?: React.ReactNode;
  childrenContentWrap?: React.ReactNode;
  pageSettings?: IPageSettings;
  subHeaderContent?: ReactNode;
  isOnboardingMobile?: boolean;
  hideHeader?: boolean;
  customHeader?: ReactNode;
  disableSafeAreaInsetTop?: boolean;
  contentOffset?: {
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
}

interface IProps extends IPageProps {}

const Page: FunctionComponent<IProps> = ({
  children,
  childrenContentWrap,
  reservedStickySpace,
  isSticky,
  hideFooter,
  hideFooterTop,
  isSettingsCordova,
  isSignupPage,
  stickyHeader,
  pageSettings = defaultPageSettings,
  subHeaderContent,
  isOnboardingMobile,
  hideHeader,
  customHeader,
  contentOffset = {},
  disableSafeAreaInsetTop = false,
}: IProps) => {
  const dispatch = useDispatch();
  const pathname = usePathWithoutMarket();
  const store = useStore();
  const isProfileLoaded = useSelector(getProfileLoaded);
  const isSearchLocationInitialized = useSelector(checkIsSearchLocationInitialized);
  const market = useMarket();
  const { isInitialized, isAuthenticated, user, wedding } = useAuthContext();
  const router = useRouter();
  const { isFallback, isReady: isRouterReady } = router;
  const isProfileLoading = useSelector(getIsProfileLoading);
  const lastPath = useSelector(getLastPath);

  /*
    This effect adds a listener to popstate event, which is triggered when
    the user navigates back or forward in the history.
    If the current market prefix for the user (i.e. after changing wedding country in settings)
    is different than the market prefix in history URL (as), that part of the URL is replaced with the new prefix.
    Also, if the last path is onboarding (and the current one doesn't), navigate to the home page.
  */
  useEffect(() => {
    Router.beforePopState(({ as }) => {
      // If the last path (the one we're about to navigate to) is onboarding and the current one isn't, navigate to the home page
      if (!router.pathname.includes('onboarding') && as.includes('/onboarding')) {
        router.push(UrlHelper.home);
        return false;
      }

      if (as.startsWith(`/${market.prefix}`)) return true;

      const asSplit = as.split('/');

      // only cont. processing if this is actually a market prefix
      if (asSplit?.[1]?.match(/^[a-z]{2}(?:-[a-z]{2}){0,2}$/)) {
        asSplit[1] = market.prefix;
        location.href = asSplit.join('/');
        return false;
      }

      return true;
    });
    return () => {
      router.beforePopState(() => true);
    };
  }, [router, market, lastPath]);

  /*
   Set pathname on asPath change
   TODO: this should be handled in an epic
  */
  useEffect(() => {
    dispatch(setPathname(pathname));
  }, [pathname, dispatch]);

  const queryClient = useQueryClient();

  /*
   Clear React Query cache on logout (when profile is not loaded)
  */
  useEffect(() => {
    if (!isProfileLoaded) {
      queryClient.clear();
    }
  }, [queryClient, isProfileLoaded]);

  useReverseSearchRedirect();

  const isOffline = useOfflineDetection();

  const styles = componentStyles();

  const showHeader = !isOnboardingMobile && !hideHeader;

  const content = (
    <PageContent
      {...{
        childrenContentWrap,
        contentOffset,
        customHeader,
        disableSafeAreaInsetTop,
        hideFooter,
        hideFooterTop,
        isSettingsCordova,
        pageSettings,
        reservedStickySpace,
        showHeader,
        stickyHeader,
        subHeaderContent,
      }}>
      {children}
    </PageContent>
  );

  /**
   * Save initial loader value to local state. This is needed in order to hide it after 25s
   * if it's still shown. This is a workaround for a bug where the loader is not hidden after
   * certain amount of time. The source of the bug remain unknown for now.
   */
  const showLoaderInitial =
    isFallback ||
    !isProfileLoaded ||
    !isSearchLocationInitialized ||
    !isRouterReady ||
    !isInitialized;

  const [showLoader, setShowLoader] = useState(showLoaderInitial);

  useEffect(() => {
    if (!showLoaderInitial) {
      setShowLoader(false);
    }
  }, [showLoaderInitial]);

  useEffect(() => {
    if (showLoader && !isSignupPage) {
      const timeout = setTimeout(() => {
        try {
          const state = store.getState();
          const isProfileLoaded = getProfileLoaded(state);
          const isSearchLocationInitialized = checkIsSearchLocationInitialized(state);

          SentryMinimal().captureMessage('[Page.tsx] App failed to hide loader after 25 seconds', {
            extra: {
              state: {
                isRouterReady: Router.isReady,
                isRouterFallback: Router.isFallback,
                isProfileLoaded,
                appState: state.app,
                isSearchLocationInitialized,
                isNativePlatform: Capacitor.isNativePlatform(),
                isInitialized,
                isAuthenticated,
                isProfileLoading,
                userId: user?.id,
                weddingId: wedding?.id,
              },
            },
            tags: {
              source: 'AppFailedToLoad',
            },
          });
        } finally {
          setShowLoader(false);
          dispatch(hideSplashScreen());
        }
      }, 30 * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    dispatch,
    isInitialized,
    isSignupPage,
    showLoader,
    store,
    user?.id,
    wedding?.id,
    isAuthenticated,
    isProfileLoading,
  ]);

  return (
    <PageSettings.Provider value={pageSettings}>
      <>
        <Snackbar />
        {isOffline && <ConnectionStatus />}
        <LoadingBar />
        <ComponentLevelErrorBoundary component={'Page'}>
          {isSignupPage && <>{content}</>}
          {!isSignupPage && showLoader && (
            <>
              <Head />
              <FullpageLoader />
            </>
          )}
          {!isSignupPage && !showLoader && (
            <>
              <Box w="100%" minH="100vh" style={styles.containerContentWrap} id="page-container">
                {isSticky ? <StickyContainer>{content}</StickyContainer> : content}
              </Box>

              <EnquiryModalCollection />
              <WarningModal />
              <ViewVenuerexPopup />
              <>
                <BeautyQuiz />
                <FloristsQuiz />
                <PhotoQuiz />
                <CakesQuiz />
                <AtYourVenueQuiz />
                <VideoQuiz />
                <BookPhotographerModalLazy />
                <AchievementBadgesModals />
                <ShortlistUserLimitModal />
                <EnquiriesUserLimitModal />
                <FeedbackPopup />
                <VenueConfirmationModal />
                <LostLeadModal />
                <ShortlistModalCollectionLazy />
                <SearchByNameOrLocationModalLazy />
              </>
            </>
          )}
        </ComponentLevelErrorBoundary>
      </>
    </PageSettings.Provider>
  );
};

export default Page;
