import { isEqual } from 'lodash';
import React, { FC, memo } from 'react';
import { Box } from '@bridebook/ui';
import RadioCheckbox from 'components/common/radio-checkbox/radio-checkbox';
import { IRadiusOption } from 'lib/search/get-radius-increase-filter-options';
import useRadiusIncreaseFilter from 'lib/search/hooks/use-radius-filter';
import componentStyles from './search-increase-radius-filter.style';

interface IProps {
  disableHeading?: boolean;
  disableHeadingTopBorder?: boolean;
  disableHeadingTopPadding?: boolean;
}
const SearchIncreaseRadiusFilter: FC<IProps> = ({
  disableHeading,
  disableHeadingTopBorder,
  disableHeadingTopPadding,
}) => {
  const { optionItems, checkedOptions, onFilterApply, defaultRadioValue, label } =
    useRadiusIncreaseFilter('filtersModal');

  const defaultOption = optionItems.find((option) => option.key === defaultRadioValue);
  const checkedOption = checkedOptions[0] || defaultOption;

  const styles = componentStyles({ disableHeadingTopBorder, disableHeadingTopPadding });

  const handleChange = (option: IRadiusOption) => {
    option.key === checkedOptions[0]?.key
      ? onFilterApply(defaultOption?.value, true)
      : onFilterApply(option.value, true);
  };

  return (
    <>
      {!disableHeading && (
        <Box as="h2" style={styles.heading}>
          {label} {checkedOption && <Box as="span">&nbsp;(1)</Box>}
        </Box>
      )}
      <Box style={styles.wrapper}>
        {optionItems.map((option) => {
          const checked = isEqual(checkedOption, option);
          return (
            <Box style={styles.radio(checked)} key={option.key} mb={6} mr={4}>
              <RadioCheckbox
                name="guests-filter"
                option={{
                  label: option.primaryText,
                  value: option.key,
                }}
                checked={checked}
                onClick={() => handleChange(option)}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default memo(SearchIncreaseRadiusFilter);
