import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
  iconWrap: FelaCSSWithCustomSelectors;
  title: FelaCSS;
}

interface IStyleArgs {
  isActive: boolean;
  disabled: boolean;
}

const styles = ({ isActive, disabled }: IStyleArgs): IStyles => {
  const activeStyles = {
    color: colors.indigoCrush,
    fontWeight: 500,
  };

  return {
    wrapper: {
      paddingHorizontal: 4,
      paddingTop: 2,
      paddingBottom: 2,
      color: colors.space60,

      ...(isActive && activeStyles),
      ...(disabled && { pointerEvents: 'none', opacity: 0.75 }),
    },

    iconWrap: {
      fontSize: 24,
      lineHeight: '24px',
      marginBottom: 1,
      marginHorizontal: 'auto',

      ...(isActive && activeStyles),
    },

    title: {
      fontDefault: 11,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
  };
};

export default styles;
