import { FC, memo } from 'react';
import { ISearchByNameOrLocationModalProps } from 'components/search/search-by-name-or-location-modal/search-by-name-or-location-modal';
import { getIsSearchModalOpen } from 'lib/search/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const SearchByNameOrLocationModal = lazyComponent<ISearchByNameOrLocationModalProps>(
  () =>
    import('components/search/search-by-name-or-location-modal/search-by-name-or-location-modal'),
);

const SearchByNameOrLocationModalLazy: FC<ISearchByNameOrLocationModalProps> = () => {
  const isModalOpen = useSelector(getIsSearchModalOpen);
  if (!isModalOpen) return null;

  return <SearchByNameOrLocationModal />;
};

export default memo(SearchByNameOrLocationModalLazy);
