import { getI18n } from 'lib/i18n/getI18n';
import { IIntlMessageDescriptor } from 'lib/types';

export const getFirebaseMessages = (): { [k: string]: IIntlMessageDescriptor } => {
  const i18n = getI18n();
  return {
    'auth/email-already-in-use': {
      defaultMessage: i18n.t('auth:invalid.email.inUse'),
      id: 'firebase.error.EMAIL_TAKEN',
    },
    'auth/invalid-email': {
      defaultMessage: i18n.t('auth:invalid.email.wrontFormat'),
      id: 'firebase.error.INVALID_EMAIL',
    },
    'auth/user-not-found': {
      defaultMessage: i18n.t('auth:invalid.email.notConnected'),
      id: 'firebase.error.INVALID_USER',
    },
    'auth/wrong-password': {
      defaultMessage: i18n.t('auth:invalid.password.notCorrect'),
      id: 'firebase.error.INVALID_PASSWORD',
    },
    'auth/network-request-failed': {
      defaultMessage: i18n.t('auth:invalid.network.requestFailed'),
      id: 'firebase.error.auth/network-request-failed',
    },
    'auth/too-many-requests': {
      defaultMessage: i18n.t('auth:invalid.network.tooManyRequests'),
      id: 'firebase.error.auth/too-many-requests',
    },
    'auth/user-token-expired': {
      defaultMessage: i18n.t('auth:invalid.session.expired'),
      id: 'firebase.error.auth/user-token-expired',
    },
    'auth/invalid-action-code': {
      defaultMessage: i18n.t('auth:invalid.password.reset.expired'),
      id: 'firebase.error.auth/password-reset-expired',
    },
    'auth/popup-closed-by-user': {
      defaultMessage: i18n.t('auth:invalid.popup.closed.by.user'),
      id: 'firebase.error.auth/popup-closed-by-user',
    },
    'auth/user-cancelled': {
      defaultMessage: i18n.t('auth:invalid.user.cancelled'),
      id: 'firebase.error.auth/user-cancelled',
    },
  };
};
