import { useRouter } from 'next/router';
import React, { memo, useEffect } from 'react';
import { fetchEnquiryConfirmationSupplier } from 'lib/enquiries/actions';
import { getEnquiryConfirmationSupplier, getShowEnquiryForm } from 'lib/enquiries/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';
import { IEnquiryConfirmationModal } from './';

const EnquiryConfirmationModal = lazyComponent<IEnquiryConfirmationModal>(
  () => import('components/enquiries/enquiry-confirmation-modal'),
);

interface LazyEnquiryConfirmationModalProps {}

const EnquiryConfirmationModalLazy: React.FC<LazyEnquiryConfirmationModalProps> = () => {
  const showEnquiryForm = useSelector(getShowEnquiryForm);
  const dispatch = useDispatch();
  const weddingId = useSelector((state) => state.weddings.profile.id);
  const enquiryConfirmationSupplier = useSelector(getEnquiryConfirmationSupplier);
  const { query } = useRouter();
  const enquirySupplierId = query?.enquirySupplier;

  useEffect(() => {
    if (enquirySupplierId && weddingId) {
      dispatch(fetchEnquiryConfirmationSupplier(enquirySupplierId as string));
    }
  }, [dispatch, enquirySupplierId, weddingId]);

  const showConfirmationScreen =
    query?.enquiryConfirmation === 'true' &&
    enquiryConfirmationSupplier?.publicId === query?.enquirySupplier;

  const backgroundLazyLoad = showEnquiryForm;
  if (!showConfirmationScreen && !backgroundLazyLoad) return null;

  return <EnquiryConfirmationModal showConfirmationScreen={showConfirmationScreen} />;
};

export default memo(EnquiryConfirmationModalLazy);
