import { useDispatch } from 'react-redux';
import { updateFiltersCount, updateSearchFilters } from 'lib/search/actions';
import { UpdateSearchFilters } from 'lib/types';

type IUseUpdateFilters = (
  location?: string,
) => (data: Omit<UpdateSearchFilters, 'location'>) => void;

/**
 * useUpdateFilters hook:
 *  - updates search filters in the store
 *  - updates filters count value in the store
 */
export const useUpdateFilters: IUseUpdateFilters = (location) => {
  const dispatch = useDispatch();

  return (data) => {
    const defaultLocation = 'filtersModal';
    const { name, value, tag, skipAnalytics, clearPreviousFilters } = data;

    dispatch(
      updateSearchFilters({
        name,
        value,
        tag,
        location: location || defaultLocation,
        clearPreviousFilters,
        skipAnalytics,
      }),
    );

    dispatch(updateFiltersCount());
  };
};
