import { AchievementIconType } from '@bridebook/ui/src/components/svg/achievements/constants';
import { getI18n } from 'lib/i18n/getI18n';

export interface IAchievementData {
  title: string;
  descLong: string;
  descShort: string;
  icon: AchievementIconType;
  order: number;
}

export const achievements: () => Record<string, () => IAchievementData> = () => {
  const i18n = getI18n();
  return {
    guestAdded: () => ({
      title: i18n.t('achievements:guestAddedTitle'),
      descLong: i18n.t('achievements:guestAddedLongDescription'),
      descShort: i18n.t('achievements:guestAddedShortDescription'),
      icon: AchievementIconType.GuestAdded,
      order: 3,
    }),
    halfChecklistChecked: () => ({
      title: i18n.t('achievements:halfChecklistCheckedTitle'),
      descLong: i18n.t('achievements:halfChecklistCheckedLongDescription'),
      descShort: i18n.t('achievements:halfChecklistCheckedShortDescription'),
      icon: AchievementIconType.HalfChecklistChecked,
      order: 8,
    }),
    firstMessageSent: () => ({
      title: i18n.t('achievements:firstMessageSentTitle'),
      descLong: i18n.t('achievements:firstMessageSentLongDescription'),
      descShort: i18n.t('achievements:firstMessageSentShortDescription'),
      icon: AchievementIconType.FirstMessageSent,
      order: 0,
    }),
    addedToFavourites: () => ({
      title: i18n.t('achievements:addedToFavouritesTitle'),
      descLong: i18n.t('achievements:addedToFavouritesLongDescription'),
      descShort: i18n.t('achievements:addedToFavouritesShortDescription'),
      icon: AchievementIconType.AddedToFavourites,
      order: 2,
    }),
    photographerBooked: () => ({
      title: i18n.t('achievements:photographerBookedTitle'),
      descLong: i18n.t('achievements:photographerBookedLongDescription'),
      descShort: i18n.t('achievements:photographerBookedShortDescription'),
      icon: AchievementIconType.PhotographerBooked,
      order: 7,
    }),
    checkedTenTasks: () => ({
      title: i18n.t('achievements:checkedTenTasksTitle'),
      descLong: i18n.t('achievements:checkedTenTasksLongDescription'),
      descShort: i18n.t('achievements:checkedTenTasksShortDescription'),
      icon: AchievementIconType.CheckedTenTasks,
      order: 6,
    }),
    profileImageAdded: () => ({
      title: i18n.t('achievements:profileImageAddedTitle'),
      descLong: i18n.t('achievements:profileImageAddedLongDescription'),
      descShort: i18n.t('achievements:profileImageAddedShortDescription'),
      icon: AchievementIconType.ProfileImageAdded,
      order: 4,
    }),
    venueBooked: () => ({
      title: i18n.t('achievements:venueBookedTitle'),
      descLong: i18n.t('achievements:venueBookedLongDescription'),
      descShort: i18n.t('achievements:venueBookedShortDescription'),
      icon: AchievementIconType.VenueBooked,
      order: 5,
    }),
    collaboratorAdded: () => ({
      title: i18n.t('achievements:collaboratorAddedTitle'),
      descLong: i18n.t('achievements:collaboratorAddedLongDescription'),
      descShort: i18n.t('achievements:collaboratorAddedShortDescription'),
      icon: AchievementIconType.CollaboratorAdded,
      order: 1,
    }),
  };
};
