import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { useFela } from 'react-fela';
import { Box } from '@bridebook/ui';
import { Link } from 'app-shared/components/link/link';
import { getIsDesktop } from 'lib/app/selectors';
import { imgixBaseURL, useSelector } from 'lib/utils';
import componentStyles from './top-bar-header-item.style';

export interface ITopBarHeaderItemProps {
  href?: string;
  dataName: string;
  label: string;
  icon: string;
  onClick?: MouseEventHandler<ReactNode>;
  endIconText?: ReactNode;
}

const TopBarHeaderItem: FC<ITopBarHeaderItemProps> = ({
  href,
  dataName,
  label,
  icon,
  onClick,
  endIconText,
}) => {
  const { css } = useFela();
  const isDesktop = useSelector(getIsDesktop);

  return (
    <LinkWrap href={href}>
      <Box style={componentStyles.wrapper} data-name={dataName} onClick={onClick}>
        {isDesktop && <div className={css(componentStyles.label)}>{label}</div>}
        {!isDesktop && (
          <Box style={componentStyles.buttonWrapper}>
            <img src={imgixBaseURL + `/assets/icons/top-bar/${icon}.svg`} width={22} height={25} />
          </Box>
        )}
        {endIconText}
      </Box>
    </LinkWrap>
  );
};

const LinkWrap: React.FC<Pick<ITopBarHeaderItemProps, 'href'> & PropsWithChildren> = ({
  children,
  href,
}) => {
  if (href) {
    return (
      <Link href={href} legacyBehavior>
        {children}
      </Link>
    );
  } else {
    return <>{children}</>;
  }
};

export default React.memo(TopBarHeaderItem);
