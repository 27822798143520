import { useMemo } from 'react';
import { Weddings } from '@bridebook/models';
import { useQuery } from '@tanstack/react-query';
import { achievements } from 'components/achievements/achievements-data';
import { useSelector } from 'lib/utils';
import { WEDDING_QUERY_CACHE_TIME } from 'lib/weddings/constants/constants';
import { getWeddingProfileId } from 'lib/weddings/selectors';

export const WEDDING_BADGES_QUERY_KEY = 'wedding-badges';

export const useWeddingBadgesQuery = () => {
  const weddingId = useSelector(getWeddingProfileId);
  const achievementsById: ReturnType<typeof achievements> = useMemo(achievements, []);

  const { data, isLoading } = useQuery(
    [WEDDING_BADGES_QUERY_KEY, weddingId],
    async () => {
      const badges = await Weddings._.getById(weddingId).Badges.query().get();
      const sortedBadges = Object.entries(badges)
        .filter((badge) => achievementsById[badge[0]])
        .sort((a, b) =>
          achievementsById[a[1].id]().order > achievementsById[b[1].id]().order ? 1 : -1,
        );

      return Object.fromEntries(sortedBadges);
    },
    {
      cacheTime: WEDDING_QUERY_CACHE_TIME,
      staleTime: WEDDING_QUERY_CACHE_TIME,
    },
  );

  return {
    data,
    isLoading,
    achievementsById,
  };
};
