import { FelaCSS } from 'src/components/fela/flowtypes';

interface IStyle {
  bar: FelaCSS;
}

const styles = (color: string): IStyle => ({
  bar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '4px',
    backgroundColor: color,
    transformOrigin: 'left',
    transform: 'scaleX(0)',
  },
});

export default styles;
