import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  rotatingAnimation: FelaCSS;
  wrapper(background?: string): FelaCSS;
}

const cssRotate = {
  '100%': { transform: 'rotate(360deg)' },
} as unknown as FelaCSS['animationName'];

const styles = (): IStyles => ({
  rotatingAnimation: {
    animationDuration: '0.9s',
    animationIterationCount: 'infinite',
    animationName: cssRotate,
  },

  wrapper: (background = colors.pinkJoy) => ({
    alignItems: 'center',
    justifyContent: 'center',
    background,
    position: 'sticky',
    height: '100vh',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  }),
});

export default styles;
