import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonSelector, Col, Row } from '@bridebook/ui';
import { capitalizeFirstLetter } from 'app-shared/lib/utils';
import { useSeasonsAndWeekDaysOptionsForPriceFilter } from 'lib/search/hooks/wedding-estimate-price-filter/use-seasons-and-week-days-options-for-price-filter';
import { IconClass } from 'lib/ui/constants';
import componentStyles from './wedding-estimate-price-filter-content.style';

const onOptionClicked = (
  option: string,
  optionsToCheck: string[],
  setOptions: Dispatch<SetStateAction<string[]>>,
) => {
  // if option is already selected, remove from array
  if (optionsToCheck.indexOf(option) >= 0) {
    return setOptions((prevOptions) => {
      // remove selected option
      const newOptions = prevOptions.filter((prevOption) => prevOption !== option);

      return newOptions;
    });
  }

  // otherwise add to array
  return setOptions((prevOptions) => [...prevOptions, option]);
};

interface IProps {
  weddingSeasons: string[];
  setWeddingSeasons: Dispatch<SetStateAction<string[]>>;
  weddingWeekDays: string[];
  setWeddingWeekDays: Dispatch<SetStateAction<string[]>>;
  isQuickFilter?: boolean;
  onSeasonOptionClicked?: (option: string) => void;
  onWeekDayOptionClicked?: (option: string) => void;
}

export const WeddingEstimatePriceFilterContent = ({
  weddingSeasons,
  weddingWeekDays,
  setWeddingSeasons,
  setWeddingWeekDays,
  isQuickFilter,
  onSeasonOptionClicked,
  onWeekDayOptionClicked,
}: IProps) => {
  const { t } = useTranslation('search');

  const { priceFilterWeekDayTranslations, priceFilterSeasonTranslations } =
    useSeasonsAndWeekDaysOptionsForPriceFilter();

  const onWeddingWeekDayClicked = useCallback(
    (option: string) => {
      onOptionClicked(option, weddingWeekDays, setWeddingWeekDays);
      onWeekDayOptionClicked?.(option);
    },
    [weddingWeekDays, setWeddingWeekDays, onWeekDayOptionClicked],
  );

  const onWeddingSeasonClicked = useCallback(
    (option: string) => {
      onOptionClicked(option, weddingSeasons, setWeddingSeasons);
      onSeasonOptionClicked?.(option);
    },
    [weddingSeasons, setWeddingSeasons, onSeasonOptionClicked],
  );

  const styles = componentStyles(isQuickFilter);

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.header}>
        <Box style={styles.title}>{t('weddingEstimatePriceFilter.description')}</Box>
        <Box style={styles.note}>{t('weddingEstimatePriceFilter.note')}</Box>
      </Box>
      <hr style={styles.hr} />
      <Box style={styles.label}>{t('weddingEstimatePriceFilter.daysOfWeek')}</Box>
      <Row style={styles.buttonsWrapper}>
        {Object.entries(priceFilterWeekDayTranslations).map(([key, day]) => (
          <Col xs={4} key={key}>
            <ButtonSelector
              size="small"
              selected={weddingWeekDays.includes(key)}
              onClick={() => onWeddingWeekDayClicked(key)}
              style={styles.button}
              disableActiveState>
              <Box style={styles.buttonTextWrapper}>{day}</Box>
            </ButtonSelector>
          </Col>
        ))}
      </Row>
      <hr style={styles.hr} />
      <Box style={styles.label}>{t('weddingEstimatePriceFilter.seasons')}</Box>
      <Row style={styles.buttonsWrapper}>
        {Object.entries(priceFilterSeasonTranslations).map(([key, season]) => (
          <Col xs={6} key={key}>
            <ButtonSelector
              size="small"
              selected={weddingSeasons.includes(key)}
              onClick={() => onWeddingSeasonClicked(key)}
              style={styles.button}
              disableActiveState>
              <Box style={styles.buttonTextWrapper}>
                <Box style={styles.buttonMainText}>
                  <span
                    className={IconClass[capitalizeFirstLetter(key) as keyof typeof IconClass]}
                  />
                  {season.translationSeason}
                </Box>
                <Box style={styles.note}>{season.translationMonthDuration}</Box>
              </Box>
            </ButtonSelector>
          </Col>
        ))}
      </Row>
    </Box>
  );
};
