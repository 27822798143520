import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconLogout = ({ id, color, width = 14, style, ...restProps }: IProps) => {
  const viewBoxWidth = 14;
  const viewBoxHeight = 12;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M6.881 10.288a4.745 4.745 0 0 0 1.044-.404.492.492 0 0 1 .46.87 5.69 5.69 0 0 1-4.548.348A5.67 5.67 0 0 1 .574 8.198 5.67 5.67 0 0 1 .32 3.836 5.672 5.672 0 0 1 3.225.573 5.674 5.674 0 0 1 7.587.32c.272.095.539.21.793.344a.492.492 0 1 1-.46.87 4.752 4.752 0 0 0-.656-.284 4.695 4.695 0 0 0-3.61.21 4.692 4.692 0 0 0-2.405 2.7 4.691 4.691 0 0 0 .21 3.61 4.693 4.693 0 0 0 2.702 2.403 4.71 4.71 0 0 0 2.72.116zm7.024-4.213c-.011.02-.02.042-.034.062a.801.801 0 0 1-.095.117l-2.772 2.772a.764.764 0 0 1-1.083 0 .765.765 0 0 1 0-1.083l1.466-1.465h-6.53a.765.765 0 1 1 0-1.53h6.53L9.92 3.481A.765.765 0 1 1 11.004 2.4l2.774 2.774a.78.78 0 0 1 .093.114c.014.02.023.041.034.062.013.023.027.045.037.07.01.026.017.053.025.08.006.021.014.041.018.062.02.1.02.202 0 .3-.004.022-.012.042-.018.063-.008.027-.014.054-.025.08-.01.025-.024.047-.037.07z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconLogout;
