import {
  AppTrackingStatusResponse,
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { useUserAgent } from 'next-useragent';
import { useEffect } from 'react';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { Capacitor } from '@capacitor/core';
import { Appsflyer, AppsflyerOptions } from '@ionic-native/appsflyer';
import { env } from 'lib/env';
import { getIsIOS as getIsIos } from 'lib/mobile-app/selectors';
import { DevicePlatform } from 'lib/types';
import { useSelector } from 'lib/utils';

const initAppsflyerSdk = async (isIOS: boolean) => {
  const options: AppsflyerOptions = {
    isDebug: !process.env.LIVE,
    onInstallConversionDataListener: true,
    devKey: env.APPSFLYER_DEV_KEY,
    appId: isIOS ? env.APPSTORE_ID : undefined,
  };

  if (!Appsflyer || !AppTrackingTransparency) return;

  try {
    if (!isIOS) {
      await Appsflyer.initSdk(options);
      return;
    }

    let response: AppTrackingStatusResponse | undefined;
    const { status } = await AppTrackingTransparency.getStatus();
    if (status === 'notDetermined') {
      response = await AppTrackingTransparency.requestPermission();
    }

    if (status === 'authorized' || response?.status === 'authorized') {
      await Appsflyer.initSdk(options);
    }
  } catch (e) {
    SentryMinimal().captureException(e, {
      tags: {
        source: 'CordovaTracking',
      },
    });
    /* eslint-disable no-console */
    console.log(e);
  }
};

const CordovaTracking = () => {
  const { isIos: isIosUserAgent } = useUserAgent(window.navigator.userAgent);
  const isIosPlatform = useSelector(getIsIos);
  const isIos = isIosUserAgent || isIosPlatform;

  useEffect(() => {
    /**
     * When accessing app.bridebook.com directly (via Apple Mail for instance), the `bb-is-app` header will be set to 1 by CloudFront.
     * This causes this gated code to run, which will initialise the appsflyer SDK.
     * If the user is on an iOS device, `isIos` will be true, but `appId` will not be set, causing the following error:
     * > 'appId' is missing or empty
     *
     * This condition attempts to fix that scenario.
     */
    const platform = Capacitor.getPlatform();

    if (platform === DevicePlatform.WEB) {
      return;
    }

    initAppsflyerSdk(isIos);
  }, [isIos]);
  return null;
};

export default CordovaTracking;
