import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';
import { getVenueBooked } from 'lib/weddings/selectors';

export const FIND_DREAM_VENUE_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'db2b72a4-f437-4e89-9e92-c03aa148fb76',
  storage: 'none',
  name: 'Find your dream venue',
  type: 'interaction',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;
    const venueBooked = getVenueBooked(state);

    return venueBooked || !suppliers?.includes('venue');
  },
};

registerTodayTask(FIND_DREAM_VENUE_TASK_DEFINITION);
