import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useSearchParams } from 'lib/search/hooks/query/use-search';

export const useIsGlobalSearch = () => {
  const weddingMarket = useMarket();
  const searchParams = useSearchParams();

  const weddingCountryCode = weddingMarket.country;
  const searchCountryCode = searchParams?.market.country;

  return weddingCountryCode !== searchCountryCode;
};
