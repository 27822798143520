import { useRouter } from 'next/router';
import React, { PropsWithChildren, ReactNode, memo, useMemo } from 'react';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import { Box } from '@bridebook/ui';
import AppListeners from 'components/app/app-listeners';
import Header from 'components/app/header/header';
import Footer from 'components/bbcommon/footer/footer';
import MobileNavigation from 'components/mobile-app/mobile-navigation';
import SettingsFooter from 'components/settings/settings-footer/settings-footer';
import ComponentLevelErrorBoundary from 'lib/app/component-level-error-boundary';
import { getDevice, getIsMobile, getIsTablet } from 'lib/app/selectors';
import { IPageSettings } from 'lib/page-settings-context';
import { getShowReviewsPage } from 'lib/supplier/selectors';
import { getMobileNavShown } from 'lib/ui/selectors';
import { showFooter } from 'lib/ui/show-footer';
import { selectCurrentUser } from 'lib/users/selectors';
import { useSelector } from 'lib/utils';
import { usePathWithoutMarket } from 'lib/utils/url';
import TopBarProtected from './app/top-bar/top-bar-protected';
import componentStyles from './page-content.style';

interface IProps {
  childrenContentWrap?: ReactNode;
  contentOffset?: {
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
  customHeader?: ReactNode;
  disableSafeAreaInsetTop?: boolean;
  hideFooter?: boolean;
  hideFooterTop?: boolean;
  isSettingsCordova?: boolean;
  pageSettings: IPageSettings;
  reservedStickySpace?: boolean;
  showHeader: boolean;
  stickyHeader?: boolean;
  subHeaderContent?: ReactNode;
}

const PageContentComponent = ({
  children,
  childrenContentWrap = null,
  contentOffset = {},
  customHeader,
  disableSafeAreaInsetTop = false,
  hideFooter,
  hideFooterTop,
  isSettingsCordova,
  pageSettings,
  reservedStickySpace,
  showHeader,
  stickyHeader,
  subHeaderContent,
}: PropsWithChildren<IProps>) => {
  const pathname = usePathWithoutMarket();
  const device = useSelector(getDevice);
  const user = useSelector(selectCurrentUser);
  const showReviewsPage = useSelector(getShowReviewsPage);
  const isMobile = useSelector(getIsMobile);
  const isTablet = useSelector(getIsTablet);
  const mobileNavShown = useSelector(getMobileNavShown);
  const isAdviceMobile = isMobile && pathname.includes('advice');
  const isBookmarkMobile = isMobile && pathname.includes('bookmark');
  const router = useRouter();

  const pageKey = useMemo(() => {
    if (router.asPath === '/') {
      return router.asPath.split(`/`).pop()?.split('/')[0] || 'index';
    }
    const market = gazetteer.getMarketByURL(router.asPath);
    return router.asPath.split(`${market.prefix}/`).pop()?.split('/')[0];
  }, [router.asPath]);

  const contentOffsetWithDefaults = useMemo(
    () => ({
      desktop: 0,
      tablet: 0,
      mobile: 0,
      ...contentOffset,
    }),
    [contentOffset],
  );

  const styles = componentStyles({
    backgroundColor: pageSettings.backgroundColor,
    isMobile,
    isTablet,
    mobileNavShown,
    reservedStickySpace,
    bottomSafeArea: pathname !== '/home',
    stickyHeader,
    contentOffset: contentOffsetWithDefaults,
    disableSafeAreaInsetTop,
  });

  return (
    <Box style={styles.pageContent} data-name="page" key={pageKey}>
      <AppListeners />
      <Box style={styles.wrapper}>
        {showHeader ? customHeader || <TopBarProtected /> : null}
        <Box style={styles.contentWrap}>
          {showHeader && (
            <>
              <Header />
              {subHeaderContent}
            </>
          )}
          {childrenContentWrap}
          <Box style={styles.childrenWrap}>
            <ComponentLevelErrorBoundary component={'PageChildren'}>
              {children}
            </ComponentLevelErrorBoundary>
          </Box>
          {!hideFooter && showFooter({ pathname, device, user, showReviewsPage }) && (
            <Footer hideTop={hideFooterTop} />
          )}
          {isSettingsCordova && !isAdviceMobile && !isBookmarkMobile && <SettingsFooter />}
        </Box>
      </Box>
      <MobileNavigation />
    </Box>
  );
};

export const PageContent = memo(PageContentComponent);
