import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button } from '@bridebook/ui';
import IconClose from '@bridebook/ui/src/components/svg/icon-close';
import { IApplicationState } from 'lib/types';
import componentStyles from './top-bar-close-pile.style';

const TopBarClosePile = () => {
  const pilingCounter = useSelector((state: IApplicationState) => state.app.pilingCounter);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (window && clicked) {
      window.history.go(-pilingCounter);
    }
  }, [clicked]);

  const handleClick = () => {
    setClicked(true);
  };

  const styles = componentStyles();

  return (
    <Box style={styles.wrapper}>
      <Button
        theme="ghost"
        color="white"
        icon={<IconClose fillColor={clicked ? 'black' : 'white'} />}
        onClick={handleClick}
      />
    </Box>
  );
};

export default TopBarClosePile;
