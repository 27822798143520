import { ReactNode } from 'react';
import { Box, P } from '@bridebook/ui';
import Modal from 'components/bbcommon/modal';
import componentStyles from './pre-prompt-modal.style';

interface IProps {
  show: boolean;
  id: string;
  children: ReactNode;
  onClose?: () => void;
}

interface IPropsChildren {
  children: ReactNode;
}

const styles = componentStyles();

const Body = ({ children }: IPropsChildren) => <Box style={styles.bodyWrapper}>{children}</Box>;

const InnerContent = ({ children }: IPropsChildren) => (
  <Box style={styles.innerContent}>{children}</Box>
);

const Title = ({ children }: IPropsChildren) => <Box style={styles.title}>{children}</Box>;

const Image = ({ children }: IPropsChildren) => <Box style={styles.imageWrapper}>{children}</Box>;

const Buttons = ({ children }: IPropsChildren) => (
  <Box style={styles.buttonWrapper}>{children}</Box>
);

const Description = ({ children }: IPropsChildren) => <P style={styles.description}>{children}</P>;

const PrePromptModal = ({ show, id, children, onClose }: IProps) => (
  <Modal alignTop onClose={onClose} show={show} id={id} forbidIOSOverscroll>
    <Box style={styles.wrapper}>
      <Box style={styles.modal}>
        <Box style={styles.content}>{children}</Box>
      </Box>
    </Box>
  </Modal>
);

PrePromptModal.Body = Body;
PrePromptModal.InnerContent = InnerContent;
PrePromptModal.Title = Title;
PrePromptModal.Image = Image;
PrePromptModal.Description = Description;
PrePromptModal.Buttons = Buttons;

export default PrePromptModal;
