import React from 'react';
import { Box } from '@bridebook/ui';
import RadioCheckbox from 'components/common/radio-checkbox/radio-checkbox';
import componentStyles from 'components/search/filters/number-of-bedrooms-filter/number-of-bedrooms.style';
import { INumberOfBedroomsOption } from 'lib/search/get-number-of-bedrooms-filter-options';
import useNumberOfBedroomsFilter from 'lib/search/hooks/use-number-of-bedrooms-filter';

const NumberOfBedroomsFilter = () => {
  const { optionItems, checkedOptions, onFilterApply, onFilterClear } =
    useNumberOfBedroomsFilter('filtersModal');

  const styles = componentStyles();

  const handleChange = (option: INumberOfBedroomsOption) => {
    option.key === checkedOptions[0]?.key
      ? onFilterClear?.()
      : onFilterApply(option.minValue, option.maxValue, true);
  };

  return (
    <Box style={styles.wrapper}>
      {optionItems.map((option) => {
        const checked = checkedOptions.includes(option);
        return (
          <Box style={styles.radio(checked)} key={option.key} mb={6} mr={4}>
            <RadioCheckbox
              name="accommodation-rooms-filter"
              option={{
                label: option.primaryText,
                value: option.key,
              }}
              checked={checked}
              onClick={() => handleChange(option)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default NumberOfBedroomsFilter;
