import { Dispatch } from 'redux';
import { Countries, Weddings } from '@bridebook/models';
import { ITask as ICountryTask } from '@bridebook/models/source/models/Countries/Tasks.types';
import { ITask as IWeddingTask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getDocsFromCacheOrServer } from 'lib/auth/utils/get-docs-from-cache-or-server';
import { fetchTasksInitialSuccess, fetchUserChecklistSuccess } from 'lib/checklist/actions';
import { mapCleanTimestamps } from 'lib/utils';

export const fetchChecklistData = async (
  dispatch: Dispatch<any>,
  weddingId: string,
  countryCode: CountryCodes,
) => {
  const countryTasksRef = Countries._.getById(countryCode).Tasks.reference;
  const countryTasksSnapshot = await getDocsFromCacheOrServer(countryTasksRef);
  const countryTasksData: Record<string, ICountryTask> = {};
  countryTasksSnapshot.docs.forEach((doc) => {
    const task = doc.data();
    if (task.active === true) {
      countryTasksData[doc.id] = task;
    }
  });

  const weddingTasksRef = Weddings._.getById(weddingId).Tasks.reference;
  const weddingTasksSnapshot = await getDocsFromCacheOrServer(weddingTasksRef);
  const weddingTasksData: Record<string, IWeddingTask> = {};
  weddingTasksSnapshot.docs.forEach((doc) => {
    weddingTasksData[doc.id] = doc.data();
  });

  // @ts-ignore FIXME
  dispatch(fetchTasksInitialSuccess(countryTasksData));
  dispatch(
    fetchUserChecklistSuccess({
      result: mapCleanTimestamps(weddingTasksData),
      source: 'server',
    }),
  );
};
