import { IRenderer } from 'fela';
import React, { Component, ReactNode } from 'react';
import { RendererProvider } from 'react-fela';
import getFelaRenderer from './getFelaRenderer';

const fallbackRenderer = getFelaRenderer();

export default class FelaProvider extends Component<{
  renderer?: IRenderer;
  children?: ReactNode;
}> {
  render() {
    const renderer = this.props.renderer || fallbackRenderer;
    return (
      <RendererProvider rehydrate={true} renderer={renderer}>
        {this.props.children}
      </RendererProvider>
    );
  }
}
