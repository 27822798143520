import React from 'react';
import { connect } from 'react-redux';
import { A, Button } from '@bridebook/ui';
import BridebookLogo from '@bridebook/ui/src/components/svg/bridebook-logo';
import IconHome from '@bridebook/ui/src/components/svg/icon-home';
import { Link } from 'app-shared/components/link/link';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { goHomeBack, usedHeaderHomeButtonDeprecated } from 'lib/ui/actions';

const storeEnhancer = connect((state: IApplicationState) => ({
  isMobile: state.app.device.isMobile,
  isTablet: state.app.device.isTablet,
  isLoggedIn: getAuthStatus({ user: state.users.user }),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const TopBarHome = ({ isMobile, isTablet, isLoggedIn, dispatch }: IProps) => {
  const isDesktop = !isMobile && !isTablet;
  const showBBLogo = isDesktop || !isLoggedIn;

  const getHomeIcon = () => {
    if (showBBLogo) return <BridebookLogo color="white" />;
    return <IconHome color="white" width={21} />;
  };

  const homeButtonWidth = showBBLogo ? 130 : undefined;
  const homeButtonHeight = showBBLogo ? 22 : undefined;

  const clickHeaderHomeButton = () => {
    dispatch(goHomeBack({ back: false }));
    dispatch(usedHeaderHomeButtonDeprecated('home'));
  };

  const button = (
    <Button
      theme="ghost"
      color="white"
      icon={getHomeIcon()}
      onClick={clickHeaderHomeButton}
      customWidth={homeButtonWidth}
      customHeight={homeButtonHeight}
    />
  );

  return isLoggedIn ? (
    button
  ) : (
    <Link href="/" passHref legacyBehavior>
      <A>{button}</A>
    </Link>
  );
};

export default storeEnhancer(TopBarHome);
