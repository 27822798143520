import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import HeadComponent from 'app-shared/components/head/head';
import getAuthStatus from 'lib/auth/utils/get-auth-status';
import { ConnectedTypes, IApplicationState } from 'lib/types';

const storeEnhancer = connect((state: IApplicationState) => ({
  isMainLiveCDN: state.app.device.isMainLiveCDN,
  isArticle: state.app.pathname.startsWith('/article'),
  pathname: state.app.pathname,
  canonical: state.app.canonicalLink,
  alternate: state.app.alternateLinks,
  seoIndex: state.supplier.seoIndex,
  isLoggedIn: getAuthStatus({ user: state.users.user }),
}));

type HeadComponentProps = Parameters<typeof HeadComponent>[0];

interface IProps
  extends ConnectedTypes<typeof storeEnhancer>,
    Omit<HeadComponentProps, 'alternate' | 'isArticle' | 'seoIndex' | 'isLoggedIn'> {}

const PageHead: FunctionComponent<IProps> = (props) => (
  <HeadComponent {...props}>{props.children}</HeadComponent>
);

export default storeEnhancer(PageHead);
