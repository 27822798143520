import Router from 'next/router';
import { Market } from '@bridebook/toolbox/src/gazetteer';

/**
 * Updates locale for the page based on market prefix and path.
 */
export const updateLocale = async (
  market: Market,
  path: string,
  callbackOnRedirect?: () => void,
) => {
  await Router.push(`/${market.prefix}${path}`, undefined, {
    locale: false,
  });

  typeof callbackOnRedirect === 'function' && callbackOnRedirect();
};
