import React, { PropsWithChildren } from 'react';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import ErrorComponent from 'components/error/error-component';
import { env } from 'lib/env';

interface ComponentErrorLevelBoundaryProps extends PropsWithChildren {
  component?: string;
}

interface ComponentErrorLevelBoundaryState {
  hasError: boolean;
}

// The Crowdin In-Context widget (internal tool) can generate errors because it
// changes the DOM structure outside react
class ComponentErrorLevelBoundary extends React.Component<
  ComponentErrorLevelBoundaryProps,
  ComponentErrorLevelBoundaryState
> {
  constructor(props: ComponentErrorLevelBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (!env.CROWDIN_IN_CONTEXT) {
      SentryMinimal()?.captureException(error, {
        tags: {
          source: 'ComponentErrorLevelBoundary',
          component: this.props.component,
        },
      });
    }
  }

  render() {
    if (this.state.hasError && !env.CROWDIN_IN_CONTEXT) {
      return (
        <ErrorComponent
          statusCode={500}
          component={this.props.component ?? 'ComponentErrorLevelBoundary'}
        />
      );
    }

    return this.props.children;
  }
}

export default ComponentErrorLevelBoundary;
