import React, { FunctionComponent, MouseEventHandler, memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IconRoundButton } from '@bridebook/ui';
import { IconChevronFat } from '@bridebook/ui/src/icons/dynamic';
import { decrementPilingCounter } from 'lib/app/actions';
import { getLastPath, getPreviousPath } from 'lib/app/selectors';
import { toggleMapView } from 'lib/search/actions';
import { getIsMapView } from 'lib/search/selectors';
import { goHomeBack } from 'lib/ui/actions';
import { UrlHelper } from 'lib/url-helper';
import { useSelector } from 'lib/utils';
import { usePathWithoutMarket } from 'lib/utils/url';

interface IAppBarBackProps {
  onClick?: MouseEventHandler<React.ReactNode>;
}

const AppBarBackButton: FunctionComponent<IAppBarBackProps> = ({ onClick }: IAppBarBackProps) => {
  const dispatch = useDispatch();
  const previousPath = useSelector(getPreviousPath);
  const lastPath = useSelector(getLastPath);
  const path = usePathWithoutMarket();
  const isMapView = useSelector(getIsMapView);
  const isMapViewEnabledOnSearch = isMapView && path.includes(UrlHelper.search);

  const onMapViewBack = useCallback(() => {
    dispatch(toggleMapView(false));
  }, [dispatch]);

  const isPreviousPathValid = useMemo(
    () => previousPath !== '' && !`${previousPath} ${lastPath}`.includes(UrlHelper.onboarding),
    [previousPath, lastPath],
  );

  const isBackEnabled = isPreviousPathValid || isMapViewEnabledOnSearch;

  const backTo: MouseEventHandler<React.ReactNode> = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(goHomeBack({ back: isBackEnabled }));
      dispatch(decrementPilingCounter());
    },
    [dispatch, isBackEnabled],
  );

  return (
    <IconRoundButton
      onClick={isMapViewEnabledOnSearch ? onMapViewBack : onClick || backTo}
      size={32}
      backgroundColor="space04"
      border={false}
      icon={<IconChevronFat color="space" style={{ transform: 'rotate(90deg) translateY(1px)' }} />}
    />
  );
};

export default memo(AppBarBackButton);
