import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconChat = ({ id, color, width = 50, style, ...restProps }: IProps) => {
  const viewBoxWidth = 50;
  const viewBoxHeight = 46;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M41.53 37.161l-8.387 8.325a1.77 1.77 0 0 1-1.25.514 1.77 1.77 0 0 1-1.25-.514l-8.388-8.325H4.597C2.062 37.161 0 35.114 0 32.6V4.562C0 2.047 2.062 0 4.597 0h40.806C47.938 0 50 2.047 50 4.562V32.6c0 2.515-2.062 4.562-4.597 4.562h-3.872zm-2.658-2.323a1.767 1.767 0 0 1 1.673-1.186h4.858c.575 0 1.061-.483 1.061-1.053V4.562a1.07 1.07 0 0 0-1.06-1.053H4.596a1.07 1.07 0 0 0-1.061 1.053V32.6c0 .57.485 1.053 1.06 1.053h18.391c.469 0 .919.185 1.25.514l7.656 7.598 6.979-6.926zm-23.506-15.78c0 1.66-1.355 3.005-3.027 3.005-1.673 0-3.028-1.345-3.028-3.005 0-1.659 1.355-3.004 3.028-3.004 1.672 0 3.027 1.345 3.027 3.004zm12.662 0c0 1.66-1.356 3.005-3.028 3.005s-3.028-1.345-3.028-3.005c0-1.659 1.356-3.004 3.028-3.004s3.028 1.345 3.028 3.004zm12.66 0c0 1.66-1.355 3.005-3.027 3.005-1.672 0-3.028-1.345-3.028-3.005 0-1.659 1.356-3.004 3.028-3.004s3.028 1.345 3.028 3.004z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconChat;
