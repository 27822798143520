import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import {
  countriesWithApplyOnboardingFilterPreferencesToSearchResultsTest,
  getVariant_ApplyOnboardingFilterPreferencesToSearchResults,
} from 'lib/ab-tests/tests/global/LIVE-21094_ApplyOnboardingFilterPreferencesToSearchResults';
import { usePageSettings } from 'lib/page-settings-context';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { useSelector } from 'lib/utils';

export const useShowWeddingEstimatePriceFilter = (supplier?: ISupplier) => {
  const LIVE_21094_variant = useSelector(
    getVariant_ApplyOnboardingFilterPreferencesToSearchResults,
  );
  const market = useMarket();
  const searchParams = useSearchParams();
  const { isSearchPage, isSupplierPage } = usePageSettings();
  const supplierCountryCode = supplier?.l10n?.country;

  const searchCountryCode = searchParams?.market.country;
  const weddingCountryCode = market.country;

  const isCoupleCountrySameAsSearchOrSupplier = isSearchPage
    ? weddingCountryCode === searchCountryCode
    : isSupplierPage && !!supplier
    ? weddingCountryCode === supplierCountryCode
    : false;

  const isVenue = isSearchPage
    ? searchParams?.slug === 'venues'
    : isSupplierPage && !!supplier
    ? supplier?.type.includes('venue')
    : false;

  // Test variant available only for venue search and UK
  return (
    LIVE_21094_variant === '3' &&
    isVenue &&
    countriesWithApplyOnboardingFilterPreferencesToSearchResultsTest === weddingCountryCode &&
    isCoupleCountrySameAsSearchOrSupplier
  );
};
