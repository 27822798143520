import { useMemo } from 'react';
import { getPrePromptModalImg } from '@bridebook/toolbox/src/i18n/assets';
import { Box } from '@bridebook/ui';
import { processImage } from '@bridebook/ui/src/components/bbcommon/utils/support';
import { getCountryCodeWithFallback } from 'lib/i18n/selectors';
import { useSelector } from 'lib/utils';
import getComponentStyles from './pre-prompt-image.style';

const IMAGE_HEIGHT = 716;
const IMAGE_WIDTH = 560;

function PrePromptImage() {
  const countryCode = useSelector(getCountryCodeWithFallback);

  const imageUrl = useMemo(
    () =>
      processImage(getPrePromptModalImg(countryCode), { width: IMAGE_WIDTH, height: IMAGE_HEIGHT }),
    [countryCode],
  );

  const styles = getComponentStyles({ imageUrl });

  return <Box style={styles.imageWrapper} />;
}

export default PrePromptImage;
