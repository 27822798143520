import { filter, values } from 'ramda';

// get amount of activated filters in a section
const getActiveFilters = (
  name: string,
  filters: Record<string, any>,
  isMiscSection?: boolean,
): number => {
  let filterState = filters[name];
  if (isMiscSection && Object.hasOwn(filters, 'misc')) {
    filterState = { [name]: filters.misc[name] };
  }

  return filterState ? values(filter((item) => !!item, filterState)).length : 0;
};
export default getActiveFilters;
