import { createSlice } from '@reduxjs/toolkit';
import { ActionWithPayload, QuizState } from 'lib/types';

const initialState: QuizState = {
  modal: { open: false },
  quizCompleted: {},
  interceptSearchParams: null,
};

const name = 'quiz';

const quizSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleQuiz: (state, action: ActionWithPayload<QuizState['modal']>) => {
      state.modal = action.payload;
    },
    setInterceptSearchParams: (
      state,
      action: ActionWithPayload<QuizState['interceptSearchParams']>,
    ) => {
      state.interceptSearchParams = action.payload;
    },
  },
});

export const { toggleQuiz, setInterceptSearchParams } = quizSlice.actions;
export default quizSlice.reducer;
