import { FelaCSS } from '../../../../components/fela/flowtypes';
import { colors } from '../../../../themes/variables';

interface PropTypes {
  disabled?: boolean;
  error?: boolean;
  checked: boolean;
  label?: boolean;
  doNotStyleCheckedLabel?: boolean;
  rounded?: boolean;
  alignCheckboxToTop?: boolean;
  variant: 'primary' | 'teal';
}

interface StyleTypes {
  wrapper: FelaCSS;
  checkboxInput: FelaCSS;
  checkbox: FelaCSS;
  label: FelaCSS;
  iconWrap: FelaCSS;
  circle: FelaCSS;
}

const VARIANT_PALETTE = {
  primary: {
    border: colors.space40,
    background: colors.indigoCrush,
  },
  teal: {
    border: colors.space40,
    background: colors.teal,
  },
};

const styles = ({
  disabled,
  error,
  label,
  doNotStyleCheckedLabel,
  checked,
  rounded,
  alignCheckboxToTop,
  variant,
}: PropTypes): StyleTypes => {
  const cursorStyle: FelaCSS = { cursor: disabled ? 'not-allowed' : 'pointer' };

  const roundedStyles = {
    checkbox: rounded
      ? {
          borderRadius: '50px',
          borderColor: disabled
            ? colors.space15
            : checked
            ? VARIANT_PALETTE[variant].background
            : VARIANT_PALETTE[variant].border,
          backgroundColor: 'transparent',
        }
      : {},
  };

  const inputBoxAlignment: FelaCSS = alignCheckboxToTop
    ? {
        top: '0',
      }
    : {
        top: '50%',
        transform: 'translateY(-50%)',
      };

  return {
    wrapper: {
      paddingLeft: '24px',
    },

    checkboxInput: {
      width: '24px',
      height: '24px',
      marginTop: '0 !important',
      marginBottom: '0',
      marginLeft: '0',
      marginRight: '0',
      position: 'absolute',
      left: '0',
      top: alignCheckboxToTop ? '0' : '50%',
      transform: alignCheckboxToTop ? undefined : 'translateY(-50%)',
      opacity: 0,
      zIndex: 1,
      cursor: disabled ? 'not-allowed' : 'pointer',
      paddingTop: '0',
      paddingRight: '0',
      paddingBottom: '0',
      paddingLeft: '0',
    },

    checkbox: {
      ...inputBoxAlignment,
      boxSizing: 'border-box',
      position: 'absolute',
      left: '0',
      width: '24px',
      height: '24px',
      lineHeight: '22px',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: checked
        ? VARIANT_PALETTE[variant].background
        : error
        ? colors.blushTangerine120
        : VARIANT_PALETTE[variant].border,
      backgroundColor: checked
        ? VARIANT_PALETTE[variant].background
        : disabled
        ? colors.space15
        : colors.white,
      borderRadius: '6px',
      ...cursorStyle,
      ...roundedStyles.checkbox,
    },

    label: {
      display: 'inline-flex',
      justifyContent: 'center',
      paddingLeft: label ? '10px' : '0',
      minHeight: '24px',
      cursor: 'pointer',
      opacity: disabled ? 0.65 : 1,
      fontDefaultSemiBold: 14,
      color: checked && !doNotStyleCheckedLabel ? colors.space : colors.space60,
      marginBottom: 0,
      ...cursorStyle,
    },

    circle: checked
      ? {
          position: 'absolute',
          top: '-2px',
          left: '2px',
          backgroundColor: disabled ? colors.space15 : colors.indigoCrush,
          borderRadius: '50px',
          paddingTop: '2px',
          paddingRight: '2px',
          paddingBottom: '2px',
          paddingLeft: '2px',
          width: '16px',
          lineHeight: '16px',
          height: '16px',
          marginRight: '4px',
          marginTop: '4px',
          marginBottom: '4px',
          boxSizing: 'border-box',
        }
      : {},

    iconWrap: {
      display: checked ? 'flex' : 'none',
      width: '20px',
      height: '20px',
      lineHeight: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '0',
      top: '0',
    },
  };
};

export default styles;
