import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  containerContentWrap: FelaCSS;
}

const styles = (): IStyles => ({
  containerContentWrap: {
    flexGrow: 1,
  },
});

export default styles;
