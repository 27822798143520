import { BEAUTY_QUIZ_TASK_DEFINITION } from 'components/task/today-task/beauty-quiz-task/task-definition';
import { CAKE_QUIZ_TASK_DEFINITION } from 'components/task/today-task/cake-quiz-task/task-definition';
import { FLORISTS_QUIZ_TASK_DEFINITION } from 'components/task/today-task/florists-quiz-task/task-definition';
import { PHOTOGRAPHY_STYLE_QUIZ_TASK_DEFINITION } from 'components/task/today-task/photography-style-quiz-task/task-definition';
import { VIDEOGRAPHY_STYLE_QUIZ_TASK_DEFINITION } from 'components/task/today-task/videography-style-quiz-task/task-definition';
import { TTaskDefinitionUI } from 'lib/task/task-flow-engine';

export type TQuizId =
  | 'photographyStyle'
  | 'cake'
  | 'mockQuiz'
  | 'florists'
  | 'atYourVenue'
  | 'beauty'
  | 'videographyStyle'
  | 'hairAndBeauty';

export type LiveQuizIds = Exclude<TQuizId, 'atYourVenue' | 'mockQuiz' | 'beauty'>;

export const quizTaskDefinitions: Record<LiveQuizIds, Pick<TTaskDefinitionUI, 'id' | 'storage'>> = {
  photographyStyle: PHOTOGRAPHY_STYLE_QUIZ_TASK_DEFINITION,
  florists: FLORISTS_QUIZ_TASK_DEFINITION,
  cake: CAKE_QUIZ_TASK_DEFINITION,
  videographyStyle: VIDEOGRAPHY_STYLE_QUIZ_TASK_DEFINITION,
  hairAndBeauty: BEAUTY_QUIZ_TASK_DEFINITION,
};
export const reverseSearchRoutesByQuizId: Record<LiveQuizIds, string> = {
  florists: '/search/wedding-florists',
  photographyStyle: '/search/wedding-photographers',
  cake: '/search/wedding-cakes',
  videographyStyle: '/search/wedding-videographers',
  hairAndBeauty: '/search/wedding-beauty-hair-makeup',
};

export interface IQuizStep {
  StepComponent: () => JSX.Element;
  index: number;
  onBack?: () => void;
  onNext?: () => void;
  withoutTopBar?: boolean;
  skippable?: boolean;
  showTopIcons?: boolean;
}

export type TQuizStepsRecord<StepId extends string> = Record<StepId, IQuizStep>;

export interface IQuizContext<StepId extends string> {
  quizId: TQuizId;
  currentStep: StepId;
  setCurrentStep: (newStep: StepId) => void;
  steps: TQuizStepsRecord<StepId>;
}
