import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const FLORISTS_QUIZ_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '0f5f346f-c8c9-4d9a-9c65-f128d4ff2990',
  storage: 'wedding',
  name: 'Florists selection quiz',
  type: 'quiz',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;

    return (
      !suppliers?.includes('florist') || isTodayTaskCompleted(state, FLORISTS_QUIZ_TASK_DEFINITION)
    );
  },
};

registerTodayTask(FLORISTS_QUIZ_TASK_DEFINITION);
