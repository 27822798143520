import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconRing = ({ id, color, width = 16, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 14;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M13.926 10.19a3.46 3.46 0 0 1-4.205 2.49 3.467 3.467 0 0 1-1.099-.502 5.56 5.56 0 0 0 2.425-5.974c-.024-.103-.056-.203-.086-.303.158.018.316.044.475.084a3.46 3.46 0 0 1 2.49 4.205M3.3 11.202a4.336 4.336 0 0 1-1.962-2.707 4.32 4.32 0 0 1 .534-3.297A4.352 4.352 0 0 1 4.59 3.244a4.366 4.366 0 0 1 3.31.532c.487.3.9.683 1.23 1.128a4.663 4.663 0 0 0-3.071 3.272 4.636 4.636 0 0 0 .505 3.535l.02.028a4.367 4.367 0 0 1-3.284-.537m6.563-4.718a4.32 4.32 0 0 1-.535 3.296 4.345 4.345 0 0 1-1.59 1.516c-.045-.066-.091-.132-.133-.202a3.432 3.432 0 0 1-.374-2.618A3.457 3.457 0 0 1 9.71 5.99c.059.16.111.324.152.494m1.872-1.67a4.628 4.628 0 0 0-1.307-.141 5.53 5.53 0 0 0-1.447-1.628 1.588 1.588 0 0 0 .895-1.818A1.58 1.58 0 0 0 9.16.238 1.594 1.594 0 0 0 7.95.044a1.59 1.59 0 0 0-1.166 1.993 5.606 5.606 0 0 0-2.474.027A5.559 5.559 0 0 0 .836 4.562a5.52 5.52 0 0 0-.683 4.212 5.538 5.538 0 0 0 2.508 3.46 5.575 5.575 0 0 0 4.23.68c.178-.041.353-.092.524-.15A4.69 4.69 0 0 0 10.586 14a4.64 4.64 0 0 0 2.37-.654 4.634 4.634 0 0 0 2.142-2.857 4.671 4.671 0 0 0-3.363-5.677"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconRing;
