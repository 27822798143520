import { FelaCSS, FelaCSSWithCustomSelectors } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';
import rgbToRgba from '../../../utils/rgb-to-rgba';
import { TSnackbarMode } from './snackbar-content';

interface IStyle {
  container: FelaCSSWithCustomSelectors;
  contentWrap: FelaCSS;
  textWrap: FelaCSS;
  ctaContentWrapper: FelaCSSWithCustomSelectors;
}

const getColorPaletteByMode = (mode: TSnackbarMode) => {
  switch (mode) {
    case 'info':
      return {
        background: colors.indigoCrush10,
        border: colors.indigoCrush120,
        color: colors.space,
      };
    case 'success':
      return {
        background: colors.teal40,
        border: colors.teal120,
        color: colors.space,
      };
    case 'alert':
      return {
        background: colors.pinkJoy,
        border: colors.blushTangerine120,
        color: colors.space,
      };
    default:
      return {
        background: colors.indigoCrush10,
        border: colors.indigoCrush120,
        color: colors.space,
      };
  }
};

const styles = (isShown: boolean, mode: TSnackbarMode, hasCTA: boolean): IStyle => {
  const palette = getColorPaletteByMode(mode);

  return {
    container: {
      position: 'relative',
      overflow: 'hidden',
      padding: 4,
      backgroundColor: palette.background,
      border: `1px solid ${palette.border}`,
      borderRadius: '16px',
      boxShadow: `0px 0px 6px 0px rgba(0, 0, 0, 0.15)`,
      minWidth: '270px',
      maxWidth: '620px',
      marginVertical: 2,

      opacity: 0,
      transform: 'scale(0.85)',
      transition: `opacity 250ms cubic-bezier(0.770, 0.000, 0.175, 1.000), transform 350ms cubic-bezier(0.775, -0.235, 0.200, 1.410), background-color 150ms ease-in`,

      ...(isShown && {
        opacity: 1,
        transform: 'scale(1)',
      }),

      minWidthMdMin: {
        padding: 5,
        minWidth: '340px',
      },

      maxWidthSmMin: {
        width: '100%',
      },

      '@supports (backdrop-filter: blur(5px))': {
        backgroundColor: rgbToRgba(palette.background, 0.75),
        backdropFilter: `blur(5px)`,

        ':hover': {
          backgroundColor: palette.background,
        },
      },

      ':before': {
        content: '""',
        position: 'absolute',
        transform: 'translateX(-100%)',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background:
          'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%)',
      },
    },

    contentWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: hasCTA ? 'space-between' : 'center',
      textAlign: hasCTA ? 'left' : 'center',
      gap: 16,
      fontDefault: 14,
      lineHeight: 1.4,
      color: palette.color,

      minWidthMdMin: {
        gap: 40,
        fontDefault: 16,
      },
    },

    textWrap: {
      flexDirection: 'row',
      gap: 8,
    },
    ctaContentWrapper: {
      position: 'relative',
      zIndex: 10,
      flexShrink: 0,
      maxWidth: '30%',

      '& a': {
        color: colors.indigoCrush,
        textDecoration: 'underline',
      },
    },
  };
};

export default styles;
