import { useEffect } from 'react';
import { OPT_OUT_GROUPS_MAP } from 'app-shared/lib/app/constants';
import { AnalyticsConsentWindow } from 'app-shared/lib/app/types';

// Function to set the MetaRouter cookie based on consent data
const setMetaRouterOptOutCookie = (cmpData: any) => {
  const consentString = Object.values(OPT_OUT_GROUPS_MAP)
    .map((id) => {
      const value = cmpData.purposeLI?.[`c5${id}`] ? 1 : 0;
      const category = `C000${id}`;
      return `${category}:${value}`;
    })
    .join(',');

  document.cookie = `OptOutOnRequest=groups=${encodeURIComponent(consentString)};path=/`;
  const win = window as AnalyticsConsentWindow;
  // Refresh analytics compliance if available
  win.analytics?.refreshCompliance?.();
};

// Custom hook to handle MetaRouter cookie setup
export const useMetaRouterOptOutCookie = () => {
  useEffect(() => {
    const win = window as AnalyticsConsentWindow;
    if (win.__cmp) {
      const handleConsentScreenOff = () => {
        const cmpData = win.__cmp?.('getCMPData');
        if (cmpData) {
          setMetaRouterOptOutCookie(cmpData);
        } else {
          // eslint-disable-next-line no-console
          console.error('CMP Data not available');
        }
      };
      win.__cmp('addEventListener', ['consentscreenoff', handleConsentScreenOff, false], null);

      return () => {
        win.__cmp?.(
          'removeEventListener',
          ['consentscreenoff', handleConsentScreenOff, false],
          null,
        );
      };
    }
  }, []);
};
