import { Auth, User as FirebaseUser, UserCredential } from 'firebase/auth';
import { useTranslation } from 'next-i18next';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IUser } from '@bridebook/models/source/models/Users.types';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { AuthSessionData, LoginOrSignupResult } from 'lib/api/authenticate/types';
import {
  handleChangePassword,
  handleCordovaOauth,
  handleDeleteAccount,
  handleInitialize,
  handleLogin,
  handleLogout,
  handleRedirectAfterAuth,
  handleRegister,
  handleSetupUserAfterLoginOrSignup,
} from 'lib/auth/auth-context';
import { getCollaboratorInvite } from 'lib/auth/selectors';
import { AuthProviders, ICredentialsFields } from 'lib/auth/types';
import { getFirebaseApp } from 'lib/configure-deps';
import { useSelector, useStore } from 'lib/utils';
import { assertExists } from 'lib/utils/assertExists';

export const LOGOUT_DELAY_TIME = 3000;

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: IUser | null;
  wedding: IWedding | null;
};

type TAuthResult = Promise<LoginOrSignupResult | undefined>;

export type TRedirectAfterLoginOrSignup = (authSuccessResult: AuthSessionData) => Promise<void>;

type FirebaseContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: IUser | null;
  wedding: IWedding | null;
  login: (authRequest: TAuthRequest) => TAuthResult;
  register: (authRequest: TAuthRequest) => TAuthResult;
  logout: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  loginOrSignupWithGoogle: (params: Pick<TAuthRequest, 'countryCode' | 'locale'>) => TAuthResult;
  loginOrSignupWithFacebook: (params: Pick<TAuthRequest, 'countryCode' | 'locale'>) => TAuthResult;
  loginOrSignupWithApple: (params: Pick<TAuthRequest, 'countryCode' | 'locale'>) => TAuthResult;
  redirectAfterLoginOrSignup: TRedirectAfterLoginOrSignup;
  changePassword: (
    actionCode: string,
    authFields: ICredentialsFields,
    countryCode: CountryCodes,
    locale: string,
    continueUrl?: string,
  ) => Promise<void>;
  prepareRedirectAfterLoginOrSignup: (result: LoginOrSignupResult) => void;
  triggerRedirectAfterLoginOrSignup: () => Promise<void>;
};

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  wedding: null,
};

const AuthContext = createContext<FirebaseContextType | null>(null);
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};

const firebaseApp = getFirebaseApp();

type AuthProviderProps = {
  children: ReactNode;
};

export type TAuthRequest = {
  countryCode: CountryCodes;
  locale: string;
} & (
  | {
      type: 'password';
      email: string;
      password: string;
      nonceId?: string;
      nonceSecret?: string;
    }
  | { type: 'social'; provider: AuthProviders }
);

export function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = useState<AuthStateType>(initialState);
  const store = useStore();
  const { nonceId, nonceSecret } = useSelector(getCollaboratorInvite);
  const { t } = useTranslation('settings');
  const alreadyInitializedRef = useRef(false);
  const authResultRef = useRef<LoginOrSignupResult | null>(null);

  const handleSetAuthState = (value: AuthStateType) => {
    setAuthState(value);
  };

  useEffect(() => {
    authStateRef.current = authState;
  }, [authState]);

  const authStateRef = useRef<AuthStateType>(initialState);
  const loginOrRegisterInProgress = useRef(false);

  /**
   * Redirects user either to the home page or onboarding based on the fact
   * whether the user just created an account or logged in
   */
  const redirectAfterLoginOrSignup: TRedirectAfterLoginOrSignup = useCallback(
    async (authSessionData: AuthSessionData) => {
      await handleRedirectAfterAuth(store, authSessionData);
    },
    [store],
  );

  /**
   * Clears all data stored in indexDB and terminates the firestore connection
   * after the user logged out
   */
  const logout = useCallback(
    async (logOutAllDevices?: boolean) => await handleLogout(store, logOutAllDevices, firebaseApp),
    [store],
  );

  /**
   * Initializes the user and the services and managers such as sentry or today's task local storage manager.
   * It runs only once per app start (this includes hard page reload as well)
   */
  const initialize = useCallback(
    () =>
      handleInitialize(
        store,
        loginOrRegisterInProgress,
        authStateRef,
        handleSetAuthState,
        firebaseApp,
      ),
    [store],
  );

  /**
   * Handles login and register with auth providers on cordova devices
   */
  const cordovaOauth = useCallback(
    (providerId: AuthProviders, firebaseAuth: Auth): Promise<UserCredential> =>
      handleCordovaOauth(providerId, firebaseAuth),
    [],
  );

  /**
   * Calls an authenticate endpoint that depending on the payload creates / reads / modifies user and wedding data
   * from / in firebase whether the user just created an account / logged in into existing account or joins a wedding as
   * a collaborator with new or existing account.
   * On success, the most recent user and wedding data is saved in store and in indexDB to speed up the "initialize" process
   * the next time the app is started.
   * On fail, no data is modified / created in the database, new account is deleted and in the case of the existing one it is logged out.
   * The notice is also displayed to the user that something went wrong and suggestion on what to do next (contact support or try again)
   */
  const setupUserAfterLoginOrSignup = useCallback(
    async (firebaseUser: FirebaseUser, countryCode: CountryCodes, locale: string) =>
      await handleSetupUserAfterLoginOrSignup(
        store,
        firebaseUser,
        handleSetAuthState,
        logout,
        countryCode,
        locale,
        nonceId,
        nonceSecret,
      ),
    [store, logout, nonceId, nonceSecret],
  );

  /**
   * Logs into an existing firebase-auth account with email and password provider only
   */
  const login: FirebaseContextType['login'] = useCallback(
    async (authRequest) =>
      await handleLogin(store, authRequest, loginOrRegisterInProgress, setupUserAfterLoginOrSignup),
    [store, setupUserAfterLoginOrSignup],
  );

  /**
   * Creates a new firebase-auth account with email and password or social providers
   * or
   * logs into an existing firebase-auth account with social providers
   */
  const register: FirebaseContextType['register'] = useCallback(
    async (authRequest) =>
      await handleRegister(
        store,
        authRequest,
        loginOrRegisterInProgress,
        setupUserAfterLoginOrSignup,
        cordovaOauth,
        firebaseApp,
      ),
    [store, cordovaOauth, setupUserAfterLoginOrSignup],
  );

  const loginOrSignupWithGoogle: FirebaseContextType['loginOrSignupWithGoogle'] = useCallback(
    async (params) => await register({ type: 'social', provider: AuthProviders.GOOGLE, ...params }),
    [register],
  );

  const loginOrSignupWithApple: FirebaseContextType['loginOrSignupWithApple'] = useCallback(
    async (params) => await register({ type: 'social', provider: AuthProviders.APPLE, ...params }),
    [register],
  );

  const loginOrSignupWithFacebook: FirebaseContextType['loginOrSignupWithFacebook'] = useCallback(
    async (params) =>
      await register({ type: 'social', provider: AuthProviders.FACEBOOK, ...params }),
    [register],
  );

  /**
   * Marks user as deleted in the firebase and logs the user out
   */
  const deleteAccount = useCallback(
    async () => await handleDeleteAccount(store, logout, t),
    [store, logout, t],
  );

  /**
   * [coupleside and cms] Changes user password in firebase-auth and on success
   * logs in the coupleside users or redirects to the continueUrl if the url was passed (mainly cms login page url for cms users)
   */
  const changePassword: FirebaseContextType['changePassword'] = useCallback(
    async (actionCode, authFields, countryCode, locale, continueUrl) =>
      await handleChangePassword(
        store,
        actionCode,
        authFields,
        login,
        redirectAfterLoginOrSignup,
        countryCode,
        locale,
        continueUrl,
      ),
    [store, login, redirectAfterLoginOrSignup],
  );

  useEffect(() => {
    //make sure the "initialize" method runs only once
    if (!alreadyInitializedRef.current) {
      alreadyInitializedRef.current = true;
      initialize();
    } else {
      SentryMinimal().captureMessage(
        '[Auth context] The auth context was to be initialized more than once. Please check dependencies in auth-context methods.',
      );
    }
  }, [initialize]);

  const triggerRedirectAfterLoginOrSignup = useCallback(async () => {
    const authResult = authResultRef.current;
    assertExists(authResult);

    if (authResult && authResult.status === 'success') {
      await redirectAfterLoginOrSignup(authResult);
    }

    authResultRef.current = null;
  }, [redirectAfterLoginOrSignup]);

  /*
    prepareRedirectAfterLoginOrSignup and triggerRedirectAfterLoginOrSignup are needed when we want to display something, like an animation, after registration.
    For example, we perform registration, store the result by using prepareRedirectAfterLoginOrSignup, display an animation, and after the registration is complete, we initiate the redirection
  */
  const prepareRedirectAfterLoginOrSignup = useCallback((authResult: LoginOrSignupResult) => {
    authResultRef.current = authResult;
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: authState.isInitialized,
      isAuthenticated: authState.isAuthenticated,
      user: authState.user,
      wedding: authState.wedding,
      login,
      loginOrSignupWithGoogle,
      loginOrSignupWithApple,
      loginOrSignupWithFacebook,
      register,
      logout,
      deleteAccount,
      redirectAfterLoginOrSignup,
      changePassword,
      triggerRedirectAfterLoginOrSignup,
      prepareRedirectAfterLoginOrSignup,
    }),
    [
      authState.isAuthenticated,
      authState.isInitialized,
      authState.user,
      authState.wedding,
      login,
      loginOrSignupWithGoogle,
      loginOrSignupWithApple,
      loginOrSignupWithFacebook,
      register,
      logout,
      deleteAccount,
      redirectAfterLoginOrSignup,
      changePassword,
      triggerRedirectAfterLoginOrSignup,
      prepareRedirectAfterLoginOrSignup,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
