import React, { MouseEventHandler, ReactNode } from 'react';
import Box from '../../../components/fela/Box';
import componentStyles from './radio.style';

export interface IProps {
  option: { label: ReactNode; value: string };
  name: string;
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

const Radio = ({ option, name, checked, onClick }: IProps) => {
  const styles = componentStyles(checked);
  return (
    <Box as="label" style={styles.label} htmlFor={option.value}>
      <input
        type="radio"
        id={option.value}
        name={name}
        value={option.value}
        style={styles.input}
        checked={checked}
        onClick={onClick}
      />
      <Box as="span" style={styles.checkmark} />
      {option.label}
    </Box>
  );
};

export default Radio;
