import { UserActionTypes } from 'lib/users/action-types';

export const markUserAsDeletedStart = () => ({
  type: UserActionTypes.MARK_USER_AS_DELETED_START,
  payload: {},
});

export const markUserAsDeletedSuccess = () => ({
  type: UserActionTypes.MARK_USER_AS_DELETED_SUCCESS,
});

export const markUserAsDeletedError = () => ({
  type: UserActionTypes.MARK_USER_AS_DELETED_ERROR,
});
