import { useTranslation } from 'next-i18next';
import React from 'react';
import { useFela } from 'react-fela';
import { connect } from 'react-redux';
import { Box, Button } from '@bridebook/ui';
import { IconBurger } from '@bridebook/ui/src/icons';
import { IconEdit, IconShortlist } from '@bridebook/ui/src/icons/dynamic';
import { Link } from 'app-shared/components/link/link';
import TopBarHeaderItem from 'components/app/top-bar/top-bar-header-item';
import TopBarInboxIcon from 'components/app/top-bar/top-bar-inbox-icon';
import { NavigateToSearchButton } from 'components/search-landing/navigate-to-search-button';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { toggleNavMenuDrawer } from 'lib/ui/actions';
import { getMenu } from 'lib/ui/menu';
import { UrlHelper } from 'lib/url-helper';
import { isHomePage } from 'lib/utils/url';
import componentStyles from './top-bar-menu-buttons.style';

const storeEnhancer = connect((state: IApplicationState) => ({
  isMobile: state.app.device.isMobile,
  isTablet: state.app.device.isTablet,
  isDesktop: state.app.device.isDesktop,
  isHomePage: isHomePage(state.app.pathname),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const TopBarMenuButtons = ({ isMobile, isTablet, isDesktop, dispatch, isHomePage }: IProps) => {
  const { t } = useTranslation('common');
  const { css } = useFela();

  const thisToggleDrawer = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(toggleNavMenuDrawer(true));
  };

  const styles = componentStyles({ isMobile });

  const menu = React.useMemo(() => getMenu(), []);

  if (isMobile) {
    const backIconWidth = isMobile ? 22 : 14;

    return (
      <Box style={styles.wrapper} data-name="top-bar-menu-buttons">
        {isHomePage && !isDesktop && (
          <Link href="/shortlist" passHref>
            <Button
              theme="ghost"
              noUnderline
              color="white"
              backIcon={<IconShortlist width={backIconWidth} />}
              style={styles.button}
            />
          </Link>
        )}
        {isHomePage && (
          <Link href="/settings" passHref>
            <Button
              theme="ghost"
              noUnderline
              color="white"
              backIcon={<IconEdit width={backIconWidth} />}
              text={isDesktop ? t('topBar.edit') : undefined}
              style={styles.button}
            />
          </Link>
        )}

        <Box style={styles.stretchWrap}></Box>

        <TopBarInboxIcon />

        <Box style={styles.menuButtonWrap}>
          <Button
            name="topbar-burger-menu-button"
            dataTest="topbar-burger-menu-button"
            theme="ghost"
            noUnderline
            color="white"
            backIcon={<IconBurger width={backIconWidth} />}
            text={isDesktop ? t('topBar.weddingPlanner') : undefined}
            onClick={thisToggleDrawer}
            style={styles.button}
          />
        </Box>
      </Box>
    );
  } else {
    const showTopHeaderItems = isDesktop || isTablet;
    const backIconWidth = isMobile ? 22 : 14;

    return (
      <Box style={styles.wrapper} data-name="top-bar-menu-buttons">
        <div className={css(styles.menuButtons)}>
          {isHomePage && !isDesktop && !isTablet && (
            <Link href="/shortlist" passHref>
              <Button
                theme="ghost"
                noUnderline
                color="white"
                backIcon={<IconShortlist width={backIconWidth} />}
                style={styles.button}
              />
            </Link>
          )}
        </div>

        <Box style={styles.stretchWrap}></Box>
        <div className={css(styles.menuButtons)}>
          <TopBarInboxIcon />

          {showTopHeaderItems && (
            <div className={css(styles.iconPadding)}>
              <NavigateToSearchButton />
            </div>
          )}
          {showTopHeaderItems && (
            <>
              <div className={css(styles.iconPadding)}>
                <TopBarHeaderItem
                  href={UrlHelper.shortlist.main}
                  label={menu.shortlist.title}
                  icon={'favourites'}
                  dataName={'top-bar-shortlist-icon'}
                />
              </div>
            </>
          )}
          <Button
            name="topbar-burger-menu-button"
            dataTest="topbar-burger-menu-button"
            theme="ghost"
            noUnderline
            color="white"
            backIcon={<IconBurger width={backIconWidth} />}
            text={isDesktop ? t('topBar.menu') : undefined}
            onClick={thisToggleDrawer}
            style={styles.button}
          />
        </div>
      </Box>
    );
  }
};

export default storeEnhancer(TopBarMenuButtons);
