import { useCallback, useMemo } from 'react';
import {
  ACCOMMODATION_ROOMS_MAX,
  ACCOMMODATION_ROOMS_MIN,
} from '@bridebook/toolbox/src/search-suppliers/elastic-queries/filters/constants';
import { useUpdateFilters } from 'components/search/hooks';
import { getNumberOfBedroomsFilterOptions } from 'lib/search/get-number-of-bedrooms-filter-options';
import getNoOfbedroomsRange from 'lib/search/utils/get-no-of-bedrooms-range';
import { IQuickDropdownRangeHookData } from 'lib/types';
import { useSelector } from 'lib/utils';
import { IGuestOption } from '../get-guest-filter-options';
import { getFilters } from '../selectors';

type IGuestFilter = IQuickDropdownRangeHookData<IGuestOption, number | undefined>;

const useGuestsFilter = (location: string): IGuestFilter => {
  const filters = useSelector(getFilters);

  const optionItems = getNumberOfBedroomsFilterOptions();
  const currentRange = getNoOfbedroomsRange(filters);
  const isFilterActive = currentRange ? currentRange[0] > 0 : false;
  const updateFilters = useUpdateFilters(location);
  const checkedOptions = useMemo<IGuestOption[]>(
    () =>
      isFilterActive
        ? optionItems
            .filter((optionItem) => currentRange && optionItem.minValue <= currentRange[0])
            .slice(-1)
        : [],
    [currentRange, isFilterActive, optionItems],
  );

  const onFilterApply: IGuestFilter['onFilterApply'] = useCallback(
    (minValue, maxValue, skipAnalytics = false) => {
      updateFilters({
        name: 'misc',
        value: {
          accommodationRooms: `${minValue || ACCOMMODATION_ROOMS_MIN}-${
            maxValue || ACCOMMODATION_ROOMS_MAX
          }`,
        },
        skipAnalytics,
      });
    },
    [updateFilters],
  );

  const onFilterClear: IGuestFilter['onFilterClear'] = useCallback(() => {
    updateFilters({
      name: 'misc',
      value: { accommodationRooms: undefined },
    });
  }, [updateFilters]);

  return {
    optionItems,
    isFilterActive,
    checkedOptions,
    onFilterApply,
    onFilterClear,
  };
};

export default useGuestsFilter;
