import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

/**
 * @deprecated
 */
const IconShortlist = ({ id, color, width = 44.3, style, ...restProps }: IProps) => {
  const viewBoxWidth = 44.3;
  const viewBoxHeight = 41.8;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M30.6 0c-3.1 0-6.1 1-8.5 2.9C19.7 1 16.8 0 13.7 0 6.1 0 0 6 0 13.5c0 3.1 1.1 6.1 3.1 8.5.1.1 3 3.4 9 10.1l7.8 8.8c.1 0 .1.1.2.1 1.3 1.1 3.2 1 4.3-.3L41.2 22c2-2.4 3.1-5.4 3.1-8.5C44.3 6 38.1 0 30.6 0zm7.9 19.6L22.2 38l-7.4-8.3c-5.2-5.8-8.8-9.9-8.9-10-1.5-1.8-2.3-4-2.3-6.2 0-5.4 4.5-9.9 10.1-9.9 2.7 0 5.3 1.1 7.2 3l1.3 1.3 1.3-1.3c1.9-1.9 4.5-2.9 7.2-2.9 5.6 0 10.1 4.4 10.1 9.9-.1 2.1-.9 4.3-2.3 6z"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconShortlist;
