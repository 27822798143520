import createCachedSelector from 're-reselect';
import { IMenuTab } from 'lib/bbcommon/types';
import { isGlobalLocationsPage, isSearchResultsOrLanding } from 'lib/search/utils';
import { IApplicationState } from 'lib/types';
import { isToolPage } from 'lib/utils/url';

const serverPage = (state: IApplicationState) => state.app.serverPage;
const pathname = (state: IApplicationState) => state.app.pathname;

export const isActiveTab = createCachedSelector(
  serverPage,
  pathname,
  (_: IApplicationState, tab: Pick<IMenuTab, 'id' | 'url'>) => tab,
  (serverPage, pathname, tab) => {
    const { id, url } = tab;
    const isSearchActive = () => {
      if (id === 'search-landing') {
        return (
          serverPage.includes('search') ||
          isGlobalLocationsPage(pathname) ||
          isSearchResultsOrLanding(pathname)
        );
      }
    };
    const searchActive = isSearchActive();
    // exclude shortlist, otherwise both shortlist and planning are considered to be active whenever shortlist is open
    const toolPageActive =
      id === 'planning' && url && !pathname.startsWith('/shortlist') && isToolPage(pathname);
    return Boolean(toolPageActive || searchActive || (url && pathname.includes(url)));
  },
)((_, tab) => tab.id);

export const getSnackbarState = (state: IApplicationState) => state.bbcommon.snackbarState;
