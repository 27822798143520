interface FontTypes {
  primary: string;
  playfair: string;
  default: string;
  system: string;
}

// @see https://github.com/necolas/normalize.css/issues/665
const systemFonts =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif';

const fonts: FontTypes = {
  default: `"Open Sans", ${systemFonts}`,
  playfair: `"Playfair Display", "Open Sans", ${systemFonts}`,
  /** @deprecated */
  primary: `"Varela Round", ${systemFonts}`,
  /** @deprecated */
  system: systemFonts,
};

/**
 * DO NOT IMPORT DIRECTLY IN THE APPS
 * Use font mixins from FelaCSS instead
 */
export default fonts;
