import { useTranslation } from 'next-i18next';
import React from 'react';
import { dashify } from '@bridebook/toolbox/src';
import { Box } from '@bridebook/ui';
import LinkPipeList from 'components/bbcommon/footer/link-pipe-list/link-pipe-list';
import TrackLink from 'components/bbcommon/footer/track-link/track-link';
import componentStyles from './settings-footer.style';

const SettingsFooter = () => {
  const { t } = useTranslation('common');

  const TRACK_LINK_LIST: { url: string; title: string }[] = [
    { url: `/cookie-policy`, title: t('footer.cookiePolicy') },
    { url: `/privacy-policy`, title: t('footer.privacyPolicy') },
    { url: `/terms`, title: t('footer.terms') },
  ];

  const styles = componentStyles();

  return (
    <Box p={4} style={styles.wrapper} data-name="settings-footer">
      <LinkPipeList
        list={TRACK_LINK_LIST.map((item) => (
          <TrackLink
            url={item.url}
            title={item.title}
            key={`settings-footer-tracked-link-${dashify(item.title)}`}
          />
        ))}
      />
    </Box>
  );
};

export default SettingsFooter;
