import { FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyleTypes {
  wrapper: FelaCSSWithCustomSelectors;
}

const styles = (): IStyleTypes => ({
  wrapper: {
    height: '80vh',
    backgroundColor: colors.white,
    overflow: 'hidden',
    '@media (min-height: 992px)': {
      height: '60vh',
    },
    maxWidthSmMin: {
      height: '100vh',
    },
    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
  },
});

export default styles;
