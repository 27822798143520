import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const BEAUTY_QUIZ_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'ac1373dd-3bb5-4fd5-a643-0301a79ae9d7',
  storage: 'wedding',
  name: 'Beauty selection quiz',
  type: 'quiz',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;

    return (
      !suppliers?.includes('beauty') || isTodayTaskCompleted(state, BEAUTY_QUIZ_TASK_DEFINITION)
    );
  },
};

registerTodayTask(BEAUTY_QUIZ_TASK_DEFINITION);
