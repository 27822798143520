import { getI18n } from 'lib/i18n/getI18n';
import { setLanguageCookie } from 'lib/i18n/utils/set-language-cookie';

export const changeLanguage = async (newLanguage: string) => {
  const i18n = getI18n();
  const oldLanguage = i18n.language;
  setLanguageCookie(newLanguage);
  if (oldLanguage !== newLanguage) {
    await i18n.changeLanguage(newLanguage);
    const validNamespaces = Object.keys(i18n.store.data[oldLanguage] || {});
    await i18n.reloadResources([newLanguage], validNamespaces);
  }
};
