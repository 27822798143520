import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect } from 'react';
import { ButtonV2, H6, Text } from '@bridebook/ui';
import Box, { IProps as IBoxProps } from '@bridebook/ui/src/components/fela/Box';
import { AchievementIcons } from '@bridebook/ui/src/icons';
import ModalNotification from 'components/generic/modalNotification/modal-notification';
import {
  AchievementModalInteractionTypes,
  completeNextAction,
  hideAchievement,
  hideNextAction,
  interactedWithAchievementModal,
  showNextAction,
} from 'lib/ui/actions';
import { selectNextAction } from 'lib/ui/selectors';
import { IBadgeState } from 'lib/ui/types';
import { useDispatch, useSelector } from 'lib/utils';
import componentStyles from './achievement-badge-modal.style';
import { IAchievementData } from './achievements-data';

interface AchievementBadgeActionsProps {
  badgeState: IBadgeState;
}

const AchievementBadgeActions: React.FC<AchievementBadgeActionsProps> = (props) => {
  const { badgeState } = props;

  const nextAction = useSelector((state) =>
    selectNextAction(state, 'venueConfirmation', badgeState.id),
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('achievements');

  const handleOnClick = (
    badgeState: IBadgeState,
    interactionType: AchievementModalInteractionTypes,
  ) => {
    const { id } = badgeState;
    dispatch(hideAchievement({ id }));
    dispatch(interactedWithAchievementModal({ achievement: badgeState, interactionType }));
  };

  const handleDiscoverPhotographers = () => {
    handleOnClick(badgeState, AchievementModalInteractionTypes.DISCOVER_WHATS_NEXT);
    dispatch(showNextAction());
  };

  const handleCountdown = () => {
    const { id } = badgeState;
    dispatch(hideAchievement({ id }));
    dispatch(completeNextAction());
  };

  return (
    <ModalNotification.Buttons>
      {!nextAction ? (
        <ButtonV2
          onClick={() => handleOnClick(badgeState, AchievementModalInteractionTypes.NOTICED)}>
          <Text>{t('coolThanksButton')}</Text>
        </ButtonV2>
      ) : (
        nextAction && (
          <>
            {nextAction.action === 'countdown' && (
              <ButtonV2 onClick={handleCountdown}>
                <Text>{t('beginCountdown')}</Text>
              </ButtonV2>
            )}
            {nextAction.action === 'discoverPhotographers' && (
              <ButtonV2 onClick={handleDiscoverPhotographers}>
                <Text>{t('discoverWhatsNextButton')}</Text>
              </ButtonV2>
            )}
          </>
        )
      )}
    </ModalNotification.Buttons>
  );
};

interface AchievementBadgeModalProps {
  badgeState: IBadgeState;
  achievement: IAchievementData;
  setAnimationRef: IBoxProps['setRef'];
}

export const AchievementBadgeModal = ({
  badgeState,
  achievement,
  setAnimationRef,
}: AchievementBadgeModalProps) => {
  const { t } = useTranslation('achievements');
  const translate = t; // Used to avoid i18next-parser parsing
  const dispatch = useDispatch();

  const styles = componentStyles();

  const onCloseModal = useCallback(() => {
    const { id } = badgeState;
    dispatch(hideAchievement({ id }));
    dispatch(
      interactedWithAchievementModal({
        achievement: badgeState,
        interactionType: AchievementModalInteractionTypes.CLOSED,
      }),
    );
  }, [dispatch, badgeState]);

  return (
    <>
      <ModalNotification
        key={badgeState.id}
        showCloseButton
        onClose={onCloseModal}
        show
        id={`achievement-badge-modal-${badgeState.id}`}>
        <Box style={styles.animation} setRef={setAnimationRef} />
        <ModalNotification.Body>
          <H6 style={styles.heading}>{t('achievementModalHeading')}</H6>
          <Box pt={10}>
            <AchievementIcons icon={badgeState.icon} width={144} height={156} />
          </Box>
          <ModalNotification.Title>
            {translate(`achievements:${achievement.title}`)}
          </ModalNotification.Title>
          <ModalNotification.Description>
            {translate(`achievements:${achievement.descLong}`)}
          </ModalNotification.Description>
        </ModalNotification.Body>
        <AchievementBadgeActions badgeState={badgeState} />
      </ModalNotification>
    </>
  );
};

export const AchievementBadgeVenueBookedModals: React.FC<AchievementBadgeModalProps> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hideNextAction());
  }, [dispatch]);

  return <AchievementBadgeModal {...props} />;
};
