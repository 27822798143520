import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const VIDEOGRAPHY_STYLE_QUIZ_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '54552c36-8909-40ab-9ac0-403e3e0d2750',
  storage: 'wedding',
  name: 'Videography style quiz',
  type: 'quiz',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;

    return (
      !suppliers?.includes('video') ||
      isTodayTaskCompleted(state, VIDEOGRAPHY_STYLE_QUIZ_TASK_DEFINITION)
    );
  },
};

registerTodayTask(VIDEOGRAPHY_STYLE_QUIZ_TASK_DEFINITION);
