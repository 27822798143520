import React from 'react';
import { getIsCustomSupplierFormModalShown } from 'lib/shortlist/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const CustomSupplierFormModal = lazyComponent(() => import('.'));

const CustomSupplierFormModalLazy: React.FC = () => {
  const show = useSelector(getIsCustomSupplierFormModalShown);

  if (!show) {
    return null;
  }

  return <CustomSupplierFormModal />;
};

export default React.memo(CustomSupplierFormModalLazy);
