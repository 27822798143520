import React from 'react';

const checkedTenTasksLocked = (width: number | '100%', height: number | '100%') => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 144 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M135.138 37.631L135.144 37.6348L135.151 37.6386C138.2 39.3193 140 42.4019 140 45.666V110.361C140 113.625 138.2 116.708 135.151 118.389L135.145 118.392L135.139 118.395L76.9866 150.723C76.985 150.723 76.9835 150.724 76.9819 150.725C73.8947 152.425 70.1053 152.425 67.0181 150.725C67.0165 150.724 67.015 150.723 67.0134 150.723L8.86115 118.395L8.85494 118.392L8.84872 118.389C5.8 116.708 4 113.625 4 110.361V45.666C4 42.402 5.8 39.3193 8.84873 37.6386L8.85557 37.6348L8.8624 37.631L67.0134 5.27742C67.0155 5.27628 67.0176 5.27514 67.0196 5.274C70.1061 3.57533 73.8939 3.57533 76.9804 5.274C76.9824 5.27514 76.9845 5.27628 76.9866 5.27742L135.138 37.631Z"
      fill="#EDEFF0"
      stroke="#DDE0E3"
      strokeWidth="8"
    />
    <rect x="60" y="98.4001" width="36" height="4.8" fill="#DDE0E3" />
    <path
      d="M89.0478 96.8813C89.143 96.4624 90.1526 90.7031 90.2478 90.2813C92.625 77.3732 92.1857 64.8882 88.6356 52.2622C88.4587 51.6767 88.1011 51.1648 87.616 50.8024C87.1308 50.44 86.5438 50.2463 85.942 50.2501H55.3884C54.9567 50.252 54.5313 50.3555 54.1454 50.5524C53.7594 50.7494 53.4235 51.0345 53.1638 51.3855C52.9041 51.7365 52.7276 52.1439 52.6482 52.5758C52.5688 53.0077 52.5886 53.4525 52.706 53.8753C59.5772 78.2884 51.6 91.3329 51.6 100.125C51.6011 100.732 51.7292 101.332 51.9759 101.885C52.2226 102.438 52.5822 102.931 53.0307 103.331C53.4792 103.732 54.0064 104.03 54.5771 104.207C55.1478 104.384 55.749 104.435 56.3406 104.357C56.9321 104.279 57.5006 104.073 58.008 103.754C58.5155 103.435 58.9503 103.009 59.2836 102.505C59.6168 102.002 59.8408 101.432 59.9407 100.833C60.0406 100.234 60.014 99.6209 59.8628 99.0336C59.817 98.8559 59.8119 98.67 59.8477 98.49C59.8835 98.31 59.9593 98.1408 60.0693 97.9952C60.1793 97.8497 60.3206 97.7317 60.4823 97.6504C60.6441 97.569 60.822 97.5264 61.0024 97.5259H94.8824C95.0994 97.5261 95.3122 97.587 95.4974 97.7022C95.6827 97.8173 95.8331 97.9821 95.9324 98.1786C96.2599 98.8304 96.4175 99.5569 96.39 100.288C96.3625 101.02 96.1509 101.732 95.7754 102.357C95.3999 102.981 94.8732 103.497 94.2457 103.855C93.6182 104.213 92.9109 104.4 92.1916 104.4H55.7916"
      fill="#F6F7F8"
    />
    <path
      d="M89.0478 96.8813C89.143 96.4624 90.1526 90.7031 90.2478 90.2813C92.625 77.3732 92.1857 64.8882 88.6356 52.2622C88.4587 51.6767 88.1011 51.1648 87.616 50.8024C87.1308 50.44 86.5438 50.2463 85.942 50.2501H55.3884C54.9567 50.252 54.5313 50.3555 54.1454 50.5524C53.7594 50.7494 53.4235 51.0345 53.1638 51.3855C52.9041 51.7365 52.7276 52.1439 52.6482 52.5758C52.5688 53.0077 52.5886 53.4525 52.706 53.8753C59.5772 78.2884 51.6 91.3329 51.6 100.125C51.6011 100.732 51.7292 101.332 51.9759 101.885C52.2226 102.438 52.5822 102.931 53.0307 103.331C53.4792 103.732 54.0064 104.03 54.5771 104.207C55.1478 104.384 55.749 104.435 56.3406 104.357C56.9321 104.279 57.5006 104.073 58.008 103.754C58.5155 103.435 58.9503 103.009 59.2836 102.505C59.6168 102.002 59.8408 101.432 59.9407 100.833C60.0406 100.234 60.014 99.6209 59.8628 99.0336C59.817 98.8559 59.8119 98.67 59.8477 98.49C59.8835 98.31 59.9593 98.1408 60.0693 97.9952C60.1793 97.8497 60.3206 97.7317 60.4823 97.6504C60.6441 97.569 60.822 97.5264 61.0024 97.5259H94.8824C95.0994 97.5261 95.3122 97.587 95.4974 97.7022C95.6827 97.8173 95.8331 97.9821 95.9324 98.1786C96.2599 98.8304 96.4175 99.5569 96.39 100.288C96.3625 101.02 96.1509 101.732 95.7754 102.357C95.3999 102.981 94.8732 103.497 94.2457 103.855C93.6182 104.213 92.9109 104.4 92.1916 104.4H55.7916"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.2578 62.3177L66.3126 66.1196L71.6578 59.4677"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.1566 73.7177L67.2114 77.5196L72.5566 70.8677"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.9437 85.1178L65.9985 88.9197L71.3437 82.2678"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.4574 64.2188H85.6574"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.3562 75.6188H86.5562"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.1445 87.0188H85.3445"
      stroke="#BABFC5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default checkedTenTasksLocked;
