import { getTestVariant } from '@bridebook/toolbox/src/ab-testing/lib/selectors';
import { AbTestingData } from '@bridebook/toolbox/src/ab-testing/lib/types';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useABTestIntegration } from 'lib/ab-tests/hooks/use-ab-test-integration';
import { IApplicationState } from 'lib/types';

const testData: AbTestingData = {
  id: 'LIVE-21330_BrochureRequestFastResponse',
  name: 'LIVE-21330 BrochureRequestFastResponse',
  description:
    'Add checkbox to brochure request modal that requests a response from the supplier within 3 days',
};

export const getVariant_BrochureRequestFastResponse = (state: IApplicationState) =>
  getTestVariant(state, testData.id);

export const useABTest_BrochureRequestFastResponse = () => {
  const {
    flags: { monetized },
  } = useMarket();
  useABTestIntegration({ testId: testData.id, triggerCondition: monetized });
};
