import AddSupplierModalLazy from './add-supplier-modal/add-supplier-modal-lazy';
import CustomSupplierFormModalLazy from './custom-supplier-form-modal/custom-supplier-form-modal-lazy';
import SearchGoogleModalLazy from './search-google-modal/search-google-modal-lazy';

/**
 * This is the lazy loading collection for all the shortlist
 * supplier modals. Each of the underlaying modal is loaded
 * on demand, whenever the modal condition is true.
 *
 * @returns Laziness
 */
const ShortlistModalCollectionLazy = () => (
  <>
    <AddSupplierModalLazy />
    <SearchGoogleModalLazy />
    <CustomSupplierFormModalLazy />
  </>
);

export default ShortlistModalCollectionLazy;
