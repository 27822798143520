import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { IDeps } from 'lib/types';
import { updateUserAction } from 'lib/users/actions';
import { AccessControlActionTypes } from '../action-types';
import { ICollaboratorForm } from '../types';

export const inviteCollaboratorError =
  (error: Error) =>
  ({ dispatch }: IDeps) => {
    dispatch(toggleSnackbar('alert', error.message));

    return {
      type: AccessControlActionTypes.INVITE_COLLABORATOR_ERROR,
      payload: { error },
    };
  };

export const inviteCollaboratorSuccess =
  (payload?: {
    location: 'supplierFeedbackRequest';
    supplier: ISupplier;
    supplierTier: number;
    supplierThumbnailURL: string;
  }) =>
  ({ dispatch }: IDeps) => {
    dispatch(updateUserAction({ hasViewedCollaboratorPopup: true }));

    dispatch({
      payload,
      type: AccessControlActionTypes.INVITE_COLLABORATOR_SUCCESS_ANALYTICS,
    });

    return {
      type: AccessControlActionTypes.INVITE_COLLABORATOR_SUCCESS,
    };
  };

export const acceptedCollaborationInvite = () => ({
  type: AccessControlActionTypes.ACCEPTED_COLLABORATOR_INVITE,
});

export const changeCollaboratorFormField = (name: 'email', value: string) => ({
  type: AccessControlActionTypes.CHANGE_COLLABORATOR_FORM_FIELD as const,
  payload: { name, value },
});

export const resetInviteForm = (payload?: Partial<ICollaboratorForm>) => ({
  payload,
  type: AccessControlActionTypes.RESET_INVITE_FORM as const,
});

export const setInviteForm = (payload: Partial<ICollaboratorForm>) => ({
  payload,
  type: AccessControlActionTypes.SET_INVITE_FORM as const,
});

export const clickedCollaborationAnalytics = () => ({
  type: AccessControlActionTypes.CLICKED_INVITE_COLLABORATOR_ANALYTICS,
});

export const openInviteCollaboratorAnalytics = () => ({
  type: AccessControlActionTypes.OPEN_INVITE_COLLABORATOR_ANALYTICS,
});

export const closeInviteCollaboratorAnalytics = () => ({
  type: AccessControlActionTypes.CLOSE_INVITE_COLLABORATOR_ANALYTICS,
});
