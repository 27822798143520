const rgbToRgba = (rgb: string, alpha: number) => {
  const rgbValues = rgb.match(/\d+/g);
  if (rgbValues && rgbValues.length === 3) {
    const [r, g, b] = rgbValues;
    return `rgba(${r},${g},${b},${alpha})`;
  }
  throw new Error('Invalid RGB color format');
};

export default rgbToRgba;
