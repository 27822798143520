import { createSelector } from 'reselect';
import { ITask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { LiveQuizIds, TQuizId, quizTaskDefinitions } from 'components/quiz/types';
import { TTaskDefinitionUI } from 'lib/task/task-flow-engine';
import { IApplicationState } from 'lib/types';
import { assertState } from 'lib/utils/assertState';
import { createDeepEqualSelector } from 'lib/utils/selectors';

export const selectTaskFlows = (state: IApplicationState) => state.task.taskFlows;
export const selectCurrentTodayTaskId = (state: IApplicationState) => {
  assertState(state.task.taskFlows, 'loaded', 'todayTask');
  return state.task.taskFlows.todayTaskFlow.currentTaskId;
};

export const selectRegisteredTodayTaskTiles = (state: IApplicationState) =>
  state.task.todayTaskTileData.registered;

export const selectCurrentTaskFlowSession = (state: IApplicationState) => {
  assertState(state.task.taskFlows, 'loaded', 'todayTask');
  return state.task.taskFlows.todayTaskFlow.currentSession.id;
};

export const selectCurrentFallbackTask = (state: IApplicationState) => {
  assertState(state.task.taskFlows, 'loaded', 'todayTask');
  assertState(state.task.todayTaskFlowDefinition, 'loaded', 'selectTasksConfig');
  const sessionID = selectCurrentTaskFlowSession(state);
  const fallbackTasksBySession = state.task.todayTaskFlowDefinition.data.fallbackTasksBySession;
  return fallbackTasksBySession ? fallbackTasksBySession[sessionID] : undefined;
};

export const selectNotCompletedTasksInCurrentSession = (state: IApplicationState) => {
  assertState(state.task.taskFlows, 'loaded', 'todayTask');
  return state.task.taskFlows.todayTaskFlow.currentSession.notCompletedTasks;
};

export const selectTodayTasksLoadedStatus = (state: IApplicationState) =>
  state.task.todayTasks.status;

export const selectTodayTaskFlowDefinition = (state: IApplicationState) =>
  state.task.todayTaskFlowDefinition;

export const selectTasksConfig = (state: IApplicationState) => {
  assertState(state.task.todayTaskFlowDefinition, 'loaded', 'selectTasksConfig');
  return state.task.todayTaskFlowDefinition.data.tasks;
};

export const selectTasksConfigById = (taskId: string) =>
  createSelector([selectTasksConfig], (tasksConfig) => tasksConfig[taskId]);

export const isTodayTaskCompleted = (
  state: IApplicationState,
  taskDefinition: Pick<TTaskDefinitionUI, 'id' | 'storage'>,
) => {
  if (taskDefinition.storage === 'none') throw new Error('Invalid storage type');
  if (state.task.todayTasks.status == 'loaded') {
    return state.task.todayTasks[taskDefinition.storage][taskDefinition.id]?.done ?? false;
  } else {
    return false;
  }
};

const getTodayTasks = (state: IApplicationState) => state.task.todayTasks;

const _getStatusOfQuizzes = createSelector([getTodayTasks], (todayTasks) => {
  {
    if (todayTasks.status !== 'loaded') return undefined;

    const quizStatuses: Partial<Record<TQuizId, ITask | undefined>> = {};

    for (const quizId of Object.keys(quizTaskDefinitions)) {
      const taskDefinition = quizTaskDefinitions[quizId as LiveQuizIds];
      if (taskDefinition.storage === 'none') throw new Error('Invalid storage type');
      quizStatuses[quizId as LiveQuizIds] = todayTasks[taskDefinition.storage][
        taskDefinition.id
        // Type assertion necessary as getTodayTasks returns different types of ITask
        // (depending on the storage: 'wedding' | 'user' | 'none').
        // In this case we are using 'wedding' storage for quizzes thus we can assert ITask type for weddings
      ] as ITask | undefined;
    }
    return quizStatuses;
  }
});
export const getStatusOfQuizzes = createDeepEqualSelector(
  _getStatusOfQuizzes,
  (quizzes) => quizzes,
);

export const selectTodayTaskStatus = (
  state: IApplicationState,
  taskDefinition: Pick<TTaskDefinitionUI, 'id' | 'storage'>,
) => {
  assertState(state.task.todayTasks, 'loaded', 'todayTask');
  if (taskDefinition.storage === 'none') throw new Error('Invalid storage type');
  return state.task.todayTasks[taskDefinition.storage][taskDefinition.id];
};

export const selectIsCollaboratorModalOpen = (state: IApplicationState) =>
  state.task.addCollaboratorTodayTask.modal.show;

export const selectCarouselTask = (state: IApplicationState) => ({
  currentTaskIndex: state.task.carousel.currentTaskIndex,
  currentTaskId: state.task.carousel.currentTaskId,
});
export const selectCarouselFadeInOut = (state: IApplicationState) => state.task.carousel.isFadeIn;
