import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const SEARCH_PHOTOGRAPHERS_TASK_DEFINITION: TTaskDefinitionUI = {
  id: '80c6bd5c-98e3-4ef4-a1b5-071e2f8e0b42',
  storage: 'user',
  name: 'Search photographers',
  type: 'interaction',
  isTaskCompleted: ({ market }) => {
    const suppliers = market.suppliers;
    return !suppliers?.includes('photo');
  },
};

registerTodayTask(SEARCH_PHOTOGRAPHERS_TASK_DEFINITION);
