import { Box } from '@bridebook/ui';
import styles from './quiz-step-wrapper.style';

interface IProps {
  children: React.ReactNode;
}

const QuizStepWrapper = ({ children }: IProps) => (
  <Box style={styles.contentWrapper}>{children}</Box>
);

export default QuizStepWrapper;
