import React from 'react';
import { useSelector } from 'react-redux';
import { IEnquiryRequiredDataModal } from 'components/enquiries/enquiry-required-data-modal/index';
import { IApplicationState } from 'lib/types';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const EnquiryRequiredDataModal = lazyComponent<IEnquiryRequiredDataModal>(
  () => import('components/enquiries/enquiry-required-data-modal'),
);

interface EnquiryRequiredDataModalLazyProps {}

const EnquiryRequiredDataModalLazy: React.FC<EnquiryRequiredDataModalLazyProps> = () => {
  const show = useSelector<IApplicationState, boolean>(
    (state) => state.enquiries.showEnquiryRequiredData,
  );
  if (!show) return null;

  return <EnquiryRequiredDataModal showEnquiryRequiredData={show} />;
};

export default React.memo(EnquiryRequiredDataModalLazy);
