import { noop } from 'remeda';
import { GetOptions, Preferences, PreferencesPlugin } from '@capacitor/preferences';
import { isCordovaPluginAvailable } from './cordova-plugin-check';

export function getPreferences(): PreferencesPlugin {
  const preferencesMock: {
    get: (options: unknown) => void;
    set: (options: unknown) => void;
    remove: (options: unknown) => void;
  } = {
    get: () => ({ value: undefined }),
    set: noop,
    remove: noop,
  };

  /** Capactor 3 Clients */
  if (isCordovaPluginAvailable('Storage')) {
    /**
     * We need to do some shady stuff and access the plugin
     * directly, since we don't have the library anymore installed
     * in the latest build - it was renamed to Preferences in
     * Capacitor 4, but old clients will have the plugin loaded
     * as "Storage"
     **/
    return window.Capacitor.Plugins.Storage as PreferencesPlugin;
  }

  /** Capacitor 4 Clients */
  if (isCordovaPluginAvailable('Preferences')) {
    return Preferences;
  }

  return preferencesMock as PreferencesPlugin;
}

export async function getPrefNumber(options: GetOptions): Promise<number> {
  const { value } = await getPreferences().get(options);

  if (!value || value === 'NO') {
    return 0;
  }

  if (value === 'YES' || isNaN(parseInt(value, 10))) {
    return 1;
  }

  return parseInt(value, 10);
}
