import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  menuList: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '0',
    paddingBottom: '50px',
  },

  menuList: {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: alpha(colors.white, 40),
  },
});

export default styles;
