import { useTranslation } from 'next-i18next';
import { Box, Button } from '@bridebook/ui';
import { useResetFilters } from 'components/search/hooks/use-reset-filters';
import { defaultSortValue } from 'lib/search/constants';
import { getFiltersCount, getSortingOption } from 'lib/search/selectors';
import { useSelector } from 'lib/utils';
import componentStyles from './filter-reset.style';

const FilterReset = () => {
  const { t } = useTranslation('search');
  const sortingOption = useSelector(getSortingOption);

  const nonDefaultSorting = sortingOption !== defaultSortValue;
  const filtersCount = useSelector(getFiltersCount);
  const resetFilters = useResetFilters();

  const styles = componentStyles();

  const buttonActive = nonDefaultSorting || filtersCount > 0;

  return (
    <Box>
      <Button
        theme="ghost"
        color="amber"
        text={t('common:button.reset')}
        onClick={resetFilters}
        disabled={!buttonActive}
        style={styles.resetButton}
        noIcon
      />
    </Box>
  );
};

export default FilterReset;
