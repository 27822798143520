import { any, groupBy, map, mapObjIndexed, merge, pipe } from 'ramda';
import uuid from 'uuid-random';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { Guests } from '@bridebook/models/source/models/Weddings/Guests';
import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { capitalizeFirstLetter } from '@bridebook/toolbox/src/utils/strings';
import {
  GuestsGroup,
  GuestsGroupsByGroupId,
} from 'components/guestlist/category-list/category-list';
import {
  DefaultCategories,
  InvitationTypes,
  RsvpStatuses,
  RsvpStatusesUI,
} from 'lib/guestlist/constants';
import { GuestlistFilterType, IGuestFilter } from 'lib/guestlist/types';
import { getI18n } from 'lib/i18n/getI18n';
import { getNextOrder } from 'lib/utils';
import { makePossessive } from 'lib/utils/strings';

const guestDefault = Guests.new('_');

/**
 * Returns Object representing statistics for a given guestlist
 * @method guestDataTotals
 * @param {List} guests List representing all guests in given guestlist
 * @returns {Object} Object of guestlist statistics
 */
export const guestDataTotals = (guests: Array<IGuest>): GuestlistFilterType => {
  const all = guests.length;
  const guestsWithEvents = guests.filter((guest) => guest.events?.wedding);
  const day = guestsWithEvents.filter(
    (guest) => guest.events.wedding.invitation === InvitationTypes.day,
  ).length;
  const evening = guestsWithEvents.filter(
    (guest) => guest.events.wedding.invitation === InvitationTypes.evening,
  ).length;
  const waitList = guestsWithEvents.filter(
    (guest) => guest.events.wedding.invitation === InvitationTypes.waitlist,
  ).length;
  const rsvpAll = guestsWithEvents.filter(
    (guest) =>
      guest.events.wedding.attending === RsvpStatuses[RsvpStatusesUI.yes] ||
      guest.events.wedding.attending === RsvpStatuses[RsvpStatusesUI.no],
  ).length;
  const rsvpYes = guestsWithEvents.filter(
    (guest) => guest.events.wedding.attending === RsvpStatuses[RsvpStatusesUI.yes],
  ).length;
  const rsvpNo = guestsWithEvents.filter(
    (guest) => guest.events.wedding.attending === RsvpStatuses[RsvpStatusesUI.no],
  ).length;
  const rsvpNoReply = guestsWithEvents.filter(
    (guest) => guest.events.wedding.attending === RsvpStatuses[RsvpStatusesUI.noReply],
  ).length;

  return {
    all,
    day,
    evening,
    waitList,
    rsvpAll,
    rsvpYes,
    rsvpNo,
    rsvpNoReply,
  };
};

/**
 * Returns new IGuest based on a default one with some partial data.
 */
export const createGuest = ({
  id,
  name,
  categoryId,
  age,
  gender,
  email,
  phone,
  invitationType,
  order,
}: {
  id?: IGuest['id'];
  name?: IGuest['name'];
  categoryId?: IGuest['category'];
  age?: IGuest['age'];
  gender?: IGuest['gender'];
  email?: Required<IGuest>['contacts']['email'];
  phone?: Required<IGuest>['contacts']['phone'];
  invitationType?: InvitationTypes;
  order?: IGuest['order'];
}): IGuest => {
  const i18n = getI18n();
  const newGuestData: Partial<IGuest> = {
    id: id || uuid(),
    name: name ? capitalizeFirstLetter(name.trim()) : i18n.t('guestlist:unknownGuest'),
    category: categoryId || DefaultCategories.me,
    ...(age && { age }),
    ...(gender && { gender }),
    ...((email || phone) && {
      contacts: {
        ...(email && { email }),
        ...(phone && { phone }),
      },
    }),
    events: {
      wedding: {
        ...guestDefault.events.wedding,
        invitation: invitationType || InvitationTypes.day,
      },
    },
    order: order || getNextOrder(),
  };

  return {
    ...guestDefault,
    ...newGuestData,
  };
};

/**
 * Create a new IGuest object with some data from another provided base guest
 *
 * @param {Object} guest
 * @param {string} name - name of the new guest
 * @returns {Object} new IGuest object
 */
export const cloneGuest = (guest: IGuest, name: string): IGuest => {
  const newGuestData: Partial<IGuest> = {
    id: uuid(),
    name: capitalizeFirstLetter(name.trim()),
    category: guest.category,
    events: { ...guest.events },
    order: getNextOrder(),
  };

  return {
    ...guestDefault,
    ...newGuestData,
  };
};

/**
 * Checks if a given guest has any address details filled in
 * @param {IGuest} guest
 * @returns {Boolean}
 */
export const hasAnyAddressDetails = (guest: IGuest): boolean => {
  const { address } = guest;
  return (
    !!address &&
    (any(Boolean, [address.country, address.city, address.postalCode]) ||
      any(Boolean, address.street || []))
  );
};

/**
 * Checks if a given guest has any contact details filled in
 * @param {IGuest} guest
 * @returns {Boolean}
 */
export const hasAnyContactDetails = (guest: IGuest): boolean => {
  const { contacts } = guest;
  return !!contacts && any(Boolean, Object.values(contacts));
};

/**
 * Returns Object filtered immutable list of guests with filters applied
 * @method filterGuestlist
 * @param {Object} filter object containing all filter values
 * @param {Object} list immutable list of all guests in given guestlist
 * @returns {Object} filtered list
 */
export const filterGuestlist = (filter: IGuestFilter, list: Array<IGuest>): Array<IGuest> => {
  let result: Array<IGuest> = list;

  Object.entries(filter).forEach(([filterName, filterValue]) => {
    switch (filterName as keyof IGuestFilter) {
      case 'invitationType':
        result = filterValue
          ? result.filter((guest: IGuest) => guest.events.wedding.invitation === filterValue)
          : result;
        break;
      case 'rsvpStatus':
        result = filterValue
          ? result.filter((guest: IGuest) => {
              const { attending } = guest.events.wedding;
              return (
                attending === RsvpStatuses[String(filterValue)] &&
                typeof attending === typeof RsvpStatuses[String(filterValue)]
              );
            })
          : result;
        break;
      case 'age':
        result = filterValue ? result.filter((guest: IGuest) => guest.age === filterValue) : result;
        break;
      case 'gender':
        result = filterValue
          ? result.filter((guest: IGuest) => guest.gender === filterValue)
          : result;
        break;
      case 'contacts': {
        switch (filterValue as IGuestFilter['contacts']) {
          case 'address': {
            const condition: boolean = !!filterValue;
            result = condition
              ? result.filter((guest: IGuest) => hasAnyAddressDetails(guest))
              : result;
            break;
          }
          case 'phone': {
            const condition: boolean = !!filterValue;
            result = condition
              ? result.filter((guest: IGuest) => guest.contacts && guest.contacts.phone)
              : result;
            break;
          }
          case 'email': {
            const condition: boolean = !!filterValue;
            result = condition
              ? result.filter((guest: IGuest) => guest.contacts && guest.contacts.email)
              : result;
            break;
          }
        }
        break;
      }
      case 'search':
        result = filterValue
          ? result.filter(
              (guest: IGuest) => guest.name.toLowerCase().indexOf(String(filterValue)) > -1,
            )
          : result;
        break;
    }
  });

  return result;
};

/**
 * Given the current element it returns the next one in a loop
 * (returns the first one if given element is the last)
 * @param current - current value
 * @param arr - array of values to loop through
 */
export const selectNextElementInLoop = <T>(current: T, arr: Array<T>): T => {
  try {
    const index = arr.indexOf(current);
    return index === arr.length - 1 ? arr[0] : arr[index + 1];
  } catch (err) {
    return arr[0];
  }
};

/**
 * Returns a guest who is the head of the given group
 *
 * @param groupId {string}
 * @param guests {Array<IGuest>}
 * @return {IGuest}
 */
export const findHeadForGroup = (groupId: string, guests: Array<IGuest>): IGuest =>
  guests.find((guest) => guest.id === groupId) ||
  merge(guestDefault, {
    name: getI18n().t('guestlist:unknownGuest'),
    id: groupId,
  });

/**
 * Returns an object with guests, where keys are groupIds and values are
 * another objects with guests divided to "head" and "plusOnes"
 *
 * @param allGuests
 * @param filteredGuests
 */
export const groupGuestsByGroupId = (
  allGuests: Array<IGuest>,
  filteredGuests: Array<IGuest>,
): GuestsGroupsByGroupId => {
  /**
   * Group guests by their groupId
   *
   * @returns an object where keys are IDs of groups and values are arrays with
   * guests in the corresponding group
   */
  const groupByGroupId = groupBy((guest: IGuest) => guest.head || guest.id);

  /**
   * For each key (which is groupId), transform the value so that
   * the guests are grouped by their type - either group head or plus one
   *
   * @returns an object where keys are IDs of groups and values are GuestsGroup objects
   * with 2 keys: "head" and "plusOnes"
   */
  const groupByType = map(
    groupBy((guest: IGuest): keyof GuestsGroup => (guest.head ? 'plusOnes' : 'head')),
  );

  /**
   * Make sure each group always has a group head - even if one doesn't match filters
   *
   * @param guestGroup
   * @param groupId
   */
  const addHeadToGroup = (
    guestGroup: { head?: Array<IGuest>; plusOnes?: Array<IGuest> },
    groupId: string,
  ): GuestsGroup => {
    const head = guestGroup.head ? guestGroup.head[0] : findHeadForGroup(groupId, allGuests);
    const plusOnes = guestGroup.plusOnes || [];
    return { head, plusOnes };
  };

  return pipe<IGuest[], any, any, GuestsGroupsByGroupId>(
    groupByGroupId,
    groupByType,
    mapObjIndexed(addHeadToGroup),
  )(filteredGuests);
};

interface IGuestCategory {
  id: string;
  value: string;
}

export const getCategoryName = ({
  category,
  partners,
}: {
  category: IGuestCategory;
  partners?: IWedding['partners'];
}): string => {
  const i18n = getI18n();
  if (category.id === DefaultCategories.me) {
    return partners && partners[0]
      ? i18n.t('guestlist:categoryPartnerGuests', {
          name: partners[0],
          possessive: makePossessive(partners[0]),
        })
      : i18n.t('guestlist:categoryMyGuests');
  }
  if (category.id === DefaultCategories.partner) {
    return partners && partners[1]
      ? i18n.t('guestlist:categoryPartnerGuests', {
          name: partners[1],
          possessive: makePossessive(partners[1]),
        })
      : i18n.t('guestlist:categoryMyPartnerGuests');
  }
  return category.value;
};

/**
 * Returns id of the group to which the guest belongs to.
 * @param guest
 */
export const getGroupIdByGuest = (guest: IGuest) => guest.head || guest.id;

/**
 * Return all guests belonging to a group with a given id
 * (both head and plus ones together)
 *
 * @param groupId
 * @param guests
 */
export const getGuestsByGroupId = (groupId: string, guests: Array<IGuest>) =>
  guests.filter((guest) => (guest.head && guest.head === groupId) || guest.id === groupId);

/**
 * Similar as getGuestsByGroupId but returns only Plus Ones for a given group
 * @param args
 */
export const getPlusOnesByGroupId = (...args: Parameters<typeof getGuestsByGroupId>) =>
  getGuestsByGroupId(...args).filter((g) => !!g.head);

/**
 * Split a string containing guest names separated by '&' sign.
 * Used to create Plus Ones directly with a main guest.
 * @param names
 */
export const splitGuestNames = (names: string) =>
  names
    .split('&')
    // Remove blank spaces at the ends
    .map((name) => name.trim())
    // Remove empty values
    .filter(Boolean);

/**
 * Create new guests from a given list of names.
 * The first name will be group head.
 * @param names
 * @param invitationType
 * @param categoryId
 * @param orderIndex - optional number for ordering
 */
export const getNewGuestGroup = ({
  names,
  invitationType,
  categoryId,
  orderIndex = 0,
}: {
  names: Array<string>;
  invitationType: InvitationTypes;
  categoryId: string;
  orderIndex?: number;
}) => {
  const mainGuestId = uuid();

  const mainGuest: Partial<IGuest> = {
    id: mainGuestId,
    name: names[0],
    category: categoryId,
    events: {
      wedding: {
        ...guestDefault.events.wedding,
        invitation: invitationType,
      },
    },
    order: getNextOrder(orderIndex),
  };

  // Names from the second and up will be main guest's Plus Ones
  const plusOnes = names.slice(1, names.length).map(
    (_name: string, i): Partial<IGuest> => ({
      ...mainGuest,
      id: uuid(),
      name: _name,
      head: mainGuestId,
      order: getNextOrder(i),
    }),
  );

  return [mainGuest, ...plusOnes] as Array<IGuest>;
};
