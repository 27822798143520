import { differenceInMinutes } from 'date-fns';
import sample from 'lodash/sample';
import { ITask as IUserTask } from '@bridebook/models/source/models/Users/Tasks.types';
import { ITask as IWeddingTask } from '@bridebook/models/source/models/Weddings/Tasks.types';
import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { TodayTaskStorage } from 'lib/task/today-task-storage';
import { TTodayTaskStorageKey } from 'lib/task/types';
import { IDeps } from 'lib/types';
import { selectCurrentUser } from 'lib/users/selectors';

export const SESSION_DURATION_IN_MINS = 60 * 8;

export const registeredTodayTasks: Record<TTaskDefinitionUI['id'], TTaskDefinitionUI> = {};

export const registerTodayTask = (todayTask: TTaskDefinitionUI) => {
  registeredTodayTasks[todayTask.id] = todayTask;
};

export interface ITaskFlowContext {
  getState: IDeps['getState'];
  market: Market;
}

export type TTaskDefinitionUI = {
  name: string;
  type: 'interaction' | 'quiz' | 'article' | 'randomArticle' | 'featureCard' | 'generic';
  isTaskCompleted: (taskFlowContext: ITaskFlowContext) => boolean;
  storage: TTodayTaskStorageKey;
  articleSlug?: string;
  markets?: CountryCodes[];
} & (
  | {
      id: IWeddingTask['id'];
      storage: 'wedding';
    }
  | {
      id: IUserTask['id'];
      storage: 'user';
    }
  | {
      id: string;
      storage: 'none';
    }
);

type SessionId = number;

export type TSessionDefinition = {
  id: SessionId;
  tasks: TTaskDefinitionUI[];
  taskSelectionOrder: 'sequence' | 'randomized';
  isSessionCompleted: (params: { sessionStarted?: number; sessionSignaled: boolean }) => boolean;
  fallbackTask?: TTaskDefinitionUI;
};

export type TTaskFlowDefinition = {
  id: string;
  sessions: TSessionDefinition[];
};

export type TTaskFlowInstance = {
  currentSession: {
    id: TSessionDefinition['id'];
    started: number;
    // session is signaled when we got event from a task (so basically when a task is interacted) When we get event, engine checks if we can go to next step - next task or session
    signaled: boolean;
    notCompletedTasks: TTaskDefinitionUI['id'][];
  };
  currentTaskId: TTaskDefinitionUI['id'];
  taskFlowDefinitionId: string;
};

const isSessionCompleted: TSessionDefinition['isSessionCompleted'] = ({ sessionStarted }) => {
  const currentDate = Date.now();
  // todo add check if last activity > 5minutes?
  if (sessionStarted)
    return (
      differenceInMinutes(currentDate, sessionStarted) >= SESSION_DURATION_IN_MINS
      // for the first version we are not using the 'sessionSignaled' value
      // more info here: https://bridebook.atlassian.net/browse/LIVE-18018
      // && sessionSignaled
    );
  else return false;
};

export const createSession = (
  sessionDefinition: Omit<TSessionDefinition, 'isSessionCompleted' | 'taskSelectionOrder'>,
  taskSelectionOrder: TSessionDefinition['taskSelectionOrder'] = 'sequence',
) => ({
  ...sessionDefinition,
  taskSelectionOrder,
  isSessionCompleted,
});

export const getNotCompletedTasksInSession = (
  session: TSessionDefinition,
  context: ITaskFlowContext,
) => {
  const notCompletedTasks = session.tasks.filter((task) => !task.isTaskCompleted(context));
  if (notCompletedTasks.length === 0 && session.fallbackTask) {
    return [session.fallbackTask];
  }

  return notCompletedTasks;
};

export const getNotCompletedTaskIdsInSession = (
  session: TSessionDefinition,
  context: ITaskFlowContext,
) => getNotCompletedTasksInSession(session, context).map((task) => task.id);

export const getCurrentTaskAndIndex = (todayTaskFlowInstance: TTaskFlowInstance) => {
  const currentTaskId = todayTaskFlowInstance.currentTaskId;
  const foundIndex = todayTaskFlowInstance.currentSession.notCompletedTasks.findIndex(
    (notCompletedTask) => notCompletedTask === currentTaskId,
  );
  const currentTaskIndex = foundIndex >= 0 ? foundIndex : 0;

  return {
    currentTaskIndex,
    currentTaskId,
  };
};

export const getNextTaskFlowInstance = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
  taskFlowInstance: TTaskFlowInstance,
  signalSession: boolean,
) => {
  const nextSession = getNextSession(taskFlowDefinition, taskFlowInstance);
  const notCompletedTasks = getNotCompletedTaskIdsInSession(nextSession, context);
  const nextTask = getTaskFromSession(nextSession, context);
  if (!nextTask) throw new Error('Something went wrong');

  const nextWorkFlowInstance: TTaskFlowInstance = {
    ...taskFlowInstance,
    currentSession:
      nextSession.id === taskFlowInstance.currentSession.id
        ? {
            ...taskFlowInstance.currentSession,
            signaled: signalSession ? true : taskFlowInstance.currentSession.signaled,
            notCompletedTasks,
          }
        : {
            id: nextSession.id,
            started: Date.now(),
            signaled: false,
            notCompletedTasks,
          },
    currentTaskId: nextTask.id,
  };

  return nextWorkFlowInstance;
};

export const migrateTaskFlowInstance = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
  taskFlowInstance: TTaskFlowInstance,
) => {
  const nextSession = getNextSession(taskFlowDefinition, taskFlowInstance);
  const notCompletedTasks = getNotCompletedTaskIdsInSession(nextSession, context);

  const newDefinitionIncludesCurrentTaskId = !!taskFlowDefinition.sessions
    .find((session) => session.id === nextSession.id)
    ?.tasks.some((task) => task.id === taskFlowInstance.currentTaskId);

  const nextTask = getTaskFromSession(nextSession, context);
  if (!nextTask) throw new Error('Something went wrong');

  const currentTaskId =
    taskFlowInstance.currentSession.id === nextSession.id
      ? newDefinitionIncludesCurrentTaskId
        ? taskFlowInstance.currentTaskId
        : nextTask?.id
      : taskFlowDefinition.sessions[taskFlowDefinition.sessions.length - 1].tasks[0].id;

  const migratedTaskFlowInstance = {
    currentSession: {
      id: nextSession.id,
      started: Date.now(),
      signaled: false,
      notCompletedTasks,
    },
    currentTaskId,
    taskFlowDefinitionId: taskFlowDefinition.id,
  };

  return migratedTaskFlowInstance;
};

export const getPreviousTaskFlowInstance = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
) => {
  const user = selectCurrentUser(context.getState());
  if (!user) throw new Error('Something went wrong, user not found');
  const taskFlowStorageManager = TodayTaskStorage(taskFlowDefinition, user.id);

  const taskFlowInstance = taskFlowStorageManager.get();
  return taskFlowInstance;
};

const getTaskFromSession = (session: TSessionDefinition, taskFlowContext: ITaskFlowContext) => {
  if (session.taskSelectionOrder === 'randomized') {
    const notCompletedTasks = getNotCompletedTasksInSession(session, taskFlowContext);
    return sample(notCompletedTasks);
  } else {
    const notCompletedTasks = getNotCompletedTasksInSession(session, taskFlowContext);
    return notCompletedTasks && notCompletedTasks.length > 0 ? notCompletedTasks[0] : null;
  }
};

export const getTaskFlowInstance = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
) => {
  const user = selectCurrentUser(context.getState());
  if (!user) throw new Error('Something went wrong, user not found');
  const taskFlowStorageManager = TodayTaskStorage(taskFlowDefinition, user.id);
  let taskFlowInstance = taskFlowStorageManager.get();

  if (taskFlowInstance) {
    const nextTaskFlowInstance = getNextTaskFlowInstance(
      taskFlowDefinition,
      context,
      taskFlowInstance,
      false,
    );
    taskFlowStorageManager.set(nextTaskFlowInstance);

    return nextTaskFlowInstance;
  } else {
    const currentSession = taskFlowDefinition.sessions.find(
      (session) =>
        !session.isSessionCompleted({
          sessionSignaled: false,
        }),
    );
    if (!currentSession) throw new Error('Something went wrong, session not found');

    const notCompletedTasks = getNotCompletedTaskIdsInSession(currentSession, context);

    const currentTask = getTaskFromSession(currentSession, context);
    if (!currentTask) throw new Error('Something went wrong, current task not found');

    taskFlowInstance = {
      currentSession: {
        id: currentSession.id,
        started: Date.now(),
        signaled: false,
        notCompletedTasks,
      },
      currentTaskId: currentTask.id,
      taskFlowDefinitionId: taskFlowDefinition.id,
    };

    taskFlowStorageManager.set(taskFlowInstance);

    return taskFlowInstance;
  }
};

export const saveTaskFlowInstance = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
  taskFlowInstance: TTaskFlowInstance,
) => {
  const user = selectCurrentUser(context.getState());
  if (!user) throw new Error('Something went wrong, user not found');

  const taskFlowStorageManager = TodayTaskStorage(taskFlowDefinition, user.id);
  taskFlowStorageManager.set(taskFlowInstance);
};

export const getCurrentSession = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
) => {
  const taskFlowInstance = getTaskFlowInstance(taskFlowDefinition, context);
  const currentSession = taskFlowDefinition.sessions.find(
    (session) => session.id === taskFlowInstance.currentSession.id,
  );
  if (!currentSession) throw new Error('Something went wrong');
  return currentSession;
};

const getNextSession = (
  taskFlowDefinition: TTaskFlowDefinition,
  taskFlowInstance: TTaskFlowInstance,
) => {
  let currentSession = taskFlowDefinition.sessions.find(
    (session) => session.id === taskFlowInstance.currentSession.id,
  );

  if (!currentSession)
    currentSession = taskFlowDefinition.sessions[taskFlowDefinition.sessions.length - 1];

  if (
    currentSession.isSessionCompleted({
      sessionSignaled: taskFlowInstance.currentSession.signaled,
      sessionStarted: taskFlowInstance.currentSession.started,
    })
  ) {
    const currentSessionIndex = taskFlowDefinition.sessions.findIndex(
      (session) => session.id === taskFlowInstance.currentSession.id,
    );

    const nextSessionIndex =
      taskFlowDefinition.sessions.length <= currentSessionIndex + 1
        ? currentSessionIndex
        : currentSessionIndex + 1;

    return taskFlowDefinition.sessions[nextSessionIndex];
  } else {
    return currentSession;
  }
};

export const signalTaskFlow = (
  taskFlowDefinition: TTaskFlowDefinition,
  context: ITaskFlowContext,
) => {
  const taskFlowInstance = getTaskFlowInstance(taskFlowDefinition, context);
  const user = selectCurrentUser(context.getState());

  if (!user) throw new Error('Something went wrong, user not found');

  const nextTaskFlowInstance = getNextTaskFlowInstance(
    taskFlowDefinition,
    context,
    taskFlowInstance,
    true,
  );

  const taskFlowStorageManager = TodayTaskStorage(taskFlowDefinition, user.id);

  taskFlowStorageManager.set(nextTaskFlowInstance);
  return nextTaskFlowInstance;
};

export const migrateToNewTaskFlowDefinition = (
  taskFlowDefinition: TTaskFlowDefinition,
  previousTaskFlowInstance: TTaskFlowInstance,
  context: ITaskFlowContext,
) => {
  const user = selectCurrentUser(context.getState());

  if (!user) throw new Error('Something went wrong, user not found');

  const nextTaskFlowInstance = migrateTaskFlowInstance(
    taskFlowDefinition,
    context,
    previousTaskFlowInstance,
  );

  const taskFlowStorageManager = TodayTaskStorage(taskFlowDefinition, user.id);

  taskFlowStorageManager.set(nextTaskFlowInstance);
  return nextTaskFlowInstance;
};
