import React, { memo, useCallback } from 'react';
import { useUpdateFilters } from 'components/search/hooks';
import { useWeddingEstimatePriceFilter } from 'lib/search/hooks/wedding-estimate-price-filter/use-wedding-estimate-price-filter';
import { WeddingEstimatePriceFilterContent } from './wedding-estimate-price-filter-content/wedding-estimate-price-filter-content';

const WeddingEstimatePriceFilter = () => {
  const { weddingSeasons, setWeddingSeasons, weddingWeekDays, setWeddingWeekDays } =
    useWeddingEstimatePriceFilter('filtersModal');

  const updateFilters = useUpdateFilters('filtersModal');

  // TODO: we should only update redux store with selected filters when
  // user clicks Apply button
  // (this is a known bug: Ticket https://bridebook.atlassian.net/browse/LIVE-21256).
  // For now redux store is being updated on option click
  // to have this filter consistent with the rest
  const onSeasonOptionClicked = useCallback(
    (option: string) =>
      updateFilters({
        name: 'priceSection',
        value: {
          [option]: weddingSeasons.includes(option) ? false : true,
        },
      }),
    [updateFilters, weddingSeasons],
  );
  const onWeekDayOptionClicked = useCallback(
    (option: string) =>
      updateFilters({
        name: 'priceSection',
        value: {
          [option]: weddingWeekDays.includes(option) ? false : true,
        },
      }),
    [updateFilters, weddingWeekDays],
  );

  return (
    <>
      <WeddingEstimatePriceFilterContent
        weddingSeasons={weddingSeasons}
        weddingWeekDays={weddingWeekDays}
        setWeddingSeasons={setWeddingSeasons}
        setWeddingWeekDays={setWeddingWeekDays}
        onSeasonOptionClicked={onSeasonOptionClicked}
        onWeekDayOptionClicked={onWeekDayOptionClicked}
      />
    </>
  );
};

export default memo(WeddingEstimatePriceFilter);
