import { AuthActionTypes } from 'lib/auth/action-types';

export const redirectAfterAuthSuccess = () => ({
  type: AuthActionTypes.REDIRECT_AFTER_AUTH_DONE,
});

export const saveNextPathAfterOnboarding = (path: string) => ({
  type: AuthActionTypes.SAVE_NEXT_PATH_AFTER_ONBOARDING,
  payload: path,
});
