import { getI18n } from 'lib/i18n/getI18n';
import { getMenu } from 'lib/ui/menu';

/**
 * Get page title to display in mobile top bar
 * @method getTopBarPageTitle
 * @param {string} serverPage
 * @param {string} actualCategory
 * @returns {string} page title
 */

const getTopBarPageTitle = ({
  serverPage,
  actualCategory,
  supplierName,
}: {
  serverPage: string;
  actualCategory: string;
  supplierName?: string;
}): string => {
  const searchLabel =
    actualCategory === 'venue'
      ? getI18n().t('common:menu.venues')
      : getI18n().t('common:menu.suppliers');

  const menu = getMenu();
  const i18n = getI18n();

  switch (serverPage.split('/')[1]) {
    case 'checklist':
      return menu.checklist.title;
    case 'shortlist':
      return menu.shortlist.title;
    case 'scrapbook':
      return menu.scrapbook.title;
    case 'guestlist':
    case 'guest list':
      return menu.guestlist.title;
    case 'budget':
      return menu.budget.title;
    case 'search':
      return searchLabel;
    case 'supplier':
      return supplierName || searchLabel;
    case 'advice':
    case 'article':
      return menu.articles.title;
    case 'settings':
      return menu.settings.title;
    case 'recommendations':
      return i18n.t('common:menu.recommendedVenues');
    case 'inbox':
      return menu.inbox.title;
    case 'special-offers':
      return i18n.t('specialOffers:toolHeader.heading');
    case 'achievements':
      return i18n.t('achievements:pageTitle');
    default:
      return '';
  }
};

export default getTopBarPageTitle;
