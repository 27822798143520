import React from 'react';
import EnquiryConfirmationModal from './enquiry-confirmation-modal/enquiry-confirmation-modal-lazy';
import EnquiryFormModal from './enquiry-form-modal/enquiry-form-modal-lazy';
import EnquiryRequiredDataModal from './enquiry-required-data-modal/enquiry-required-data-modal-lazy';
import MultiEnquiryModal from './multi-enquiry-modal/multi-enquiry-modal-lazy';

const EnquiryModalCollection = () => (
  <>
    <EnquiryFormModal />
    <EnquiryConfirmationModal />
    <MultiEnquiryModal />
    <EnquiryRequiredDataModal />
  </>
);

export default EnquiryModalCollection;
