import React, { memo } from 'react';
import { Box, Button, FelaCSS } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { IconCross } from '@bridebook/ui/src/icons/dynamic';
import Modal from 'components/bbcommon/modal';
import componentStyles from './popup-rounded.style';

interface IProps {
  show: boolean;
  id: string;
  onClose?: () => void;
  children: React.ReactNode;
  modalStyle?: FelaCSS;
  maxWidth?: string | number;
  buttonStyle?: FelaCSS;
}

const PopupRounded = ({
  show,
  onClose,
  id,
  children,
  modalStyle,
  maxWidth,
  buttonStyle = {},
}: IProps) => {
  const styles = componentStyles();

  return (
    <Modal {...{ show, onClose, id, maxWidth }}>
      <Box style={mergeStyles([styles.contentWrap, modalStyle || {}])}>
        {onClose && (
          <Box style={mergeStyles([styles.buttonWrap, buttonStyle])}>
            <Button theme="ghost" color="space" icon={<IconCross width={18} />} onClick={onClose} />
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default memo(PopupRounded);
