import { IRenderer, createRenderer } from 'fela';
import friendlyPseudoClass from 'fela-plugin-friendly-pseudo-class';
import placeholderPrefixer from 'fela-plugin-placeholder-prefixer';
import { fonts } from '../themes/variables';
import customProperty from './customPropertyPlugin';
import { getDefaultStylesGlobal } from './defaults.styles-global';
import namedKeysPlugin from './namedKeysPlugin';
import { webPreset } from './web-preset';

interface IProps {
  extraFonts?: {
    playfair?: boolean;
  };
}
const getFelaRenderer = ({ extraFonts = {} }: IProps = {}) => {
  const plugins = [
    // Important! Order of these plugins matters!
    customProperty, // Process our custom and spacing properties first, so that eg. "padding: 4" can be converted into "padding: 16px"
    ...webPreset, // Add px units to all other properties which need it, such as fontSize or width
    friendlyPseudoClass(),
    placeholderPrefixer(),
    namedKeysPlugin,
  ];

  const renderer: IRenderer = createRenderer({
    devMode: process.env.NODE_ENV !== 'production',
    optimizeCaching: true,
    selectorPrefix: 'bb-',
    plugins,
  });

  // console.log('Fela optimizedPlugins', (renderer as any).optimizedPlugins);

  const FONTS_BASE = 'https://cloud.bridebook.co.uk/assets/fonts';

  renderer.renderFont('Open Sans', [`${FONTS_BASE}/OpenSans-Light.woff2`], {
    fontWeight: 300,
    fontDisplay: 'auto',
  });
  renderer.renderFont('Open Sans', [`${FONTS_BASE}/OpenSans-Regular.woff2`], {
    fontWeight: 400,
    fontDisplay: 'auto',
  });
  renderer.renderFont('Open Sans', [`${FONTS_BASE}/OpenSans-Italic.woff2`], {
    fontWeight: 400,
    fontStyle: 'italic',
    fontDisplay: 'auto',
  });
  renderer.renderFont('Open Sans', [`${FONTS_BASE}/OpenSans-SemiBold.woff2`], {
    fontWeight: 600,
    fontDisplay: 'auto',
  });
  renderer.renderFont('Open Sans', [`${FONTS_BASE}/OpenSans-SemiBold.woff2`], {
    fontWeight: 700,
    fontDisplay: 'auto',
  });

  if (extraFonts.playfair) {
    renderer.renderFont('Playfair Display', [`${FONTS_BASE}/Playfair-ExtraBold.woff2`], {
      fontWeight: 800,
      fontDisplay: 'auto',
    });
  }

  renderer.renderStatic(getDefaultStylesGlobal(fonts.default));

  return renderer;
};

export default getFelaRenderer;
