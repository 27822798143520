import { AuthProviders } from 'lib/auth/types';
import { IDeps } from 'lib/types';
import { AuthActionTypes } from '../action-types';

export const toggleAuthFormStateToSignup =
  (showSignup: boolean) =>
  ({ getState }: IDeps) => {
    const signUp = typeof showSignup !== 'boolean' ? !getState().auth.signup : showSignup;

    return {
      type: AuthActionTypes.TOGGLE_AUTH_FORM_STATE_TO_SIGNUP,
      payload: signUp,
    };
  };

export const toggleResetPasswordModal = (payload: { show: boolean }) => ({
  type: AuthActionTypes.TOGGLE_PASSWORD_RESET,
  payload,
});

export const emailExists = () => ({
  type: AuthActionTypes.SIGNUP_EMAIL_EXISTS,
});

export const emailExistsWithDifferentProvider = (provider?: AuthProviders) => ({
  type: AuthActionTypes.SIGNUP_EMAIL_EXISTS_WITH_DIFFERENT_PROVIDER,
  payload: provider || null,
});

export const toggleAuthModalAnalytics = (toggledModalMethod: string, open: boolean = true) => ({
  type: AuthActionTypes.TOGGLE_AUTH_MODAL_ANALYTICS,
  payload: { toggledModalMethod, open },
});

export const initChangePassword = ({
  resetEmail,
  resetToken,
  continueUrl,
}: {
  resetEmail: string;
  resetToken: string;
  continueUrl?: string;
}) => ({
  type: AuthActionTypes.INIT_CHANGE_PASSWORD,
  payload: {
    passChange: {
      email: resetEmail,
      oldPassword: resetToken,
      continueUrl,
    },
  },
});
