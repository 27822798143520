import {
  IGuest,
  IGuest_Event,
  IGuest_Gender,
} from '@bridebook/models/source/models/Weddings/Guests.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { routingCountryCodes } from '@bridebook/toolbox/src/i18n/constants';

export enum RsvpStatusesUI {
  noReply = 'noReply',
  yes = 'yes',
  no = 'no',
}

export const RsvpStatuses: Record<string, IGuest_Event['attending']> = {
  [RsvpStatusesUI.noReply]: null,
  [RsvpStatusesUI.yes]: true,
  [RsvpStatusesUI.no]: false,
};

export enum InvitationTypes {
  day = 'All Day',
  evening = 'Evening',
  waitlist = 'Waitlist',
}

export enum DefaultCategories {
  me = '00000000-0000-4000-8000-000000000001',
  partner = '00000000-0000-4000-8000-000000000002',
}

export const GuestAge: Record<'adult' | 'baby' | 'child', Required<IGuest>['age']> = {
  adult: 'A',
  baby: 'B',
  child: 'C',
};

export const GuestGender: Record<'female' | 'male' | 'other', NonNullable<IGuest_Gender>> = {
  female: 'F',
  male: 'M',
  other: 'O',
};

/**
 * This is again TS black magic. To get a utility type for dietary options
 * we exclude undefined from the IGuest dietaryOptions property (since it's
 * optional), which leaves us with the string literal array
 * ('hala' | 'vegan' | ...)[] which we then cast to each entry in the array
 * which leaves use with the string literals only...
 */
export type GuestDietaryOptionsType = Exclude<IGuest['dietaryOptions'], undefined>[number];

export const countriesWithInvitationGuestlist = routingCountryCodes.filter(
  (c) => c !== CountryCodes.DE,
);
