import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { GuestlistAnalyticsActionTypes } from 'lib/guestlist/analytics/action-types';
import { IGuestlistCategory } from 'lib/guestlist/types';

export const addedGuestAnalytics = (payload: {
  edit: boolean;
  guest: IGuest;
  method: 'inline' | 'plus1';
}) => ({
  type: 'ADDED_GUEST_ANALYTICS',
  payload,
});

export const addedMultipleGuestsAnalytics = (payload: {
  category: IGuestlistCategory;
  count: number;
  method: 'inline' | 'popup' | 'importContacts';
}) => ({
  type: GuestlistAnalyticsActionTypes.ADDED_MULTIPLE_GUESTS,
  payload,
});

export const clickedDeleteGuestAnalytics = () => ({
  type: 'CLICKED_DELETE_GUEST_ANALYTICS',
});

export const deletedGuestAnalytics = () => ({
  type: 'DELETED_GUEST_ANALYTICS',
});

export const clickedResetGuestlistAnalytics = () => ({
  type: 'CLICKED_RESET_GUESTLIST_ANALYTICS',
});

export const resetGuestlistAnalytics = () => ({
  type: 'RESET_GUESTLIST_ANALYTICS',
});

export const cancelResetGuestlistAnalytics = () => ({
  type: 'CANCELLED_RESET_GUESTLIST_ANALYTICS',
});

export const initialisedGuestlistAnalytics = (
  method: 'bulkAddGuestPopup' | 'importContacts' | 'emptyGuestList',
) => ({
  type: GuestlistAnalyticsActionTypes.INITIALISED_GUESTLIST,
  payload: { method },
});

export const toggleBulkAddGuestsMethodAnalytics = (
  method: 'bulkAddGuestPopup' | 'importContacts',
) => ({
  type: GuestlistAnalyticsActionTypes.TOGGLE_BULK_ADD_METHOD,
  payload: { method },
});

export const clickedAddGuestsAnalytics = (method: 'bulkAddGuestPopup' | 'importContacts') => ({
  type: GuestlistAnalyticsActionTypes.CLICKED_ADD_GUESTS,
  payload: { method },
});

export const copiedGuestCollectorLinkAnalytics = () => ({
  type: GuestlistAnalyticsActionTypes.COPIED_GUEST_COLLECTOR_LINK,
});

export const toggleGuestCollectorPopupAnalytics = (payload: { show: boolean }) => ({
  type: payload.show
    ? GuestlistAnalyticsActionTypes.OPENED_GUEST_COLLECTOR_POPUP
    : GuestlistAnalyticsActionTypes.CLOSED_GUEST_COLLECTOR_POPUP,
  payload: payload,
});

export const clickedToShareGuestCollectorLinkAnalytics = (payload: { url: string }) => ({
  type: GuestlistAnalyticsActionTypes.CLICKED_TO_SHARE_GUEST_COLLECTOR_LINK,
  payload: payload,
});

export const sharedGuestCollectorLinkAnalytics = (payload: {
  url: string;
  appChosen?: string;
}) => ({
  type: GuestlistAnalyticsActionTypes.SHARED_GUEST_COLLECTOR_LINK,
  payload: payload,
});
