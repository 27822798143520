import * as nextI18next from 'next-i18next';

const addUKPluralUpdate = () => {
  const { i18n } = nextI18next;

  if (!i18n) return; // it is null on initial render

  // this function can be called many times without any issue as the object is
  // replaced, not pushed
  // https://github.com/i18next/i18next/blob/5e6780c6da9a97f261b5b25bc890d39bda71c7c0/src/PluralResolver.js#L90
  i18n.services.pluralResolver.addRule('uk', {
    numbers: [1, 2],
    plurals: (n: number) => (Math.abs(n) !== 1 ? 1 : 0),
  });

  i18n.services.pluralResolver.addRule('ie', {
    numbers: [1, 2],
    plurals: (n: number) => (Math.abs(n) !== 1 ? 1 : 0),
  });
};

export default addUKPluralUpdate;
