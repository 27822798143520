import { CSSProperties } from 'react';
import { FelaCSS, FelaCSSWithCustomSelectors, colors, spacing } from '@bridebook/ui';

interface IStyles {
  fakeInput: FelaCSSWithCustomSelectors;
  fakeInputText: CSSProperties;
  buttonStyle: FelaCSS;
}

export type componentSize = 'default' | 'large' | 'small';

interface IProps {
  size: componentSize;
  withIconButtonStyle?: boolean;
}

const styles = ({ size, withIconButtonStyle }: IProps): IStyles => ({
  fakeInput: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%',
    height: 48,
    lineHeight: '20px',
    backgroundColor: 'white',
    borderRadius: '24px',
    color: colors.space75,
    fontDefault: 16,
    cursor: 'pointer',
    border: `1px solid ${colors.space40}`,

    ...(size === 'small' && {
      height: 40,
      fontDefault: 14,
    }),
  },
  fakeInputText: {
    alignItems: 'center',
    display: 'block',
    overflow: 'hidden',
    paddingRight: spacing[12],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.space75,
    width: '100%',
    ...(size === 'large' && {
      paddingRight: '140px',
    }),
  },
  buttonStyle: {
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 2,
    marginLeft: 3,

    ...(withIconButtonStyle && {
      backgroundColor: colors.indigoCrush,
      width: 32,
      height: 32,
      borderRadius: '100%',
      marginRight: 3,
      marginLeft: 2,
    }),
  },
});

export default styles;
