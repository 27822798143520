import React from 'react';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import { FelaCSS } from '../fela/flowtypes';
import addedToFavourites from './achievements/added-to-favourites';
import addedToFavouritesLocked from './achievements/added-to-favourites-locked';
import checkedTenTasks from './achievements/checked-ten-tasks';
import checkedTenTasksLocked from './achievements/checked-ten-tasks-locked';
import collaboratorAdded from './achievements/collaborator-added';
import collaboratorAddedLocked from './achievements/collaborator-added-locked';
import { AchievementIconType } from './achievements/constants';
import firstMessageSent from './achievements/first-message-sent';
import firstMessageSentLocked from './achievements/first-message-sent-locked';
import guestAdded from './achievements/guest-added';
import guestAddedLocked from './achievements/guest-added-locked';
import halfChecklistChecked from './achievements/half-checklist-checked';
import halfChecklistCheckedLocked from './achievements/half-checklist-checked-locked';
import photographerBooked from './achievements/photographer-booked';
import photographerBookedLocked from './achievements/photographer-booked-locked';
import profileImageAdded from './achievements/profile-image-added';
import profileImageAddedLocked from './achievements/profile-image-added-locked';
import venueBooked from './achievements/venue-booked';
import venueBookedLocked from './achievements/venue-booked-locked';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  icon?: AchievementIconType;
  color?: TColor;
  width?: number | '100%';
  style?: FelaCSS;
  locked?: boolean;
  height?: number | '100%';
}

const AchievementIcons = ({
  icon,
  color,
  locked,
  width = 50,
  height = 50,
  style,
  ...restProps
}: IProps) => {
  const viewBoxWidth = 50;
  const viewBoxHeight = 42;

  const getIcon = () => {
    if (icon === AchievementIconType.VenueBooked)
      return locked ? venueBookedLocked(width, height) : venueBooked(width, height);
    if (icon === AchievementIconType.CollaboratorAdded)
      return locked ? collaboratorAddedLocked(width, height) : collaboratorAdded(width, height);
    if (icon === AchievementIconType.FirstMessageSent)
      return locked ? firstMessageSentLocked(width, height) : firstMessageSent(width, height);
    if (icon === AchievementIconType.GuestAdded)
      return locked ? guestAddedLocked(width, height) : guestAdded(width, height);
    if (icon === AchievementIconType.ProfileImageAdded)
      return locked ? profileImageAddedLocked(width, height) : profileImageAdded(width, height);
    if (icon === AchievementIconType.AddedToFavourites)
      return locked ? addedToFavouritesLocked(width, height) : addedToFavourites(width, height);
    if (icon === AchievementIconType.CheckedTenTasks)
      return locked ? checkedTenTasksLocked(width, height) : checkedTenTasks(width, height);
    if (icon === AchievementIconType.PhotographerBooked)
      return locked ? photographerBookedLocked(width, height) : photographerBooked(width, height);
    if (icon === AchievementIconType.HalfChecklistChecked)
      return locked
        ? halfChecklistCheckedLocked(width, height)
        : halfChecklistChecked(width, height);
    return null;
  };

  return (
    <IconWrap
      icon={getIcon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default AchievementIcons;
