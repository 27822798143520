import { insertAll, pathOr } from 'ramda';
import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { useAuthContext } from 'components/auth/auth-context';
import DrawerMenu from 'components/bbcommon/drawer-menu/drawer-menu';
import { NavigationDestination } from 'lib/analyticsTypes';
import { navigateToVenuesAndSuppliers } from 'lib/search-landing/actions';
import { ConnectedTypes, IApplicationState, IOnClick, MenuOptionTypes } from 'lib/types';
import {
  coupleClickedInboxMenu,
  toggleNavMenuDrawer,
  usedMenuNavigationDeprecated,
} from 'lib/ui/actions';
import { getMenu } from 'lib/ui/menu';
import { menuFilter } from 'lib/utils';
import { getInboxUnreadCount } from 'lib/weddings/selectors';

type IOnClickEvent = Parameters<NonNullable<IOnClick['onClick']>>[0];

const storeEnhancer = connect((state: IApplicationState) => ({
  user: state.users.user,
  profile: state.weddings.profile,
  inboxUnreadCount: getInboxUnreadCount(state),
  achievements: state.ui.achievements,
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  alwaysMounted?: boolean;
}

const DrawerMenuWrapper = ({
  user,
  dispatch,
  alwaysMounted,
  inboxUnreadCount,
  achievements,
}: IProps) => {
  const market = useMarket();
  const hasFullDirectory = market.hasFullDirectory;
  const hasAnyDirectory = market.hasAnyDirectory;
  const hasVenueDirectory = market.hasVenueDirectory;
  const { logout } = useAuthContext();
  const pageLogout = (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(usedMenuNavigationDeprecated('logout'));
    logout();
  };

  const callSupplierSearch = (e: IOnClickEvent) => {
    e?.preventDefault();
    dispatch(toggleNavMenuDrawer());
    dispatch(navigateToVenuesAndSuppliers());
  };

  const callVenueSearch: IOnClick['onClick'] = (e) => {
    callSupplierSearch(e);
  };

  const onMessagesClick = (e: IOnClickEvent) => {
    e?.preventDefault();

    dispatch(coupleClickedInboxMenu());
  };

  const menuList = getMenu();

  const searchToggles: MenuOptionTypes[] = [
    ...(hasVenueDirectory
      ? [
          {
            title: menuList.searchVenues.title,
            url: menuList.searchVenues.url,
            icon: menuList.searchVenues.icon,
            color: menuList.searchVenues.color,
            onClick: callVenueSearch as IOnClick['onClick'],
          },
        ]
      : []),
    ...(hasFullDirectory
      ? [
          {
            title: menuList.searchSuppliers.title,
            id: menuList.searchSuppliers.id,
            url: menuList.searchSuppliers.url,
            icon: menuList.searchSuppliers.icon,
            color: menuList.searchSuppliers.color,
            onClick: callSupplierSearch as IOnClick['onClick'],
          },
        ]
      : []),

    {
      title: menuList.inbox.title,
      url: menuList.inbox.url,
      icon: menuList.inbox.icon,
      color: menuList.inbox.color,
      counter: inboxUnreadCount,
      onClick: onMessagesClick as IOnClick['onClick'],
    },
  ];

  const upperTabs = menuFilter(
    [
      'home',
      'tool',
      'tool-subdivision',
      'articles',
      'scrapbook',
      ...(Object.keys(achievements).length > 0 ? ['achievements'] : []),
    ],
    hasAnyDirectory,
  );

  const navigate = (e: IOnClickEvent) => {
    const goto = e?.currentTarget.getAttribute('data-tab-name') as NavigationDestination;
    dispatch(usedMenuNavigationDeprecated(goto));
  };

  const updatedMenu: MenuOptionTypes[] = upperTabs.map((item) => {
    const optionUpdate = { ...item };

    let updatedItem = false;

    if (item.id === 'booked-suppliers' && !(Object.keys(achievements).length > 0)) {
      optionUpdate.newOption = user ? !pathOr(false, ['actions', 'hasVisitedBooked'])(user) : false;
      updatedItem = true;
    }
    if (item.id === 'shortlist') {
      optionUpdate.onClick = (e: IOnClickEvent) => {
        navigate(e);
      };
      updatedItem = true;
    }

    if (item.id === 'booked-suppliers') {
      optionUpdate.onClick = (e: IOnClickEvent) => {
        navigate(e);
      };
      updatedItem = true;
    }

    if (!updatedItem) {
      optionUpdate.onClick = navigate;

      return optionUpdate;
    }

    return optionUpdate;
  });

  const upperTabsFull = insertAll(1, searchToggles, updatedMenu);

  const bottomMenuItems = {
    settings: {
      title: menuList.settings.title,
      url: menuList.settings.url,
      icon: menuList.settings.icon,
      color: menuList.settings.color,
      onClick: navigate,
    },
    logOut: {
      title: menuList.logOut.title,
      url: menuList.logOut.url,
      icon: menuList.logOut.icon,
      color: menuList.logOut.color,
      onClick: pageLogout as IOnClick['onClick'],
    },
  };

  const bottomTabs = [bottomMenuItems.settings, bottomMenuItems.logOut];

  const menu = [upperTabsFull, bottomTabs];

  return <DrawerMenu alwaysMounted={alwaysMounted} menu={menu} />;
};

export default storeEnhancer(DrawerMenuWrapper);
