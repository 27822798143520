import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * How the hook works:
 * 1. Initially adds a callback to a window `resize` event listener and sets
 *    `isResizing` value to false and empty timeout reference.
 * 2. When `resize` event is triggered (when user starts to change window size),
 *    sets `isResizing` value to `true`, clears timeout and sets timeout to call callback
 *     function after 100ms (by default).
 * 3. If `resize` event has not been triggered within 100ms (which means that user potentially
 *    has stopped resizing window), callas callback function and sets `isResizing` value to
 *    false. If it has been triggered within
 *    100ms - goto point number 2.
 */
export const useOnWindowResizeStop = (callback: () => unknown, interval: number = 100) => {
  const [isResizing, setIsResizing] = useState(false);
  const timeout = useRef<any>();
  const resizeCb = useCallback(() => {
    setIsResizing(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setIsResizing(false);
      callback();
    }, interval);
  }, [callback, interval]);

  useEffect(() => {
    window.addEventListener('resize', resizeCb);
    return () => {
      removeEventListener('resize', resizeCb);
    };
  }, [resizeCb]);

  return isResizing;
};
