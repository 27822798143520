import React, { ChangeEvent, ChangeEventHandler, ReactNode, useState } from 'react';
import { ValidationError } from '@bridebook/toolbox/src';
import { IStylingProps } from '@bridebook/ui/src/themes/types';
import { TSetRef } from '@bridebook/ui/src/types';
import { useInputErrorState, useInputValueListener } from '@bridebook/ui/src/utils/hooks';
import CheckboxShell from '../checkbox-shell/checkbox-shell';

export interface IProps extends IStylingProps {
  label?: string | ReactNode;
  id?: string;
  error?: boolean | ValidationError;
  name?: string;
  value?: boolean;
  disabled?: boolean;
  setRef?: TSetRef;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  rounded?: boolean;
  alignCheckboxToTop?: boolean;
  controlled?: boolean;
  variant?: 'primary' | 'teal';
  dataTest?: string;
}

const Checkbox = ({
  label,
  id,
  name,
  disabled,
  setRef,
  rounded,
  alignCheckboxToTop,
  value,
  error,
  controlled,
  onChange,
  variant = 'primary',
  ...props
}: IProps) => {
  const [stateValue, setStateValue] = useState(value || false);
  const { hasError } = useInputErrorState(error, '', name);
  useInputValueListener(value, setStateValue);

  const _onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    !controlled && setStateValue(event.target.checked);
    if (onChange) onChange(event);
  };

  return (
    <CheckboxShell
      {...props}
      variant={variant}
      id={id}
      disabled={disabled}
      name={name || id}
      onChange={_onChange}
      checked={stateValue}
      setRef={setRef}
      rounded={rounded}
      label={label}
      alignCheckboxToTop={alignCheckboxToTop}
      error={hasError}
      value={value}
    />
  );
};

export default Checkbox;
