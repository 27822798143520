import { GoogleSignInResult, cfaSignIn } from 'capacitor-firebase-auth/alternative';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { AuthProviders } from 'lib/auth/types';
import { MOBILE_APP_ID } from 'lib/mobile-app/constants';
import { UrlHelper } from 'lib/url-helper';

const nativeLogin = async ({
  providerId,
  successCallback,
  errorCallback,
}: {
  providerId: string;
  successCallback: Function;
  errorCallback: Function;
}) => {
  const errorCallbackWithLogging = (error: any) => {
    SentryMinimal().captureException(error, {
      tags: {
        source: 'nativeLogin',
      },
    });
    errorCallback(error);
  };

  if (providerId === AuthProviders.GOOGLE || providerId === AuthProviders.FACEBOOK) {
    try {
      const { result } = await cfaSignIn(providerId).toPromise();
      successCallback({ token: (result as GoogleSignInResult).idToken });
    } catch (error) {
      errorCallbackWithLogging(error);
    }
  } else if (providerId === AuthProviders.APPLE) {
    try {
      const result = await SignInWithApple.authorize({
        clientId: MOBILE_APP_ID,
        redirectURI: UrlHelper.index,
        scopes: 'email, name',
      });
      if (!result?.response?.identityToken) {
        throw new Error('Apple sign in authorization failed. Please contact support');
      }
      successCallback({ token: result.response.identityToken });
    } catch (error) {
      errorCallbackWithLogging(error);
    }
  }
};

export default nativeLogin;
