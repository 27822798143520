import React, { ReactNode } from 'react';
import { Box, FelaCSS, Pill } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import componentStyles from './filter-pill.style';

interface IProps {
  children?: ReactNode;
  colorGradient?: boolean;
  filterSelected?: boolean;
  onClick: () => void;
  style?: FelaCSS;
  height?: number;
}

const FilterPill = ({
  children,
  colorGradient,
  filterSelected,
  onClick,
  style = {},
  height = 35,
}: IProps) => {
  const styles = componentStyles({ filterSelected, colorGradient });

  return (
    <Box onClick={onClick} style={mergeStyles([styles.wrapper, style])}>
      <Pill bordered sidePaddings={12} height={height} style={styles.pill}>
        {children}
      </Pill>
    </Box>
  );
};

export default FilterPill;
