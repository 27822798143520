import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useUpdateFilters } from 'components/search/hooks';
import { getFilters } from 'lib/search/selectors';
import {
  priceFilterSeasonsArray,
  priceFilterWeekDaysArray,
} from 'lib/search/utils/wedding-estimate-price-filter-helpers';
import { useSelector } from 'lib/utils';

export const useWeddingEstimatePriceFilter = (location: string) => {
  const { t } = useTranslation('search');

  const filters = useSelector(getFilters);
  const priceFilter = filters?.priceSection;

  const weddingWeekDaysInitialValue = useMemo(
    () => priceFilterWeekDaysArray.filter((weekDay) => priceFilter?.[weekDay]),
    [priceFilter],
  );
  const weddingSeasonsInitialValue = useMemo(
    () => priceFilterSeasonsArray.filter((season) => priceFilter?.[season]),
    [priceFilter],
  );

  const weddingWeekDayDefaultValues = useMemo(
    () =>
      priceFilterWeekDaysArray.reduce(
        (acc, item) => ({
          ...acc,
          [item]: false,
        }),
        {},
      ),
    [],
  );
  const weddingSeasonDefaultValues = useMemo(
    () =>
      priceFilterSeasonsArray.reduce(
        (acc, item) => ({
          ...acc,
          [item]: false,
        }),
        {},
      ),
    [],
  );

  const [weddingSeasons, setWeddingSeasons] = useState<string[]>(weddingSeasonsInitialValue || []);
  const [weddingWeekDays, setWeddingWeekDays] = useState<string[]>(
    weddingWeekDaysInitialValue || [],
  );

  const updateFilters = useUpdateFilters(location);

  const onFilterApply = useCallback(
    (skipAnalytics?: boolean) => {
      updateFilters({
        name: 'priceSection',
        value: {
          ...weddingSeasonDefaultValues,
          ...weddingSeasons.reduce(
            (acc, season) => ({
              [season]: true,
              ...acc,
            }),
            {},
          ),
          ...weddingWeekDayDefaultValues,
          ...weddingWeekDays.reduce(
            (acc, weekDay) => ({
              [weekDay]: true,
              ...acc,
            }),
            {},
          ),
        },
        skipAnalytics,
      });
    },
    [
      updateFilters,
      weddingSeasons,
      weddingWeekDays,
      weddingWeekDayDefaultValues,
      weddingSeasonDefaultValues,
    ],
  );

  const onFilterClear = useCallback(
    (skipAnalytics?: boolean) => {
      updateFilters({
        name: 'priceSection',
        value: { ...weddingWeekDayDefaultValues, ...weddingSeasonDefaultValues },
        skipAnalytics,
      });
    },
    [updateFilters, weddingWeekDayDefaultValues, weddingSeasonDefaultValues],
  );

  return {
    weddingSeasonsInitialValue,
    weddingWeekDaysInitialValue,
    setWeddingSeasons,
    setWeddingWeekDays,
    weddingSeasons,
    weddingWeekDays,
    onFilterApply,
    onFilterClear,
    label: t('filters.quickPricingFilter.label'),
  };
};
