import {
  getVariant_BrowsePresetsInSearchPage,
  useShouldTriggerBrowsePresetsTest,
} from 'lib/ab-tests/tests/LIVE-20805_BrowsePresetsInSearchPage';
import { useSelector } from 'lib/utils';

export const useShowPresets = () => {
  const testVariant = useSelector(getVariant_BrowsePresetsInSearchPage);
  const shouldShowBrowsePresets = useShouldTriggerBrowsePresetsTest();

  return shouldShowBrowsePresets && testVariant === '1';
};
