import { useTranslation } from 'next-i18next';
import React, { MouseEventHandler, ReactNode } from 'react';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import TopBarHeaderItem from 'components/app/top-bar/top-bar-header-item';
import { navigateToVenuesAndSuppliers } from 'lib/search-landing/actions';
import { useDispatch, useSelector } from 'lib/utils';
import { getVenueBooked } from 'lib/weddings/selectors';

interface NavigateToSearchButtonProps {}

export const NavigateToSearchButton: React.FC<NavigateToSearchButtonProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const market = useMarket();
  const hasFullDirectory = market.hasFullDirectory;
  const isVenueBooked = useSelector(getVenueBooked);

  const searchToggle: MouseEventHandler<Element | ReactNode> = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(navigateToVenuesAndSuppliers());
    },
    [dispatch],
  );

  const searchButtonText = React.useMemo(() => {
    if (hasFullDirectory) {
      return isVenueBooked ? t('common:menu.searchSuppliers') : t('common:menu.searchVenues');
    } else {
      return t('common:menu.searchVenues');
    }
  }, [hasFullDirectory, isVenueBooked, t]);

  return (
    <TopBarHeaderItem
      onClick={searchToggle}
      label={searchButtonText}
      icon={'search'}
      dataName={'topbar-search-toggle-button'}
    />
  );
};
