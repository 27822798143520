import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { selectMarketWithFallback } from 'lib/i18n/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const ARTICLE_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'ea6bbb4c-e5cd-454d-89fe-1a0179b4a759',
  name: 'Article task',
  type: 'article',
  storage: 'none',
  isTaskCompleted: ({ getState }) => {
    const state = getState();
    const market = selectMarketWithFallback(state);
    const isUK = market.country === CountryCodes.GB;
    const isUserLocaleUK = market.locale === 'en-GB';
    return !isUK || !isUserLocaleUK;
  },
};

export const taskIdByArticleSlug = {
  'the-best-accommodation-for-your-guests-bridebook-x-hotelplanner':
    '3074da94-3e29-40d8-8b63-503f7994f09d',
  'meet-the-wedding-venues-championing-lgbtq-inclusivity': 'fd68efdf-4b30-40dc-bbd0-98ce51aa46a4',
  '11-tips-for-planning-a-queer-wedding-from-a-queer-engaged-wedding-photographer':
    'f494aea7-6fdb-48e8-86e5-8d4149f8d6fb',
};

export const articleSlugByTaskId = Object.fromEntries(
  Object.entries(taskIdByArticleSlug).map(([key, value]) => [
    value,
    key as keyof typeof taskIdByArticleSlug,
  ]),
); // flipping keys and values of taskIdByArticleSlug

export const SPECIFIC_ARTICLE_TASK_DEFINITION = (
  articleSlug: keyof typeof taskIdByArticleSlug,
): TTaskDefinitionUI => ({
  ...ARTICLE_TASK_DEFINITION,
  id: taskIdByArticleSlug[articleSlug],
});

registerTodayTask(ARTICLE_TASK_DEFINITION);

Object.entries(articleSlugByTaskId).forEach(([key]) => {
  registerTodayTask({
    ...ARTICLE_TASK_DEFINITION,
    id: key,
  });
});
