import dynamic from 'next/dynamic';
import React from 'react';
import { useFela } from 'react-fela';
import { Box } from '@bridebook/ui';
import FilterMobile from 'components/search/filters/filter-mobile/filter-mobile';
import { ToggleViewButton } from 'components/search/toggle-view-button/toggle-view-button';
import { getHasNewMap30 } from 'lib/ab-tests/tests/LIVE-20786_Map30';
import { useShowPresets } from 'lib/search/hooks/use-show-presets';
import { areFiltersShown, getFiltersLoaded, getIsMapView } from 'lib/search/selectors';
import { IUrl } from 'lib/types';
import { useSelector } from 'lib/utils';
import componentStyles from './mobile-search-controls.style';

interface IProps {
  location: IUrl;
}

const DynamicQuickFilterContainer = dynamic(
  () => import('components/search/filters/quick-filters/quick-filters-container'),
);

const DynamicSearchPresets = dynamic(
  () => import('components/search/search-presets/search-presets'),
);

const MobileSearchControls = ({ location }: IProps) => {
  const { css } = useFela();

  const filtersShown = useSelector(areFiltersShown);
  const isMapView = useSelector(getIsMapView);
  const filtersLoaded = useSelector(getFiltersLoaded);
  const hasNewMap = useSelector(getHasNewMap30);

  const showPresets = useShowPresets();

  const styles = componentStyles({
    isMapView,
  });

  return (
    <Box style={styles.wrapper}>
      <Box flexDirection="row">
        {hasNewMap && (
          <>
            <ToggleViewButton />
            <Box style={styles.verticalDivider} />
          </>
        )}
        {showPresets ? (
          <DynamicSearchPresets />
        ) : (
          <>
            {filtersLoaded && (
              <div className={css(styles.filterWrap(hasNewMap))}>
                <DynamicQuickFilterContainer {...{ location }} />
              </div>
            )}
          </>
        )}
        <FilterMobile location={location} show={filtersShown} />
      </Box>
    </Box>
  );
};

export default MobileSearchControls;
