import { getIsCordova } from 'lib/mobile-app/selectors';
import { useSelector } from 'lib/utils';

export const withFeedback =
  <P,>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const isCordova = useSelector(getIsCordova);

    return <WrappedComponent {...props} withFeedback={isCordova} />;
  };

export type WithFeedback<T> = T & { withFeedback?: boolean };
