import getAuthStatus from 'lib/auth/utils/get-auth-status';
import {
  isCollaboratorOnboardingPage,
  isOnboardingPage,
  isSearchResultsPage,
} from 'lib/search/utils';
import { DeviceState, IUserSerialized } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

interface IProps {
  pathname: string;
  device: DeviceState;
  user?: IUserSerialized | null;
  showReviewsPage: boolean;
}

export const showFooter = ({
  pathname,
  device: { isMobile, isTablet, isCordova, isLandscape },
  user,
  showReviewsPage,
}: IProps): boolean => {
  const isRecommendations = isMobile && pathname.includes('recommendations');
  const isGuestListMobile = isMobile && pathname.includes('guestlist');
  const isChecklistMobile = isMobile && pathname.includes('checklist');
  const isShortlistMobile = isMobile && pathname.includes('shortlist');
  const isAdviceMobile = isMobile && pathname.includes('advice');
  const isBookmarkMobile = isMobile && pathname.includes('bookmark');
  const isOnboardingMobile = (isMobile || isTablet) && isOnboardingPage(pathname);
  const isCollaboratorOnboardingMobile =
    (isMobile || isTablet) && isCollaboratorOnboardingPage(pathname);
  const isLoggedIn = getAuthStatus({ user });
  const isBudgetMobile = isMobile && pathname.includes('budget');
  const isSearch = isSearchResultsPage(pathname) && isLoggedIn;
  const isToolsPage = pathname.toLowerCase().includes('tools');
  const isInspirationPage = pathname.toLowerCase().includes('inspiration');
  const isScrapbookPage = pathname.toLowerCase().includes('scrapbook');
  const isInboxPage = pathname.toLowerCase().includes('inbox');
  const isCreateCollaborationInvite = pathname.includes(UrlHelper.settings.inviteCollaborator);
  const needsFooter =
    (!isLoggedIn && pathname === '/') ||
    pathname.toLowerCase().includes('wedding-suppliers') ||
    isInspirationPage ||
    isToolsPage;
  const isScrapbookMobileOrTabletPortrait =
    isScrapbookPage && (isMobile || (isTablet && !isLandscape));

  const isSignUpPage = pathname.toLowerCase().startsWith(UrlHelper.signup);
  const isAcceptInvitePage = pathname.toLowerCase().startsWith(UrlHelper.acceptCollaborationInvite);

  return isCordova
    ? isSignUpPage || isAcceptInvitePage
    : !showReviewsPage &&
        !needsFooter &&
        !isGuestListMobile &&
        !isChecklistMobile &&
        !isBudgetMobile &&
        !isOnboardingMobile &&
        !isCollaboratorOnboardingMobile &&
        !isSearch &&
        !isShortlistMobile &&
        !isScrapbookMobileOrTabletPortrait &&
        !isRecommendations &&
        !isInboxPage &&
        !isCreateCollaborationInvite &&
        !isAdviceMobile &&
        !isBookmarkMobile;
};
