import React from 'react';

const firstMessageSent = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 121 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M113.041 31.9418L113.047 31.9456L113.054 31.9493C115.388 33.2357 116.75 35.5841 116.75 38.055V91.9677C116.75 94.4386 115.388 96.787 113.054 98.0733L113.048 98.0768L113.042 98.0802L64.5836 125.018C64.582 125.019 64.5804 125.02 64.5787 125.021C62.2069 126.326 59.2931 126.326 56.9213 125.021C56.9197 125.02 56.918 125.019 56.9164 125.018L8.45821 98.0802L8.452 98.0768L8.44579 98.0733C6.11235 96.787 4.75 94.4385 4.75 91.9677V38.055C4.75 35.5841 6.11235 33.2357 8.44578 31.9493L8.45263 31.9456L8.45946 31.9418L56.9164 4.98168C56.9184 4.98056 56.9204 4.97945 56.9224 4.97834C59.2937 3.67389 62.2063 3.67389 64.5776 4.97834C64.5796 4.97945 64.5816 4.98056 64.5836 4.98168L113.041 31.9418Z"
      fill="#F27084"
      stroke="#F7A9B5"
      strokeWidth="8"
    />
    <g clipPath="url(#clip0_13715_17388)">
      <path
        d="M67.0521 58.8689H86.5775C87.8928 58.8728 89.1532 59.397 90.0833 60.3271C91.0134 61.2572 91.5376 62.5176 91.5415 63.8329V78.5164C91.5377 79.8318 91.0135 81.0922 90.0834 82.0224C89.1533 82.9525 87.8929 83.4767 86.5775 83.4806C86.5775 83.4806 86.7741 88.0792 86.877 89.61C86.9354 90.4793 85.8836 91.09 85.2697 90.4718C83.7056 88.8959 79.7929 83.4806 79.7929 83.4806H67.0519C65.7366 83.4767 64.4762 82.9525 63.5461 82.0224C62.616 81.0923 62.0918 79.8319 62.0879 78.5166V63.8331C62.0917 62.5177 62.6159 61.2572 63.5461 60.3271C64.4762 59.3969 65.7367 58.8727 67.0521 58.8689Z"
        fill="#FEF1F3"
      />
      <path
        d="M55.3044 37.2773H35.779C34.4636 37.2812 33.2032 37.8054 32.2731 38.7355C31.343 39.6656 30.8188 40.926 30.8149 42.2414V56.9249C30.8188 58.2402 31.343 59.5006 32.2731 60.4307C33.2032 61.3608 34.4636 61.885 35.779 61.8889C35.779 61.8889 35.5823 66.4875 35.4794 68.0182C35.421 68.8875 36.4729 69.4983 37.0867 68.88C38.6508 67.3042 42.5636 61.8889 42.5636 61.8889H55.3045C56.6199 61.885 57.8803 61.3608 58.8104 60.4307C59.7404 59.5006 60.2647 58.2402 60.2685 56.9249V42.2414C60.2647 40.926 59.7404 39.6656 58.8103 38.7355C57.8802 37.8054 56.6197 37.2812 55.3044 37.2773Z"
        fill="#FEF1F3"
      />
      <path
        d="M51.22 48.0764C51.22 47.3234 50.9208 46.6012 50.3884 46.0687C49.8559 45.5363 49.1337 45.2371 48.3807 45.2371C47.6277 45.2371 46.9055 45.5363 46.373 46.0687C45.8405 46.6012 45.5414 47.3234 45.5414 48.0764C45.5414 47.3234 45.2422 46.6012 44.7098 46.0687C44.1773 45.5363 43.4551 45.2371 42.7021 45.2371C41.9491 45.2371 41.2269 45.5363 40.6944 46.0687C40.1619 46.6012 39.8628 47.3234 39.8628 48.0764C39.8628 50.6978 44.0754 53.929 45.5414 53.929C47.0074 53.929 51.22 50.7434 51.22 48.0764Z"
        fill="#F7A9B5"
      />
      <path
        d="M71.1367 69.6679C71.1367 68.9149 71.4359 68.1927 71.9683 67.6602C72.5008 67.1278 73.223 66.8286 73.976 66.8286C74.729 66.8286 75.4512 67.1278 75.9837 67.6602C76.5162 68.1927 76.8153 68.9149 76.8153 69.6679C76.8153 68.9149 77.1145 68.1927 77.6469 67.6602C78.1794 67.1278 78.9016 66.8286 79.6546 66.8286C80.4076 66.8286 81.1298 67.1278 81.6623 67.6602C82.1948 68.1927 82.4939 68.9149 82.4939 69.6679C82.4939 72.2893 78.2813 75.5204 76.8153 75.5204C75.3493 75.5204 71.1367 72.3349 71.1367 69.6679Z"
        fill="#F7A9B5"
      />
      <path
        d="M86.5776 57.8044H67.0524C65.4541 57.8063 63.9218 58.4421 62.7916 59.5723C61.6615 60.7025 61.0258 62.2348 61.0239 63.8331V78.5166C61.0258 80.1149 61.6616 81.6471 62.7917 82.7773C63.9219 83.9074 65.4541 84.5432 67.0524 84.5451H79.2514C80.2306 85.8862 83.1609 89.8579 84.5152 91.2219C84.6919 91.4008 84.9024 91.5428 85.1346 91.6397C85.3667 91.7365 85.6157 91.7861 85.8673 91.7858C86.1503 91.7852 86.4299 91.7242 86.6875 91.6069C87.0813 91.4296 87.4119 91.1369 87.6355 90.7674C87.8591 90.3979 87.9651 89.9691 87.9394 89.538C87.868 88.4761 87.749 85.8836 87.6849 84.4422C89.067 84.182 90.3149 83.4475 91.2133 82.3656C92.1118 81.2836 92.6044 79.922 92.6063 78.5157V63.8322C92.6042 62.234 91.9683 60.7019 90.8382 59.5719C89.708 58.4419 88.1758 57.8063 86.5776 57.8044ZM90.4774 78.5161C90.4761 79.55 90.0649 80.5412 89.3338 81.2723C88.6027 82.0033 87.6115 82.4146 86.5776 82.4158C86.4341 82.4158 86.292 82.4449 86.1599 82.5012C86.0279 82.5576 85.9086 82.6401 85.8093 82.7437C85.7099 82.8474 85.6326 82.97 85.5819 83.1044C85.5312 83.2387 85.5082 83.3819 85.5143 83.5253C85.5219 83.7065 85.6986 87.8265 85.7986 89.4838C84.1823 87.7313 80.6927 82.9077 80.6557 82.8561C80.5571 82.7196 80.4275 82.6085 80.2776 82.5318C80.1277 82.4552 79.9617 82.4152 79.7934 82.4152H67.0524C66.0185 82.414 65.0273 82.0028 64.2962 81.2717C63.5651 80.5406 63.1539 79.5494 63.1527 78.5155V63.832C63.1539 62.7981 63.5651 61.8069 64.2962 61.0758C65.0273 60.3447 66.0185 59.9335 67.0524 59.9323H86.5776C87.6115 59.9335 88.6027 60.3447 89.3338 61.0758C90.0649 61.8069 90.4761 62.7981 90.4774 63.832V78.5161Z"
        fill="#B14847"
      />
      <path
        d="M61.3323 42.2412C61.3305 40.643 60.6947 39.1108 59.5645 37.9807C58.4344 36.8506 56.9021 36.2149 55.3039 36.2131H35.7787C34.1804 36.2149 32.6481 36.8505 31.5179 37.9806C30.3877 39.1107 29.7519 40.643 29.75 42.2412V56.9247C29.7518 58.3311 30.2445 59.6926 31.1429 60.7745C32.0414 61.8564 33.2893 62.5908 34.6714 62.8509C34.6073 64.2923 34.4883 66.8852 34.4169 67.9471C34.3912 68.3782 34.4972 68.807 34.7209 69.1765C34.9446 69.546 35.2753 69.8388 35.6692 70.0159C35.9264 70.1332 36.2058 70.1942 36.4885 70.1948C36.7402 70.1952 36.9894 70.1455 37.2217 70.0486C37.4539 69.9517 37.6646 69.8096 37.8415 69.6306C39.1963 68.2666 42.1257 64.2951 43.1048 62.954H55.3039C56.9021 62.9522 58.4344 62.3165 59.5645 61.1864C60.6947 60.0563 61.3305 58.5241 61.3323 56.9258V42.2412ZM59.204 56.9247C59.2028 57.9587 58.7916 58.9499 58.0605 59.681C57.3294 60.4121 56.3382 60.8233 55.3042 60.8245H42.5633C42.3949 60.8245 42.2289 60.8645 42.0789 60.9411C41.929 61.0177 41.7993 61.1288 41.7006 61.2652C41.664 61.3164 38.1683 66.1486 36.5535 67.8975C36.6584 66.2226 36.8345 62.1151 36.8424 61.9338C36.8485 61.7904 36.8255 61.6472 36.7748 61.5129C36.7241 61.3785 36.6467 61.2559 36.5474 61.1522C36.448 61.0486 36.3288 60.9661 36.1967 60.9097C36.0647 60.8534 35.9226 60.8243 35.779 60.8243C34.7451 60.8231 33.7539 60.4119 33.0228 59.6808C32.2917 58.9497 31.8805 57.9585 31.8793 56.9246V42.2411C31.8805 41.2071 32.2917 40.2159 33.0228 39.4848C33.7539 38.7537 34.7451 38.3425 35.779 38.3413H55.3039C56.3378 38.3425 57.329 38.7537 58.0601 39.4848C58.7912 40.2159 59.2024 41.2071 59.2036 42.2411L59.204 56.9247Z"
        fill="#B14847"
      />
      <path
        d="M48.3806 44.1729C47.8487 44.1726 47.3223 44.2812 46.834 44.4922C46.3457 44.7032 45.9058 45.012 45.5415 45.3996C45.0056 44.8302 44.3111 44.435 43.5479 44.2651C42.7846 44.0953 41.988 44.1587 41.2613 44.4471C40.5345 44.7355 39.9112 45.2356 39.4721 45.8826C39.033 46.5295 38.7985 47.2935 38.7988 48.0754C38.7988 51.3746 43.5316 54.9924 45.5415 54.9924C47.5514 54.9924 52.284 51.3746 52.284 48.0754C52.2826 47.0407 51.8708 46.0488 51.1391 45.3172C50.4074 44.5856 49.4154 44.1741 48.3806 44.1729ZM45.5428 52.8649C44.5825 52.8433 40.9276 50.082 40.9276 48.0763C40.9276 47.6055 41.1146 47.1541 41.4475 46.8212C41.7803 46.4883 42.2318 46.3013 42.7026 46.3013C43.1734 46.3013 43.6248 46.4883 43.9577 46.8212C44.2906 47.1541 44.4776 47.6055 44.4776 48.0763C44.4813 48.3561 44.595 48.6232 44.7942 48.8198C44.9934 49.0164 45.2619 49.1266 45.5418 49.1266C45.8216 49.1266 46.0902 49.0164 46.2894 48.8198C46.4886 48.6232 46.6023 48.3561 46.606 48.0763C46.606 47.6055 46.793 47.1541 47.1259 46.8212C47.4588 46.4883 47.9102 46.3013 48.381 46.3013C48.8518 46.3013 49.3032 46.4883 49.6361 46.8212C49.969 47.1541 50.156 47.6055 50.156 48.0763C50.1566 50.0921 46.5013 52.8484 45.5428 52.8649Z"
        fill="#B14847"
      />
      <path
        d="M79.6541 65.7646C79.1221 65.7642 78.5958 65.8729 78.1075 66.0839C77.6192 66.2949 77.1793 66.6037 76.815 66.9913C76.2791 66.4219 75.5845 66.0267 74.8213 65.8568C74.0581 65.687 73.2614 65.7504 72.5347 66.0388C71.808 66.3272 71.1846 66.8273 70.7455 67.4743C70.3065 68.1212 70.0719 68.8852 70.0723 69.6671C70.0723 72.9659 74.805 76.5837 76.815 76.5837C78.8249 76.5837 83.5575 72.9659 83.5575 69.6671C83.556 68.6323 83.1443 67.6404 82.4125 66.9089C81.6808 66.1773 80.6888 65.7658 79.6541 65.7646ZM76.8162 74.4562C75.856 74.4397 72.2016 71.6838 72.2016 69.668C72.2164 69.2071 72.4099 68.77 72.7411 68.4492C73.0724 68.1284 73.5154 67.9491 73.9766 67.9491C74.4377 67.9491 74.8808 68.1284 75.212 68.4492C75.5433 68.77 75.7368 69.2071 75.7516 69.668C75.7553 69.9478 75.869 70.2149 76.0682 70.4115C76.2674 70.6081 76.5359 70.7183 76.8158 70.7183C77.0956 70.7183 77.3642 70.6081 77.5634 70.4115C77.7626 70.2149 77.8763 69.9478 77.88 69.668C77.88 69.1972 78.067 68.7457 78.3999 68.4128C78.7327 68.08 79.1842 67.893 79.655 67.893C80.1258 67.893 80.5772 68.08 80.9101 68.4128C81.243 68.7457 81.43 69.1972 81.43 69.668C81.4291 71.6737 77.7743 74.4346 76.8153 74.456L76.8162 74.4562Z"
        fill="#B14847"
      />
    </g>
    <defs>
      <clipPath id="clip0_13715_17388">
        <rect width="104" height="57" fill="white" transform="translate(8.75 36.0002)" />
      </clipPath>
    </defs>
  </svg>
);

export default firstMessageSent;
