import { IUserScopedVersionableItem } from '@bridebook/toolbox/src/storage-managers/managers/user-scoped-versionable-storage-manager-sync';
import {
  IUserScopedItem,
  UserScopedWithMigrationsStorageManager,
} from '@bridebook/toolbox/src/storage-managers/managers/user-scoped-with-migrations-storage-manager';
import { IMigration } from '@bridebook/toolbox/src/storage-managers/utils/local-storage-migrator';
import { TTaskFlowDefinition, TTaskFlowInstance } from 'lib/task/task-flow-engine';

export const TodayTaskStorage = (
  taskFlowDefinition: Pick<TTaskFlowDefinition, 'id'>,
  uid: string,
) => {
  const todayTaskMigrations = (userSpecificStorageKey: string): IMigration[] => [
    {
      name: 'task_flows_v1.1',
      up: () => {
        const item = window.localStorage.getItem('todayTaskFlow');
        if (!item) return;
        const savedObject: IUserScopedVersionableItem<TTaskFlowInstance> = JSON.parse(item);

        if (savedObject.data.currentSession.id > 5) {
          const migratedItem: IUserScopedItem<TTaskFlowInstance> = {
            data: {
              taskFlowDefinitionId: taskFlowDefinition.id,
              currentTaskId: '457a688a-ecbc-4536-abb5-25081d8097c8', // this will be re checked on initialization
              currentSession: {
                id: 5,
                signaled: false,
                started: Date.now(),
                notCompletedTasks: ['457a688a-ecbc-4536-abb5-25081d8097c8'], // this will be re checked on initialization
              },
            },
          };
          window.localStorage.setItem(userSpecificStorageKey, JSON.stringify(migratedItem));
        } else {
          window.localStorage.setItem(
            userSpecificStorageKey,
            JSON.stringify({ data: savedObject.data }),
          );
        }
      },
    },
    {
      name: 'remove_old_task_flows_after_v1.1',
      up: () => {
        window.localStorage.removeItem('todayTaskFlow');
      },
    },
  ];
  return UserScopedWithMigrationsStorageManager<TTaskFlowInstance>({
    uid,
    // storageKey: taskFlowDefinition.id,
    // todo use hardcoded todayTaskFlow for now until switching active flows is implemented
    storageKey: 'todayTaskFlow',
    migrations: todayTaskMigrations,
  });
};
