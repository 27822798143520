const screenXxs = 320;
const screenXxsMin = screenXxs;
const screenXs = 480;
const screenXsMin = screenXs;
const screenSm = 768;
const screenSmMin = screenSm;
const screenMd = 992;
const screenMdMin = screenMd;
const screenLg = 1260;
const screenLgMin = screenLg;
const screenXL = 1281;
const screenXLMin = screenXL;
const screenXXL = 1440;
const screenXXLMin = screenXXL;

const screenXxsMax = screenXs - 1;
const screenXsMax = screenSmMin - 1;
const screenSmMax = screenMdMin - 1;
const screenMdMax = screenLgMin - 1;
const screenXLMax = screenXXLMin - 1;

/* ############################################################################
 *  EXPORTS
 * ######################################################################### */

interface ScreenSizeTypes {
  screenXxs: number;
  screenXxsMin: number;
  screenXs: number;
  screenXsMin: number;
  screenSm: number;
  screenSmMin: number;
  screenMd: number;
  screenMdMin: number;
  screenLg: number;
  screenLgMin: number;
  screenXxsMax: number;
  screenXsMax: number;
  screenSmMax: number;
  screenMdMax: number;
  screenXLMin: number;
  screenXLMax: number;
  screenXXLMin: number;
}

const screenSizes: ScreenSizeTypes = {
  screenXxs,
  screenXxsMin,
  screenXs,
  screenXsMin,
  screenSm,
  screenSmMin,
  screenMd,
  screenMdMin,
  screenLg,
  screenLgMin,
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMax,
  screenXLMin,
  screenXLMax,
  screenXXLMin,
};

export default screenSizes;
