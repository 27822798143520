import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
    fontDefaultSemiBold: 18,
    lineHeight: '1.2em',
    color: colors.space,

    maxWidthXsMax: {
      fontDefaultSemiBold: 16,
    },

    maxWidthSmMax: {
      minWidthSm: {
        fontDefaultSemiBold: 17,
      },
    },

    maxWidthMdMax: {
      minWidthMd: {
        fontDefaultSemiBold: 18,
      },
    },

    minWidthLg: {
      fontDefaultSemiBold: 18,
    },
  },
});

export default styles;
