import { IGuest } from '@bridebook/models/source/models/Weddings/Guests.types';
import { GuestlistActionTypes } from 'lib/guestlist/action-types';
import {
  cancelResetGuestlistAnalytics,
  clickedResetGuestlistAnalytics,
  initialisedGuestlistAnalytics,
} from 'lib/guestlist/analytics/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { InitialisedGuestListStorage } from 'lib/storage-manager/basic/initialised-guest-list';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import { IDeps } from 'lib/types';

export const onFirebaseGuestlist = (guests: Array<IGuest>) => ({
  type: GuestlistActionTypes.ON_FIREBASE_GUESTLIST,
  payload: guests,
});

export const triggerResetGuestlist =
  () =>
  ({ dispatch, warningModal }: IDeps) => {
    const i18n = getI18n();
    dispatch(clickedResetGuestlistAnalytics());
    warningModal.toggleWarningModal({
      title: i18n.t('guestlist:resetWarningTitle'),
      subtitle: i18n.t('guestlist:resetWarningSubtitle'),
      confirmText: i18n.t('guestlist:resetWarningReset'),
      onConfirm: () => dispatch(resetGuestlist()),
      onCancel: () => dispatch(cancelResetGuestlistAnalytics()),
    });

    return {
      type: GuestlistActionTypes.TRIGGER_RESET_GUESTLIST,
    };
  };

export const resetGuestlist = () => ({
  type: GuestlistActionTypes.RESET_GUESTLIST,
});

export const initEmptyGuestlist =
  () =>
  ({ dispatch, cordovaTracker }: IDeps) => {
    InitialisedGuestListStorage.set(true);
    cordovaTracker.track(TrackingEvent.InitializedGuestList);
    dispatch(initialisedGuestlistAnalytics('emptyGuestList'));
    return {
      type: GuestlistActionTypes.INIT_EMPTY_GUESTLIST,
    };
  };
