import { FelaCSS, FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyles {
  pill: FelaCSS;
  wrapper: FelaCSSWithCustomSelectors;
}

interface IStyleProps {
  colorGradient?: boolean;
  filterSelected?: boolean;
}

const styles = ({ filterSelected, colorGradient }: IStyleProps): IStyles => {
  const activeBgColor = colors.indigoCrush04;
  const activeBorderColor = colors.indigoCrush;
  const inactiveBorderColor = colorGradient
    ? `linear-gradient(to right, ${colors.indigoCrush}, ${colors.rose})`
    : colors.space15;

  return {
    wrapper: {
      zIndex: 1,
      position: 'relative',
      desktopDesign: {
        zIndex: 'unset',
      },
    },

    pill: {
      ...(!colorGradient && {
        boxShadow: filterSelected
          ? `inset 0px 0px 0px 2px ${activeBorderColor}`
          : `inset 0px 0px 0px 1px ${inactiveBorderColor}`,
      }),
      fontDefaultSemiBold: 14,
      color: filterSelected ? colors.space : colors.space75,
      paddingHorizontal: 3,
      cursor: 'pointer',
      transition: 'background-color 150ms ease-in',
      backgroundColor: filterSelected ? activeBgColor : colors.white,

      // Using gradient border trick from https://css-tricks.com/gradient-borders-in-css/
      ...(colorGradient && {
        backgroundClip: 'padding-box',
        border: 'solid 2px transparent',

        '&:before': {
          content: '" "',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: '-2px',
          borderRadius: 'inherit',
          background: filterSelected ? activeBorderColor : inactiveBorderColor,
        },
      }),

      ':hover': {
        desktopDesign: {
          backgroundColor: filterSelected ? colors.indigoCrush20 : colors.indigoCrush10,
        },
        ...(colorGradient && {
          '&:before': {
            desktopDesign: {
              background: activeBorderColor,
            },
          },
        }),
      },
    },
  };
};

export default styles;
