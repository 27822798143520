import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

/**
 * @deprecated
 */
const IconHome = ({ color, width = 15, style, ...restProps }: IProps) => {
  const viewBoxWidth = 16;
  const viewBoxHeight = 16;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill={iconColor}
      fillRule="evenodd"
      fillOpacity="0"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 15.25V7.50729L8 1.00729L15.25 7.50729V15.25H9.75V12.5C9.75 11.8096 9.19036 11.25 8.5 11.25H7.5C6.80964 11.25 6.25 11.8096 6.25 12.5V15.25H0.75Z"
        stroke={iconColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconHome;
