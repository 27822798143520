import { Dispatch } from 'redux';
import { Weddings } from '@bridebook/models';
import { ISupplier as IDBWeddingSupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { getDocsFromCacheOrServer } from 'lib/auth/utils/get-docs-from-cache-or-server';
import { fetchUserShortlistSuccess } from 'lib/shortlist/actions';
import { mapWeddingSuppliersSnapshotToShortlistData } from 'lib/shortlist/utils/map-wedding-suppliers-snapshot-to-shortlist-data';

export const fetchShortlistData = async (dispatch: Dispatch<any>, weddingId: string) => {
  const weddingSuppliersRef = Weddings._.getById(weddingId).Suppliers.reference;
  const weddingSuppliersSnapshot = await getDocsFromCacheOrServer(weddingSuppliersRef);
  const weddingSuppliersData: Record<string, IDBWeddingSupplier> = {};
  weddingSuppliersSnapshot.docs.forEach((doc) => {
    weddingSuppliersData[doc.id] = doc.data();
  });
  const shortlisted = await mapWeddingSuppliersSnapshotToShortlistData(weddingSuppliersData);
  dispatch(fetchUserShortlistSuccess({ shortlisted }));
};
