import { Cards } from '@bridebook/models/source/models/Countries/Cards';
import { ICard } from '@bridebook/models/source/models/Countries/Cards.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { sampleSize } from 'lib/utils/sample-size';

export interface IFeatureCards {
  [key: string]: ICard | ICardWithTodaysTask;
}

export type ICardWithTodaysTask = Omit<Required<ICard>, 'metadata'> & {
  name: string;
  metadata: {
    todaysTask: {
      ctaText: string;
      session: number;
    };
  };
};

/**
 * Class representing a feature cards service.
 */
export class FeatureCardsService {
  /** Default value for features card that should be returned by getFeatureCards method. */
  #DEFAULT_AMOUNT: number = 2;
  /** All the feature cards grabbed from the firestore. */
  #allFeatureCards: {
    [key in CountryCodes]?: IFeatureCards;
  } = {};
  /** Sampled feature cards displayed to user. */
  #sampledCards: { [key: string]: ICard[] } = {};

  /**
   * Create a feature cards service.
   * @param {Cards} cards - Cards collection class
   */
  constructor(private cards: typeof Cards) {}

  /**
   * Get given amount of feature cards.
   * @param {string} country - ie. 'GB'
   * @param {number} amount
   * @returns Array of feature cards
   */
  getFeatureCards = async (country: CountryCodes): Promise<ICard[]> => {
    const cards = await this.fetchFeatureCards(country);
    return Object.values(cards).filter((card) => !this.isTodaysTask(card));
  };

  getTodaysTasks = async (country: CountryCodes): Promise<ICardWithTodaysTask[]> => {
    const cards = await this.fetchFeatureCards(country);
    return Object.values(cards).filter(this.isTodaysTask);
  };

  sampleSize =
    (id: string) =>
    (cards: ICard[], amount: number = this.#DEFAULT_AMOUNT) => {
      if (this.#sampledCards[id]?.length === amount) {
        return this.#sampledCards[id];
      }
      this.#sampledCards[id] = sampleSize(cards, amount);
      return this.#sampledCards[id];
    };

  private async fetchFeatureCards(country: CountryCodes): Promise<IFeatureCards> {
    const allFeatureCards = this.#allFeatureCards[country];
    if (allFeatureCards) {
      return allFeatureCards;
    }

    this.#allFeatureCards[country] = await this.cards.featured(country);
    return this.#allFeatureCards[country] as IFeatureCards;
  }

  private isTodaysTask = (card: any): card is ICardWithTodaysTask => card?.metadata?.todaysTask;
}
