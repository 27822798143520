import { createWebPreset } from 'fela-preset-web';

/**
 * Config unit plugin for fela
 * Important that this plugin is processed AFTER our shorthand spacing properties (such as
 * `padding: 4`) are converted. Otherwise, spacing would get rendered as 4px instead of
 * 4 * SPACING_CONSTANT px
 *
 * @see https://www.npmjs.com/package/fela-plugin-unit
 */
export const webPreset = createWebPreset({
  unit: ['px', {}],
});
