import React from 'react';

const venueBooked = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 121 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M113.041 31.9418L113.047 31.9456L113.054 31.9493C115.388 33.2357 116.75 35.5841 116.75 38.055V91.9677C116.75 94.4386 115.388 96.787 113.054 98.0733L113.048 98.0768L113.042 98.0802L64.5836 125.018C64.582 125.019 64.5804 125.02 64.5787 125.021C62.2069 126.326 59.2931 126.326 56.9213 125.021C56.9197 125.02 56.918 125.019 56.9164 125.018L8.45821 98.0802L8.452 98.0768L8.44579 98.0733C6.11235 96.787 4.75 94.4385 4.75 91.9677V38.055C4.75 35.5841 6.11235 33.2357 8.44578 31.9493L8.45263 31.9456L8.45946 31.9418L56.9164 4.98168C56.9184 4.98056 56.9204 4.97945 56.9224 4.97834C59.2937 3.67389 62.2063 3.67389 64.5776 4.97834C64.5796 4.97945 64.5816 4.98056 64.5836 4.98168L113.041 31.9418Z"
      fill="#F27084"
      stroke="#F7A9B5"
      strokeWidth="8"
    />
    <path
      d="M91.2778 65.1596C91.5688 65.7589 91.8463 65.9786 92.1846 65.7855C93.3215 65.1397 94.0929 65.7788 94.9253 66.418C95.7508 67.0572 96.617 67.0571 97.3682 66.2449C97.578 66.0185 97.8148 65.7322 97.49 65.426C97.2599 65.2062 96.9757 65.2462 96.7727 65.4526C95.9606 66.2782 95.426 65.5658 94.8644 65.1197C93.9305 64.3807 92.9764 64.1476 91.9072 64.8334C91.6771 64.9799 91.4267 65.0864 91.2778 65.1596Z"
      fill="#FBD4DA"
    />
    <path
      d="M75.678 46.7566C75.5562 45.6181 75.3532 44.7725 76.5374 44.5528C76.6322 44.5328 76.7608 44.16 76.6999 44.0202C76.6389 43.8803 76.375 43.7738 76.1856 43.7538C75.184 43.634 74.3652 44.5861 74.5479 45.8045C74.7036 46.8498 74.866 47.8152 73.6953 48.4277C73.4178 48.5742 73.3908 48.8605 73.5532 49.1135C73.7494 49.4131 74.0269 49.2866 74.2637 49.1734C75.475 48.6141 75.8269 47.6421 75.678 46.7566Z"
      fill="#FBD4DA"
    />
    <path
      d="M92.0518 53.0754C92.4781 53.1154 92.7894 53.1154 92.9112 52.8424C93.033 52.5827 92.9315 52.2765 92.627 52.2099C91.3751 51.9436 91.8149 51.0115 91.869 50.2924C91.9503 49.1872 91.6796 48.2884 90.6374 47.7424C90.3735 47.6026 90.0487 47.3496 89.778 47.7025C89.5276 48.0287 89.6901 48.3217 90.0081 48.4881C91.0435 49.034 90.9487 49.9196 90.7728 50.845C90.5562 52.0301 90.969 52.8158 92.0518 53.0754Z"
      fill="#FBD4DA"
    />
    <path
      d="M85.3381 55.2459C85.8321 55.2126 86.1637 54.9463 86.211 54.4803C86.2652 53.9277 85.9336 53.5548 85.3719 53.5415C84.8103 53.5282 84.4651 53.8877 84.499 54.447C84.5193 54.9197 84.8509 55.186 85.3381 55.2459Z"
      fill="#FBD4DA"
    />
    <path
      d="M68.102 55.2459C68.6434 55.1993 69.0088 54.9263 69.0088 54.3604C69.0088 53.8744 68.6907 53.5614 68.2103 53.5348C67.6418 53.5082 67.2967 53.8411 67.29 54.4003C67.2832 54.8864 67.6148 55.146 68.102 55.2459Z"
      fill="#FBD4DA"
    />
    <path
      d="M91.0012 59.3119C90.5072 59.425 90.047 59.6514 90.0741 60.1974C90.1011 60.7167 90.541 61.0296 91.0621 61.0096C91.5696 60.9897 92.023 60.6834 91.9959 60.1508C91.9756 59.6248 91.5222 59.3851 91.0012 59.3119Z"
      fill="#FBD4DA"
    />
    <path
      d="M80.6353 61.3786C81.0616 61.4186 81.3729 61.4186 81.4947 61.1456C81.6165 60.8859 81.515 60.5797 81.2105 60.5131C79.9585 60.2468 80.3984 59.3147 80.4525 58.5956C80.5337 57.4904 80.2631 56.5916 79.2209 56.0456C78.957 55.9058 78.6322 55.6528 78.3615 56.0057C78.1111 56.3319 78.2735 56.6248 78.5916 56.7913C79.627 57.3372 79.5322 58.2228 79.3563 59.1482C79.1397 60.3333 79.5525 61.119 80.6353 61.3786Z"
      fill="#FBD4DA"
    />
    <path
      d="M56.686 63.5491C57.2274 63.5025 57.5928 63.2295 57.5928 62.6636C57.5928 62.1776 57.2747 61.8647 56.7943 61.838C56.2258 61.8114 55.8807 62.1443 55.8739 62.7036C55.8672 63.1896 56.1988 63.4492 56.686 63.5491Z"
      fill="#FBD4DA"
    />
    <path
      d="M29.7671 65.1596C29.4761 65.7589 29.1987 65.9786 28.8603 65.7855C27.7234 65.1397 26.952 65.7788 26.1197 66.418C25.2941 67.0572 24.4279 67.0571 23.6767 66.2449C23.467 66.0185 23.2301 65.7322 23.5549 65.426C23.785 65.2062 24.0692 65.2462 24.2722 65.4526C25.0843 66.2782 25.6189 65.5658 26.1806 65.1197C27.1144 64.3807 28.0686 64.1476 29.1378 64.8334C29.3678 64.9799 29.6182 65.0864 29.7671 65.1596Z"
      fill="#FBD4DA"
    />
    <path
      d="M45.3669 46.7566C45.4887 45.6181 45.6917 44.7725 44.5075 44.5528C44.4127 44.5328 44.2842 44.16 44.3451 44.0202C44.406 43.8803 44.6699 43.7738 44.8594 43.7538C45.8609 43.634 46.6797 44.5861 46.497 45.8045C46.3413 46.8498 46.1789 47.8152 47.3496 48.4277C47.6271 48.5742 47.6542 48.8605 47.4917 49.1135C47.2955 49.4131 47.0181 49.2866 46.7812 49.1734C45.5699 48.6141 45.218 47.6421 45.3669 46.7566Z"
      fill="#FBD4DA"
    />
    <path
      d="M28.9932 53.0754C28.5668 53.1154 28.2556 53.1154 28.1337 52.8424C28.0119 52.5827 28.1134 52.2765 28.418 52.2099C29.6699 51.9436 29.23 51.0115 29.1759 50.2924C29.0947 49.1872 29.3654 48.2884 30.4075 47.7424C30.6714 47.6026 30.9962 47.3496 31.2669 47.7025C31.5173 48.0287 31.3549 48.3217 31.0368 48.4881C30.0015 49.034 30.0962 49.9196 30.2721 50.845C30.4887 52.0301 30.0759 52.8158 28.9932 53.0754Z"
      fill="#FBD4DA"
    />
    <path
      d="M35.7068 55.2459C35.2128 55.2126 34.8812 54.9463 34.8339 54.4803C34.7797 53.9277 35.1113 53.5548 35.673 53.5415C36.2347 53.5282 36.5798 53.8877 36.5459 54.447C36.5256 54.9197 36.1941 55.186 35.7068 55.2459Z"
      fill="#FBD4DA"
    />
    <path
      d="M52.9429 55.2459C52.4016 55.1993 52.0361 54.9263 52.0361 54.3604C52.0361 53.8744 52.3542 53.5614 52.8346 53.5348C53.4031 53.5082 53.7482 53.8411 53.755 54.4003C53.7617 54.8864 53.4301 55.146 52.9429 55.2459Z"
      fill="#FBD4DA"
    />
    <path
      d="M30.0433 59.3119C30.5373 59.425 30.9974 59.6514 30.9704 60.1974C30.9433 60.7167 30.5034 61.0296 29.9824 61.0096C29.4748 60.9897 29.0214 60.6834 29.0485 60.1508C29.0688 59.6248 29.5222 59.3851 30.0433 59.3119Z"
      fill="#FBD4DA"
    />
    <path
      d="M40.4097 61.3786C39.9833 61.4186 39.6721 61.4186 39.5503 61.1456C39.4284 60.8859 39.5299 60.5797 39.8345 60.5131C41.0864 60.2468 40.6465 59.3147 40.5924 58.5956C40.5112 57.4904 40.7819 56.5916 41.824 56.0456C42.0879 55.9058 42.4127 55.6528 42.6834 56.0057C42.9338 56.3319 42.7714 56.6248 42.4533 56.7913C41.418 57.3372 41.5127 58.2228 41.6886 59.1482C41.9052 60.3333 41.4924 61.119 40.4097 61.3786Z"
      fill="#FBD4DA"
    />
    <path
      d="M64.3589 63.5491C63.8176 63.5025 63.4521 63.2295 63.4521 62.6636C63.4521 62.1776 63.7702 61.8647 64.2507 61.838C64.8191 61.8114 65.1642 62.1443 65.171 62.7036C65.1777 63.1896 64.8462 63.4492 64.3589 63.5491Z"
      fill="#FBD4DA"
    />
    <path
      d="M81.5163 87.3334H41.0391V70.7273L50.38 63.4622V57.2349L61.2777 45.8182L72.1754 57.2349V63.4622L81.5163 70.7273V87.3334Z"
      fill="#FEF1F3"
      stroke="#B14847"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M65.1987 44.2335C65.1987 43.7009 64.9872 43.19 64.6105 42.8134C64.2339 42.4368 63.7231 42.2252 63.1904 42.2252C62.6578 42.2252 62.147 42.4368 61.7704 42.8134C61.3937 43.19 61.1821 43.7009 61.1821 44.2335C61.1821 43.7009 60.9705 43.19 60.5939 42.8134C60.2173 42.4368 59.7065 42.2252 59.1738 42.2252C58.6412 42.2252 58.1304 42.4368 57.7537 42.8134C57.3771 43.19 57.1655 43.7009 57.1655 44.2335C57.1655 46.088 60.1452 48.3731 61.1821 48.3731C62.2191 48.3731 65.1987 46.12 65.1987 44.2335Z"
      fill="#FBD4DA"
    />
    <path
      d="M61.1823 48.9318C62.5098 48.9318 65.7572 46.4239 65.7572 44.2336C65.7575 43.7011 65.592 43.1817 65.2838 42.7474C64.9756 42.3131 64.5398 41.9855 64.037 41.81C63.5342 41.6345 62.9893 41.6198 62.4777 41.768C61.9662 41.9162 61.5135 42.2198 61.1823 42.6369C60.8512 42.2198 60.3984 41.9161 59.8869 41.768C59.3753 41.6198 58.8304 41.6345 58.3276 41.81C57.8247 41.9855 57.389 42.3131 57.0808 42.7474C56.7726 43.1817 56.6071 43.7012 56.6074 44.2337C56.6074 46.424 59.8548 48.9318 61.1823 48.9318ZM59.174 42.7839C59.5584 42.7843 59.9269 42.9372 60.1988 43.209C60.4706 43.4807 60.6235 43.8493 60.624 44.2336C60.624 44.3817 60.6828 44.5237 60.7875 44.6284C60.8922 44.7331 61.0343 44.7919 61.1823 44.7919C61.3304 44.7919 61.4724 44.7331 61.5771 44.6284C61.6818 44.5237 61.7406 44.3817 61.7406 44.2336C61.7406 43.8491 61.8934 43.4803 62.1653 43.2083C62.4372 42.9364 62.8061 42.7836 63.1906 42.7836C63.5752 42.7836 63.944 42.9364 64.2159 43.2083C64.4879 43.4803 64.6406 43.8491 64.6406 44.2336C64.6406 45.782 61.9602 47.8148 61.1823 47.8148C60.4631 47.8148 57.724 45.7669 57.724 44.2336C57.7245 43.8493 57.8774 43.4807 58.1493 43.209C58.4211 42.9372 58.7896 42.7843 59.174 42.7839Z"
      fill="#B14847"
    />
    <path
      d="M61.2777 71.7652C62.7916 71.7652 64.2436 72.351 65.3141 73.3937C66.3846 74.4364 66.986 75.8506 66.986 77.3253V87.3334H55.5693V77.3253C55.5693 75.8506 56.1707 74.4364 57.2413 73.3937C58.3118 72.351 59.7637 71.7652 61.2777 71.7652Z"
      fill="#FBD4DA"
      stroke="#B14847"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="50.3423"
      y1="63.5016"
      x2="53.5317"
      y2="63.5016"
      stroke="#B14847"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="67.9858"
      y1="63.5016"
      x2="71.1752"
      y2="63.5016"
      stroke="#B14847"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default venueBooked;
