import { colors } from '@bridebook/ui';

const LoadingBar = () => (
  <style jsx global>
    {`
      #nprogress {
        pointer-events: none;
      }

      #nprogress .bar {
        background: ${colors.indigoCrush};
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
      }

      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px ${colors.indigoCrush}, 0 0 5px ${colors.indigoCrush};
        opacity: 1;
        transform: rotate(3deg) translate(0px, -4px);
      }
    `}
  </style>
);

export default LoadingBar;
