import { getIsQuizOpen } from 'lib/quiz/selectors';
import { useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';

const PhotoQuizLazy = lazyComponent(() => import('./photo-quiz'));

const PhotoQuiz = () => {
  const isQuizOpen = useSelector(getIsQuizOpen('photographyStyle'));

  if (!isQuizOpen) {
    return null;
  }

  return <PhotoQuizLazy />;
};

export default PhotoQuiz;
