import { FelaCSS, colors } from '@bridebook/ui';

const styles: FelaCSS = {
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  fontDefaultSemiBold: 16,
  color: colors.white,
  textTransform: 'capitalize',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default styles;
