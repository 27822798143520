import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import MultilineEllipsis from '../../../components/bbcommon/multiline-ellipsis/multiline-ellipsis';
import Box from '../../../components/fela/Box';
import Text from '../../../components/fela/Text';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './snackbar-content.style';
import SnackbarProgress from './snackbar-progress';

export type TSnackbarMode = 'info' | 'success' | 'alert';

export interface ISnackbarOptions {
  /** Indicates if the snackbar is shown or not */
  show: boolean;
  /** Unique ID that is used to determine a new snackbar to render if a new goes in while the previous one is still shown */
  uuid: string;
  /** Timeout to hide the snackbar. Defaults to 3000ms */
  timeout?: number;
  /** Callback function to be called when timeout to hide is reached (like actually hiding the snackbar) */
  onTimeout?: () => void;
  /** Snackbar mode which defines it's colors */
  mode: TSnackbarMode;
  /** Text to display inside the snackbar */
  text: string;
  /** Emoji to display in front of the text */
  emoji?: string | null;
  /** Lines limit for multiline text */
  linesLimit?: number;
  /** Additional CTA content to be rendered right-hand to the text. Designed to pass links here. */
  ctaContent?: ReactNode;
}

export interface IProps extends IStylingProps, ISnackbarOptions {}

const shineSlideAnimation: Keyframe[] = [
  { transform: 'translateX(-50%)' },
  { transform: 'translateX(100%)' },
];

const SnackbarContent = ({
  show = false,
  uuid,
  timeout = 3000,
  onTimeout,
  mode,
  text,
  emoji,
  linesLimit,
  ctaContent,
}: IProps) => {
  const hasCTA = !!ctaContent;
  const [isPaused, setIsPaused] = React.useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show && elementRef.current) {
      elementRef.current.animate(shineSlideAnimation, {
        duration: 800,
        easing: 'ease-in-out',
        pseudoElement: '::before',
        iterations: 1,
      });
    }
  }, [show, uuid]);

  const isTouchSupported =
    typeof window !== 'undefined' && ('ontouchstart' in window || navigator.maxTouchPoints > 0);

  // If there is a CTA, we render the emoji next to the text, not as a prefix character
  const textToRender = emoji && !hasCTA ? `${emoji} ${text}` : text;

  const styles = componentStyles(show, mode, hasCTA);

  const handleMouseEnter = useCallback(() => {
    setIsPaused(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setIsPaused(false);
  }, []);

  return (
    <Box
      setRef={(ref) => {
        elementRef.current = ref;
      }}
      onMouseEnter={isTouchSupported ? null : handleMouseEnter}
      onMouseLeave={isTouchSupported ? null : handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
      style={styles.container}>
      <Box style={styles.contentWrap}>
        <Box style={styles.textWrap}>
          {hasCTA && emoji && (
            <Box dataTest="snackbar-icon" data-test-id={`snackbar-icon-${mode}`}>
              {emoji}
            </Box>
          )}
          <Text dataTest="snackbar-message">
            {linesLimit ? (
              <MultilineEllipsis lines={linesLimit} fontSize={16} lineHeight={20}>
                {textToRender}
              </MultilineEllipsis>
            ) : (
              textToRender
            )}
          </Text>
        </Box>
        {ctaContent && <Box style={styles.ctaContentWrapper}>{ctaContent}</Box>}
      </Box>
      <SnackbarProgress
        isPaused={isPaused}
        mode={mode}
        uuid={uuid}
        timeout={timeout}
        onTimeout={onTimeout}
        show={show}
      />
    </Box>
  );
};

export default SnackbarContent;
