import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconAllFilters = ({ id, color, width = 16, style, ...restProps }: IProps) => {
  const viewBoxWidth = 17;
  const viewBoxHeight = 17;

  const icon = ({ width, height }: IconTypes) => (
    <svg
      id={id}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}
      fill="none">
      <line
        x1="0.750122"
        y1="3.17963"
        x2="15.2499"
        y2="3.17963"
        stroke="#546170"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="0.750122"
        y1="13.4632"
        x2="15.2499"
        y2="13.4632"
        stroke="#546170"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle
        cx="11.1114"
        cy="13.6435"
        r="2.1071"
        fill="white"
        stroke="#546170"
        strokeWidth="1.5"
      />
      <circle cx="5.39629" cy="3.3571" r="2.1071" fill="white" stroke="#546170" strokeWidth="1.5" />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconAllFilters;
