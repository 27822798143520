import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  resetWrap: FelaCSS;
  heading: FelaCSS;
  closeWrap: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    flexShrink: 0,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    height: '54px',
    backgroundColor: colors.white,
    borderBottom: `1px ${colors.space15} solid`,

    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
      height: 'calc(54px + env(safe-area-inset-top))',
    },
  },

  resetWrap: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',

    supportsSafeAreaInset: {
      top: 'env(safe-area-inset-top)',
    },
  },

  heading: {
    fontDefaultSemiBold: 16,
    color: colors.space,
  },

  closeWrap: {
    position: 'absolute',
    right: '4px',
    top: 0,
    bottom: 0,
    justifyContent: 'center',

    supportsSafeAreaInset: {
      top: 'env(safe-area-inset-top)',
    },
  },
});

export default styles;
