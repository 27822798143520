import { FelaCSS } from '@bridebook/ui';
import { createAnimation } from 'components/onboarding-new/components/animations/helpers/create-animation';
import { fadeInAnimation } from 'components/onboarding-new/components/animations/helpers/fade-animations';
import { onboardingImages } from 'lib/onboarding-new/helpers';

interface IStyles {
  wrapper: FelaCSS;
  stars: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    ...fadeInAnimation(0.5),
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },

  stars: {
    width: '100%',
    height: '100%',
    backgroundImage: `url("${onboardingImages}/graphics/stars-mobile.png")`,
    backgroundSize: '100%',
    backgroundRepeat: 'repeat',
    ...createAnimation(
      {
        '0%': {
          opacity: 0.2,
        },
        '100%': {
          opacity: 0.7,
        },
      },
      {
        animationIterationCount: 'infinite',
        animationDirection: 'alternate-reverse',
      },
    )(2),

    desktopDesign: {
      backgroundImage: `url("${onboardingImages}/graphics/stars-desktop.png")`,
      backgroundSize: '60%',
    },
  },
});

export default styles;
