import React, { useEffect, useMemo, useRef } from 'react';
import Box from '../../../components/fela/Box';
import { colors } from '../../../themes/variables';
import { TSnackbarMode } from './snackbar-content';
import componentStyles from './snackbar-progress.style';

interface IProps {
  mode: TSnackbarMode;
  timeout: number;
  show: boolean;
  uuid: string;
  onTimeout?: () => void;
  isPaused: boolean;
}

const getBarColor = (mode: TSnackbarMode) => {
  switch (mode) {
    case 'info':
      return colors.indigoCrush120;
    case 'success':
      return colors.teal120;
    case 'alert':
      return colors.blushTangerine120;
    default:
      return colors.indigoCrush120;
  }
};

const progressAnimationKeyframes: Keyframe[] = [
  { transform: 'scaleX(100%)' },
  { transform: 'scaleX(0)' },
];
const progressAnimationTimingOptions: KeyframeAnimationOptions = {
  iterations: 1,
  fill: 'forwards',
  delay: 50,
};

const SnackbarProgress: React.FC<IProps> = ({ show, uuid, mode, onTimeout, timeout, isPaused }) => {
  const barColor = useMemo(() => getBarColor(mode), [mode]);
  const elementRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation | null>(null);

  // Attach the animation to the element on mount. On timeout callback is required to be set
  useEffect(() => {
    if (!onTimeout || !elementRef.current) return;
    const animation = elementRef.current?.animate(progressAnimationKeyframes, {
      ...progressAnimationTimingOptions,
      duration: timeout,
    });
    animation.pause();
    animation.finished.then(onTimeout);
    animationRef.current = animation;

    return () => {
      animationRef.current.finish();
    };
  }, [onTimeout]);

  // The snackbar has been shown, or a new snackbar (new UUID) triggered
  useEffect(() => {
    if (!animationRef.current) return;
    if (show) {
      // Start the animation from the beginning
      animationRef.current.currentTime = 0;
      animationRef.current.play();
    } else {
      animationRef.current?.finish();
    }
  }, [show, uuid]);

  // If the snackbar is paused, pause the animation, resume when unpaused
  useEffect(() => {
    if (!animationRef.current) return;
    if (isPaused) {
      animationRef.current?.pause();
    } else {
      animationRef.current?.play();
    }
  }, [isPaused]);

  const styles = componentStyles(barColor);
  return (
    <Box
      setRef={(ref) => {
        elementRef.current = ref;
      }}
      style={styles.bar}
    />
  );
};

export default React.memo(SnackbarProgress);
