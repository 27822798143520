import React from 'react';

const firstMessageSentLocked = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 144 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M135.138 37.631L135.144 37.6348L135.151 37.6386C138.2 39.3193 140 42.4019 140 45.666V110.361C140 113.625 138.2 116.708 135.151 118.389L135.145 118.392L135.139 118.395L76.9866 150.723C76.9848 150.724 76.983 150.725 76.9812 150.726C73.894 152.425 70.1051 152.425 67.0181 150.725C67.0165 150.724 67.015 150.723 67.0134 150.723L8.86115 118.395L8.85494 118.392L8.84873 118.389C5.8 116.708 4 113.625 4 110.361V45.666C4 42.402 5.8 39.3193 8.84873 37.6386L8.85557 37.6348L8.8624 37.631L67.0134 5.27742C67.0155 5.27628 67.0176 5.27514 67.0196 5.274C70.1059 3.57546 73.8933 3.57533 76.9797 5.27362C76.982 5.27488 76.9843 5.27615 76.9866 5.27742L135.138 37.631Z"
      fill="#EDEFF0"
      stroke="#DDE0E3"
      strokeWidth="8"
    />
    <g clipPath="url(#clip0_12666_15147)">
      <path
        d="M79.5627 70.6425H102.993C104.572 70.6471 106.084 71.2762 107.2 72.3923C108.316 73.5084 108.945 75.0209 108.95 76.5993V94.2195C108.945 95.7979 108.316 97.3104 107.2 98.4266C106.084 99.5428 104.572 100.172 102.993 100.177C102.993 100.177 103.229 105.695 103.353 107.532C103.423 108.575 102.161 109.308 101.424 108.566C99.5469 106.675 94.8516 100.177 94.8516 100.177H79.5625C77.9841 100.172 76.4716 99.5428 75.3555 98.4267C74.2394 97.3106 73.6103 95.7981 73.6057 94.2197V76.5995C73.6102 75.021 74.2393 73.5085 75.3555 72.3923C76.4717 71.2761 77.9842 70.647 79.5627 70.6425Z"
        fill="#F6F7F8"
      />
      <path
        d="M65.4652 44.7326H42.0347C40.4563 44.7372 38.9438 45.3663 37.8277 46.4824C36.7116 47.5986 36.0825 49.111 36.0779 50.6894V68.3096C36.0825 69.888 36.7116 71.4005 37.8277 72.5166C38.9438 73.6327 40.4563 74.2618 42.0347 74.2664C42.0347 74.2664 41.7987 79.7847 41.6753 81.6217C41.6052 82.6648 42.8674 83.3978 43.604 82.6558C45.481 80.7648 50.1762 74.2664 50.1762 74.2664H65.4654C67.0438 74.2618 68.5563 73.6327 69.6724 72.5166C70.7885 71.4005 71.4176 69.888 71.4222 68.3096V50.6894C71.4176 49.111 70.7885 47.5985 69.6723 46.4824C68.5561 45.3662 67.0436 44.7372 65.4652 44.7326Z"
        fill="#F6F7F8"
      />
      <path
        d="M60.5639 57.6915C60.5639 56.7879 60.2049 55.9213 59.5659 55.2823C58.927 54.6433 58.0604 54.2844 57.1567 54.2844C56.2531 54.2844 55.3865 54.6433 54.7475 55.2823C54.1085 55.9213 53.7496 56.7879 53.7496 57.6915C53.7496 56.7879 53.3906 55.9213 52.7516 55.2823C52.1127 54.6433 51.246 54.2844 50.3424 54.2844C49.4388 54.2844 48.5721 54.6433 47.9332 55.2823C47.2942 55.9213 46.9352 56.7879 46.9352 57.6915C46.9352 60.8372 51.9904 64.7146 53.7496 64.7146C55.5087 64.7146 60.5639 60.8919 60.5639 57.6915Z"
        fill="#DDE0E3"
      />
      <path
        d="M84.4641 83.6013C84.4641 82.6977 84.823 81.8311 85.462 81.1921C86.1009 80.5531 86.9676 80.1942 87.8712 80.1942C88.7748 80.1942 89.6415 80.5531 90.2804 81.1921C90.9194 81.8311 91.2784 82.6977 91.2784 83.6013C91.2784 82.6977 91.6373 81.8311 92.2763 81.1921C92.9153 80.5531 93.7819 80.1942 94.6855 80.1942C95.5892 80.1942 96.4558 80.5531 97.0948 81.1921C97.7337 81.8311 98.0927 82.6977 98.0927 83.6013C98.0927 86.747 93.0375 90.6244 91.2784 90.6244C89.5192 90.6244 84.4641 86.8017 84.4641 83.6013Z"
        fill="#DDE0E3"
      />
      <path
        d="M102.993 69.3652H79.5631C77.6451 69.3674 75.8064 70.1304 74.4502 71.4866C73.094 72.8428 72.3311 74.6816 72.3289 76.5996V94.2198C72.3312 96.1377 73.0941 97.9764 74.4503 99.3326C75.8064 100.689 77.6452 101.452 79.5631 101.454H94.2019C95.3769 103.063 98.8933 107.829 100.518 109.466C100.73 109.681 100.983 109.851 101.262 109.967C101.54 110.084 101.839 110.143 102.141 110.143C102.481 110.142 102.816 110.069 103.125 109.928C103.598 109.715 103.995 109.364 104.263 108.921C104.531 108.477 104.658 107.963 104.628 107.445C104.542 106.171 104.399 103.06 104.322 101.33C105.981 101.018 107.478 100.137 108.556 98.8385C109.634 97.5402 110.226 95.9063 110.228 94.2187V76.5985C110.225 74.6807 109.462 72.8421 108.106 71.4861C106.75 70.1301 104.911 69.3674 102.993 69.3652V69.3652ZM107.673 94.2191C107.672 95.4598 107.178 96.6492 106.301 97.5265C105.423 98.4038 104.234 98.8973 102.993 98.8988C102.821 98.8988 102.651 98.9337 102.492 99.0013C102.334 99.0689 102.191 99.1679 102.071 99.2923C101.952 99.4167 101.859 99.5639 101.798 99.7251C101.738 99.8862 101.71 100.058 101.717 100.23C101.727 100.448 101.939 105.392 102.059 107.38C100.119 105.277 95.9314 99.4891 95.8871 99.4272C95.7687 99.2634 95.6132 99.13 95.4334 99.0381C95.2535 98.9461 95.0543 98.8981 94.8522 98.8981H79.5631C78.3224 98.8967 77.133 98.4032 76.2557 97.5259C75.3784 96.6486 74.8849 95.4591 74.8834 94.2185V76.5982C74.8849 75.3576 75.3784 74.1681 76.2557 73.2908C77.133 72.4135 78.3224 71.92 79.5631 71.9186H102.993C104.234 71.92 105.423 72.4135 106.301 73.2908C107.178 74.1681 107.672 75.3576 107.673 76.5982V94.2191Z"
        fill="#BABFC5"
      />
      <path
        d="M72.6988 50.6893C72.6965 48.7714 71.9336 46.9327 70.5774 45.5766C69.2212 44.2205 67.3825 43.4577 65.4646 43.4556H42.0344C40.1165 43.4577 38.2777 44.2204 36.9214 45.5765C35.5652 46.9327 34.8023 48.7714 34.8 50.6893V68.3095C34.8022 69.9971 35.3934 71.631 36.4715 72.9292C37.5497 74.2275 39.0472 75.1087 40.7056 75.4209C40.6287 77.1506 40.4859 80.262 40.4002 81.5363C40.3694 82.0537 40.4967 82.5682 40.7651 83.0116C41.0335 83.455 41.4303 83.8063 41.903 84.0189C42.2117 84.1597 42.5469 84.2328 42.8862 84.2336C43.1882 84.234 43.4872 84.1744 43.766 84.0581C44.0447 83.9419 44.2976 83.7713 44.5098 83.5565C46.1356 81.9197 49.6508 77.1539 50.8258 75.5446H65.4646C67.3825 75.5424 69.2212 74.7796 70.5774 73.4235C71.9336 72.0674 72.6965 70.2287 72.6988 68.3108V50.6893ZM70.1447 68.3095C70.1434 69.5502 69.6499 70.7397 68.7726 71.617C67.8953 72.4943 66.7058 72.9878 65.4651 72.9892H50.1759C49.9738 72.9892 49.7747 73.0372 49.5947 73.1291C49.4147 73.221 49.2592 73.3543 49.1407 73.518C49.0967 73.5795 44.9019 79.3782 42.9642 81.4768C43.09 79.4669 43.3014 74.5379 43.3109 74.3204C43.3181 74.1482 43.2905 73.9764 43.2297 73.8152C43.1689 73.6541 43.076 73.5069 42.9568 73.3825C42.8376 73.2581 42.6945 73.1591 42.536 73.0915C42.3776 73.0239 42.2071 72.989 42.0348 72.989C40.7941 72.9876 39.6046 72.4941 38.7273 71.6168C37.85 70.7395 37.3565 69.55 37.3551 68.3093V50.6891C37.3565 49.4484 37.85 48.2589 38.7273 47.3816C39.6046 46.5043 40.7941 46.0108 42.0348 46.0094H65.4646C66.7053 46.0108 67.8948 46.5043 68.7721 47.3816C69.6494 48.2589 70.1429 49.4484 70.1443 50.6891L70.1447 68.3095Z"
        fill="#BABFC5"
      />
      <path
        d="M57.1567 53.0073C56.5184 53.0069 55.8867 53.1373 55.3008 53.3905C54.7148 53.6436 54.1869 54.0142 53.7497 54.4793C53.1067 53.7961 52.2732 53.3218 51.3573 53.118C50.4415 52.9142 49.4855 52.9903 48.6134 53.3364C47.7413 53.6825 46.9933 54.2826 46.4664 55.0589C45.9395 55.8353 45.6581 56.752 45.6585 57.6903C45.6585 61.6493 51.3378 65.9907 53.7497 65.9907C56.1616 65.9907 61.8407 61.6493 61.8407 57.6903C61.839 56.4486 61.3449 55.2583 60.4668 54.3805C59.5887 53.5026 58.3983 53.0088 57.1567 53.0073ZM53.7513 63.4377C52.5989 63.4118 48.213 60.0982 48.213 57.6914C48.213 57.1265 48.4374 56.5847 48.8369 56.1852C49.2363 55.7858 49.7781 55.5614 50.343 55.5614C50.9079 55.5614 51.4497 55.7858 51.8492 56.1852C52.2486 56.5847 52.473 57.1265 52.473 57.6914C52.4774 58.0272 52.6139 58.3477 52.8529 58.5836C53.092 58.8195 53.4143 58.9518 53.7501 58.9518C54.0859 58.9518 54.4082 58.8195 54.6472 58.5836C54.8862 58.3477 55.0227 58.0272 55.0271 57.6914C55.0271 57.1265 55.2515 56.5847 55.651 56.1852C56.0504 55.7858 56.5922 55.5614 57.1571 55.5614C57.722 55.5614 58.2638 55.7858 58.6633 56.1852C59.0627 56.5847 59.2871 57.1265 59.2871 57.6914C59.2878 60.1103 54.9014 63.418 53.7513 63.4377Z"
        fill="#BABFC5"
      />
      <path
        d="M94.6849 78.9173C94.0466 78.9169 93.4149 79.0473 92.829 79.3004C92.243 79.5536 91.7151 79.9242 91.2779 80.3893C90.6349 79.7061 89.8014 79.2318 88.8855 79.028C87.9697 78.8242 87.0137 78.9003 86.1416 79.2463C85.2695 79.5924 84.5215 80.1926 83.9946 80.9689C83.4678 81.7453 83.1863 82.662 83.1867 83.6003C83.1867 87.5589 88.866 91.9002 91.2779 91.9002C93.6898 91.9002 99.369 87.5589 99.369 83.6003C99.3672 82.3586 98.8731 81.1683 97.995 80.2904C97.1169 79.4126 95.9265 78.9187 94.6849 78.9173V78.9173ZM91.2795 89.3473C90.1271 89.3275 85.7419 86.0203 85.7419 83.6014C85.7596 83.0483 85.9918 82.5238 86.3893 82.1388C86.7869 81.7539 87.3185 81.5387 87.8719 81.5387C88.4252 81.5387 88.9569 81.7539 89.3544 82.1388C89.7519 82.5238 89.9841 83.0483 90.0019 83.6014C90.0063 83.9371 90.1428 84.2577 90.3818 84.4936C90.6208 84.7295 90.9431 84.8617 91.2789 84.8617C91.6148 84.8617 91.9371 84.7295 92.1761 84.4936C92.4151 84.2577 92.5516 83.9371 92.556 83.6014C92.556 83.0364 92.7804 82.4947 93.1798 82.0952C93.5793 81.6957 94.1211 81.4713 94.686 81.4713C95.2509 81.4713 95.7927 81.6957 96.1921 82.0952C96.5916 82.4947 96.816 83.0364 96.816 83.6014C96.8149 86.0082 92.4292 89.3213 91.2784 89.347L91.2795 89.3473Z"
        fill="#BABFC5"
      />
    </g>
    <defs>
      <clipPath id="clip0_12666_15147">
        <rect width="124.8" height="68.4" fill="white" transform="translate(9.59998 43.2001)" />
      </clipPath>
    </defs>
  </svg>
);

export default firstMessageSentLocked;
