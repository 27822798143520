import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.space04,
  },
});

export default styles;
