import { getDocFromCache, getDocFromServer } from 'firebase/firestore';
import { isEmpty } from 'ramda';
import { DocumentReference, DocumentSnapshot } from '@firebase/firestore';

export const getDocFromCacheOrServer = async <T>(
  reference: DocumentReference<T>,
): Promise<DocumentSnapshot<T>> => {
  let document;
  try {
    document = await getDocFromCache(reference);
    if (isEmpty(document)) {
      throw new Error('document not found in cache');
    }
  } catch (error) {
    document = await getDocFromServer(reference);
  }
  return document;
};
