// Get an element's distance from the top of the page
const getElemDistance = (elem: HTMLElement): number => {
  let location = 0;
  if (elem && elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent as HTMLElement;
    } while (elem);
  }
  return location >= 0 ? location : 0;
};

export const scrollToElement = (
  element: HTMLElement,
  offset: number = 0,
  smooth: boolean = true,
  search: boolean = false,
) => {
  const offsetTop = getElemDistance(element);
  const topEl = search ? document.getElementById('search-list-results') : window;

  if (search && topEl && 'scrollTop' in topEl) {
    topEl.scrollTop = offsetTop + offset;
    return;
  }

  window.scroll({
    top: offsetTop + offset,
    left: 0,
    behavior: smooth ? 'smooth' : 'auto',
  });
};

export const scrollToId = (
  id: string,
  offset: number = 0,
  delay: number = 0,
  smooth: boolean = true,
  search: boolean = false,
) => {
  const el = document.getElementById(id);

  if (!el) return;

  setTimeout(() => {
    scrollToElement(el, offset, smooth, search);
  }, delay);
};

export const scrollToTop = (behavior: ScrollToOptions['behavior'] = 'smooth'): void => {
  window.scroll({
    top: 0,
    left: 0,
    behavior,
  });
};
