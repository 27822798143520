import React, { useCallback } from 'react';
import { RadioGroup } from '@bridebook/ui';
import { SortOptionValue } from 'app-shared/lib/search/types';
import { setSortingOption } from 'lib/search/actions';
import { useSortOptions } from 'lib/search/hooks/use-sort-options';
import { getSortingOption } from 'lib/search/selectors';
import { useDispatch, useSelector } from 'lib/utils';

const SortingOptions: React.FC = () => {
  const sortingOptions = useSortOptions();
  const dispatch = useDispatch();
  const selectedOption = useSelector(getSortingOption);

  const handleSelect = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      dispatch(setSortingOption(e.currentTarget.value as SortOptionValue));
    },
    [dispatch],
  );

  return (
    <RadioGroup
      name="sorting-options"
      selectedOption={selectedOption}
      options={sortingOptions}
      onSelect={handleSelect}
      radioWrapperStyle={() => ({ marginBottom: 3 })}
    />
  );
};

export default SortingOptions;
