import Cookies from 'universal-cookie';

/**
 * Set a cookie with the specified name and value. Default expiration is 1 year.
 */
export const setCookie = (
  name: string,
  value: string | undefined,
  amount: number = 1,
  unit: 'days' | 'months' | 'years' = 'years',
) => {
  // Create a new date object for the current date and time
  const currentDate = new Date();

  switch (unit) {
    case 'months':
      // Set the month of the date object to the current month plus the specified number of months
      currentDate.setMonth(currentDate.getMonth() + amount);
      break;
    case 'days':
      // Calculate the number of milliseconds in the specified number of days and set the time of
      // the date object to the current time plus the specified number of days
      currentDate.setTime(currentDate.getTime() + amount * 1000 * 60 * 60 * 24);
      break;
    case 'years':
    default:
      // Set the year of the date object to the current year plus the specified number of years
      currentDate.setFullYear(currentDate.getFullYear() + amount);
      break;
  }

  const cookies = new Cookies();
  cookies.set(name, value, {
    expires: currentDate,
    httpOnly: false,
    path: '/',
  });
};
