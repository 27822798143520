import React from 'react';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import { FelaCSS } from '../fela/flowtypes';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconCup = ({ id, color, width = 20, style, ...restProps }: IProps) => {
  const viewBoxWidth = 11;
  const viewBoxHeight = 8;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26551 3.27687H1.42181C1.44599 4.4613 1.79319 6.99069 4.16177 8.29805C3.48557 6.73345 3.29031 5.51679 3.27336 5.40587C3.26802 5.37048 3.26551 5.33508 3.26551 5.29906V3.27687ZM15.8774 8.22758C18.1468 6.90379 18.5367 4.44992 18.5869 3.27687H16.7435V5.29906C16.7435 5.33508 16.7407 5.37048 16.7353 5.40587C16.719 5.51521 16.5294 6.69711 15.8774 8.22758ZM19.9891 2.5038C19.9944 2.56479 20.4499 8.60322 14.8548 10.1814C13.9491 11.6183 12.6269 13.0967 10.7107 14.1793V15.9933H13.3618C13.7517 15.9933 14.0681 16.3118 14.0681 16.7043C14.0681 17.0968 13.7517 17.4153 13.3618 17.4153H6.6472C6.2573 17.4153 5.94086 17.0968 5.94086 16.7043C5.94086 16.3118 6.2573 15.9933 6.6472 15.9933H9.298V14.1793C7.39529 13.1042 6.07805 11.6386 5.17268 10.2111C5.16602 10.2097 5.15936 10.2092 5.15269 10.2086C5.1446 10.208 5.13651 10.2074 5.12842 10.2051C0.574905 8.96759 -0.193589 4.61668 0.036206 2.48894C0.075133 2.12805 0.378073 1.8547 0.738461 1.8547H3.26557V0.711036C3.26557 0.318544 3.5817 0 3.97191 0H10.0043H16.0371C16.427 0 16.7434 0.318544 16.7434 0.711036V1.8547H19.2856C19.6516 1.8547 19.9574 2.13659 19.9891 2.5038ZM4.67834 1.42201V5.23854C4.77315 5.76028 5.75292 10.5482 10.0044 12.9478C14.272 10.5413 15.2367 5.76565 15.3308 5.23949V1.42201H10.0044H4.67834Z"
        fill={iconColor}
      />
    </svg>
  );
  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconCup;
