import { FelaCSS, colors, spacing, transition } from '@bridebook/ui';

interface IStyles {
  iconPadding: FelaCSS;
  wrapper: FelaCSS;
  stretchWrap: FelaCSS;
  menuButtons: FelaCSS;
  menuButtonWrap: FelaCSS;
  button: FelaCSS;
}

const style = ({ isMobile }: { isMobile: boolean }): IStyles => {
  if (!isMobile)
    return {
      button: {
        fontDefault: 16,
        color: colors.blueCrush04,
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        width: 'auto',
      },

      wrapper: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        marginHorizontal: '3px',
      },

      stretchWrap: {
        width: '100%',
        flex: 'auto',
      },
      iconPadding: {},
      menuButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: spacing[6],
        marginRight: '13px',
        desktopDesign: {
          marginRight: 0,
        },
      },
      menuButtonWrap: {
        desktopDesign: {
          marginLeft: '20px',
        },
      },
    };
  else {
    return {
      button: {
        fontDefault: 16,
        lineHeight: '22px',
        transition: `color ${transition.fast} ease-in-out`,
        whiteSpace: 'nowrap',

        ':hover': {
          color: 'space40',
        },
      },
      iconPadding: {},
      menuButtons: {},

      wrapper: {
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        marginHorizontal: '3px',
      },

      stretchWrap: {
        width: '100%',
        flex: 'auto',
      },

      menuButtonWrap: {
        desktopDesign: {
          marginLeft: '20px',
        },
      },
    };
  }
};

export default style;
