import React from 'react';
import { Box } from '@bridebook/ui';
import { TMenuOptionsListTypes } from 'lib/types';
import MenuOption from './menu-option';
import componentStyles from './menu-options-list.style';

interface IProps {
  menu: TMenuOptionsListTypes;
}

const MenuOptionsList = ({ menu }: IProps) => {
  const styles = componentStyles();

  return (
    <Box style={styles.wrapper} data-name="menu-option-list">
      {menu.map((item) => (
        <Box style={styles.menuList} key={`drawer-menu-block-${item[0].title}`}>
          {item.map((tab, index) => {
            const dataTest = `drawer-menu-option-${tab.id || tab.title}`;

            return (
              <MenuOption
                id={tab.id}
                first={index === 0}
                dataTest={dataTest}
                key={dataTest}
                title={tab.title}
                url={tab.url}
                icon={tab.icon}
                onClick={tab.onClick}
                color={tab.color}
                newOption={tab.newOption}
                counter={tab.counter}
              />
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default MenuOptionsList;
