import { FelaCSSWithCustomSelectors } from '@bridebook/ui';

interface IStyles {
  resetButton: FelaCSSWithCustomSelectors;
}

const styles = (): IStyles => ({
  resetButton: {
    paddingLeft: 4,
  },
});

export default styles;
