import React from 'react';
import { IIconProps } from '../../types';
import SimpleIconWrap from './simple-icon-wrap';

const IconBookmark = ({ id, color, width = 30, style, onClick }: IIconProps) => {
  const viewBoxWidth = 30;
  const viewBoxHeight = 50;
  return (
    <SimpleIconWrap
      onClick={onClick}
      id={id}
      viewBox={[0, 0, viewBoxWidth, viewBoxHeight]}
      width={width}
      style={style}
      color={color}
      path="M15 35.814c.552 0 1.104.18 1.55.54l9.166 7.363V3.736H4.284v39.98l9.166-7.363a2.47 2.47 0 0 1 1.55-.54M27.682 50a2.392 2.392 0 0 1-1.5-.522L15 40.493 3.818 49.478a2.422 2.422 0 0 1-2.473.319C.525 49.436 0 48.659 0 47.803V2.197C0 .984 1.039 0 2.318 0h25.364C28.962 0 30 .984 30 2.197v45.606c0 .856-.524 1.633-1.345 1.994a2.439 2.439 0 0 1-.973.203"
    />
  );
};

export default IconBookmark;
