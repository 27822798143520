import { UserScopedWithMigrationsStorageManager } from '@bridebook/toolbox/src/storage-managers/managers/user-scoped-with-migrations-storage-manager';
import { IMigration } from '@bridebook/toolbox/src/storage-managers/utils/local-storage-migrator';
import { TQuizId } from 'components/quiz/types';

const QUIZ_SEARCH_INTERCEPT_STORAGE_ID = 'quizIntercept';

type QuizSearchInterceptState = {
  skipCounter?: number;
  lastSkipped?: number;
};

type QuizSearchInterceptStatePerQuiz = Partial<Record<TQuizId, QuizSearchInterceptState>>;

export const QuizSearchInterceptStorage = (uid: string) => {
  const quizSearchInterceptMigrations = (): IMigration[] => [];
  return UserScopedWithMigrationsStorageManager<QuizSearchInterceptStatePerQuiz>({
    uid,
    storageKey: QUIZ_SEARCH_INTERCEPT_STORAGE_ID,
    migrations: quizSearchInterceptMigrations,
  });
};
