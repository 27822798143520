import { useEffect } from 'react';
import { connect } from 'react-redux';
import { hideSnackbar, toggleSnackbar } from 'lib/bbcommon/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { hideSplashScreen } from 'lib/mobile-app/actions';
import { ConnectedTypes } from 'lib/types';

const storeEnhancer = connect();

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const ConnectionStatus = ({ dispatch }: IProps) => {
  useEffect(() => {
    dispatch(hideSplashScreen());
    dispatch({ type: 'SET_APP_ONLINE', payload: { online: false } });
    dispatch(toggleSnackbar('alert', getI18n().t('common:noInternet')));

    return () => {
      dispatch({ type: 'SET_APP_ONLINE', payload: { online: true } });
      dispatch(hideSnackbar());
    };
  }, [dispatch]);

  return null;
};

export default storeEnhancer(ConnectionStatus);
