import gazetteer from '@bridebook/toolbox/src/gazetteer';
import {
  BCP47LanguageTags,
  RoutingLocales as Locales,
  countryCodeToRoutingLocale as countryCodeToLocale,
  routingLocaleToCountryCode as localeToCountryCode,
  routingLocaleToLanguageTag,
} from '@bridebook/toolbox/src/i18n/constants';

/**
 * These locales are used only for analytics due to legacy reasons
 */
export enum AnalyticsLocales {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IE = 'ie',
}

export const localeToAnalyticsLanguage: Record<Locales, AnalyticsLocales> = {
  [Locales.DE]: AnalyticsLocales.DE,
  [Locales.FR]: AnalyticsLocales.FR,
  [Locales.UK]: AnalyticsLocales.EN,
  [Locales.IE]: AnalyticsLocales.IE,
};

export const webCountryCodes = Object.values(localeToCountryCode);

export const alwaysLoadedNS = [
  'auth',
  'common',
  'countries',
  'datepicker',
  'enquiries',
  'mobileApp',
  'searchBar',
  'settings',
  'shortlistCategories',
  'shortlistModals',
  'supplierCategories',
  'ui',
  'venueConfirmation',
  'lostLead',
  'venuerex',
  'achievements',
  'filters',
  'navigation',
  'quiz',
  'localeChange',
  'supplierCard',
  'toolWidgets',
];
const languageTags = gazetteer
  .getMarkets()
  .filter((m) => m.alias)
  .map((m) => ({
    [m.alias as string]: m.locale,
  }));

export {
  BCP47LanguageTags,
  countryCodeToLocale,
  Locales,
  languageTags,
  localeToCountryCode,
  routingLocaleToLanguageTag,
};
