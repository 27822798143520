import { IItem } from 'components/bbcommon/option-list/option-list';
import { getI18n } from 'lib/i18n/getI18n';

export interface IGuestOption extends IItem {
  key: string;
  primaryText: string;
  minValue: number;
  maxValue?: number;
}

export const getGuestFilterOptions = (): IGuestOption[] => {
  const i18n = getI18n();

  return [
    {
      key: '<50',
      primaryText: i18n.t('search:filters.quickGuestsFilter.upTo50', 'Up to 50'),
      minValue: 1,
      maxValue: 50,
    },
    {
      key: '50',
      primaryText: i18n.t('search:filters.quickGuestsFilter.50'),
      minValue: 50,
    },
    {
      key: '80',
      primaryText: i18n.t('search:filters.quickGuestsFilter.80'),
      minValue: 80,
    },
    {
      key: '100',
      primaryText: i18n.t('search:filters.quickGuestsFilter.100'),
      minValue: 100,
    },
    {
      key: '150',
      primaryText: i18n.t('search:filters.quickGuestsFilter.150'),
      minValue: 150,
    },
    {
      key: '200',
      primaryText: i18n.t('search:filters.quickGuestsFilter.200'),
      minValue: 200,
    },
  ];
};
