import { isTodayTaskCompleted } from 'lib/task/selectors';
import { TTaskDefinitionUI, registerTodayTask } from 'lib/task/task-flow-engine';

export const CAKE_QUIZ_TASK_DEFINITION: TTaskDefinitionUI = {
  id: 'dc035ce9-eac9-4272-a350-f7652ca72c12',
  storage: 'wedding',
  name: 'Cakes selection quiz',
  type: 'quiz',
  isTaskCompleted: ({ getState, market }) => {
    const state = getState();
    const suppliers = market.suppliers;

    return !suppliers?.includes('cakes') || isTodayTaskCompleted(state, CAKE_QUIZ_TASK_DEFINITION);
  },
};

registerTodayTask(CAKE_QUIZ_TASK_DEFINITION);
