import { FelaCSS, colors } from '@bridebook/ui';

interface IStylesArgs {
  disableHeadingTopBorder?: boolean;
  disableHeadingTopPadding?: boolean;
}
interface IStyles {
  wrapper: FelaCSS;
  heading: FelaCSS;
  radioGroup: FelaCSS;
  radio: (isChecked: boolean) => FelaCSS;
}

const styles = ({ disableHeadingTopBorder, disableHeadingTopPadding }: IStylesArgs): IStyles => ({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  heading: {
    borderTopWidth: disableHeadingTopBorder ? '0' : '1px',
    borderTopStyle: 'solid',
    borderColor: colors.space15,
    fontDefault: 16,
    marginTop: 0,
    marginBottom: 6,
    paddingTop: disableHeadingTopPadding ? 0 : 6,
    flexDirection: 'row',
  },
  radioGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 16,
    marginBottom: 5,
  },
  radio: (isChecked) => ({
    color: isChecked ? colors.space : colors.space60,
    '> label': {
      fontDefaultSemiBold: 14,
    },
  }),
});

export default styles;
