import namedKeys from 'fela-plugin-named-keys';
import { FelaCSS } from '../components/fela/flowtypes';
import { screenSizes } from '../themes/variables';

const mediaQueryMap = {
  minWidthXxs: `@media (min-width: ${screenSizes.screenXxs}px)`,
  maxWidthXxs: `@media (max-width: ${screenSizes.screenXxs}px)`,
  minWidthXxsMin: `@media (min-width: ${screenSizes.screenXxsMin}px)`,
  maxWidthXxsMin: `@media (max-width: ${screenSizes.screenXxsMin}px)`,
  minWidthXxsMax: `@media (min-width: ${screenSizes.screenXxsMax}px)`,
  maxWidthXxsMax: `@media (max-width: ${screenSizes.screenXxsMax}px)`,
  minWidthXs: `@media (min-width: ${screenSizes.screenXs}px)`,
  maxWidthXs: `@media (max-width: ${screenSizes.screenXs}px)`,
  minWidthXsMin: `@media (min-width: ${screenSizes.screenXsMin}px)`,
  maxWidthXsMin: `@media (max-width: ${screenSizes.screenXsMin}px)`,
  minWidthXsMax: `@media (min-width: ${screenSizes.screenXsMax}px)`,
  maxWidthXsMax: `@media (max-width: ${screenSizes.screenXsMax}px)`,
  minWidthSm: `@media (min-width: ${screenSizes.screenSm}px)`,
  maxWidthSm: `@media (max-width: ${screenSizes.screenSm}px)`,
  minWidthSmMin: `@media (min-width: ${screenSizes.screenSmMin}px)`,
  maxWidthSmMin: `@media (max-width: ${screenSizes.screenSmMin}px)`,
  minWidthSmMax: `@media (min-width: ${screenSizes.screenSmMax}px)`,
  maxWidthSmMax: `@media (max-width: ${screenSizes.screenSmMax}px)`,
  minWidthMd: `@media (min-width: ${screenSizes.screenMd}px)`,
  maxWidthMd: `@media (max-width: ${screenSizes.screenMd}px)`,
  minWidthMdMin: `@media (min-width: ${screenSizes.screenMdMin}px)`,
  maxWidthMdMin: `@media (max-width: ${screenSizes.screenMdMin}px)`,
  minWidthMdMax: `@media (min-width: ${screenSizes.screenMdMax}px)`,
  maxWidthMdMax: `@media (max-width: ${screenSizes.screenMdMax}px)`,
  minWidthLg: `@media (min-width: ${screenSizes.screenLg}px)`,
  maxWidthLg: `@media (max-width: ${screenSizes.screenLg}px)`,
  minWidthLgMin: `@media (min-width: ${screenSizes.screenLgMin}px)`,
  maxWidthLgMin: `@media (max-width: ${screenSizes.screenLgMin}px)`,
  minWidthXLMin: `@media (min-width: ${screenSizes.screenXLMin}px)`,
  maxWidthXLMin: `@media (max-width: ${screenSizes.screenXLMin}px)`,
  minWidthXLMax: `@media (min-width: ${screenSizes.screenXLMax}px)`,
  maxWidthXLMax: `@media (max-width: ${screenSizes.screenXLMax}px)`,
  minWidthXXLMin: `@media (min-width: ${screenSizes.screenXXLMin}px)`,
  maxWidthXXLMin: `@media (max-width: ${screenSizes.screenXXLMin}px)`,
  desktopDesign: `@media only screen and (min-width: ${screenSizes.screenMdMin}px), screen and (orientation:landscape) and (min-width: ${screenSizes.screenSmMin}px)`,
  supportsSafeAreaInset: '@supports (bottom: env(safe-area-inset-bottom))',
  // @see https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
  noTouchDevices: '@media(hover: hover) and (pointer: fine)',
  msEdgeOnly: '@supports (-ms-ime-align: auto)',
  landscape: '@media (orientation: landscape)',
  portrait: '@media (orientation: portrait)',
};

export type FelaNamedMediaQueriesProperties = {
  [Properties in keyof typeof mediaQueryMap]?: FelaCSS;
};

export default namedKeys(mediaQueryMap);
