import { Box, FelaCSS, colors } from '@bridebook/ui';
import IconClose from '@bridebook/ui/src/components/svg/icon-close';
import componentStyles from './modal-close-button.style';

interface IProps {
  onClose?: () => void;
  style?: FelaCSS;
}

const ModalCloseButton = ({ onClose, style }: IProps) => {
  const styles = componentStyles();
  return (
    <Box style={{ ...styles.wrapper, ...style }} onClick={onClose} data-name="close-modal-button">
      <IconClose color={colors.space60} />
    </Box>
  );
};

export default ModalCloseButton;
