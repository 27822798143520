import { CSSProperties } from 'react';
import { FelaCSS, alpha, colors } from '@bridebook/ui';
import { SharedTypes } from './menu-option';

interface IProps extends SharedTypes {}

interface IStyles {
  wrapper: FelaCSS;
  iconWrapper: FelaCSS;
  titleWrap: FelaCSS;
  iconTextWrapper: FelaCSS;
  counter: FelaCSS;
  homeIcon: CSSProperties;
  guestIcon: CSSProperties;
  guestVariantIcon: CSSProperties;
  favouritesIcon: CSSProperties;
  settingsIcon: CSSProperties;
  scrapbookIcon: CSSProperties;
  tickboldIcon: CSSProperties;
  awardbadgeIcon: CSSProperties;
}

const styles = ({ color, first, newOption }: IProps): IStyles => {
  const itemColor = color ? colors[color] : colors.blueCrush;

  const activeStates: FelaCSS = {
    color: itemColor,
    textDecoration: 'none',
  };

  const itemBorder: FelaCSS = first
    ? {}
    : {
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: alpha(colors.white, 10),
      };

  return {
    wrapper: {
      ...itemBorder,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '14px',
      paddingBottom: '14px',
      fontDefaultSemiBold: 16,
      lineHeight: '19px',
      color: colors.white,
      cursor: 'pointer',

      ':hover': {
        ...activeStates,
      },

      ':active': {
        ...activeStates,
      },

      ':focus': {
        ...activeStates,
      },
    },

    iconWrapper: {
      alignItems: 'center',
      width: '34px',
      marginRight: '0',
    },

    titleWrap: {
      marginRight: newOption ? '8px' : '0',
    },

    iconTextWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
    },

    counter: {
      fontDefault: 12,
      background: colors.amber,
      borderRadius: '12px',
      minWidth: '20px',
      height: '20px',
      paddingHorizontal: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    homeIcon: {
      color: colors.rose,
      fontSize: '14px',
    },
    guestIcon: {
      color: colors.yellow,
      fontSize: '14px',
    },
    guestVariantIcon: {
      color: colors.mint,
      fontSize: '16px',
    },
    favouritesIcon: {
      color: colors.blueCrush,
      fontSize: '14px',
    },
    settingsIcon: {
      color: colors.silver,
      fontSize: '13px',
    },
    scrapbookIcon: {
      color: colors.purple,
      fontSize: '14px',
    },
    tickboldIcon: {
      color: colors.mint,
      fontSize: '15px',
      marginBottom: '2px',
    },
    awardbadgeIcon: {
      color: colors.yellow,
      fontSize: '17px',
    },
  };
};

export default styles;
