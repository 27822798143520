import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@bridebook/ui';
import { IconInbox } from '@bridebook/ui/src/icons/dynamic';
import { Link } from 'app-shared/components/link/link';
import TopBarHeaderItem from 'components/app/top-bar/top-bar-header-item';
import { getIsMobile } from 'lib/app/selectors';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { usedHeaderNavigation } from 'lib/ui/actions';
import { getMenu } from 'lib/ui/menu';
import { UrlHelper } from 'lib/url-helper';
import { getInboxUnreadCount } from 'lib/weddings/selectors';
import componentStyles from './top-bar-inbox-icon.style';

const storeEnhancer = connect((state: IApplicationState) => ({
  inboxUnreadCount: getInboxUnreadCount(state),
  isDesktop: state.app.device.isDesktop,
  isMobile: getIsMobile(state),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const TopBarInboxIcon = ({ inboxUnreadCount, isDesktop, dispatch, isMobile }: IProps) => {
  const isNotMobile = !isMobile;
  const styles = componentStyles({ isNotMobile });

  const onClick = () => {
    dispatch(usedHeaderNavigation('messages'));
  };

  if (isNotMobile) {
    return (
      <TopBarHeaderItem
        href={UrlHelper.inbox.main}
        onClick={onClick}
        label={getMenu().inbox.title}
        icon={'messages'}
        endIconText={inboxUnreadCount > 0 && <Box style={styles.badge} />}
        dataName={'top-bar-inbox-icon'}
      />
    );
  } else {
    return (
      <Link href={UrlHelper.inbox.main} legacyBehavior>
        <Box style={styles.wrapper} data-name="top-bar-inbox-icon" onClick={onClick}>
          {isDesktop && <Box style={styles.label}>{getMenu().inbox.title}</Box>}
          <Box style={styles.buttonWrapper}>
            <IconInbox />
            {inboxUnreadCount > 0 && <Box style={styles.badge} />}
          </Box>
        </Box>
      </Link>
    );
  }
};

export default storeEnhancer(TopBarInboxIcon);
