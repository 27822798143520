import { mapUrlSlugToSupplierType } from '@bridebook/toolbox';
import { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { IItem } from 'components/bbcommon/option-list/option-list';
import { getI18n } from 'lib/i18n/getI18n';

export interface IRadiusOption extends IItem {
  key: string;
  primaryText: string;
  value: number;
  countries?: CountryCodes[];
}

export const getDistanceFilterOptions = (
  market?: Market,
  slug?: UrlFriendlySlug,
): IRadiusOption[] => {
  if (!market) {
    return [];
  }

  const i18n = getI18n();

  const supplierType = mapUrlSlugToSupplierType(slug || 'venues');
  const { radius } = market.mappings.search;
  const baseRadius = radius?.[supplierType || 'venue'] ?? radius['_'];

  const isVenue = supplierType === 'venue';

  const options = isVenue
    ? [
        {
          key: '-10',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius - 10,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: -10,
        },
        {
          key: '0',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')} (${i18n.t(
            'search:filters.quickFilterRadiusIncrease.default',
          )})`,
          value: 0,
        },
        {
          key: '10',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 10,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 10,
        },
        {
          key: '25',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 25,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 25,
        },
        {
          key: '40',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 40,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 40,
        },
        {
          key: '60',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 60,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 60,
          excludeCountries: [CountryCodes.GB, CountryCodes.DE, CountryCodes.IE],
        },
      ]
    : [
        {
          key: '-40',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius - 40,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: -40,
        },
        {
          key: '-20',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius - 20,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: -20,
        },
        {
          key: '0',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')} (${i18n.t(
            'search:filters.quickFilterRadiusIncrease.default',
          )})`,
          value: 0,
        },
        {
          key: '20',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 20,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 20,
        },
        {
          key: '40',
          primaryText: `${i18n.t('search:filters.quickFilterRadiusIncrease.label', {
            distance: baseRadius + 40,
          })} ${i18n.t('search:filters.quickFilterRadiusIncrease.unit')}`,
          value: 40,
        },
      ];

  return options.filter(
    (option) =>
      !option.excludeCountries ||
      (market.country != null && !option.excludeCountries.includes(market.country)),
  );
};
