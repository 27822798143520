import Router from 'next/router';
import { addCountryToSearchUrl } from '@bridebook/toolbox/src/add-country-to-search-url';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { URLOpenListenerEvent } from '@capacitor/app';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { countryCodeToLocale } from 'lib/i18n/constants';
import { getIsPathnameRouterLocale } from 'lib/i18n/utils/get-is-pathname-router-locale';
import { MobileAppActionTypes } from 'lib/mobile-app/action-types';
import { getIsLocationSearch } from 'lib/search/utils';

export const hideSplashScreen = () => ({
  type: MobileAppActionTypes.HIDE_SPLASH_SCREEN,
});

export const savePushNotificationsRegistrationToken = (token: string) => ({
  type: 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_START',
  payload: { token },
});

export const registerPushNotificationsError = (error: Error) => ({
  type: 'REGISTER_PUSH_NOTIFICATIONS_ERROR',
  error,
});

export const registerPushNotificationsDeviceError = (error: Error) => ({
  type: 'PUSH_NOTIFICATIONS_REGISTER_DEVICE_ERROR',
  error,
});

export const receivedPushNotification = (notification: PushNotificationSchema) => {
  const wasInBackground = false;

  if (notification.data) {
    const { redirect, countryCode } = notification.data as {
      redirect: string;
      countryCode: CountryCodes;
    };

    const cioDeliveryId = notification.data['CIO-Delivery-ID'];
    const cioDeviceId = notification.data['CIO-Delivery-Token'];

    if (redirect) {
      const isLocationSearch = getIsLocationSearch(redirect);

      if (countryCode) {
        const expectedLocale = countryCodeToLocale[countryCode];

        // Avoid redirecting if the country code sent in the PN does not match
        // the expected locale
        if (expectedLocale && expectedLocale !== Router.locale) {
          return;
        }
      }

      const resolvedPathname =
        isLocationSearch && countryCode ? addCountryToSearchUrl(redirect, countryCode) : redirect;
      const isUrlCorrectLocale = getIsPathnameRouterLocale(resolvedPathname);

      // Avoid redirecting if the locale of the redirect url is not the existing one
      if (!isUrlCorrectLocale) {
        return;
      }

      Router.push(resolvedPathname);
    }

    // CIO-Delivery-ID is empty when using customer-io "Send test".
    // For testing purposes a real customer-io campaign must be triggered, for instance
    // "PN TEST" campaign in bridebook DEV.
    if (cioDeliveryId && cioDeviceId) {
      fetch(`/api/push-notification/mark-as-opened`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: {
            cioDeliveryId,
            cioDeviceId,
          },
        }),
      });
    }
  }

  return {
    type: 'RECEIVED_PUSH_NOTIFICATION',
    payload: { notification, wasInBackground },
  };
};

export const requestPushNotificationPermissions = () => ({
  type: MobileAppActionTypes.REQUEST_PUSH_NOTIFICATION_PERMISSIONS,
});

export const registerPushNotifications = () => ({
  type: MobileAppActionTypes.REGISTER_PUSH_NOTIFICATIONS,
});

export const setPushPrePromptShown = () => ({
  type: MobileAppActionTypes.PUSH_PRE_PROMPT_SHOWN,
});

export const registerTalkJsDevice = (payload: { token: string }) => ({
  type: MobileAppActionTypes.REGISTER_TALKJS_DEVICE,
  payload,
});

export const appUrlOpenListenerRegistered = () => ({
  type: MobileAppActionTypes.APP_URL_OPEN_LISTENER_REGISTERED,
});

export const appOpenedFromUrl = (payload: URLOpenListenerEvent) => ({
  type: MobileAppActionTypes.APP_OPENED_FROM_URL,
  payload,
});

export const resetStatusBar = () => {
  window.StatusBar?.hide();
  window.StatusBar?.show();
};
