/**
 * User Scoped Storage Manager.
 * Can be used in any place.
 */
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { IMigration, migrateLocalStorage } from '../utils/local-storage-migrator';

export interface IUserScopedItem<D> {
  data: D;
}

export const UserScopedWithMigrationsStorageManager = <T extends object>({
  storageKey,
  uid,
  migrations,
}: {
  storageKey: string;
  uid: string;
  migrations?: (userSpecificStorageKey: string) => IMigration[];
}) => {
  const userSpecificStorageKey = `${storageKey}_${uid}`;
  return {
    set: (item: T): void => {
      if (typeof window === 'undefined' || !window.localStorage) {
        return;
      }
      const savedObject: IUserScopedItem<T> = {
        data: item,
      };
      window.localStorage.setItem(userSpecificStorageKey, JSON.stringify(savedObject));
    },

    get: (): T | null => {
      if (typeof window === 'undefined' || !window.localStorage) {
        return null;
      }
      try {
        const item = window.localStorage.getItem(userSpecificStorageKey);
        if (item) {
          const savedObject: IUserScopedItem<T> = JSON.parse(item);
          return savedObject?.data;
        }
        return null;
      } catch (e) {
        const errorMessage = `UserScopedVersionableStorageManager Error parsing state from local storage; storageKey ${storageKey}`;
        //Wrapping SentryMinimal in try-catch as we are not sure if sentry is available in all frontends
        try {
          SentryMinimal().captureMessage(errorMessage);
        } catch (e) {
          console.error(errorMessage);
        }
        return null;
      }
    },
    migrate: () => {
      // do not run migration server side
      if (typeof window === 'undefined' || !window.localStorage) {
        return;
      }
      const migrationStorageKey = `appliedMigrations_${userSpecificStorageKey}`;
      if (migrations) {
        migrateLocalStorage(migrations, migrationStorageKey, userSpecificStorageKey);
      }
    },
  };
};
