import React from 'react';

const checkedTenTasks = (width, height) => (
  <svg
    width={width ? width : '144'}
    height={height ? height : '156'}
    viewBox="0 0 121 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M112.541 31.9418L112.547 31.9456L112.554 31.9493C114.888 33.2357 116.25 35.5841 116.25 38.055V91.9677C116.25 94.4386 114.888 96.787 112.554 98.0733L112.548 98.0768L112.542 98.0802L64.0836 125.018C64.082 125.019 64.0804 125.02 64.0787 125.021C61.7069 126.326 58.7931 126.326 56.4213 125.021C56.4197 125.02 56.418 125.019 56.4164 125.018L7.95821 98.0802L7.952 98.0768L7.94579 98.0733C5.61235 96.787 4.25 94.4385 4.25 91.9677V38.055C4.25 35.5841 5.61235 33.2357 7.94578 31.9493L7.95263 31.9456L7.95946 31.9418L56.4164 4.98168C56.4184 4.98056 56.4204 4.97945 56.4224 4.97834C58.7937 3.67389 61.7063 3.67389 64.0776 4.97834C64.0796 4.97945 64.0816 4.98056 64.0836 4.98168L112.541 31.9418Z"
      fill="#7597DF"
      stroke="#CBD8F3"
      strokeWidth="8"
    />
    <rect x="50.25" y="82.0002" width="30" height="4" fill="#CBD8F3" />
    <path
      d="M74.4565 80.7346C74.5359 80.3855 75.3772 75.5861 75.4565 75.2346C77.4375 64.4779 77.0714 54.0737 74.113 43.5521C73.9656 43.0641 73.6676 42.6376 73.2633 42.3356C72.859 42.0335 72.3698 41.8721 71.8683 41.8753H46.407C46.0472 41.8769 45.6927 41.9631 45.3711 42.1272C45.0495 42.2914 44.7696 42.529 44.5532 42.8215C44.3367 43.114 44.1897 43.4534 44.1235 43.8134C44.0573 44.1733 44.0738 44.5439 44.1717 44.8963C49.8977 65.2406 43.25 76.1109 43.25 83.4378C43.2509 83.9438 43.3577 84.4438 43.5633 84.9045C43.7689 85.3652 44.0685 85.7761 44.4423 86.1097C44.816 86.4433 45.2553 86.6921 45.7309 86.8394C46.2065 86.9868 46.7075 87.0293 47.2005 86.9641C47.6935 86.899 48.1672 86.7277 48.59 86.4617C49.0129 86.1957 49.3753 85.841 49.653 85.4213C49.9307 85.0017 50.1174 84.5266 50.2006 84.0277C50.2838 83.5288 50.2617 83.0177 50.1357 82.5282C50.0975 82.3801 50.0932 82.2252 50.1231 82.0752C50.1529 81.9252 50.2161 81.7842 50.3077 81.6629C50.3994 81.5416 50.5172 81.4433 50.652 81.3755C50.7867 81.3077 50.935 81.2722 51.0853 81.2718H79.3187C79.4995 81.2719 79.6768 81.3227 79.8312 81.4187C79.9855 81.5146 80.111 81.652 80.1937 81.8157C80.4666 82.3589 80.5979 82.9643 80.575 83.5739C80.5521 84.1836 80.3757 84.7771 80.0628 85.2974C79.75 85.8178 79.311 86.2477 78.7881 86.5459C78.2651 86.844 77.6757 87.0005 77.0763 87.0003H46.743"
      fill="#EEF2FB"
    />
    <path
      d="M74.4565 80.7346C74.5359 80.3855 75.3772 75.5861 75.4565 75.2346C77.4375 64.4779 77.0714 54.0737 74.113 43.5521C73.9656 43.0641 73.6676 42.6376 73.2633 42.3356C72.859 42.0335 72.3698 41.8721 71.8683 41.8753H46.407C46.0472 41.8769 45.6927 41.9631 45.3711 42.1272C45.0495 42.2914 44.7696 42.529 44.5532 42.8215C44.3367 43.114 44.1897 43.4534 44.1235 43.8134C44.0573 44.1733 44.0738 44.5439 44.1717 44.8963C49.8977 65.2406 43.25 76.1109 43.25 83.4378C43.2509 83.9438 43.3577 84.4438 43.5633 84.9045C43.7689 85.3652 44.0685 85.7761 44.4423 86.1097C44.816 86.4433 45.2553 86.6921 45.7309 86.8394C46.2065 86.9868 46.7075 87.0293 47.2005 86.9641C47.6935 86.899 48.1672 86.7277 48.59 86.4617C49.0129 86.1957 49.3753 85.841 49.653 85.4213C49.9307 85.0017 50.1174 84.5266 50.2006 84.0277C50.2838 83.5288 50.2617 83.0177 50.1357 82.5282C50.0975 82.3801 50.0932 82.2252 50.1231 82.0752C50.1529 81.9252 50.2161 81.7842 50.3077 81.6629C50.3994 81.5416 50.5172 81.4433 50.652 81.3755C50.7867 81.3077 50.935 81.2722 51.0853 81.2718H79.3187C79.4995 81.2719 79.6769 81.3227 79.8312 81.4187C79.9855 81.5146 80.111 81.652 80.1937 81.8157C80.4666 82.3589 80.5979 82.9643 80.575 83.5739C80.5521 84.1836 80.3757 84.7771 80.0628 85.2974C79.75 85.8178 79.311 86.2477 78.7881 86.5459C78.2651 86.844 77.6757 87.0005 77.0763 87.0003H46.743"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.9648 51.9316L55.5105 55.0999L59.9648 49.5566"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.7139 61.4316L56.2595 64.5999L60.7139 59.0566"
      stroke="#98B1E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.7031 70.9316L55.2488 74.0999L59.7031 68.5566"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.2979 53.5159H71.6312"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.0469 63.0159H72.3802"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.0371 72.5159H71.3704"
      stroke="#324B81"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default checkedTenTasks;
