import React from 'react';
import { FelaCSS } from '../../components/fela/flowtypes';
import { IStylingProps } from '../../themes/types';
import { TColor } from '../../types';
import IconWrap from './icon-wrap';

interface IProps extends IStylingProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

/**
 * @deprecated
 */
const IconInbox = ({ id, color = 'white', width = 24, style, ...restProps }: IProps) => {
  const viewBoxWidth = 24;
  const viewBoxHeight = 24;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 14.7308V8C1 6.34314 2.34314 5 4 5H16.61C18.2668 5 19.61 6.34315 19.61 8V14.7308C19.61 16.3876 18.2668 17.7308 16.61 17.7308H15.6056C14.9651 17.7308 14.3414 17.9358 13.8257 18.3158L8.79908 22.0207V20.7308C8.79908 19.0739 7.45594 17.7308 5.79908 17.7308H4C2.34315 17.7308 1 16.3876 1 14.7308Z"
        stroke={iconColor}
        strokeWidth="2"
      />
      <path
        d="M5.57678 10H13.5436M5.57678 13.2H10.0028"
        stroke={iconColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 13.5V13.5C21.2091 13.5 23 11.7091 23 9.5V5C23 2.79086 21.2091 1 19 1H8.80769C6.70476 1 5 2.70476 5 4.80769V4.80769"
        stroke={iconColor}
        strokeWidth="2"
      />
    </svg>
  );

  return (
    <IconWrap
      icon={icon}
      color={color}
      width={width}
      viewBoxWidth={viewBoxWidth}
      viewBoxHeight={viewBoxHeight}
      style={style}
      {...restProps}
    />
  );
};

export default IconInbox;
