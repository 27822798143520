import { ISupplier as IDBWeddingSupplier } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { mapSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { IWeddingSupplier } from '@bridebook/toolbox/src/types';
import { TShortlistList } from 'lib/shortlist/types';
import { enrichWeddingSuppliers, mapSlug } from 'lib/shortlist/utils';
import { sortByCreatedAt } from 'lib/utils';

export const mapWeddingSuppliersSnapshotToShortlistData = async (
  suppliersList: Record<string, IDBWeddingSupplier>,
): Promise<TShortlistList> => {
  const weddingSuppliers: IWeddingSupplier[] =
    sortByCreatedAt(mapSerializeTimestamps(mapSlug(suppliersList))) ?? [];

  const enrichedSuppliers = await enrichWeddingSuppliers(weddingSuppliers);
  const { shortlisted } = weddingSuppliers.reduce(
    (acc, supplier) => {
      const enrichedSupplier = enrichedSuppliers[supplier.id];
      if (!enrichedSupplier) return acc;
      if (supplier.shortlisted) acc.shortlisted[supplier.id] = enrichedSupplier;
      return acc;
    },
    { shortlisted: {} as TShortlistList },
  );

  return shortlisted;
};
