import invariant from 'invariant';
import { compose, filter, prop, sortBy, values } from 'ramda';
import { KeyboardEventHandler } from 'react';
import type { Slug } from '@bridebook/toolbox/src/types';
import { getMenu } from 'lib/ui/menu';
import { MenuOptionTypes, MenuOptionsObjectTypes } from 'lib/ui/types';

/**
 * Input field event handler.
 * Allows to input only numbers
 *
 * @function onlyNumberKey
 * @param {object} - event
 */
export const onlyNumberKey = (event: any) => {
  const charCode = event.code;
  const charStr = event.key;
  const serviceKeys = ['Backspace', 'Tab', 'Escape', 'Delete', 'ArrowLeft', 'ArrowRight'];
  if (!serviceKeys.includes(charCode) && !/^\d+$/.test(charStr)) {
    event.preventDefault();
  }
};

// TODO: @jury write docs
export const isNumberKeyBool = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode;
  const ok = !(
    (charCode > 31 && (charCode < 48 || charCode > 57)) ||
    (event.keyCode >= 96 && event.keyCode <= 105)
  );

  return ok;
};

// TODO: @jury write docs
export const isNumberKey = (event: any) => {
  if (!isNumberKeyBool(event)) event.preventDefault();
};

/**
 * Function `menuFilter` returns array of menu items.
 */
export const menuFilter = (showTypes: Array<string>, hasDirectory?: boolean): MenuOptionTypes[] => {
  invariant(Array.isArray(showTypes), 'Expected showTypes to be array.');
  const menu = getMenu();
  return compose<MenuOptionsObjectTypes, MenuOptionTypes[], MenuOptionTypes[], MenuOptionTypes[]>(
    filter(({ type }: MenuOptionTypes) => !!(type && showTypes.includes(type))),
    sortBy(hasDirectory ? prop('staticIndex') : prop('staticIndexI18n') || prop('staticIndex')),
    values,
  )(menu);
};

/**
 * Function `getDefaultImage` returns thumbnail path based on supplier slug
 *
 * @function getDefaultImage
 * @param {String} - supplierslug
 * @returns {String} - thumbnail image path
 */
export const getDefaultImage = (slug: Slug | string) => {
  let img = '';
  switch (slug) {
    case 'venue':
      img = 'assets/home/venues-home-block';
      break;
    case 'photo':
      img = 'assets/home/photographers-home-tile';
      break;
    case 'catering':
      img = 'assets/home/caterers-home-tile';
      break;
    case 'cakes':
      img = 'assets/home/cakes-home-tile';
      break;
    case 'florist':
      img = 'assets/home/florists-home-tile';
      break;
    case 'transport':
      img = 'initial/transport/transport-vintage-wedding-car-splitshire';
      break;
    case 'music':
      img = 'initial/music/music-guitarist-picjumbo';
      break;
    case 'entertainment':
      img = 'initial/entertainment/entertainment-candy-carts-liquorice-pixabay';
      break;
    case 'jewellery':
      img = 'initial/jewellery/jewellery-Nail-art-kaboompics';
      break;
    case 'menswear':
      img = 'initial/menswear/menswear-white-suite-pixabay';
      break;
    case 'dress':
      img = 'stock/venue/venue-wedding-ceremony-stocksnap';
      break;
    case 'planners':
      img = 'initial/planners/planners-book-and-heart-kaboompics';
      break;
    case 'video':
      img = 'initial/video/video-converse-pixabay';
      break;
    case 'stationery':
      img = 'initial/stationery/stationery-hyacinth-pixabay';
      break;
    case 'decoration':
      img = 'initial/decoration/decoration-balloons-pixabay';
      break;
    case 'marquee':
      img = 'initial/marquee/marquee-aisle-pixabay';
      break;
    case 'beauty':
      img = 'initial/beauty/beauty-bride-black-and-white-pixabay';
      break;
    default:
      img = 'assets/home/venues-home-block';
  }
  return img;
};

export const preventSubmit: KeyboardEventHandler = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    return false;
  }
  return true;
};

export const colors = {
  white: '#FFFFFF',
  'mine-shaft': '#4A4A4A',
  silver: '#9B9B9B',
  alto: '#BFBFBF',
  dust: '#DAD8D8',
  grey: '#E2E2E2',
  soft: '#F0F0F0',
  light: '#F7F7F7',
  'pale-grey': '#F7F8FA',
  forest: '#0EA675',
  mint: '#2DBAA7',
  amber: '#DD5A59',
  yellow: '#E7BE3E',
  space: '#1A2C40',
  'blue-crush': '#537DD7',
  rose: '#F27084',
  'facebook-blue': '#3b5998',
  'youtube-red': '#c51a18',
  'twitter-blue': '#32506d',
  'vimeo-blue': '#1cb1e6',
  'linkedIn-blue': '#187fb8',
  'google-red': '#ea4335',
  'instagram-color': '#517fa4',
  'pinterest-color': '#c41618',
  'blog-orange': '#f6831f',
  'yellow-soft': '#fefaf0',
};

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `
    ${parseInt(result[1], 16)},
    ${parseInt(result[2], 16)},
    ${parseInt(result[3], 16)}
  `
    : null;
};

export const getCarouselDirection = (currentSlide: number, index: number) =>
  Math.abs(currentSlide - index) === 1
    ? currentSlide - index > 0
      ? 'left'
      : 'right'
    : currentSlide < index
    ? 'left'
    : 'right';
