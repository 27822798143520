export interface IMigration {
  name: string;
  up: () => void;
}

export interface IAppliedMigration {
  name: string;
  dateApplied: Date;
}

export function migrate(
  migrations: IMigration[],
  appliedMigrations: IAppliedMigration[],
): IAppliedMigration[] {
  const migrationsToRun = migrations.filter(
    (m) => appliedMigrations.filter((a) => a.name === m.name).length === 0,
  );
  const appliedMigrationsThisTime = [] as IAppliedMigration[];
  migrationsToRun.forEach((m) => {
    m.up();
    appliedMigrationsThisTime.push({
      name: m.name,
      dateApplied: new Date(),
    });
  });
  return appliedMigrationsThisTime;
}

function reviver(key: string, value: any) {
  if (key === 'dateApplied') {
    return new Date(value);
  }
  return value;
}

export function getAppliedMigrations(storageKey: string): IAppliedMigration[] {
  const value = localStorage.getItem(storageKey);
  const parsedValue = value && (JSON.parse(value, reviver) as IAppliedMigration[]);
  return parsedValue || ([] as IAppliedMigration[]);
}

export function storeAppliedMigrations(
  appliedMigrations: IAppliedMigration[],
  storageKey: string,
): void {
  localStorage.setItem(storageKey, JSON.stringify(appliedMigrations));
}

export function migrateLocalStorage(
  migrations: (dataStorageKey: string) => IMigration[],
  migrationsStorageKey: string,
  dataStorageKey: string,
) {
  const appliedMigrations = getAppliedMigrations(migrationsStorageKey);
  const appliedMigrationsThisTime = migrate(migrations(dataStorageKey), appliedMigrations);
  storeAppliedMigrations(appliedMigrations.concat(appliedMigrationsThisTime), migrationsStorageKey);
}
