import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { RWState, RealWedding, ViewedLocation } from './types';

const initialState: RWState = {
  list: [],
  listType: null,
  loading: false,
  loaded: false,
  error: null,
};

const name = 'realWeddings';

const realWeddingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchRealWeddings: (state, action: PayloadAction<RWState['listType']>) => {
      state.loading = true;
      state.listType = action.payload;
      state.loaded = false;
    },
    fetchRealWeddingsSuccess: (state, action: PayloadAction<RealWedding[]>) => {
      if (state.loading) {
        state.list = action.payload;
        state.loading = false;
        state.error = null;
        state.loaded = true;
      }
    },
    fetchRealWeddingsError: (state, action: PayloadAction<Error>) => {
      state.list = [];
      state.loading = false;
      state.error = action.payload;
      state.loaded = false;
    },
    clearRealWeddings: (state) => {
      state.list = [];
      state.loading = false;
      state.error = null;
      state.listType = null;
      state.loaded = false;
    },
  },
});

type CommonAnalytics = {
  realWedding: RealWedding;
  viewedLocation: ViewedLocation;
};

export const trackClickedRealWedding = createAction<CommonAnalytics>(
  `${name}/trackClickedRealWedding`,
);

export const trackViewedRealWedding = createAction<CommonAnalytics>(
  `${name}/trackViewedRealWedding`,
);

export const {
  clearRealWeddings,
  fetchRealWeddings,
  fetchRealWeddingsError,
  fetchRealWeddingsSuccess,
} = realWeddingsSlice.actions;
export default realWeddingsSlice.reducer;
