import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@bridebook/ui';
import getTopBarPageTitle from 'lib/app/utils/get-top-bar-page-title';
import { getSearchRequestSlug } from 'lib/search/selectors';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { truncate } from 'lib/utils';
import componentStyles from './top-bar-page-title.style';

const storeEnhancer = connect((state: IApplicationState) => ({
  serverPage: state.app.serverPage,
  slug: getSearchRequestSlug(state),
  supplierName: state.supplier.supplier.data?.name || '',
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {}

const TopBarPageTitle = ({ serverPage, slug, supplierName }: IProps) => {
  const pageTitle = getTopBarPageTitle({
    serverPage,
    actualCategory: slug ?? '',
    supplierName,
  });

  return <Box style={componentStyles}>{truncate(pageTitle, 23)}</Box>;
};
export default storeEnhancer(TopBarPageTitle);
