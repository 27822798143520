import { FelaCSS } from '@bridebook/ui';

interface IStyles {
  iconTabWrap: FelaCSS;
}

const styles: IStyles = {
  iconTabWrap: {
    paddingBottom: 1,
    paddingTop: 2,
    paddingHorizontal: 2,
  },
};

export default styles;
