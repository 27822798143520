import { useTranslation } from 'next-i18next';
import React from 'react';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { Box, ButtonV2 } from '@bridebook/ui';
import { Link } from 'app-shared/components/link/link';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import PopupRounded from 'components/generic/popup-rounded/popup-rounded';
import { UrlHelper } from 'lib/url-helper';
import { useDispatch, useSelector } from 'lib/utils';
import { closeBookingConfirmationModal } from 'lib/venue-confirmation/actions';
import { getSupplierToBook } from 'lib/venue-confirmation/selectors';
import styles from './global-venue-modal-info.style';

const GlobalVenueModalInfo = () => {
  const { t } = useTranslation('venueConfirmation');
  const market = useMarket();
  const countryName = market.getCountryName();
  const dispatch = useDispatch();
  const supplierToBook = useSelector(getSupplierToBook);
  if (!supplierToBook) return null;
  const supplierToBookCountryCode =
    supplierToBook.countryCode ?? supplierToBook.l10n?.country ?? market.country;

  const supplierCountryName =
    supplierToBook?.countryCode &&
    Gazetteer.getCountryName(supplierToBookCountryCode, market.locale);

  const handleOnClose = () => {
    dispatch(closeBookingConfirmationModal());
  };

  return (
    <PopupRounded
      show={true}
      modalStyle={styles.wrapper}
      onClose={handleOnClose}
      id={'budget-country-change-info'}>
      <Box style={styles.title}>
        {t('globalVenueModal.title', {
          supplier: supplierToBook?.name,
          currentCountry: countryName,
        })}
      </Box>
      <Box style={styles.description}>
        {t('globalVenueModal.description', {
          supplier: supplierToBook?.name,
          suppliersCountry: supplierCountryName,
        })}
      </Box>

      <Box pt={10}>
        <Link href={UrlHelper.settings.main}>
          <ButtonV2 onClick={handleOnClose}>{t('globalVenueModal.cta')}</ButtonV2>
        </Link>
      </Box>
    </PopupRounded>
  );
};

export default GlobalVenueModalInfo;
