import {
  addCordovaContacts,
  addMultipleGuests,
  addPlusOne,
  deleteGuest,
  editGuest,
  editGuestAddress,
  editGuestContacts,
  editInvitationType,
  editNewGuest,
  editRsvpStatus,
  guestAddressManualEnter,
  guestSearchToggle,
  guestlistSetFilter,
  onFirebaseGuestlist,
  onGuestSearch,
  removePlusOne,
  resetGuestlist,
  saveCustomCategory,
  saveCustomCategoryDone,
  saveGuestForm,
  setGuestLocation,
  toggleAddGuestsPopup,
  toggleCategoryDetailsPopup,
  toggleGuestCollectorPopup,
  toggleGuestInvitationType,
  toggleGuestRsvpStatus,
  toggleGuestlistFilters,
} from 'lib/guestlist/actions';
import { toggleImportContactsView } from 'lib/guestlist/actions/import-contacts';

export enum GuestlistActionTypes {
  INIT_LISTENER = '[Guestlist] Init listener',
  STOP_LISTENER = '[Guestlist] Stop listener',
  SAVE_CUSTOM_CATEGORY = '[Guestlist] Save custom category',
  SAVE_CUSTOM_CATEGORY_DONE = '[Guestlist] Save custom category done',
  DELETE_CUSTOM_CATEGORY = '[Guestlist] Delete custom category',
  DELETE_CUSTOM_CATEGORY_ERROR = '[Guestlist] Delete custom category error',
  UPDATED_GUEST_CATEGORY_ANALYTICS = '[Analytics] Updated guestlist category',
  ON_FIREBASE_GUESTLIST = '[Guestlist] On guestlist Firebase',
  UPDATED_GUESTS_COUNT = '[Guestlist] Updated guests count',
  EDIT_GUEST = '[Guestlist] Edit guest',
  EDIT_GUEST_INVITATION_TYPE = '[Guestlist] Edit guest invitation type',
  EDIT_GUEST_RSVP_STATUS = '[Guestlist] Edit guest RSVP status',
  EDIT_GUEST_CONTACTS = '[Guestlist] Edit guest contact details',
  EDIT_GUEST_ADDRESS = '[Guestlist] Edit guest address',
  EDIT_GUEST_FORM = '[Guestlist] Edit guest form',
  SAVE_GUEST_FORM = '[Guestlist] Save guest form',
  SAVE_GUEST_SUCCESS = '[Guestlist] Save guest success',
  CANCEL_EDIT_GUEST = '[Guestlist] Cancel edit guest',
  CANCEL_EDIT_GUEST_ANALYTICS = '[Analytics] Cancel edit guest',
  DELETE_GUEST = '[Guestlist] Delete guest',
  DELETE_GUEST_SUCCESS = '[Guestlist] Delete guest success',
  DELETE_GUEST_ERROR = '[Guestlist] Delete guest error',
  SET_GUEST_LOCATION = '[Guestlist] Set guest location',
  ADD_PLUS_ONE = '[Guestlist] Add plus one',
  REMOVE_PLUS_ONE = '[Guestlist] Remove plus one',
  CREATE_PLUS_ONE_AND_ASSIGN = '[Guestlist] Create new plus one and assign',
  ON_GUEST_SEARCH = '[Guestlist] On guest search',
  SAVE_CUSTOM_PLUS_ONE = '[Guestlist] Save custom plus one',
  GUESTLIST_SET_FILTER = '[Guestlist] Set filter',
  GUESTLIST_TOGGLE_FILTERS = '[Guestlist] Toggle filters',
  GUESTLIST_ADDRESS_MANUAL_ENTER = '[Guestlist] Address manual enter',
  MAKE_GROUP_HEAD = '[Guestlist] Make group head',
  GUESTLIST_FILTER_RESET = '[Guestlist] Filter reset',
  SAVE_GUEST_DRAFT = '[Guestlist] Save guest draft',
  SET_GUEST_RSVP_STATUS = '[Guestlist] Set guest RSVP status',
  SET_GUEST_INVITATION_TYPE = '[Guestlist] Set guest invitation type',
  TOGGLE_CATEGORY_DETAILS_POPUP = '[Guestlist] Toggle category details popup',
  TOGGLE_ADD_GUESTS_POPUP = '[Guestlist] Toggle add guests popup',
  ADD_MULTIPLE_GUESTS = '[Guestlist] Add multiple guests',
  EXPORT_GUESTLIST_CSV = '[Guestlist] Export CSV',
  TRIGGER_RESET_GUESTLIST = '[Guestlist] Trigger reset guestlist',
  RESET_GUESTLIST = '[Guestlist] Reset guestlist',
  GUEST_SEARCH_TOGGLE = '[Guestlist] Search toggle',
  SHOW_ERROR = '[Guestlist] Show error',
  TOGGLE_IMPORT_CONTACTS_VIEW = '[Guestlist] Toggle import contacts view',
  ADD_CORDOVA_CONTACTS = '[Guestlist] Add Cordova contacts',
  INIT_EMPTY_GUESTLIST = '[Guestlist] Init empty guestlist',
  SAVE_GUEST_ERROR = '[Guestlist] Save guest error',
  TOGGLE_GUEST_COLLECTOR_POPUP = '[Guestlist] Toggle guest collector popup',
  TOGGLE_FREE_GUESTLIST_EXPIRED_MODAL = '[Guestlist] Toggle free guestlist expired modal',
}

export type ISaveCustomCategoryAction = ReturnType<typeof saveCustomCategory>;

export type ISaveCustomCategoryDoneAction = ReturnType<typeof saveCustomCategoryDone>;

export type IOnFirebaseGuestlistAction = ReturnType<typeof onFirebaseGuestlist>;

export type IEditGuestAction = ReturnType<typeof editNewGuest>;

export type IEditInvitationTypeAction = ReturnType<typeof editInvitationType>;

export type IEditRsvpStatusAction = ReturnType<typeof editRsvpStatus>;

export type IEditGuestContactsAction = ReturnType<typeof editGuestContacts>;

export type IEditGuestAddressAction = ReturnType<typeof editGuestAddress>;

export type IEditGuestFormAction = ReturnType<ReturnType<typeof editGuest>>;

export type ISaveGuestFormAction = ReturnType<typeof saveGuestForm>;

export type ISetGuestLocationAction = ReturnType<typeof setGuestLocation>;

export type IAddPlusOneAction = ReturnType<typeof addPlusOne>;

export type IRemovePlusOneAction = ReturnType<typeof removePlusOne>;

export type IOnGuestSearchAction = ReturnType<typeof onGuestSearch>;

export type IGuestlistSetFilterAction = ReturnType<ReturnType<typeof guestlistSetFilter>>;

export type IGuestlistSearchToggleAction = ReturnType<ReturnType<typeof guestSearchToggle>>;

export type IGuestlistToggleFiltersAction = ReturnType<ReturnType<typeof toggleGuestlistFilters>>;

export type IGuestlistAddressManualEnterAction = ReturnType<typeof guestAddressManualEnter>;

export type ISetGuestRsvpStatusAction = ReturnType<typeof toggleGuestRsvpStatus>;

export type ISetGuestInvitationTypeAction = ReturnType<typeof toggleGuestInvitationType>;

export type IToggleCategoryDetailsPopupAction = ReturnType<
  ReturnType<typeof toggleCategoryDetailsPopup>
>;

export type IToggleAddGuestsPopupAction = ReturnType<ReturnType<typeof toggleAddGuestsPopup>>;

export type IAddMultipleGuestsAction = ReturnType<ReturnType<typeof addMultipleGuests>>;

export type IDeleteGuestAction = ReturnType<typeof deleteGuest>;

export type IResetGuestlistAction = ReturnType<typeof resetGuestlist>;

export type IToggleImportContactsViewAction = ReturnType<
  ReturnType<typeof toggleImportContactsView>
>;

export type IAddCordovaContactsAction = ReturnType<typeof addCordovaContacts>;

export type IToggleGuestCollectorPopupAction = ReturnType<
  ReturnType<typeof toggleGuestCollectorPopup>
>;
