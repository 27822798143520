import type { ISupplier_PricingIndicator } from '@bridebook/models/source/models/Suppliers.types';
import type { IItem } from 'components/bbcommon/option-list/option-list';
import msg, { MsgKey } from 'lib/supplier/msg';

export interface IPricingOption extends IItem {
  key: string;
  primaryText: string;
  value: ISupplier_PricingIndicator;
}

export const getPricingFilterOptions = (): IPricingOption[] =>
  (
    [
      ['pricingAffordable', '$'],
      ['pricingModerate', '$$'],
      ['pricingLuxury', '$$$'],
      ['pricingSuperLuxury', '$$$$'],
    ] as const
  ).map(([key, value]) => ({
    key,
    primaryText: msg[key as MsgKey](),
    value,
  }));
